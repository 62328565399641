import { appSettings } from 'AppSettings';
import { orderBy } from 'lodash';

export interface ConsentEntitlement {
  consentEntitlementId: string;
  description: string;
  selected: boolean;
}

export async function GetConsentEntitlements(accessToken: string): Promise<ConsentEntitlement[]> {
  let consentEntitlements: ConsentEntitlement[] = [];
  if (accessToken) {
    try {
      const response = await fetch(`${appSettings.api.endpoint}/api/v2/ConsentEntitlements`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      if (response.ok) {
        const values: ConsentEntitlement[] = await response.json();
        values.forEach(value => (value.selected = true));
        consentEntitlements = [...orderBy(values, [(c: ConsentEntitlement) => c.description.toLowerCase()], ['asc'])];
      }
    } catch (err) {
      console.dir(err);
    }
  }
  return consentEntitlements;
}
