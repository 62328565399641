import { Box, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { DialogCloseButton } from '../../components/DialogCloseButton';
import { DialogOpenButton } from '../../components/DialogOpenButton';
import InputIcon from '@mui/icons-material/Input';
import useMemoTranslation from '../../hooks/UseMemoTranslation';
import { CancelButton } from '../../components/CancelButton';
import { PrimaryButton } from '../../components/PrimaryButton';

export interface SampleTrackingRowSelectionModalProps {
  onSubmit?: (ids: ReadonlyArray<string>) => void;
}

export const SampleTrackingRowSelectionModal = ({ onSubmit = () => {} }: SampleTrackingRowSelectionModalProps) => {
  const { t } = useMemoTranslation();

  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [stringData, setStringData] = useState<string>('');
  const [parsedData, setParsedData] = useState<ReadonlyArray<string>>([]);

  useEffect(() => {
    const data: string[] = [];

    stringData.split('\n').forEach(rowString => {
      if (!rowString || rowString.length === 0) {
        return;
      }

      data.push(rowString.trim());
    });

    setParsedData(data);
  }, [stringData]);

  function handleClose() {
    setModalOpen(false);
    setStringData('');
    setParsedData([]);
  }

  return (
    <>
      <DialogOpenButton title={t('selectSamples')} onClick={() => setModalOpen(true)}>
        <InputIcon />
      </DialogOpenButton>
      <Dialog open={modalOpen} fullWidth={true} maxWidth={'sm'}>
        <DialogTitle>
          <DialogCloseButton onClick={() => handleClose()} />
          {t('selectSamples')}
          <Typography variant='subtitle1'>{t('selectRowsInfo')}</Typography>
        </DialogTitle>
        <DialogContent>
          <Box component='form' sx={{ marginTop: 2 }}>
            <TextField
              multiline
              fullWidth
              label={t('ids')}
              type='text'
              onChange={event => setStringData(event.target.value)}
              placeholder={t('sampleBbidOrSampleForeignHashOrLabAssignedId')}
              rows={10}
              autoFocus
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <CancelButton onClick={handleClose} />
          <PrimaryButton
            onClick={() => {
              onSubmit(parsedData);
              handleClose();
            }}
            disabled={parsedData.length === 0}
          >
            {t('submit')}
          </PrimaryButton>
        </DialogActions>
      </Dialog>
    </>
  );
};
