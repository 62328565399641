import { DataGridPro, GridColDef, useGridApiRef } from '@mui/x-data-grid-pro';
import useAuth from 'auth/UseAuth';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  destinationStorageTemperatureId,
  extractionKitId,
  extractionPlatformId,
  extractionType,
  libraryKitId,
  minimumSampleVolume,
  minimumSampleVolumeUnitId,
  orderedAt,
  organizationId,
  primaryShippingContainerId,
  receiverOrganizationId,
  receivingCarrierId,
  receivingDate,
  senderOrganizationId,
  sequencingExpectedCoverage,
  sequencingPlatformId,
  shipByDate,
  shippingCarrierId,
  shippingStorageTemperatureId,
  subsettingType,
  trackingNumber,
  transition,
  transitionId,
} from '../../../util/Constants';
import { keyBy } from 'lodash';
import {
  ExtractionKit,
  ExtractionPlatform,
  ExtractionType,
  GetExtractionKit,
  GetExtractionPlatform,
  GetExtractionType,
  GetLibraryKit,
  getOrganizationLabel,
  GetOrganizations,
  GetSequencingExpectedCoverage,
  GetSequencingPlatform,
  GetShippingCarrier,
  GetShippingContainer,
  GetShippingTemperature,
  GetSubsetType,
  GetVolumeUnit,
  LibraryKit,
  Organization,
  SequencingExpectedCoverage,
  SequencingPlatform,
  ShippingCarrier,
  ShippingContainer,
  ShippingTemperature,
  SubsetType,
  VolumeUnit,
} from '../../../data/ReferenceData';
import { Dictionary } from '../../../util/TypeUtil';
import { SampleTrackingOrderRow } from './SampleTrackingOrderGrid';
import { TransitionType } from '../../../data/SampleTrackingData';
import { ErrorManagement, LoadingProps, LoadingState, LoadState } from '../../../components/LoadingStateUtil';
import { LoadingIndicator } from '../../../components/LoadingIndicator';
import { ErrorIndicator } from '../../../components/ErrorIndicator';
import useMemoTranslation from '../../../hooks/UseMemoTranslation';
import { truncateGuid } from '../../../data/SampleData';
import { CompactGridWrapper } from '../../../components/grid/CompactGridWrapper';
import { GridExportButton } from '../../../components/GridExportButton';
import { FlexBox } from '../../../components/FlexBox';

export interface SampleTrackingOrderGridDetailsProps {
  transitionType: TransitionType;
  row: SampleTrackingOrderRow;
}

export const SampleTrackingOrderGridDetails = ({ transitionType, row }: SampleTrackingOrderGridDetailsProps) => {
  const { accessToken } = useAuth();
  const { t } = useMemoTranslation();

  const [loadingState, setLoadingState] = useState<LoadingState>({ status: 'NotStarted' });
  const [refDataLoaded, setRefDataLoaded] = useState<boolean>(false);

  // Reference Data
  const [organizations, setOrganizations] = useState<Dictionary<Organization>>({});

  const [subsetTypes, setSubsetTypes] = useState<Dictionary<SubsetType>>({});

  const [extractionKits, setExtractionKits] = useState<Dictionary<ExtractionKit>>({});
  const [extractionPlatforms, setExtractionPlatforms] = useState<Dictionary<ExtractionPlatform>>({});
  const [extractionTypes, setExtractionTypes] = useState<Dictionary<ExtractionType>>({});

  const [libraryKits, setLibraryKits] = useState<Dictionary<LibraryKit>>({});

  const [sequencingExpectedCoverages, setSequencingExpectedCoverages] = useState<
    Dictionary<SequencingExpectedCoverage>
  >({});
  const [sequencingPlatforms, setSequencingPlatforms] = useState<Dictionary<SequencingPlatform>>({});

  const setupData = useCallback(async () => {
    const organization = await GetOrganizations(accessToken);

    setOrganizations(keyBy(organization, 'id'));
  }, [accessToken]);

  const setupSubsettingData = useCallback(async () => {
    const subsetType = await GetSubsetType(accessToken);

    setSubsetTypes(keyBy(subsetType, 'name'));
  }, [accessToken]);

  const setupExtractionData = useCallback(async () => {
    const extractionKits = await GetExtractionKit(accessToken);
    const extractionPlatform = await GetExtractionPlatform(accessToken);
    const extractionType = await GetExtractionType(accessToken);

    setExtractionKits(keyBy(extractionKits, 'extractionKitId'));
    setExtractionPlatforms(keyBy(extractionPlatform, 'extractionPlatformId'));
    setExtractionTypes(keyBy(extractionType, 'name'));
  }, [accessToken]);

  const setupLibraryPrepData = useCallback(async () => {
    const libraryKit = await GetLibraryKit(accessToken);

    setLibraryKits(keyBy(libraryKit, 'libraryKitId'));
  }, [accessToken]);

  const setupSequencingData = useCallback(async () => {
    const sequencingExpectedCoverages = await GetSequencingExpectedCoverage(accessToken);
    const sequencingPlatforms = await GetSequencingPlatform(accessToken);

    setSequencingExpectedCoverages(keyBy(sequencingExpectedCoverages, 'name'));
    setSequencingPlatforms(keyBy(sequencingPlatforms, 'sequencingPlatformId'));
  }, [accessToken]);

  useEffect(() => {
    return LoadState(setLoadingState, async () => {
      if (!accessToken) {
        return;
      }

      await setupData();

      switch (transitionType) {
        case 'Subsetting':
          await setupSubsettingData();
          break;

        case 'Extraction':
          await setupExtractionData();
          break;

        case 'LibraryPrep':
          await setupLibraryPrepData();
          break;

        case 'Sequencing':
          await setupSequencingData();
          break;
      }

      setRefDataLoaded(true);
    });
  }, [
    accessToken,
    transitionType,
    setupData,
    setupSubsettingData,
    setupExtractionData,
    setupLibraryPrepData,
    setupSequencingData,
  ]);

  function getColumns(): GridColDef[] {
    const defaultColumns: GridColDef[] = [
      {
        field: transitionId,
        headerName: t(transitionId),
        headerAlign: 'center',
        align: 'center',
        width: 150,
        valueFormatter: ({ value }) => value && truncateGuid(value),
      },
      {
        field: transition,
        headerName: t(transition),
        headerAlign: 'center',
        align: 'center',
        width: 150,
        valueFormatter: ({ value }) => value && t(value),
      },
      {
        field: orderedAt,
        headerName: t(orderedAt),
        headerAlign: 'center',
        align: 'center',
        width: 150,
        valueFormatter: ({ value }) => value && new Date(value).toLocaleDateString(),
      },
    ];
    const organizationColumn: GridColDef = {
      field: organizationId,
      headerName: t('performingOrganization'),
      headerAlign: 'center',
      align: 'center',
      width: 250,
      valueFormatter: ({ value }) => value && getOrganizationLabel(organizations[value]),
    };

    const subsettingColumns: GridColDef[] = [
      {
        field: subsettingType,
        headerName: t(subsettingType),
        headerAlign: 'center',
        align: 'center',
        width: 150,
        valueFormatter: ({ value }) => value && subsetTypes[value]?.displayName,
      },
    ];

    const extractionColumns: GridColDef[] = [
      {
        field: extractionKitId,
        headerName: t(extractionKitId),
        headerAlign: 'center',
        align: 'center',
        width: 150,
        valueFormatter: ({ value }) => value && extractionKits[value]?.name,
      },
      {
        field: extractionPlatformId,
        headerName: t(extractionPlatformId),
        headerAlign: 'center',
        align: 'center',
        width: 200,
        valueFormatter: ({ value }) => value && extractionPlatforms[value]?.name,
      },
      {
        field: extractionType,
        headerName: t(extractionType),
        headerAlign: 'center',
        align: 'center',
        width: 150,
        valueFormatter: ({ value }) => value && extractionTypes[value]?.displayName,
      },
    ];

    const libraryPrepColumns: GridColDef[] = [
      {
        field: libraryKitId,
        headerName: t(libraryKitId),
        headerAlign: 'center',
        align: 'center',
        width: 150,
        valueFormatter: ({ value }) => value && libraryKits[value]?.name,
      },
    ];

    const sequencingColumns: GridColDef[] = [
      {
        field: sequencingPlatformId,
        headerName: t(sequencingPlatformId),
        headerAlign: 'center',
        align: 'center',
        width: 200,
        valueFormatter: ({ value }) => value && sequencingPlatforms[value]?.name,
      },
      {
        field: sequencingExpectedCoverage,
        headerName: t(sequencingExpectedCoverage),
        headerAlign: 'center',
        align: 'center',
        width: 200,
        valueFormatter: ({ value }) => value && sequencingExpectedCoverages[value]?.name,
      },
    ];

    switch (transitionType) {
      case 'Subsetting':
        return [...defaultColumns, organizationColumn, ...subsettingColumns];

      case 'Extraction':
        return [...defaultColumns, organizationColumn, ...extractionColumns];

      case 'LibraryPrep':
        return [...defaultColumns, organizationColumn, ...libraryPrepColumns];

      case 'Sequencing':
        return [...defaultColumns, organizationColumn, ...sequencingColumns];

      default:
        return [...defaultColumns];
    }
  }

  return (
    <>
      {transitionType === 'Shipping' && (
        <ShippingOrderDetailsGrid {...{ row }} loadingProps={[loadingState, setLoadingState]} />
      )}
      {transitionType === 'Receiving' && (
        <ReceivingOrderDetailsGrid {...{ row }} loadingProps={[loadingState, setLoadingState]} />
      )}
      {transitionType !== 'Shipping' && transitionType !== 'Receiving' && (
        <>
          {loadingState.status === 'Complete' && refDataLoaded && (
            <DataGridPro rows={[row]} columns={getColumns()} density='compact' autoHeight />
          )}
          <LoadingIndicator loadingState={loadingState} margin={'LR'} />
          <ErrorIndicator loadingState={loadingState} />
        </>
      )}
    </>
  );
};

const ShippingOrderDetailsGrid = ({ loadingProps, row }: { loadingProps: LoadingProps; row: any }) => {
  const { accessToken } = useAuth();
  const { t } = useMemoTranslation();
  const [loadingState, setLoadingState] = loadingProps;

  const [shippingCarriers, setShippingCarriers] = useState<Dictionary<ShippingCarrier>>({});
  const [shippingTemperatures, setShippingTemperatures] = useState<Dictionary<ShippingTemperature>>({});
  const [volumeUnits, setVolumeUnits] = useState<Dictionary<VolumeUnit>>({});
  const [shippingContainers, setShippingContainers] = useState<Dictionary<ShippingContainer>>({});
  const [organizations, setOrganizations] = useState<Dictionary<Organization>>({});

  const apiRef = useGridApiRef();

  const useColumns = (
    shippingCarriers: Dictionary<ShippingCarrier>,
    shippingTemperatures: Dictionary<ShippingTemperature>,
    volumeUnits: Dictionary<VolumeUnit>,
    shippingContainers: Dictionary<ShippingContainer>,
    organizations: Dictionary<Organization>
  ) => {
    const { t } = useMemoTranslation();
    const defaultColumns = useDefaultColumns();

    return useMemo((): GridColDef[] => {
      return [
        ...defaultColumns,
        {
          field: receiverOrganizationId,
          headerName: t(receiverOrganizationId),
          headerAlign: 'center',
          align: 'center',
          width: 150,
          valueFormatter: ({ value }) => value && organizations[value]?.name,
        },
        {
          field: senderOrganizationId,
          headerName: t(senderOrganizationId),
          headerAlign: 'center',
          align: 'center',
          width: 150,
          valueFormatter: ({ value }) => value && organizations[value]?.name,
        },
        {
          field: shippingCarrierId,
          headerName: t(shippingCarrierId),
          headerAlign: 'center',
          align: 'center',
          width: 150,
          valueFormatter: ({ value }) => value && shippingCarriers[value]?.name,
        },
        {
          field: trackingNumber,
          headerName: t(trackingNumber),
          headerAlign: 'center',
          align: 'center',
          width: 150,
        },
        {
          field: shippingStorageTemperatureId,
          headerName: t(shippingStorageTemperatureId),
          headerAlign: 'center',
          align: 'center',
          width: 150,
          valueFormatter: ({ value }) => value && shippingTemperatures[value]?.name,
        },
        {
          field: destinationStorageTemperatureId,
          headerName: t(destinationStorageTemperatureId),
          headerAlign: 'center',
          align: 'center',
          width: 150,
          valueFormatter: ({ value }) => value && shippingTemperatures[value]?.name,
        },
        {
          field: minimumSampleVolume,
          headerName: t(minimumSampleVolume),
          headerAlign: 'center',
          align: 'center',
          width: 150,
        },
        {
          field: minimumSampleVolumeUnitId,
          headerName: t(minimumSampleVolumeUnitId),
          headerAlign: 'center',
          align: 'center',
          width: 150,
          valueFormatter: ({ value }) => value && `${volumeUnits[value]?.name} (${volumeUnits[value]?.unitOfMeasure})`,
        },
        {
          field: shipByDate,
          headerName: t(shipByDate),
          headerAlign: 'center',
          align: 'center',
          width: 150,
          valueFormatter: ({ value }) => value && new Date(value).toLocaleDateString(),
        },
        {
          field: primaryShippingContainerId,
          headerName: t(primaryShippingContainerId),
          headerAlign: 'center',
          align: 'center',
          width: 150,
          valueFormatter: ({ value }) => value && shippingContainers[value]?.name,
        },
      ];
    }, [defaultColumns, t, shippingCarriers, shippingTemperatures, volumeUnits, shippingContainers, organizations]);
  };

  const columns = useColumns(shippingCarriers, shippingTemperatures, volumeUnits, shippingContainers, organizations);

  useEffect(() => {
    ErrorManagement('Loading', setLoadingState, async () => {
      const [carriers, temperatures, units, containers, orgs] = await Promise.all([
        await GetShippingCarrier(accessToken),
        await GetShippingTemperature(accessToken),
        await GetVolumeUnit(accessToken),
        await GetShippingContainer(accessToken),
        await GetOrganizations(accessToken),
      ]);

      setShippingCarriers(keyBy(carriers, 'shippingCarrierId'));
      setShippingTemperatures(keyBy(temperatures, 'shippingTemperatureId'));
      setVolumeUnits(keyBy(units, 'volumeUnitId'));
      setShippingContainers(keyBy(containers, 'shippingContainerId'));
      setOrganizations(keyBy(orgs, 'id'));
    });
  }, [accessToken, setLoadingState]);

  return (
    <>
      <FlexBox flexDirection={'row-reverse'}>
        <GridExportButton apiRef={apiRef.current} fileName={t('orderDetailsFileName')} fieldsToNotExport={[]} />
      </FlexBox>
      <CompactGridWrapper apiRef={apiRef} rows={[row]} columns={columns} density='compact' autoHeight />
      <LoadingIndicator loadingState={loadingState} margin={'LR'} />
      <ErrorIndicator loadingState={loadingState} />
    </>
  );
};

const ReceivingOrderDetailsGrid = ({ loadingProps, row }: { loadingProps: LoadingProps; row: any }) => {
  const { accessToken } = useAuth();
  const { t } = useMemoTranslation();
  const [loadingState, setLoadingState] = loadingProps;

  const [shippingCarriers, setShippingCarriers] = useState<Dictionary<ShippingCarrier>>({});
  const [shippingTemperatures, setShippingTemperatures] = useState<Dictionary<ShippingTemperature>>({});
  const [organizations, setOrganizations] = useState<Dictionary<Organization>>({});

  const apiRef = useGridApiRef();

  const useColumns = (
    shippingCarriers: Dictionary<ShippingCarrier>,
    shippingTemperatures: Dictionary<ShippingTemperature>,
    organizations: Dictionary<Organization>
  ) => {
    const { t } = useMemoTranslation();
    const defaultColumns = useDefaultColumns();

    return useMemo((): GridColDef[] => {
      return [
        ...defaultColumns,
        {
          field: receiverOrganizationId,
          headerName: t(receiverOrganizationId),
          headerAlign: 'center',
          align: 'center',
          width: 150,
          valueFormatter: ({ value }) => value && organizations[value]?.name,
        },
        {
          field: senderOrganizationId,
          headerName: t(senderOrganizationId),
          headerAlign: 'center',
          align: 'center',
          width: 150,
          valueFormatter: ({ value }) => value && organizations[value]?.name,
        },
        {
          field: receivingCarrierId,
          headerName: t(receivingCarrierId),
          headerAlign: 'center',
          align: 'center',
          width: 150,
          valueFormatter: ({ value }) => value && shippingCarriers[value]?.name,
        },
        {
          field: trackingNumber,
          headerName: t(trackingNumber),
          headerAlign: 'center',
          align: 'center',
          width: 150,
        },
        {
          field: shippingStorageTemperatureId,
          headerName: t(shippingStorageTemperatureId),
          headerAlign: 'center',
          align: 'center',
          width: 150,
          valueFormatter: ({ value }) => value && shippingTemperatures[value]?.name,
        },
        {
          field: destinationStorageTemperatureId,
          headerName: t(destinationStorageTemperatureId),
          headerAlign: 'center',
          align: 'center',
          width: 150,
          valueFormatter: ({ value }) => value && shippingTemperatures[value]?.name,
        },
        {
          field: receivingDate,
          headerName: t(receivingDate),
          headerAlign: 'center',
          align: 'center',
          width: 150,
          valueFormatter: ({ value }) => value && new Date(value).toLocaleDateString(),
        },
      ];
    }, [defaultColumns, t, shippingCarriers, shippingTemperatures, organizations]);
  };

  const columns = useColumns(shippingCarriers, shippingTemperatures, organizations);

  useEffect(() => {
    ErrorManagement('Loading', setLoadingState, async () => {
      const [carriers, temperatures, orgs] = await Promise.all([
        await GetShippingCarrier(accessToken),
        await GetShippingTemperature(accessToken),
        await GetOrganizations(accessToken),
      ]);

      setShippingCarriers(keyBy(carriers, 'shippingCarrierId'));
      setShippingTemperatures(keyBy(temperatures, 'shippingTemperatureId'));
      setOrganizations(keyBy(orgs, 'id'));
    });
  }, [accessToken, setLoadingState]);

  return (
    <>
      <FlexBox flexDirection={'row-reverse'}>
        <GridExportButton apiRef={apiRef.current} fileName={t('orderDetailsFileName')} fieldsToNotExport={[]} />
      </FlexBox>
      <CompactGridWrapper apiRef={apiRef} rows={[row]} columns={columns} density='compact' autoHeight />
      <LoadingIndicator loadingState={loadingState} margin={'LR'} />
      <ErrorIndicator loadingState={loadingState} />
    </>
  );
};

const useDefaultColumns = () => {
  const { t } = useMemoTranslation();

  return useMemo((): GridColDef[] => {
    return [
      {
        field: transitionId,
        headerName: t(transitionId),
        headerAlign: 'center',
        align: 'center',
        width: 150,
        valueFormatter: ({ value }) => value && truncateGuid(value),
      },
      {
        field: transition,
        headerName: t(transition),
        headerAlign: 'center',
        align: 'center',
        width: 150,
        valueFormatter: ({ value }) => value && t(value),
      },
      {
        field: orderedAt,
        headerName: t(orderedAt),
        headerAlign: 'center',
        align: 'center',
        width: 150,
        valueFormatter: ({ value }) => value && new Date(value).toLocaleDateString(),
      },
    ];
  }, [t]);
};
