import { Chart } from 'react-google-charts';
import { ReactElement } from 'react';
import { HistoricCount } from 'data/HistoricCountData';
import { DateAggregate, formatTooltipDate, getHAxisDateFormat } from 'historicCounts/DateAggregate';

export interface Props {
  entities: string[];
  historicCounts: HistoricCount[];
  dateAggregate: DateAggregate;
  width: string;
  height: string;
  chartAreaWidth: string;
}

export const HistoricCountLineChart = ({
  entities,
  historicCounts,
  dateAggregate,
  width,
  height,
  chartAreaWidth,
}: Props) => {
  const dataTable = createDataTable(entities, historicCounts, dateAggregate);
  return (
    <div>
      {entities.length > 0 && historicCounts.length > 0 && (
        <Chart
          width={width}
          height={height}
          chartType='LineChart'
          loader={<div>Loading Chart</div>}
          data={dataTable}
          options={{
            chartArea: { top: '2%', left: '8%', height: '85%', width: chartAreaWidth },
            hAxis: {
              format: getHAxisDateFormat(dateAggregate),
              minorGridlines: { interval: 0 },
              gridlines: {
                color: 'transparent',
              },
              slantedText: true,
              slantedTextAngle: 30,
              textStyle: { fontSize: 14 },
              ticks: historicCounts.map(count => count.date),
            },
            legend: {
              textStyle: { fontSize: 14 },
            },
            pointSize: getPointSize(historicCounts.length),
            tooltip: { isHtml: true, trigger: 'visible' },
          }}
        />
      )}
    </div>
  );
};

function createDataTable(
  entities: string[],
  historicCounts: HistoricCount[],
  dateAggregate: DateAggregate
): [(string | number | Object)[]] {
  if (entities.length > 0 && historicCounts.length > 0 && dateAggregate) {
    // Set first column header as 'Date'
    let dataTable: [(string | Date | number | Object)[]] = [['Date']];

    // Add entities to table column headers
    entities.forEach(entity => {
      dataTable[0].push(entity);

      // Need one tooltip column for each entity column
      dataTable[0].push({ type: 'string', role: 'tooltip', p: { html: true } });
    });

    // Add rows to table
    for (let i = 0; i < historicCounts.length; i++) {
      let row: (Date | number | string | ReactElement)[] = [historicCounts[i].date];

      for (let j = 0; j < entities.length; j++) {
        let entity = entities[j];

        let entityTotal = i === 0 ? 0 : Number(dataTable[i][dataTable[0].indexOf(entity)]);

        let historicCount = historicCounts[i].counts.find(e => e?.name === entity);

        entityTotal = historicCount ? historicCount.count : entityTotal;

        row.push(entityTotal);
        row.push(getTooltip(entity, entityTotal, formatTooltipDate(historicCounts[i].date, dateAggregate)));
      }

      dataTable.push(row);
    }
    return dataTable;
  }
  return [[]];
}

function getTooltip(entityName: string, count: number, date: string) {
  const pStyle = 'style="margin-top: 0em; margin-bottom: 0.5em"';
  const pStyleBold = 'style="margin-top: 0em; margin-bottom: 0.5em; font-weight: bold;"';

  return `
    <div style="width: max-content; max-width: 180px; margin: 10px">
      <p ${pStyle}>
        <span style="font-weight: bold; font-size: 14px">
          ${entityName}
        </span>
      </p>
      <p ${pStyleBold}>
        ${date}
      </p>
      <p ${pStyle}>
        Total: <strong>${count.toLocaleString()}</strong> <br />
      </p>
    </div>`;
}

function getPointSize(numDataPoints: number) {
  return numDataPoints > 20 ? 0 : 3;
}
