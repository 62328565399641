import useAuth from 'auth/UseAuth';
import CloseIcon from '@mui/icons-material/Close';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Tab,
  Tabs,
  Typography,
  useTheme,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { InformaticsCheckByGridData, InformaticsQcMetricsGrid } from './InformaticsQcMetricsGrid';
import { InformaticsQcMetrics, SequenceType } from 'data/InformaticsQcData';
import {
  CreateQualityCheck,
  GetInformaticsQcMetricsAsync,
  QualityCheckStatus,
  SampleTrackingCheckBy,
} from 'data/SampleTrackingData';
import { ErrorManagement, LoadingState, LoadState } from 'components/LoadingStateUtil';
import { cloneDeep, map } from 'lodash';
import { DialogOpenButton } from 'components/DialogOpenButton';
import { recordQc } from 'util/Constants';
import { ErrorIndicator } from 'components/ErrorIndicator';
import { LoadingIndicator } from 'components/LoadingIndicator';
import UseMemoTranslation from '../../hooks/UseMemoTranslation';
import { FlexTableBox } from '../../components/FlexTableBox';
import { CancelButton } from '../../components/CancelButton';
import { PrimaryButton } from '../../components/PrimaryButton';

type BaseData = {
  sampleId: string;
  sampleIdentifier: string;
  selectionStatus: string;
  qualityCheckStatus: string;
  sequenceType: string;
};

export const InformaticsQcModal = ({
  researchProjectId,
  data,
  onClose,
  configuredTransitionId,
}: {
  researchProjectId: string;
  data: ReadonlyArray<BaseData>;
  onClose: (hasChanges: boolean) => void;
  configuredTransitionId: string;
}) => {
  const { accessToken } = useAuth();
  const { t } = UseMemoTranslation();
  const theme = useTheme();
  const INITIAL_LOADING_STATE: LoadingState = { status: 'NotStarted' };
  const INITIAL_READY_TO_SUBMIT = false;
  const INITIAL_SEQ_TYPE = 'DNA';

  const [open, setOpen] = useState<boolean>(false);
  const [DNACount, setDNACount] = useState<number>(0);
  const [RNACount, setRNACount] = useState<number>(0);
  const [TNACount, setTNACount] = useState<number>(0);
  const [loadingState, setLoadingState] = useState<LoadingState>(INITIAL_LOADING_STATE);
  const [readyToSubmit, setReadyToSubmit] = useState<boolean>(INITIAL_READY_TO_SUBMIT);
  const [informaticsCheckByCache, setInformaticsCheckByCache] = useState<ReadonlyArray<InformaticsCheckByGridData>>([]);
  const [gridSeqType, setGridSeqType] = useState<SequenceType>(INITIAL_SEQ_TYPE);

  useEffect(() => {
    return LoadState(setLoadingState, async () => {
      const sampleIds = map(data, i => i.sampleId);

      if (sampleIds.length === 0 || !open) {
        return;
      }

      const informaticsQcMetrics = await GetInformaticsQcMetricsAsync(sampleIds, accessToken);
      setInformaticsCheckByCache(getRowData(data, informaticsQcMetrics));
    });
  }, [data, open, accessToken]);

  useEffect(() => {
    setDNACount(informaticsCheckByCache.filter(qc => qc.sequenceType === 'DNA').length);
    setRNACount(informaticsCheckByCache.filter(qc => qc.sequenceType === 'RNA').length);
    setTNACount(informaticsCheckByCache.filter(qc => qc.sequenceType === 'TNA').length);
  }, [informaticsCheckByCache]);

  useEffect(() => {
    if (DNACount !== 0) {
      setGridSeqType('DNA');
    } else if (RNACount !== 0) {
      setGridSeqType('RNA');
    } else if (TNACount !== 0) {
      setGridSeqType('TNA');
    } else {
      setGridSeqType('DNA');
    }
  }, [DNACount, RNACount, TNACount]);

  const handleSeqTypeTabChange = async (event: React.SyntheticEvent, newValue: SequenceType) => {
    setGridSeqType(newValue);
  };

  const handleButtonClick = async () => {
    setOpen(true);
  };

  const handleQcSelection = (sampleIds: string[], selection: QualityCheckStatus) => {
    let tempCache = [...informaticsCheckByCache];
    setReadyToSubmit(true);

    sampleIds.forEach(sampleId => {
      const cacheIndex = tempCache.findIndex(qc => qc.sampleId === sampleId);

      let updated = tempCache[cacheIndex];
      updated.qualityCheckStatus = selection;
    });

    setInformaticsCheckByCache([...cloneDeep(tempCache)]);
  };

  const handleSubmit = async () => {
    ErrorManagement('Loading', setLoadingState, async () => {
      if (!accessToken) {
        return;
      }

      const idMappings: SampleTrackingCheckBy[] = informaticsCheckByCache
        .filter(i => i.qualityCheckStatus !== 'NotYetDecided')
        .map(i => {
          return {
            originalSampleId: i.sampleId,
            sampleIdentifier: i.sampleIdentifier,
            labAssignedSampleId: i.sampleIdentifier,
            qualityCheckStatus: i.qualityCheckStatus,
          };
        });

      await CreateQualityCheck(researchProjectId, configuredTransitionId, idMappings, 'none', accessToken);

      await handleClose(true);
    });
  };

  function handleClose(hasChanges: boolean) {
    onClose(hasChanges);
    setOpen(false);
    setLoadingState(INITIAL_LOADING_STATE);
    setReadyToSubmit(INITIAL_READY_TO_SUBMIT);
    setInformaticsCheckByCache([]);
    setGridSeqType(INITIAL_SEQ_TYPE);
  }

  function getFilteredGridData() {
    return informaticsCheckByCache.filter(qc => qc.sequenceType === gridSeqType);
  }

  function getTabDisplayValue(seqType: SequenceType) {
    switch (seqType) {
      case 'DNA':
        return `${seqType} (${DNACount})`;
      case 'RNA':
        return `${seqType} (${RNACount})`;
      case 'TNA':
        return `${seqType} (${TNACount})`;
    }
  }

  return (
    <>
      <DialogOpenButton title={t(recordQc)} onClick={handleButtonClick} />
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        fullWidth
        maxWidth='xl'
        PaperProps={{ sx: { width: '90%', height: '90%' } }}
      >
        <DialogTitle>
          <IconButton
            aria-label='close'
            onClick={() => handleClose(false)}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: theme => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <Typography variant='h4'>Informatics Metrics</Typography>
        </DialogTitle>
        <DialogContent>
          <FlexTableBox>
            <Tabs value={gridSeqType} onChange={handleSeqTypeTabChange} sx={{ mb: 1 }}>
              <Tab value='DNA' label={getTabDisplayValue('DNA')} sx={{ color: theme.colors.alpha.black[100] }} />
              <Tab value='RNA' label={getTabDisplayValue('RNA')} sx={{ color: theme.colors.alpha.black[100] }} />
              <Tab value='TNA' label={getTabDisplayValue('TNA')} sx={{ color: theme.colors.alpha.black[100] }} />
            </Tabs>
            <InformaticsQcMetricsGrid
              seqType={gridSeqType}
              data={getFilteredGridData()}
              onQcSelection={handleQcSelection}
            />
          </FlexTableBox>
        </DialogContent>
        <ErrorIndicator loadingState={loadingState} />
        <LoadingIndicator loadingState={loadingState} margin={'LR'} />
        <DialogActions>
          <CancelButton onClick={() => handleClose(false)} />
          <PrimaryButton onClick={handleSubmit} disabled={loadingState.status === 'Loading' || !readyToSubmit}>
            {t('submit')}
          </PrimaryButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

function getRowData(
  data: ReadonlyArray<BaseData>,
  informaticsQcMetrics: ReadonlyArray<InformaticsQcMetrics>
): ReadonlyArray<InformaticsCheckByGridData> {
  return map(data, (i): InformaticsCheckByGridData => {
    const informaticsQcMetric = informaticsQcMetrics.find(j => j.sampleId === i.sampleId);

    return {
      previousQcUpdated: false,
      ...i,
      ...informaticsQcMetric,
      qualityCheckStatus: (i.qualityCheckStatus as QualityCheckStatus) ?? '',
      sequenceType: (i.sequenceType ? i.sequenceType.toUpperCase() : '') as SequenceType,
    };
  })
    .filter(qc => qc.sequenceRunId !== undefined)
    .filter(qc => qc.qualityCheckStatus === 'NotYetDecided');
}
