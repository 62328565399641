import { Accordion, Card } from 'react-bootstrap';
import { CustomToggle, getAccordianExpansionSymbolCOPY } from './HistoricCountFilters';
import { ChangeEvent } from 'react';
import { SampleTypeSelection } from 'data/SampleTypeData';

export interface SampleTypeFilterProps {
  eventKey: string;
  toggleSelection: string | undefined;
  setToggleSelection: Function;
  sampleTypes: SampleTypeSelection[];
  handleSampleTypeSelection: (ev: ChangeEvent<HTMLInputElement>) => void;
  sampleTypeSelectAll: boolean;
  handleSampleTypeSelectAll: (ev: ChangeEvent<HTMLInputElement>) => void;
}

export const SampleTypeFilter = ({
  eventKey,
  toggleSelection,
  setToggleSelection,
  sampleTypes,
  handleSampleTypeSelection,
  sampleTypeSelectAll,
  handleSampleTypeSelectAll,
}: SampleTypeFilterProps) => {
  return (
    <Card bsPrefix='historic-counts-filters' key={`card-${eventKey}`}>
      <Card.Header bsPrefix='historic-counts-filters-subgroup'>
        <CustomToggle eventKey={eventKey} toggleSelection={toggleSelection} setToggleSelection={setToggleSelection}>
          <div>Sample Types</div>
          <div>{getAccordianExpansionSymbolCOPY(eventKey, toggleSelection)}</div>
        </CustomToggle>
      </Card.Header>
      <Accordion.Collapse eventKey={eventKey}>
        <Card.Body bsPrefix='historic-counts-filters-accordian-item-selection'>
          <div key='sample-types-select-all-div'>
            <input
              className='historic-counts-filters-select-all-checkbox'
              type='checkbox'
              id='sample-types-select-all-chckbx'
              key='sample-types-select-all-chckbx'
              name='sample-types-select-all'
              value='sample-types-select-all'
              checked={sampleTypeSelectAll}
              onChange={handleSampleTypeSelectAll}
            />
            <label className='historic-counts-filters-select-all-checkboxlbl' key='sample-type-select-all-chckbxlbl'>
              Select All
            </label>
          </div>
          {sampleTypes.map(sampleType => (
            <div key={`${sampleType.displayName} selection`} className='historic-counts-filters-accordian-item-test'>
              <input
                className='historic-counts-filters-accordian-item-checkbox'
                type='checkbox'
                id={sampleType.displayName}
                key={`${sampleType.displayName} chckbx`}
                name={sampleType.displayName}
                value={sampleType.displayName}
                checked={sampleType.selected}
                onChange={handleSampleTypeSelection}
              />
              <label
                className='historic-counts-filters-accordian-item-checkboxlbl'
                htmlFor={sampleType.displayName}
                key={`${sampleType.displayName} lbl`}
              >
                {sampleType.displayName}
              </label>
            </div>
          ))}
        </Card.Body>
      </Accordion.Collapse>
    </Card>
  );
};
