import { appSettings } from 'AppSettings';
import { orderBy } from 'lodash';
import { LlCustomer } from 'data/LlCustomerData';
import { ItemOfResearchType } from './ResearchProjectPatientData';

export interface ResearchProject {
  researchProjectId: string;
  name: string;
  customer: LlCustomer;
  expectedCohortSize?: number;
  actualCohortSize?: number;
  status: string;
  salesforceLink?: string;
  mondayLink?: string;
}

export interface ItemOfResearch {
  id: string;
  name: string;
  type: ItemOfResearchType;
}

const customerSorter = (rp: ResearchProject) => rp.customer.name.toLowerCase();
const projectSorter = (rp: ResearchProject) => rp.name.toLowerCase();

export async function GetResearchProject(
  researchProjectId: string,
  accessToken: string
): Promise<ResearchProject | undefined> {
  if (accessToken) {
    try {
      const response = await fetch(`${appSettings.api.endpoint}/api/v2/ResearchProjects/${researchProjectId}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      if (response.ok) {
        const researchProject: ResearchProject = await response.json();
        return researchProject;
      }
    } catch (err) {
      console.dir(err);
    }
  }
  return undefined;
}

export async function GetResearchProjects(customerId: string, accessToken: string): Promise<ResearchProject[]> {
  let researchProjects: ResearchProject[] = [];
  if (accessToken) {
    try {
      const response = await fetch(`${appSettings.api.endpoint}/api/v2/ResearchProjects?customerId=${customerId}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      if (response.ok) {
        const values: ResearchProject[] = await response.json();

        researchProjects = [...orderBy(values, [projectSorter], ['asc'])];
      }
    } catch (err) {
      console.dir(err);
    }
  }
  return researchProjects;
}

export async function GetAllResearchProjects(accessToken?: string): Promise<ResearchProject[]> {
  let researchProjects: ResearchProject[] = [];
  if (accessToken) {
    try {
      const response = await fetch(`${appSettings.api.endpoint}/api/v2/ResearchProjects`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      if (response.ok) {
        const values: ResearchProject[] = await response.json();
        researchProjects = [...orderBy(values, [customerSorter, projectSorter], ['asc', 'asc'])];
      }
    } catch (err) {
      console.dir(err);
    }
  }
  return researchProjects;
}
