import { DataGridPro, GridColDef, GridComparatorFn, GridRenderCellParams, GridRowsProp } from '@mui/x-data-grid-pro';
import { renderGuidCellTooltip } from 'components/grid/cell/GuidGridCellTooltip';
import { renderCellBooleanCheck } from 'components/grid/GridCellBooleanCheck';
import { renderCellDateTime } from 'components/grid/GridCellDateTime';
import { Biobank } from 'data/BiobankData';
import { InventoryCheckRequest } from 'data/InventoryCheckRequest';
import { useEffect, useState } from 'react';
import {
  InventoryCheckRequestBiobankCell,
  InventoryCheckRequestBiobankCellProps,
} from './InventoryCheckRequestBiobankCell';

const HeaderName = {
  biobank: 'Biobank',
  inventoryCheckRequestId: 'Request Id',
  numSamplesRequested: 'Samples Requested',
  numSamplesAvailable: 'Samples Available',
  numSamplesNotAvailable: 'Samples Not Available',
  complete: 'Complete',
  created: 'Created',
  lastUpdated: 'Last Updated',
};

export interface InventoryCheckRequestGridProps {
  inventoryCheckRequests: InventoryCheckRequest[];
  biobankFacilites: Biobank[];
  onInventoryCheckRequestUpdate: Function;
  autoHeight: boolean;
}

export const InventoryCheckRequestGrid = ({
  inventoryCheckRequests,
  biobankFacilites,
  onInventoryCheckRequestUpdate,
  autoHeight,
}: InventoryCheckRequestGridProps) => {
  const [columns, setColumns] = useState<GridColDef[]>([]);
  const [rows, setRows] = useState<GridRowsProp>([]);

  useEffect(() => {
    setColumns(getColumns(inventoryCheckRequests, biobankFacilites, onInventoryCheckRequestUpdate));
    setRows(getRows(inventoryCheckRequests, biobankFacilites, onInventoryCheckRequestUpdate));
  }, [inventoryCheckRequests, biobankFacilites, onInventoryCheckRequestUpdate]);

  return (
    <DataGridPro
      rows={rows}
      columns={columns}
      autoHeight={autoHeight}
      disableColumnFilter={true}
      disableColumnMenu={true}
      pagination
      paginationMode='client'
      pageSizeOptions={[100, 250, 500]}
      density='compact'
      hideFooterSelectedRowCount={true}
      initialState={{
        pinnedColumns: { left: ['Biobank'] },
        sorting: {
          sortModel: [{ field: 'Created', sort: 'asc' }],
        },
      }}
    />
  );
};

function getRows(
  inventoryCheckRequests: InventoryCheckRequest[],
  biobanks: Biobank[],
  onInventoryCheckRequestUpdate: Function
) {
  const rows: any[] = [];
  let id = 1;

  inventoryCheckRequests.forEach(i => {
    const biobank = biobanks.find(b => b.biobankId === i.biobankId);

    if (biobank) {
      let biobankCellValue: InventoryCheckRequestBiobankCellProps = {
        inventoryCheckRequest: i,
        biobank: biobank,
      };

      rows.push({
        id: id++,
        Biobank: biobankCellValue,
        InventoryCheckRequestId: i.inventoryCheckRequestId,
        NumSamplesRequested: i.numSamplesRequested.toLocaleString(),
        NumSamplesAvailable: i.createdAt === i.updatedAt ? '-' : i.numSamplesAvailable.toLocaleString(),
        NumSamplesNotAvailable: i.createdAt === i.updatedAt ? '-' : i.numSamplesNotAvailable.toLocaleString(),
        Complete: i.requestComplete,
        Created: new Date(i.createdAt),
        LastUpdated: i.createdAt === i.updatedAt ? null : new Date(i.updatedAt),
      });
    }
  });

  return rows;
}

function getColumns(
  inventoryCheckRequests: InventoryCheckRequest[],
  biobanks: Biobank[],
  onInventoryCheckRequestUpdate: Function
) {
  const colWidths = getColumnWidths(inventoryCheckRequests, biobanks);

  const columns: GridColDef[] = [
    {
      field: 'Biobank',
      headerName: HeaderName.biobank,
      width: colWidths.biobankColWidth,
      renderCell: (params: GridRenderCellParams<InventoryCheckRequestBiobankCellProps>) => {
        if (params.value) {
          return (
            <InventoryCheckRequestBiobankCell
              inventoryCheckRequest={params.value.inventoryCheckRequest}
              biobank={params.value.biobank}
            />
          );
        } else {
          return <></>;
        }
      },
      sortComparator: biobankComparator,
    },
    {
      field: 'InventoryCheckRequestId',
      headerName: HeaderName.inventoryCheckRequestId,
      width: colWidths.inventoryCheckRequestIdColWidth,
      sortable: false,
      renderCell: renderGuidCellTooltip,
    },
    {
      field: 'NumSamplesRequested',
      headerName: HeaderName.numSamplesRequested,
      headerAlign: 'center',
      align: 'center',
      width: colWidths.numSamplesRequested,
      sortable: false,
    },
    {
      field: 'NumSamplesAvailable',
      headerName: HeaderName.numSamplesAvailable,
      headerAlign: 'center',
      align: 'center',
      width: colWidths.numSamplesAvailable,
      sortable: false,
    },
    {
      field: 'NumSamplesNotAvailable',
      headerName: HeaderName.numSamplesNotAvailable,
      headerAlign: 'center',
      align: 'center',
      width: colWidths.numSamplesNotAvailable,
      sortable: false,
    },
    {
      field: 'Complete',
      headerName: HeaderName.complete,
      description: 'Marked complete if all requested samples were verified (y/n) by biobank',
      headerAlign: 'center',
      align: 'center',
      width: colWidths.completeColWidth,
      renderCell: renderCellBooleanCheck,
    },
    {
      field: 'Created',
      headerName: HeaderName.created,
      headerAlign: 'center',
      align: 'center',
      width: colWidths.createdColWidth,
      type: 'date',
      renderCell: renderCellDateTime,
    },
    {
      field: 'LastUpdated',
      headerName: HeaderName.lastUpdated,
      headerAlign: 'center',
      align: 'center',
      width: colWidths.lastUpdatedColWidth,
      type: 'date',
      renderCell: renderCellDateTime,
    },
  ];

  return columns;
}

interface InventoryCheckRequestColWidths {
  biobankColWidth: number;
  inventoryCheckRequestIdColWidth: number;
  numSamplesRequested: number;
  numSamplesAvailable: number;
  numSamplesNotAvailable: number;
  completeColWidth: number;
  createdColWidth: number;
  lastUpdatedColWidth: number;
}

function getColumnWidths(inventoryCheckRequests: InventoryCheckRequest[], biobanks: Biobank[]) {
  let maxBiobankLength = HeaderName.biobank.length;

  inventoryCheckRequests.forEach(i => {
    const biobank = biobanks.find(b => b.biobankId === i.biobankId)?.name ?? '';

    maxBiobankLength = biobank.length > maxBiobankLength ? biobank.length : maxBiobankLength;
  });

  let colWiths: InventoryCheckRequestColWidths = {
    biobankColWidth: maxBiobankLength * 18,
    inventoryCheckRequestIdColWidth: 150,
    numSamplesRequested: 170,
    numSamplesAvailable: 170,
    numSamplesNotAvailable: 170,
    completeColWidth: 110,
    createdColWidth: 210,
    lastUpdatedColWidth: 210,
  };

  return colWiths;
}

const biobankComparator: GridComparatorFn = (v1, v2) => {
  const biobank1 = (v1 as InventoryCheckRequestBiobankCellProps).biobank.name;
  const biobank2 = (v2 as InventoryCheckRequestBiobankCellProps).biobank.name;
  if (biobank1 < biobank2) {
    return -1;
  } else if (biobank1 > biobank2) {
    return 1;
  } else {
    return 0;
  }
};
