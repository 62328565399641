import { appSettings } from 'AppSettings';
import { FileProcessingStatus, GenerateFileDownload } from 'helpers/FileProcessing';
import { Button, Typography, useTheme } from '@mui/material';
import DownloadDoneIcon from '@mui/icons-material/DownloadDone';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import useAuth from 'auth/UseAuth';
import { useState } from 'react';
import { CohortType } from 'data/ResearchProjectCohortData';

export interface Props {
  researchProjectId: string;
  cohortType: CohortType;
  useDecryptedHashes: boolean;
}

export const ResearchProjectCohortExportButton = ({ researchProjectId, cohortType }: Props) => {
  const { accessToken } = useAuth();
  const theme = useTheme();

  const [status, setStatus] = useState<FileProcessingStatus>('NotStarted');

  const invokeGenerator = async () => {
    if (accessToken) {
      setStatus('Downloading');

      const cohort = cohortType === 'patient' ? 'Patients' : 'Samples';

      try {
        let requestUrl = `${appSettings.api.endpoint}/api/v2/ResearchProjectCohort/${researchProjectId}/${cohort}`;

        const response = await fetch(requestUrl, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        if (response.ok) {
          await GenerateFileDownload(response);
          setStatus('Complete');
        } else {
          setStatus('Error');
        }
      } catch (err) {
        setStatus('Error');
      }
    }
  };

  return (
    <Button
      id='research-project-cohort-export-button'
      onClick={invokeGenerator}
      disabled={status === 'Downloading'}
      sx={[
        {
          color: theme.colors.alpha.black[100],
          '&:hover': {
            backgroundColor: 'transparent',
          },
        },
      ]}
    >
      {status === 'NotStarted' && <SaveAltIcon />}
      {status === 'Downloading' && <HourglassBottomIcon />}
      {status === 'Complete' && <DownloadDoneIcon />}
      {status === 'Error' && <ErrorOutlineIcon />}
      <Typography variant='button' ml={1}>{`Export ${cohortType === 'patient' ? 'Patients' : 'Samples'}`}</Typography>
    </Button>
  );
};
