import { QualityCheckStatus } from './SampleTrackingData';

export interface InformaticsQcMetrics {
  sampleId: string;
  sequenceRunId?: string;

  // Global metrics
  percentDuplication?: number;

  // DNA specific metrics
  medianCoverage?: number;
  rawTotalSequences?: number;
  readsMapped?: number;
  readsMappedPercent?: number;
  percentGcContent?: number;
  readsProperlyPairedPercent?: number;

  // RNA specific metrics
  totalReads?: number;
  readsAligned?: number;
  uniquelyMapped?: number;
  uniquelyMappedPercent?: number;
  percentDups?: number;
  trimmedR1PercentGc?: number;
  trimmedR2PercentGc?: number;
}

export interface InformaticsQcSelection {
  sampleId: string;
  qualityCheckStatus: QualityCheckStatus;
}

export type TissueType = 'Diseased' | 'Normal';
export type SequenceType = 'DNA' | 'RNA' | 'TNA';

export enum InformaticsQcThresholdEnum {
  Undetermined = 0,
  Fail = 1,
  Warn = 2,
  Pass = 3,
}

export interface MetricThreshold {
  //Both DNA and RNA
  percentDuplication: Threshold;

  // DNA
  medianCoverage: Threshold;
  rawTotalSequences: Threshold;
  readsMapped: Threshold;
  readsMappedPercent: Threshold;
  percentGcContent: Threshold;
  readsProperlyPairedPercent: Threshold;

  // RNA
  totalReads: Threshold;
  readsAligned: Threshold;
  uniquelyMapped: Threshold;
  uniquelyMappedPercent: Threshold;
  trimmedR1PercentGc: Threshold;
  trimmedR2PercentGc: Threshold;
}

export interface Threshold {
  lowerLimit: number;
  upperLimit: number;
  getThresholdEnum: (value: number) => InformaticsQcThresholdEnum;
}

export const Thresholds: MetricThreshold = {
  percentDuplication: {
    lowerLimit: 29,
    upperLimit: 30,
    getThresholdEnum: (value: number) => {
      if (value === undefined) {
        return InformaticsQcThresholdEnum.Undetermined;
      } else if (value < Thresholds.percentDuplication.lowerLimit) {
        return InformaticsQcThresholdEnum.Pass;
      } else if (
        value >= Thresholds.percentDuplication.lowerLimit &&
        value <= Thresholds.percentDuplication.upperLimit
      ) {
        return InformaticsQcThresholdEnum.Warn;
      } else {
        return InformaticsQcThresholdEnum.Fail;
      }
    },
  },
  medianCoverage: {
    lowerLimit: 10,
    upperLimit: 14,
    getThresholdEnum: (value: number) => {
      if (value === undefined) {
        return InformaticsQcThresholdEnum.Undetermined;
      } else if (value < Thresholds.medianCoverage.lowerLimit) {
        return InformaticsQcThresholdEnum.Fail;
      } else if (value >= Thresholds.medianCoverage.lowerLimit && value <= Thresholds.medianCoverage.upperLimit) {
        return InformaticsQcThresholdEnum.Warn;
      } else {
        return InformaticsQcThresholdEnum.Pass;
      }
    },
  },
  rawTotalSequences: {
    lowerLimit: 600,
    upperLimit: 800,
    getThresholdEnum: (value: number) => {
      if (value === undefined) {
        return InformaticsQcThresholdEnum.Undetermined;
      } else if (value < Thresholds.rawTotalSequences.lowerLimit) {
        return InformaticsQcThresholdEnum.Fail;
      } else if (value >= Thresholds.rawTotalSequences.lowerLimit && value <= Thresholds.rawTotalSequences.upperLimit) {
        return InformaticsQcThresholdEnum.Warn;
      } else {
        return InformaticsQcThresholdEnum.Pass;
      }
    },
  },
  readsMapped: {
    lowerLimit: 400,
    upperLimit: 500,
    getThresholdEnum: (value: number) => {
      if (value === undefined) {
        return InformaticsQcThresholdEnum.Undetermined;
      } else if (value < Thresholds.readsMapped.lowerLimit) {
        return InformaticsQcThresholdEnum.Fail;
      } else if (value >= Thresholds.readsMapped.lowerLimit && value <= Thresholds.readsMapped.upperLimit) {
        return InformaticsQcThresholdEnum.Warn;
      } else {
        return InformaticsQcThresholdEnum.Pass;
      }
    },
  },
  readsMappedPercent: {
    lowerLimit: 70,
    upperLimit: 99,
    getThresholdEnum: (value: number) => {
      if (value === undefined) {
        return InformaticsQcThresholdEnum.Undetermined;
      } else if (value < Thresholds.readsMappedPercent.lowerLimit) {
        return InformaticsQcThresholdEnum.Fail;
      } else if (
        value >= Thresholds.readsMappedPercent.lowerLimit &&
        value <= Thresholds.readsMappedPercent.upperLimit
      ) {
        return InformaticsQcThresholdEnum.Warn;
      } else {
        return InformaticsQcThresholdEnum.Pass;
      }
    },
  },
  percentGcContent: {
    lowerLimit: 42,
    upperLimit: 50,
    getThresholdEnum: (value: number) => {
      if (value === undefined) {
        return InformaticsQcThresholdEnum.Undetermined;
      } else if (value < Thresholds.percentGcContent.lowerLimit) {
        return InformaticsQcThresholdEnum.Pass;
      } else if (value >= Thresholds.percentGcContent.lowerLimit && value <= Thresholds.percentGcContent.upperLimit) {
        return InformaticsQcThresholdEnum.Warn;
      } else {
        return InformaticsQcThresholdEnum.Fail;
      }
    },
  },
  readsProperlyPairedPercent: {
    lowerLimit: 80,
    upperLimit: 98,
    getThresholdEnum: (value: number) => {
      if (value === undefined) {
        return InformaticsQcThresholdEnum.Undetermined;
      } else if (value < Thresholds.readsProperlyPairedPercent.lowerLimit) {
        return InformaticsQcThresholdEnum.Fail;
      } else if (
        value >= Thresholds.readsProperlyPairedPercent.lowerLimit &&
        value <= Thresholds.readsProperlyPairedPercent.upperLimit
      ) {
        return InformaticsQcThresholdEnum.Warn;
      } else {
        return InformaticsQcThresholdEnum.Pass;
      }
    },
  },
  totalReads: {
    lowerLimit: 7,
    upperLimit: 10,
    getThresholdEnum: (value: number) => {
      if (value === undefined) {
        return InformaticsQcThresholdEnum.Undetermined;
      } else if (value < Thresholds.totalReads.lowerLimit) {
        return InformaticsQcThresholdEnum.Fail;
      } else if (value >= Thresholds.totalReads.lowerLimit && value <= Thresholds.totalReads.upperLimit) {
        return InformaticsQcThresholdEnum.Warn;
      } else {
        return InformaticsQcThresholdEnum.Pass;
      }
    },
  },
  readsAligned: {
    lowerLimit: 7,
    upperLimit: 10,
    getThresholdEnum: (value: number) => {
      if (value === undefined) {
        return InformaticsQcThresholdEnum.Undetermined;
      } else if (value < Thresholds.readsAligned.lowerLimit) {
        return InformaticsQcThresholdEnum.Fail;
      } else if (value >= Thresholds.readsAligned.lowerLimit && value <= Thresholds.readsAligned.upperLimit) {
        return InformaticsQcThresholdEnum.Warn;
      } else {
        return InformaticsQcThresholdEnum.Pass;
      }
    },
  },
  uniquelyMapped: {
    lowerLimit: 7,
    upperLimit: 10,
    getThresholdEnum: (value: number) => {
      if (value === undefined) {
        return InformaticsQcThresholdEnum.Undetermined;
      } else if (value < Thresholds.uniquelyMapped.lowerLimit) {
        return InformaticsQcThresholdEnum.Fail;
      } else if (value >= Thresholds.uniquelyMapped.lowerLimit && value <= Thresholds.uniquelyMapped.upperLimit) {
        return InformaticsQcThresholdEnum.Warn;
      } else {
        return InformaticsQcThresholdEnum.Pass;
      }
    },
  },
  uniquelyMappedPercent: {
    lowerLimit: 7,
    upperLimit: 10,
    getThresholdEnum: (value: number) => {
      if (value === undefined) {
        return InformaticsQcThresholdEnum.Undetermined;
      } else if (value < Thresholds.uniquelyMappedPercent.lowerLimit) {
        return InformaticsQcThresholdEnum.Fail;
      } else if (
        value >= Thresholds.uniquelyMappedPercent.lowerLimit &&
        value <= Thresholds.uniquelyMappedPercent.upperLimit
      ) {
        return InformaticsQcThresholdEnum.Warn;
      } else {
        return InformaticsQcThresholdEnum.Pass;
      }
    },
  },
  trimmedR1PercentGc: {
    lowerLimit: 7,
    upperLimit: 10,
    getThresholdEnum: (value: number) => {
      if (value === undefined) {
        return InformaticsQcThresholdEnum.Undetermined;
      } else if (value < Thresholds.trimmedR1PercentGc.lowerLimit) {
        return InformaticsQcThresholdEnum.Fail;
      } else if (
        value >= Thresholds.trimmedR1PercentGc.lowerLimit &&
        value <= Thresholds.trimmedR1PercentGc.upperLimit
      ) {
        return InformaticsQcThresholdEnum.Warn;
      } else {
        return InformaticsQcThresholdEnum.Pass;
      }
    },
  },
  trimmedR2PercentGc: {
    lowerLimit: 7,
    upperLimit: 10,
    getThresholdEnum: (value: number) => {
      if (value === undefined) {
        return InformaticsQcThresholdEnum.Undetermined;
      } else if (value < Thresholds.trimmedR2PercentGc.lowerLimit) {
        return InformaticsQcThresholdEnum.Fail;
      } else if (
        value >= Thresholds.trimmedR2PercentGc.lowerLimit &&
        value <= Thresholds.trimmedR2PercentGc.upperLimit
      ) {
        return InformaticsQcThresholdEnum.Warn;
      } else {
        return InformaticsQcThresholdEnum.Pass;
      }
    },
  },
};
