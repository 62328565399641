import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Input,
  LinearProgress,
  Typography,
  useTheme,
} from '@mui/material';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import useAuth from 'auth/UseAuth';
import { ChangeEvent, useState } from 'react';
import { appSettings } from 'AppSettings';
import { InvenvtoryCheckRequestUploadResults } from 'data/InventoryCheckRequest';
import { DialogCloseButton } from '../../components/DialogCloseButton';
import { FileProcessingStatus } from 'helpers/FileProcessing';

export interface UpdateInventoryCheckRequestModalProps {
  onUpdateInventoryCheckRequest: Function;
}

export const UpdateInventoryCheckRequestModal = ({
  onUpdateInventoryCheckRequest,
}: UpdateInventoryCheckRequestModalProps) => {
  const { accessToken } = useAuth();
  const theme = useTheme();

  const [open, setOpen] = useState<boolean>(false);
  const [fileProcessingStatus, setFileProcessingStatus] = useState<FileProcessingStatus>('NotStarted');
  const [uploadResults, setUploadResults] = useState<string>();
  const [errorMessage, setErrorMessage] = useState<string>();
  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const handleModalButtonClick = () => {
    setOpen(true);
  };

  const handleUpdaterSaveButtonClick = async () => {
    setFileProcessingStatus('Uploading');

    if (accessToken && selectedFile) {
      try {
        const formData = new FormData();
        formData.append('file', selectedFile);
        formData.append('filename', selectedFile.name);

        const response = await fetch(`${appSettings.api.endpoint}/api/v2/InventoryCheckRequests`, {
          method: 'PUT',
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          body: formData,
        });

        if (response.ok) {
          let results = (await response.json()) as InvenvtoryCheckRequestUploadResults;
          const samplesAvailable = `\n-  ${results.numYesAvailable} ${sample(results.numYesAvailable)} available`;
          const samplesNotAvailable = `\n-  ${results.numNoAvailable} ${sample(results.numNoAvailable)} not available`;
          const samplesNotChecked =
            results.numNullAvailable === 0
              ? ''
              : `\n-  ${results.numNullAvailable} ${sample(results.numNullAvailable)} not checked`;
          const invlaidSamples =
            results.numInvalidSampleIds === 0
              ? ''
              : `\n-  ${results.numInvalidSampleIds} invalid sample ${id(results.numInvalidSampleIds)}`;

          setUploadResults(
            `Upload results:${samplesAvailable}${samplesNotAvailable}${samplesNotChecked}${invlaidSamples}`
          );
          setFileProcessingStatus('Complete');
          onUpdateInventoryCheckRequest();
        } else {
          let responseBody: { message: string } = await response.json();
          setFileProcessingStatus('Error');

          if (responseBody.message) {
            setErrorMessage(responseBody.message);
          } else if (response.status === 401) {
            setErrorMessage('Error: unauthorized user.');
          } else {
            setErrorMessage('Unknown error.');
          }
        }
      } catch (err) {
        setFileProcessingStatus('Error');
        setErrorMessage('Unknown error.');
      }
    } else {
      setFileProcessingStatus('NotStarted');
    }
  };

  const handleUpdaterClose = () => {
    clearSelectedState();
    setOpen(false);
  };

  const clearSelectedState = () => {
    setFileProcessingStatus('NotStarted');
    setErrorMessage(undefined);
    setSelectedFile(null);
  };

  const handleFileSelection = async (e: ChangeEvent<HTMLInputElement>) => {
    setFileProcessingStatus('NotStarted');
    if (e.target.files) {
      setSelectedFile(e.target.files[0]);
    }
  };

  return (
    <>
      <Button
        onClick={handleModalButtonClick}
        sx={[
          {
            color: theme.colors.alpha.black[100],
            '&:hover': {
              backgroundColor: 'transparent',
            },
          },
        ]}
      >
        <FileUploadIcon />
        <Typography variant='button' ml={1}>
          Upload Completed Request
        </Typography>
      </Button>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>
          <DialogCloseButton onClick={handleUpdaterClose} />
          <Typography variant='h3' gutterBottom>
            Update Inventory Check Request
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Box component='form' mt={2}>
            <Typography variant='h5' mt={2}>
              Select completed inventory check request
            </Typography>
            <Input type='file' onChange={handleFileSelection} sx={{ mt: 2, minWidth: 500 }} />
          </Box>
          {fileProcessingStatus === 'Complete' && (
            <DialogContentText display='flex' alignItems='center' mt={2} whiteSpace='pre'>
              {uploadResults}
            </DialogContentText>
          )}
          {fileProcessingStatus === 'Error' && (
            <DialogContentText display='flex' alignItems='center' mt={2} color='error'>
              <ErrorOutlineIcon sx={{ mr: 1 }} />
              {errorMessage}
            </DialogContentText>
          )}
        </DialogContent>
        {fileProcessingStatus === 'Uploading' && <LinearProgress />}
        <DialogActions>
          <Button onClick={handleUpdaterSaveButtonClick} disabled={fileProcessingStatus === 'Uploading'}>
            Save
          </Button>
          <Button onClick={handleUpdaterClose} disabled={fileProcessingStatus === 'Uploading'}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

function sample(count: number) {
  return count === 1 ? 'sample' : 'samples';
}

function id(count: number) {
  return count === 1 ? 'ID' : 'Ids';
}
