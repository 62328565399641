import { appSettings } from 'AppSettings';
import { orderBy } from 'lodash';

export interface LlCustomer {
  llCustomerId: string;
  name: string;
}

export async function GetLlCustomers(accessToken?: string): Promise<LlCustomer[]> {
  let llCustomers: LlCustomer[] = [];
  if (accessToken) {
    try {
      const response = await fetch(`${appSettings.api.endpoint}/api/v2/Customers`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      if (response.ok) {
        const values: LlCustomer[] = await response.json();
        llCustomers = [...orderBy(values, [(c: LlCustomer) => c.name.toLowerCase()], ['asc'])];
      }
    } catch (err) {
      console.dir(err);
    }
  }
  return llCustomers;
}
