import { Autocomplete, Box, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import useAuth from 'auth/UseAuth';
import { ChangeEvent, useState } from 'react';
import { DialogCloseButton } from '../components/DialogCloseButton';
import { DialogOpenButton } from '../components/DialogOpenButton';
import { CreatePathogenGroup, PathogenGroup } from 'data/PathogenGroupData';
import { PathogenGroupOption } from 'pathogenGroups/PathogenGroupAutoCompleteOptions';
import { ErrorManagement, LoadingState } from 'components/LoadingStateUtil';
import { PrimaryButton } from 'components/PrimaryButton';
import { CancelButton } from 'components/CancelButton';
import useMemoTranslation from '../hooks/UseMemoTranslation';
import { LoadingIndicator } from '../components/LoadingIndicator';
import { ErrorIndicator } from '../components/ErrorIndicator';
import { nameRequired, newPathogen, snomedClinicalTermsRequired, submit } from 'util/Constants';

export interface CreatePathogenGroupModalProps {
  pathogenGroupOptions: PathogenGroupOption[];
  onPathogenGroupCreate: Function;
}

export const CreatePathogenGroupModal = ({
  pathogenGroupOptions,
  onPathogenGroupCreate,
}: CreatePathogenGroupModalProps) => {
  const { accessToken } = useAuth();
  const { t } = useMemoTranslation();

  const defaultPathogenGroupOptions: PathogenGroupOption[] = [];
  const defaultPathogenGroupName = '';

  const defaultLoadingState: LoadingState = { status: 'NotStarted' };

  const [open, setOpen] = useState<boolean>(false);
  const [loadingState, setLoadingState] = useState<LoadingState>(defaultLoadingState);
  const [selectedPathogenGroupName, setSelectedPathogenGroupName] = useState<string>(defaultPathogenGroupName);
  const [selectedPathogenGroupOptions, setSelectedPathogenGroupOptions] =
    useState<PathogenGroupOption[]>(defaultPathogenGroupOptions);

  const handleButtonClick = async () => {
    setOpen(true);
  };

  const handleSave = async () => {
    ErrorManagement('Loading', setLoadingState, async () => {
      if (!selectedPathogenGroupOptions) {
        setLoadingState({ status: 'Error', errorMessage: 'Please select a pathogen.' });
        return;
      }

      let newPathogenGroup: PathogenGroup = {
        pathogenGroupId: '00000000-0000-0000-0000-000000000000',
        name: selectedPathogenGroupName,
        snomedClinicalTermIds: selectedPathogenGroupOptions.map(o => o.snomedClinicalTerm.snomedClinicalTermId),
        snomedConceptIds: selectedPathogenGroupOptions.map(o => o.snomedClinicalTerm.conceptId),
      };

      await CreatePathogenGroup(newPathogenGroup, accessToken);
      setLoadingState({ status: 'Complete' });
      handleClose();
      onPathogenGroupCreate();
    });
  };

  const handleClose = () => {
    clearSelectedState();
    setOpen(false);
    setLoadingState(defaultLoadingState);
  };

  const clearSelectedState = () => {
    setSelectedPathogenGroupOptions(defaultPathogenGroupOptions);
    setSelectedPathogenGroupName(defaultPathogenGroupName);
    setLoadingState(defaultLoadingState);
  };

  const handlePathogenGroupNameChange = (ev: ChangeEvent<HTMLInputElement>) => {
    setSelectedPathogenGroupName(ev.target.value);
  };

  const handleSnomedClinicalTermChange = (pathogenGroupOptions: PathogenGroupOption[]) => {
    setSelectedPathogenGroupOptions(pathogenGroupOptions);
  };

  return (
    <>
      <DialogOpenButton title={t(newPathogen)} onClick={handleButtonClick} />
      <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth={'sm'}>
        <DialogTitle>
          <DialogCloseButton onClick={handleClose} />
          {t('createNewPathogen')}
        </DialogTitle>
        <DialogContent>
          <Box component='form'>
            <TextField
              id='create-pathogen-group-name-input'
              fullWidth
              margin='normal'
              label={t(nameRequired)}
              onChange={handlePathogenGroupNameChange}
              type='text'
              value={selectedPathogenGroupName}
            />
            <Autocomplete
              id='create-pathogen-group-snomed-clinical-term-selection'
              data-testid='create-pathogen-group-snomed-clinical-term-selection'
              fullWidth
              multiple
              value={selectedPathogenGroupOptions}
              options={pathogenGroupOptions}
              getOptionLabel={option => option.label}
              renderInput={params => <TextField {...params} label={t(snomedClinicalTermsRequired)} margin='normal' />}
              onChange={(event, value) => handleSnomedClinicalTermChange(value ?? [])}
            />
          </Box>
          <LoadingIndicator loadingState={loadingState} margin={'None'} />
          <ErrorIndicator loadingState={loadingState} />
        </DialogContent>
        <DialogActions>
          <CancelButton onClick={handleClose} disabled={loadingState.status === 'Loading'} />
          <PrimaryButton
            onClick={handleSave}
            disabled={
              loadingState.status === 'Loading' ||
              selectedPathogenGroupName === defaultPathogenGroupName ||
              selectedPathogenGroupOptions.length < 1
            }
          >
            {t(submit)}
          </PrimaryButton>
        </DialogActions>
      </Dialog>
    </>
  );
};
