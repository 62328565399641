import { appSettings } from 'AppSettings';
import { orderBy } from 'lodash';

export interface ResearchProjectStatus {
  researchProjectStatus: string;
  description: string;
}

export async function GetResearchProjectStatus(accessToken?: string): Promise<ResearchProjectStatus[]> {
  let researchProjects: ResearchProjectStatus[] = [];

  if (accessToken) {
    try {
      const response = await fetch(`${appSettings.api.endpoint}/api/v2/ResearchProjects/Statuses`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      if (response.ok) {
        const values: ResearchProjectStatus[] = await response.json();

        researchProjects = [
          ...orderBy(values, [(status: ResearchProjectStatus) => status.researchProjectStatus.toLowerCase()], ['asc']),
        ];
      }
    } catch (err) {
      console.dir(err);
    }
  }
  return researchProjects;
}

export function CamelCaseToLowerCaseWithSpace(researchProjectStatus: string) {
  return researchProjectStatus.replace(/[A-Z]/g, ' $&').toLowerCase();
}
