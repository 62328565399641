import { AppBar, Box, Button, Dialog, DialogContent, IconButton, Toolbar, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { ResearchProject } from 'data/ResearchProjectData';
import { useState } from 'react';
import { AuthorizedSection } from 'auth/AuthorizedSection';
import { ResearchProjectCohortGrid } from '../cohortGrid/ResearchProjectCohortGrid';
import { ResearchProjectCohortExportButton } from './ResearchProjectCohortExportButton';
import { Link } from 'react-router-dom';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';

export interface ResearchProjectGridCellProps {
  researchProject: ResearchProject;
  onUpdateResearchProjectClick?: Function;
  url: string;
}

export const ResearchProjectGridCell = ({
  researchProject,
  onUpdateResearchProjectClick,
  url,
}: ResearchProjectGridCellProps) => {
  const [openResearchProjectPatientGrid, setOpenResearchProjectPatientGrid] = useState<boolean>(false);
  const [useDecryptedHashes, setUseDecryptedHashes] = useState<boolean>(false);

  const handleResearchProjectPatientGridClose = async () => {
    setOpenResearchProjectPatientGrid(false);
    setUseDecryptedHashes(false);
  };

  const handleUpdateResearchProjectButtonClick = async () => {
    if (!onUpdateResearchProjectClick) {
      return;
    }

    onUpdateResearchProjectClick(researchProject);
  };

  return (
    <>
      <Box display='flex' justifyContent='space-between' width='100%'>
        {/* Research project patient drill down button. Modal/dialog is below */}
        <Button
          component={Link}
          to={url}
          sx={[
            {
              fontSize: 'inherit',
              fontWeight: 'inherit',
              justifyContent: 'left',
              padding: 0,
              '&:hover': {
                backgroundColor: 'transparent',
              },
            },
          ]}
        >
          {researchProject.name}
        </Button>

        {/* 
                This is a research project update button with a click handler. 
                Modal is rendered in ResearchProjectManagmentPage.
            */}
        {onUpdateResearchProjectClick && (
          <Button
            onClick={handleUpdateResearchProjectButtonClick}
            sx={[
              {
                fontWeight: 'inherit',
                justifyContent: 'right',
                padding: 0,
                margin: '0px 3px 0px 5px',
                '&:hover': {
                  backgroundColor: 'transparent',
                },
              },
            ]}
          >
            <ModeEditOutlineOutlinedIcon />
          </Button>
        )}
      </Box>

      {/* Research project patient drill down modal */}
      <Dialog
        fullScreen={true}
        open={openResearchProjectPatientGrid}
        onClose={() => setOpenResearchProjectPatientGrid(false)}
      >
        <AppBar sx={{ position: 'relative', backgroundColor: 'black' }}>
          <Toolbar>
            <IconButton edge='start' color='inherit' onClick={handleResearchProjectPatientGridClose} aria-label='close'>
              <CloseIcon />
            </IconButton>
            {researchProject.name && (
              <Typography sx={{ ml: 2, flex: 1 }} variant='h6' component='div'>
                {`${researchProject.customer.name} - ${researchProject.name}`}
              </Typography>
            )}
          </Toolbar>
        </AppBar>
        <DialogContent>
          <AuthorizedSection requiredRole='ll-green-side-user'>
            <Box mt={1.5} mb={3}>
              <ResearchProjectCohortExportButton
                researchProjectId={researchProject.researchProjectId}
                cohortType='patient'
                useDecryptedHashes={useDecryptedHashes}
              />
              <ResearchProjectCohortExportButton
                researchProjectId={researchProject.researchProjectId}
                cohortType='sample'
                useDecryptedHashes={useDecryptedHashes}
              />
            </Box>
          </AuthorizedSection>
          {researchProject.researchProjectId && (
            <ResearchProjectCohortGrid researchProjectId={researchProject.researchProjectId} autoHeight={true} />
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};
