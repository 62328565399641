import { LoadingState } from './LoadingStateUtil';
import { Alert } from '@mui/material';

export interface ErrorIndicatorProps {
  loadingState: LoadingState;
}

export const ErrorIndicator = ({ loadingState }: ErrorIndicatorProps) => {
  if (loadingState.status !== 'Error') {
    return <></>;
  }

  const msg = loadingState.errorMessage?.trim().length === 0 ? 'Unknown Error' : loadingState.errorMessage;

  return (
    <Alert sx={{ margin: 2 }} severity='error'>
      {msg}
    </Alert>
  );
};
