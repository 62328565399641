import { ResearchProjectStatusOption } from 'researchProjectManagement/ResearchProjectAutocompleteOptions';
import useAuth from '../../auth/UseAuth';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { CamelCaseToLowerCaseWithSpace, GetResearchProjectStatus } from 'data/ResearchProjectStatusData';

export const useResearchProjectStatusOptions = (): [
  ResearchProjectStatusOption[],
  boolean,
  Dispatch<SetStateAction<boolean>>
] => {
  const { accessToken } = useAuth();
  const [researchProjectStatusOptions, setResearchProjectStatusOptions] = useState<ResearchProjectStatusOption[]>([]);
  const [refresh, refreshOptions] = useState<boolean>(true);

  useEffect(() => {
    async function getData() {
      const researchProjectStatuses = await GetResearchProjectStatus(accessToken);
      setResearchProjectStatusOptions(
        researchProjectStatuses.map(rps => {
          const statusFormatted = CamelCaseToLowerCaseWithSpace(rps.researchProjectStatus);
          return { label: statusFormatted, status: rps.researchProjectStatus };
        })
      );
    }

    getData();
  }, [refresh, accessToken]);

  return [researchProjectStatusOptions, refresh, refreshOptions];
};
