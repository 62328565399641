import { Accordion, Card } from 'react-bootstrap';
import { CustomToggle, getAccordianExpansionSymbolCOPY } from './HistoricCountFilters';
import { ChangeEvent } from 'react';
import { DiseaseArea } from 'data/DiseaseAreaData';

export interface DiseaseAreaFilterProps {
  eventKey: string;
  toggleSelection: string | undefined;
  setToggleSelection: Function;
  diseaseAreas: DiseaseArea[];
  handleDiseaseAreaSelection: (ev: ChangeEvent<HTMLInputElement>) => void;
  diseaseAreaSelectAll: boolean;
  handleDiseaseAreaSelectAll: (ev: ChangeEvent<HTMLInputElement>) => void;
}

export const DiseaseAreaFilter = ({
  eventKey,
  toggleSelection,
  setToggleSelection,
  diseaseAreas,
  handleDiseaseAreaSelection,
  diseaseAreaSelectAll,
  handleDiseaseAreaSelectAll,
}: DiseaseAreaFilterProps) => {
  return (
    <Card bsPrefix='historic-counts-filters' key={`card-${eventKey}`}>
      <Card.Header bsPrefix='historic-counts-filters-subgroup'>
        <CustomToggle eventKey={eventKey} toggleSelection={toggleSelection} setToggleSelection={setToggleSelection}>
          <div>Disease Areas</div>
          <div>{getAccordianExpansionSymbolCOPY(eventKey, toggleSelection)}</div>
        </CustomToggle>
      </Card.Header>
      <Accordion.Collapse eventKey={eventKey}>
        <Card.Body bsPrefix='historic-counts-filters-accordian-item-selection'>
          <div key='disease-areas-select-all-div'>
            <input
              className='historic-counts-filters-select-all-checkbox'
              type='checkbox'
              id='disease-areas-select-all-chckbx'
              key='disease-areas-select-all-chckbx'
              name='disease-areas-select-all'
              value='disease-areas-select-all'
              checked={diseaseAreaSelectAll}
              onChange={handleDiseaseAreaSelectAll}
            />
            <label className='historic-counts-filters-select-all-checkboxlbl' key='disease-areas-select-all-chckbxlbl'>
              Select All
            </label>
          </div>
          {diseaseAreas.map(diseaseArea => (
            <div key={`${diseaseArea.name} selection`} className='historic-counts-filters-accordian-item-test'>
              <input
                className='historic-counts-filters-accordian-item-checkbox'
                type='checkbox'
                id={diseaseArea.name}
                key={`${diseaseArea.name} chckbx`}
                name={diseaseArea.name}
                value={diseaseArea.name}
                checked={diseaseArea.selected}
                onChange={handleDiseaseAreaSelection}
              />
              <label
                className='historic-counts-filters-accordian-item-checkboxlbl'
                htmlFor={diseaseArea.name}
                key={`${diseaseArea.name} lbl`}
              >
                {diseaseArea.name}
              </label>
            </div>
          ))}
        </Card.Body>
      </Accordion.Collapse>
    </Card>
  );
};
