import { Accordion, Card } from 'react-bootstrap';
import { Lab } from 'data/LabData';
import { CustomToggle, getAccordianExpansionSymbolCOPY } from './HistoricCountFilters';
import { ChangeEvent } from 'react';

export interface LabFilterProps {
  eventKey: string;
  toggleSelection: string | undefined;
  setToggleSelection: Function;
  labs: Lab[];
  handleLabSelection: (ev: ChangeEvent<HTMLInputElement>) => void;
  labSelectAll: boolean;
  handleLabSelectAll: (ev: ChangeEvent<HTMLInputElement>) => void;
}

export const LabFilter = ({
  eventKey,
  toggleSelection,
  setToggleSelection,
  labs,
  handleLabSelection,
  labSelectAll,
  handleLabSelectAll,
}: LabFilterProps) => {
  return (
    <Card bsPrefix='historic-counts-filters' key={`card-${eventKey}`}>
      <Card.Header bsPrefix='historic-counts-filters-subgroup'>
        <CustomToggle eventKey={eventKey} toggleSelection={toggleSelection} setToggleSelection={setToggleSelection}>
          <div>Labs</div>
          <div>{getAccordianExpansionSymbolCOPY(eventKey, toggleSelection)}</div>
        </CustomToggle>
      </Card.Header>
      <Accordion.Collapse eventKey={eventKey}>
        <Card.Body bsPrefix='historic-counts-filters-accordian-item-selection'>
          <div key='labs-select-all-div'>
            <input
              className='historic-counts-filters-select-all-checkbox'
              type='checkbox'
              id='labs-select-all-chckbx'
              key='labs-select-all-chckbx'
              name='labs-select-all'
              value='labs-select-all'
              checked={labSelectAll}
              onChange={handleLabSelectAll}
            />
            <label className='historic-counts-filters-select-all-checkboxlbl' key='labs-select-all-chckbxlbl'>
              Select All
            </label>
          </div>
          {labs.map(lab => (
            <div key={`${lab.name} selection`} className='historic-counts-filters-accordian-item-test'>
              <input
                className='historic-counts-filters-accordian-item-checkbox'
                type='checkbox'
                id={lab.name}
                key={`${lab.name} chckbx`}
                name={lab.name}
                value={lab.name}
                checked={lab.selected}
                onChange={handleLabSelection}
              />
              <label
                className='historic-counts-filters-accordian-item-checkboxlbl'
                htmlFor={lab.name}
                key={`${lab.name} lbl`}
              >
                {lab.name}
              </label>
            </div>
          ))}
        </Card.Body>
      </Accordion.Collapse>
    </Card>
  );
};
