import React, { useEffect, useState } from 'react';
import { Box, Dialog, DialogContent, DialogTitle, Tab, Tabs, useTheme } from '@mui/material';
import { SampleJourneyHierarchyGrid } from './SampleJourneyHierarchyGrid';
import { DialogCloseButton } from '../../components/DialogCloseButton';
import useMemoTranslation from '../../hooks/UseMemoTranslation';
import { DialogTypography } from '../../components/DialogTypography';
import { SampleJourneyHistoryGrid } from './SampleJourneyHistoryGrid';
import { LoadingState, LoadState } from '../../components/LoadingStateUtil';
import {
  ConvertToSampleJourneyTree,
  GetAllSampleIds,
  GetAllTransitionAndTransitionSamples,
  GetHierarchyForSampleId,
  GetSampleJourneyTransitionInformation,
  GetSampleSpecificBranch,
  SampleJourneyTransitionInformation,
  SampleJourneyTree,
} from '../../data/SampleJourneyData';
import { TransitionAndTransitionSample } from '../../data/SampleTrackingTransitionData';
import { Dictionary } from '../../util/TypeUtil';
import { chunk, compact, flatMap, keyBy, mapValues, uniqBy } from 'lodash';
import { getSampleIdentifier, Sample } from '../../data/SampleData';
import useAuth from '../../auth/UseAuth';
import { LoadingIndicator } from '../../components/LoadingIndicator';
import { ErrorIndicator } from '../../components/ErrorIndicator';
import { FlexTableBox } from '../../components/FlexTableBox';
import { CustomizableSwitch } from '../../components/CustomizableSwitch';
import { useParams } from 'react-router-dom';
import { useResearchProjectTransitionPath } from '../../components/hooks/UseResearchProjectTransitionPaths';

type PageTabs = 'history' | 'hierarchy';

export interface SampleHierarchyDialogProps {
  sampleIds: ReadonlyArray<string>;
  open: boolean;
  onClose?: () => void;
}

export const SampleHierarchyDialog = ({ sampleIds, open, onClose = () => {} }: SampleHierarchyDialogProps) => {
  const { accessToken } = useAuth();
  let { researchProjectId } = useParams() as { researchProjectId: string };
  const { t } = useMemoTranslation();
  const theme = useTheme();

  const [modalOpen, setModalOpen] = useState<boolean>(open);
  const [selectedTab, setSelectedTab] = useState<PageTabs>('history');

  const [loadingState, setLoadingState] = useState<LoadingState>({ status: 'Loading' });

  const [selectedSampleIds, setSelectedSampleIds] = useState<ReadonlyArray<string>>(sampleIds);
  const [baseHierarchyTreeData, setBaseHierarchyTreeData] = useState<ReadonlyArray<SampleJourneyTree>>([]);
  const [transitionInformation, setTransitionInformation] = useState<ReadonlyArray<SampleJourneyTransitionInformation>>(
    []
  );
  const [transitionSample, setTransitionSample] = useState<ReadonlyArray<TransitionAndTransitionSample>>([]);
  const [samples, setSamples] = useState<ReadonlyArray<Sample>>([]);
  const [identifiersBySampleId, setIdentifiersBySampleId] = useState<Dictionary<string>>({});
  const [useDecryptedHashes, setUseDecryptedHashes] = useState<boolean>(false);
  const [filterOutInputOrders, setFilterOutInputOrders] = useState<boolean>(true);

  const [branchSpecificHierarchyData, setBranchSpecificHierarchyData] = useState<ReadonlyArray<SampleJourneyTree>>([]);
  const [branchSpecificTransitionSampleData, setBranchSpecificTransitionSampleData] = useState<
    ReadonlyArray<TransitionAndTransitionSample>
  >([]);

  const [transitionPaths] = useResearchProjectTransitionPath(researchProjectId, setLoadingState);

  useEffect(() => {
    return LoadState(setLoadingState, async () => {
      if (accessToken && sampleIds && sampleIds.length > 0) {
        const data = flatMap(
          await Promise.all(
            sampleIds.map(async id => await GetHierarchyForSampleId(id, accessToken, useDecryptedHashes))
          )
        );

        if (data === null) {
          return;
        }

        const allSamples = uniqBy(
          data.flatMap(d => [d.root, ...d.hierarchy.map(h => h.ancestor), ...d.hierarchy.map(h => h.descendant)]),
          i => i.sampleId
        );
        const allSampleIds = allSamples.map(i => i.sampleId);

        const informationData = compact(
          flatMap(
            await Promise.all(
              chunk(allSampleIds, 10).map(async ids => await GetSampleJourneyTransitionInformation(ids, accessToken))
            )
          )
        );

        const transitionSamplesData = compact(
          flatMap(
            await Promise.all(
              chunk(allSampleIds, 10).map(async ids => await GetAllTransitionAndTransitionSamples(ids, accessToken))
            )
          )
        );

        const sampleById = keyBy(allSamples, i => i.sampleId);
        const informationById = keyBy(informationData, i => i.sampleId);

        const identifiers = mapValues(
          keyBy(allSampleIds, i => i),
          sampleId => {
            const info = informationById[sampleId];
            const sample = sampleById[sampleId];

            return getSampleIdentifier(sample, info?.currentTransitionSample ?? info?.transitionSampleCreatedIn);
          }
        );

        setSamples(allSamples);
        setIdentifiersBySampleId(identifiers);
        setTransitionInformation(informationData);
        setTransitionSample(transitionSamplesData);
        setBaseHierarchyTreeData(data.map(tree => ConvertToSampleJourneyTree(tree)));
      }
    });
  }, [accessToken, sampleIds, useDecryptedHashes]);

  useEffect(() => {
    const hierarchyData = compact(baseHierarchyTreeData.map(tree => GetSampleSpecificBranch(tree, selectedSampleIds)));
    setBranchSpecificHierarchyData(hierarchyData);

    const allSampleIds = GetAllSampleIds(hierarchyData);

    setBranchSpecificTransitionSampleData(
      transitionSample.filter(i => allSampleIds.includes(i.transitionSample.sampleId))
    );
  }, [baseHierarchyTreeData, selectedSampleIds, transitionSample]);

  function handleClose() {
    onClose();
    setModalOpen(false);
  }

  return (
    <Dialog maxWidth={'xl'} fullWidth={true} open={modalOpen}>
      <DialogTitle sx={{ minWidth: '300px', paddingBottom: 0 }}>
        <DialogCloseButton onClick={() => handleClose()} />
        {t('sampleHierarchy')}
        <DialogTypography variant='subtitle1' sx={{ maxWidth: '75%' }}>
          {t('sampleHierarchyInfo')}
        </DialogTypography>
      </DialogTitle>
      <DialogContent>
        <Box mb={1}>
          <Tabs
            value={selectedTab}
            onChange={(event: React.SyntheticEvent, newValue: PageTabs) => {
              setSelectedTab(newValue);
            }}
            sx={{ borderBottom: 1, borderColor: 'divider', marginBottom: 2 }}
          >
            <Tab value='history' label={t('history')} sx={{ color: theme.colors.alpha.black[100] }} />
            <Tab value='hierarchy' label={t('hierarchy')} sx={{ color: theme.colors.alpha.black[100] }} />
          </Tabs>
        </Box>
        <Box mb={1} display='flex' justifyContent={'space-between'}>
          <Box mb={1} display='flex'>
            <CustomizableSwitch
              title={t('decryptedForeignHashes')}
              checked={useDecryptedHashes}
              onChange={() => {
                setUseDecryptedHashes(!useDecryptedHashes);
              }}
            />
            {selectedTab === 'history' && (
              <CustomizableSwitch
                title={t('filterOutInputOrders')}
                checked={filterOutInputOrders}
                onChange={() => {
                  setFilterOutInputOrders(!filterOutInputOrders);
                }}
              />
            )}
          </Box>
        </Box>
        <FlexTableBox sx={{ height: '60vh' }}>
          {selectedTab === 'history' && (
            <SampleJourneyHistoryGrid
              identifiersBySampleId={identifiersBySampleId}
              transitionInformation={transitionInformation}
              transitionSample={branchSpecificTransitionSampleData}
              selectedSampleIds={selectedSampleIds}
              setSelectedSampleIds={setSelectedSampleIds}
              filterOutInputOrders={filterOutInputOrders}
              samples={samples}
              transitionPaths={transitionPaths}
            />
          )}
          {selectedTab === 'hierarchy' && (
            <SampleJourneyHierarchyGrid
              identifiersBySampleId={identifiersBySampleId}
              transitionInformation={transitionInformation}
              hierarchyTreeData={branchSpecificHierarchyData}
              selectedSampleIds={selectedSampleIds}
              setSelectedSampleIds={setSelectedSampleIds}
              transitionPaths={transitionPaths}
            />
          )}
        </FlexTableBox>
      </DialogContent>
      <LoadingIndicator loadingState={loadingState} margin={'LRB'} />
      <ErrorIndicator loadingState={loadingState} />
    </Dialog>
  );
};
