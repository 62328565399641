import { Grid } from '@mui/material';
import { DiseaseAreaBlock } from './DiseaseAreaBlock';
import { ResearchProjectBlock } from './ResearchProjectBlock';
import { PathogenGroupBlock } from './PathogenGroupBlock';
import { SampleTrackingBlock } from './SampleTrackingBlock';

export const HomePageGrid = () => {
  return (
    <Grid
      sx={{
        px: 4,
      }}
      container
      direction='row'
      justifyContent='center'
      alignItems='stretch'
      spacing={4}
    >
      <Grid item md={6} xs={12}>
        <DiseaseAreaBlock />
      </Grid>
      <Grid item md={6} xs={12}>
        <PathogenGroupBlock />
      </Grid>
      <Grid item md={6} xs={12}>
        <ResearchProjectBlock />
      </Grid>
      <Grid item md={6} xs={12}>
        <SampleTrackingBlock />
      </Grid>

      {/* Living Lab Administration*/}
      {/* TODO: set auth roles */}
    </Grid>
  );
};
