export const appSettings = {
  auth: {
    domain: process.env.REACT_APP_AUTH0_DOMAIN || 'livinglab-development.us.auth0.com',
    client_id: process.env.REACT_APP_AUTH0_CLIENT_ID || '4GCIJ5CqacRCS5KiUTF3R0beS7mMkCxq',
    redirect_uri: window.location.origin,
    scope: 'openid profile email user_metadata LivingLab',
    audience: process.env.REACT_APP_AUDIENCE || 'https://livinglab',
  },
  api: {
    endpoint: process.env.REACT_APP_API_URL,
    prince_metrics_dns: process.env.REACT_APP_PRINCE_METRICS_DNS,
  },
  license: {
    muiX: 'd5b1af81ba5a5af69badc2110cda5c02Tz02MjU4NCxFPTE3MTExMjI2NjM5MTYsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=',
  },
};
