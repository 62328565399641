export type DateAggregate = 'day' | 'week' | 'month' | 'threeMonths' | 'year' | 'allTime';

export function formatTooltipDate(date: Date, dateAggregate: DateAggregate) {
  if (dateAggregate === 'day') {
    return date.toLocaleString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    });
  } else if (dateAggregate === 'week') {
    let start = date.toLocaleString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    });

    let endOfWeek = new Date(date);
    endOfWeek.setDate(endOfWeek.getDate() + 6);

    let end = endOfWeek.toLocaleString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    });

    return `${start} - ${end}`;
  } else if (dateAggregate === 'month') {
    return date.toLocaleString('en-US', {
      year: 'numeric',
      month: 'long',
    });
  } else {
    return date.toLocaleString('en-US', {
      year: 'numeric',
    });
  }
}

export function formatHAxisDate(date: Date, dateAggregate: DateAggregate) {
  if (dateAggregate === 'day' || dateAggregate === 'week') {
    return date.toLocaleString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    });
  } else if (dateAggregate === 'month') {
    return date.toLocaleString('en-US', {
      year: 'numeric',
      month: 'short',
    });
  } else {
    return date.toLocaleString('en-US', {
      year: 'numeric',
    });
  }
}

export function getHAxisDateFormat(dateAggregate: DateAggregate) {
  if (dateAggregate === 'day' || dateAggregate === 'week') {
    return 'MMM d, y';
  } else if (dateAggregate === 'month') {
    return 'MMM y';
  } else {
    return 'y';
  }
}
