import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  FormLabel,
  LinearProgress,
  Typography,
} from '@mui/material';
import useAuth from 'auth/UseAuth';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { FileProcessingStatus, GenerateFileDownload } from 'helpers/FileProcessing';
import { GetSampleSelectionStatuses, SampleSelectionStatus } from 'data/SampleSelectionStatusData';
import { ChangeEvent, useEffect, useState } from 'react';
import { ConvertToCapitalizedDashSeparated } from 'data/conversions/StatusEnumConversions';
import { appSettings } from 'AppSettings';
import { Biobank } from 'data/BiobankData';
import { GetResearchProjectBiobanks } from 'data/ResearchProjectCohortData';
import { DialogCloseButton } from '../../components/DialogCloseButton';
import { DialogOpenButton } from '../../components/DialogOpenButton';

export interface CreateInventoryCheckRequestModalProps {
  researchProjectId: string;
  onCreateInventoryCheckRequest: Function;
}

export const CreateInventoryCheckRequestModal = ({
  researchProjectId,
  onCreateInventoryCheckRequest,
}: CreateInventoryCheckRequestModalProps) => {
  const { accessToken } = useAuth();

  const [open, setOpen] = useState<boolean>(false);
  const [status, setStatus] = useState<FileProcessingStatus>('NotStarted');
  const [buttonDisabled, setButtonDisabled] = useState<boolean>(false);
  const [biobankCache, setBiobankCache] = useState<Biobank[]>([]);
  const [sampleSelectionStatusCache, setSampleSelectionStatusCache] = useState<SampleSelectionStatus[]>([]);

  useEffect(() => {
    async function getState() {
      if (accessToken) {
        const biobankValues: Biobank[] = await GetResearchProjectBiobanks(researchProjectId, accessToken);
        biobankValues.forEach(value => (value.selected = true));
        setBiobankCache([...biobankValues]);

        const statusValues: SampleSelectionStatus[] = (await GetSampleSelectionStatuses(accessToken)).filter(
          s => s.sampleSelectionStatus !== 'remove'
        );
        statusValues.forEach(value => (value.selected = value.sampleSelectionStatus !== 'possible'));
        setSampleSelectionStatusCache([...statusValues]);
      }
    }
    getState();
  }, [researchProjectId, accessToken]);

  const handleBiobankSelection = async (ev: ChangeEvent<HTMLInputElement>) => {
    let temp = [...biobankCache];
    const index = biobankCache.findIndex(b => b.name === ev.target.value);
    temp[index].selected = ev.target.checked;
    setBiobankCache(temp);
  };

  const handleSampleSelectionStatusSelection = async (ev: ChangeEvent<HTMLInputElement>) => {
    let temp = [...sampleSelectionStatusCache];
    const index = sampleSelectionStatusCache.findIndex(s => s.sampleSelectionStatus === ev.target.value);
    temp[index].selected = ev.target.checked;
    setSampleSelectionStatusCache(temp);
  };

  const handleExport = async () => {
    try {
      if (accessToken) {
        setButtonDisabled(true);
        setStatus('Downloading');

        let url = `${appSettings.api.endpoint}/api/v2/InventoryCheckRequests/${researchProjectId}`;
        let params = new URLSearchParams();

        biobankCache.forEach(b => {
          if (b.selected) {
            params.append('biobankIds', b.biobankId);
          }
        });

        sampleSelectionStatusCache.forEach(s => {
          if (s.selected) {
            params.append('sampleSelectionStatuses', s.sampleSelectionStatus);
          }
        });

        const response = await fetch((url += '?' + params), {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        if (response.ok) {
          await GenerateFileDownload(response);
          onCreateInventoryCheckRequest();
          setStatus('Complete');
        } else {
          setStatus('Error');
        }
      }
    } catch (err) {
      setStatus('Error');
    }

    setButtonDisabled(false);
    clearSelectedState();
    setOpen(false);
  };

  const handleClose = () => {
    clearSelectedState();
    setOpen(false);
  };

  const clearSelectedState = () => {
    let biobankTemp = [...biobankCache];
    biobankTemp.forEach(value => (value.selected = true));
    setBiobankCache([...biobankTemp]);

    let statusTemp = [...sampleSelectionStatusCache];
    statusTemp.forEach(value => (value.selected = value.sampleSelectionStatus !== 'possible'));
    setSampleSelectionStatusCache([...statusTemp]);
  };

  function AtLeastOneSelectionMade() {
    const biobankSelectionMade = biobankCache.find(b => b.selected);
    const statusSelectionMade = sampleSelectionStatusCache.find(s => s.selected);
    var test = biobankSelectionMade !== undefined && statusSelectionMade !== undefined;
    return test;
  }

  return (
    <>
      <DialogOpenButton disabled={buttonDisabled} onClick={() => setOpen(true)} />
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>
          <DialogCloseButton onClick={handleClose} />
          Create Inventory Check Requests
        </DialogTitle>
        <DialogContent>
          <Box component='form'>
            <Box mt={2.5} display='flex' flexDirection='row'>
              <FormGroup>
                <FormLabel sx={{ mb: 1 }}>
                  <Typography variant='caption'>Biobank Facilities</Typography>
                </FormLabel>
                {/* Make these radio buttons dynamic */}
                {biobankCache.map(b => {
                  return (
                    <FormControlLabel
                      value={`create-inventory-check-request-${b.name}-checkbox`}
                      label={b.name}
                      control={
                        <Checkbox size='small' checked={b.selected} value={b.name} onChange={handleBiobankSelection} />
                      }
                    />
                  );
                })}
              </FormGroup>
              <FormGroup sx={{ ml: 5 }}>
                <FormLabel sx={{ mb: 1 }}>
                  <Typography variant='caption'>Selection Statuses</Typography>
                </FormLabel>
                {sampleSelectionStatusCache.map(s => {
                  return (
                    <FormControlLabel
                      value={`create-inventory-check-request-${s.sampleSelectionStatus}-checkbox`}
                      label={ConvertToCapitalizedDashSeparated(s.sampleSelectionStatus)}
                      control={
                        <Checkbox
                          size='small'
                          checked={s.selected}
                          value={s.sampleSelectionStatus}
                          onChange={handleSampleSelectionStatusSelection}
                        />
                      }
                    />
                  );
                })}
              </FormGroup>
            </Box>
          </Box>
          {status === 'Error' && (
            <DialogContentText display='flex' alignItems='center' mt={1} color='error'>
              <ErrorOutlineIcon sx={{ mr: 1 }} />
              Error generating requests
            </DialogContentText>
          )}
        </DialogContent>
        {status === 'Downloading' && <LinearProgress />}
        <DialogActions>
          <Button
            onClick={handleExport}
            disabled={!AtLeastOneSelectionMade() || status === 'Downloading'}
          >{`Create & Export`}</Button>
          <Button onClick={handleClose} disabled={status === 'Downloading'}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
