import { Button, useTheme } from '@mui/material';
import { DiseaseArea } from 'data/DiseaseAreaData';
import { PatientCountType } from 'components/grid/GridCountType';

export interface BiobankCountGridCellProps {
  diseaseArea: DiseaseArea;
  biobankId: string;
  biobankName?: string;
  count: number;
  onBiobankCountClick(
    count: number,
    diseaseArea: DiseaseArea,
    patientRecordCountType: PatientCountType,
    biobankId?: string,
    sampleTypeId?: string
  ): void;
}

export const BiobankCountGridCell = ({
  diseaseArea,
  biobankId,
  biobankName,
  count,
  onBiobankCountClick,
}: BiobankCountGridCellProps) => {
  const theme = useTheme();

  const handleBiobankCountButtonClick = async () => {
    onBiobankCountClick(count, diseaseArea, 'byBiobank', biobankId, undefined);
  };

  return (
    <Button
      disabled={count === 0}
      onClick={handleBiobankCountButtonClick}
      sx={[
        {
          fontWeight: 'inherit',
          '&:hover': {
            backgroundColor: 'transparent',
          },
          '&:disabled': {
            color: `${theme.colors.primary.dark}`,
          },
        },
      ]}
    >
      {count.toLocaleString() ?? 0}
    </Button>
  );
};
