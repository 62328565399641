import { useSnackbar, VariantType, WithSnackbarProps } from 'notistack';
import React from 'react';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

let useSnackbarRef: WithSnackbarProps;

export const SnackbarUtilsConfigurator: React.FC = () => {
  useSnackbarRef = useSnackbar();
  return null;
};

let notificationCallbacks: any = {
  success(msg: string) {
    this.toast(msg, 'success');
  },
  warning(msg: string) {
    this.toast(msg, 'warning');
  },
  info(msg: string) {
    this.toast(msg, 'info');
  },
  error(msg: string) {
    this.toast(msg, 'error');
  },
  toast(msg: string, variant: VariantType = 'default') {
    useSnackbarRef.enqueueSnackbar(msg, {
      variant,
      key: new Date().getTime() + Math.random(),
      autoHideDuration: 10000,
      action: key => (
        <IconButton size='small' color='inherit' onClick={() => useSnackbarRef.closeSnackbar(key)}>
          <CloseIcon />
        </IconButton>
      ),
    });
  },
};

export default notificationCallbacks;
