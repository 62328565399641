import { appSettings } from 'AppSettings';
import { DownloadFile, FileProcessingState } from 'helpers/FileProcessing';
import useAuth from 'auth/UseAuth';
import DownloadDoneIcon from '@mui/icons-material/DownloadDone';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import { Button, Typography, useTheme } from '@mui/material';
import { useState } from 'react';
import { PatientCountType } from 'components/grid/GridCountType';
import { ItemOfResearchType } from 'data/ResearchProjectPatientData';
import { BaseDownloadFile } from 'data/BaseData';

export interface PatientCountExportButtonProps {
  countType: PatientCountType;
  itemOfResearchType: ItemOfResearchType;
  disabled: boolean;
}

export const PatientCountExportButton = ({
  countType,
  itemOfResearchType,
  disabled,
}: PatientCountExportButtonProps) => {
  const { accessToken } = useAuth();
  const theme = useTheme();

  const [buttonDisabled, setButtonDisabled] = useState<boolean>(disabled);
  const [fileProcessingState, setFileProcessingState] = useState<FileProcessingState>({ status: 'NotStarted' });

  const invokeGenerator = async () => {
    let metrics = itemOfResearchType === 'DiseaseArea' ? 'v2/DiseaseAreaMetrics' : 'v2/PathogenGroupMetrics';
    let url = `${appSettings.api.endpoint}/api/${metrics}/Counts/countBy/${countType}?exportType=xlsx`;

    setButtonDisabled(true);
    DownloadFile(setFileProcessingState, () => BaseDownloadFile(url, accessToken));
    setButtonDisabled(disabled);
  };

  return (
    <Button
      id='patient-count-export-button'
      onClick={invokeGenerator}
      disabled={buttonDisabled}
      sx={[
        {
          color: theme.colors.alpha.black[100],
          '&:hover': {
            backgroundColor: 'transparent',
          },
        },
      ]}
    >
      {fileProcessingState.status === 'NotStarted' && <SaveAltIcon />}
      {fileProcessingState.status === 'Downloading' && <HourglassBottomIcon />}
      {fileProcessingState.status === 'Complete' && <DownloadDoneIcon />}
      {fileProcessingState.status === 'Error' && <ErrorOutlineIcon />}

      <Typography variant='button' ml={1}>
        Export
      </Typography>
    </Button>
  );
};
