import { Autocomplete, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import useAuth from 'auth/UseAuth';
import React, { useEffect, useState } from 'react';
import { DialogOpenButton } from '../../../components/DialogOpenButton';
import { DialogCloseButton } from '../../../components/DialogCloseButton';
import { GetSupplementalFileTypes, SupplementalFileType } from '../../../data/SupplementalFileTypeData';
import {
  CreateSupplementalFilesForQualityCheckGroupId,
  CreateSupplementalFilesForTransitionId,
  DEFAULT_URL_SUPPLEMENTAL_FILE,
  SupplementalFile,
} from '../../../data/SupplementalFileData';
import { ErrorManagement, LoadingState, LoadState } from '../../../components/LoadingStateUtil';
import { LoadingIndicator } from '../../../components/LoadingIndicator';
import { ErrorIndicator } from '../../../components/ErrorIndicator';
import useMemoTranslation from '../../../hooks/UseMemoTranslation';

export interface BaseModalProps {
  id: string;
  onClose?: (hasChanges: boolean) => void;
}

export interface DelayedSavingModalProps {
  onCreate: (supplementalFile: SupplementalFile) => void;
  onClose?: (hasChanges: boolean) => void;
}

export const CreateSupplementalFilesModalForTransition = ({ id, onClose }: BaseModalProps) => {
  return (
    <Modal
      onClose={onClose}
      create={async (supplementalFile: SupplementalFile, accessToken: string) =>
        await CreateSupplementalFilesForTransitionId(id, supplementalFile, accessToken)
      }
    />
  );
};
export const CreateSupplementalFilesModalForQualityCheckGroup = ({ id, onClose }: BaseModalProps) => {
  return (
    <Modal
      onClose={onClose}
      create={async (supplementalFile: SupplementalFile, accessToken: string) =>
        await CreateSupplementalFilesForQualityCheckGroupId(id, supplementalFile, accessToken)
      }
    />
  );
};

export const CreateSupplementalFilesModal = ({ onClose, onCreate }: DelayedSavingModalProps) => {
  return <Modal onClose={onClose} create={onCreate} />;
};

export interface CreateSupplementalFilesModalProps {
  create: (supplementalFile: SupplementalFile, accessToken: string) => void;
  onClose?: (hasChanges: boolean) => void;
}

const Modal = ({ create, onClose = () => {} }: CreateSupplementalFilesModalProps) => {
  const { accessToken } = useAuth();
  const { t } = useMemoTranslation();

  const [loadingState, setLoadingState] = useState<LoadingState>({ status: 'NotStarted' });
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const [supplementalFileTypes, setSupplementalFileTypes] = useState<ReadonlyArray<SupplementalFileType>>([]);
  const [supplementalFile, setSupplementalFile] = useState<SupplementalFile>(DEFAULT_URL_SUPPLEMENTAL_FILE);

  function handleClose(hasChanges: boolean) {
    onClose(hasChanges);
    setModalOpen(false);
    setSupplementalFile(DEFAULT_URL_SUPPLEMENTAL_FILE);
    setLoadingState({ status: 'NotStarted' });
  }

  async function createFile() {
    ErrorManagement('Loading', setLoadingState, async () => {
      if (!accessToken) {
        return;
      }
      await create(supplementalFile, accessToken);
      handleClose(true);
    });
  }

  useEffect(() => {
    return LoadState(setLoadingState, async () => {
      if (!accessToken) {
        return;
      }

      const data = await GetSupplementalFileTypes(accessToken);
      setSupplementalFileTypes(data);
    });
  }, [accessToken]);

  return (
    <>
      <DialogOpenButton title={t('createSupplementalFile')} onClick={() => setModalOpen(true)} />
      <Dialog maxWidth={'xs'} fullWidth={true} open={modalOpen}>
        <DialogTitle>
          <DialogCloseButton onClick={() => handleClose(false)} />
          {t('createSupplementalFile')}
        </DialogTitle>
        <DialogContent>
          <Box
            alignItems='center'
            sx={{
              flexGrow: 1,
              flexDirection: 'column',
              display: 'flex',
            }}
          >
            <Autocomplete
              fullWidth
              options={supplementalFileTypes.map(s => s.name)}
              renderInput={params => <TextField {...params} label='Supplemental File Type *' margin='normal' />}
              onChange={(event, value) => {
                const type = supplementalFileTypes.find(s => s.name === value);

                setSupplementalFile({
                  ...supplementalFile,
                  supplementalFileTypeId: type?.supplementalFileTypeId ?? supplementalFile.supplementalFileTypeId,
                });
              }}
              value={
                supplementalFileTypes.find(s => s.supplementalFileTypeId === supplementalFile.supplementalFileTypeId)
                  ?.name
              }
            />
            <TextField
              fullWidth
              label={t('fileLocationRequired')}
              margin='normal'
              onChange={value =>
                setSupplementalFile({
                  ...supplementalFile,
                  location: value.target.value,
                })
              }
              value={supplementalFile.location}
              type='text'
              variant='outlined'
            />
            <TextField
              fullWidth
              label='Name'
              margin='normal'
              onChange={value =>
                setSupplementalFile({
                  ...supplementalFile,
                  name: value.target.value,
                })
              }
              value={supplementalFile.name ?? undefined}
              type='text'
              variant='outlined'
            />
            <TextField
              fullWidth
              label='Description'
              margin='normal'
              multiline={true}
              maxRows={3}
              onChange={value =>
                setSupplementalFile({
                  ...supplementalFile,
                  description: value.target.value,
                })
              }
              type='text'
              value={supplementalFile.description}
              variant='outlined'
            />
          </Box>
        </DialogContent>
        <LoadingIndicator loadingState={loadingState} margin={'LR'} />
        <ErrorIndicator loadingState={loadingState} />
        <DialogActions>
          <Button
            onClick={createFile}
            disabled={!supplementalFile.supplementalFileTypeId || !supplementalFile.location}
          >
            {t('add')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
