import { Typography } from '@mui/material';
import { TypographyProps } from '@mui/material/Typography';
import * as React from 'react';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';

export interface DialogTypographyProps {
  children: React.ReactNode;
  variant: TypographyProps['variant'];
  sx?: SxProps<Theme>;
}

/**
 * Mui's DialogTitle compiles into a <hN> tag. Meanwhile, Mui's Typography also compiles into a <hN> tag. React will
 * throw HTML DOM Validation errors because a higher order <h> tag is present in a lower order <h> tag.
 *
 * EX:
 * <h6>
 * <h1></h1>
 * </h6>
 *
 * But if you do
 * <h6>
 *   <div>
 *     <h1></h1>
 *   </div>
 * </h6>
 *
 * Then it's considered "valid" HTML. This component simply reduces HTML bloat by automatically wrapping MUI's Typography
 * in a div
 */
export const DialogTypography = ({ children, variant, sx }: DialogTypographyProps) => {
  return (
    <div>
      <Typography variant={variant} sx={sx}>
        {children}
      </Typography>
    </div>
  );
};
