import useAuth from '../../auth/UseAuth';
import { CheckByFailedReason, GetCheckByFailedReason } from '../../data/ReferenceData';
import { useEffect, useState } from 'react';
import { filter, orderBy } from 'lodash';

const useCheckByFailedReasons = (transitionEnumId?: string): ReadonlyArray<CheckByFailedReason> => {
  const { accessToken } = useAuth();
  const [checkByFailedReasons, setCheckByFailedReasons] = useState<ReadonlyArray<CheckByFailedReason>>([]);

  useEffect(() => {
    let isCancelled = false;

    if (transitionEnumId === undefined) {
      return;
    }

    GetCheckByFailedReason(accessToken).then(data => {
      if (isCancelled) {
        return;
      }

      setCheckByFailedReasons(
        filter(
          orderBy(data, i => i.name),
          i => i.transitionEnumId === transitionEnumId
        )
      );
    });

    return () => {
      isCancelled = true;
    };
  }, [accessToken, transitionEnumId]);

  return checkByFailedReasons;
};

export default useCheckByFailedReasons;
