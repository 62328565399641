export function RemoveUnderscores(dbString: string) {
  return dbString ? dbString.replaceAll('_', ' ') : dbString;
}

export function ConvertToSingleValue(countItems: number, possibleValues: string[]) {
  if (countItems > 1) {
    return 'multiple';
  }

  return possibleValues.length === 0 ? '' : possibleValues[0];
}
