import React, { useState } from 'react';
import { Button } from '@mui/material';
import { DeletePathogenGroup, PathogenGroup } from 'data/PathogenGroupData';
import DeleteIcon from '@mui/icons-material/Delete';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
import { ErrorManagement, LoadingState } from 'components/LoadingStateUtil';
import useAuth from 'auth/UseAuth';

export interface DeletePathogenGroupGridCellProps {
  pathogenGroup?: PathogenGroup;
  onDeletePathogenGroupClick: Function;
}

export function renderDeletePathogenGroupGridCell({
  pathogenGroup,
  onDeletePathogenGroupClick,
}: DeletePathogenGroupGridCellProps) {
  return (
    <DeletePathogenGroupGridCell
      pathogenGroup={pathogenGroup}
      onDeletePathogenGroupClick={onDeletePathogenGroupClick}
    />
  );
}

export const DeletePathogenGroupGridCell = ({
  pathogenGroup,
  onDeletePathogenGroupClick,
}: DeletePathogenGroupGridCellProps) => {
  const { accessToken } = useAuth();
  const [loadingState, setLoadingState] = useState<LoadingState>({ status: 'NotStarted' });

  const handleDeletePathogenGroupButtonClick = async () => {
    if (pathogenGroup) {
      ErrorManagement('Loading', setLoadingState, async () => {
        await DeletePathogenGroup(pathogenGroup, accessToken);
        setLoadingState({ status: 'Complete' });
        onDeletePathogenGroupClick();
      });
    }
  };

  return (
    <Button
      onClick={handleDeletePathogenGroupButtonClick}
      sx={[
        {
          fontWeight: 'inherit',
          padding: 0,
          margin: 0,
          '&:hover': {
            backgroundColor: 'transparent',
          },
        },
      ]}
    >
      {loadingState.status === 'NotStarted' && <DeleteIcon />}
      {loadingState.status === 'Loading' && <HourglassBottomIcon />}
      {loadingState.status === 'Error' && <ErrorOutlineIcon />}
    </Button>
  );
};
