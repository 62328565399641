import { Accordion, Card } from 'react-bootstrap';
import { CustomToggle, getAccordianExpansionSymbolCOPY } from './HistoricCountFilters';
import { ChangeEvent } from 'react';
import { ConsentEntitlement } from 'data/ConsentEntitlementData';

export interface ConsentEntitlementFilterProps {
  eventKey: string;
  toggleSelection: string | undefined;
  setToggleSelection: Function;
  consentEntitlements: ConsentEntitlement[];
  handleConsentSelection: (ev: ChangeEvent<HTMLInputElement>) => void;
}

export const ConsentEntitlementFilter = ({
  eventKey,
  toggleSelection,
  setToggleSelection,
  consentEntitlements,
  handleConsentSelection,
}: ConsentEntitlementFilterProps) => {
  return (
    <Card bsPrefix='historic-counts-filters' key={`card-${eventKey}`}>
      <Card.Header bsPrefix='historic-counts-filters-subgroup'>
        <CustomToggle eventKey={eventKey} toggleSelection={toggleSelection} setToggleSelection={setToggleSelection}>
          <div>Consent Entitlements</div>
          <div>{getAccordianExpansionSymbolCOPY(eventKey, toggleSelection)}</div>
        </CustomToggle>
      </Card.Header>
      <Accordion.Collapse eventKey={eventKey}>
        <Card.Body bsPrefix='historic-counts-filters-accordian-item-selection'>
          {consentEntitlements.map(consent => (
            <div key={`${consent.description} selection`} className='historic-counts-filters-accordian-item-test'>
              <input
                className='historic-counts-filters-accordian-item-checkbox'
                type='checkbox'
                id={consent.description}
                key={`${consent.description} chckbx`}
                name={consent.description}
                value={consent.description}
                checked={true}
                disabled={true}
                onChange={handleConsentSelection}
              />
              <label
                className='historic-counts-filters-accordian-item-checkboxlbl'
                htmlFor={consent.description}
                key={`${consent.description} lbl`}
              >
                {consent.description}
              </label>
            </div>
          ))}
        </Card.Body>
      </Accordion.Collapse>
    </Card>
  );
};
