import { appSettings } from 'AppSettings';
import { Biobank } from './BiobankData';
import { BasePutVoid } from './BaseData';
import { ResearchProjectSamplesToInclude } from './ResearchProjectPatientData';

export interface ResearchProjectPatient {
  researchProjectId: string;
  patientId: string;
  deidentity: string;
  patientSelectionStatus: string;
  numOtherProjects: number;
  sampleCount: number;
  numSamplesSelected: number;
  biobanks: string[];
  labs: string[];
  sampleTypes: string[];
  sampleComments: string[];
  latestSampleCollectionDate: Date;
  gender: string;
  yearOfBirth: string;
  race: string;
  ethnicity: string;
  snomedConceptIds: string[];
  snomedTerms: string[];
  icd9AndIcd10Codes: string[];
  notes?: string;
}

export type ResearchProjectCohortRecordOrderBy =
  | 'patientId'
  | 'deidentity'
  | 'patientSelectionStatus'
  | 'numOtherProjects'
  | 'sampleCount'
  | 'numSamplesSelected'
  | 'biobanks'
  | 'labs'
  | 'sampleTypes'
  | 'sampleComments'
  | 'latestSampleCollectionDate'
  | 'gender'
  | 'yearOfBirth'
  | 'race'
  | 'ethinicity'
  | 'snomedConceptIds'
  | 'snomedTerms'
  | 'icd9AndIcd10Codes'
  | 'notes';

export type SortOrder = 'asc' | 'desc';

export type PatientSelectionStatus = 'possible' | 'selected' | 'selectedExperimental' | 'selectedControl';

export type CohortType = 'patient' | 'sample';

export async function GetResearchProjectPatientCount(researchProjectId: string, accessToken: string): Promise<number> {
  let count: number = -1;

  if (accessToken) {
    try {
      const response = await fetch(
        `${appSettings.api.endpoint}/api/v2/ResearchProjectCohort/${researchProjectId}/PatientCount`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      if (response.ok) {
        count = await response.json();
      }
    } catch (err) {
      console.dir(err);
    }
  }
  return count;
}

export async function GetResearchProjectPatients(
  researchProjectId: string,
  orderBy: ResearchProjectCohortRecordOrderBy,
  sortOrder: string,
  pageSize: number,
  pageStart: number,
  accessToken: string
): Promise<ResearchProjectPatient[]> {
  let researchProjectPatients: ResearchProjectPatient[] = [];
  if (accessToken) {
    try {
      let url = `${appSettings.api.endpoint}/api/v2/ResearchProjectCohort/${researchProjectId}`;
      let params = new URLSearchParams();
      params.append('orderBy', orderBy);
      params.append('sortOrder', sortOrder);
      params.append('pageSize', pageSize.toString());
      params.append('pageStart', pageStart.toString());

      const response = await fetch((url += '?' + params), {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      if (response.ok) {
        const values: ResearchProjectPatient[] = await response.json();
        researchProjectPatients = [...values];
      } else {
        let message = await response.text();
        throw new Error(message);
      }
    } catch (err) {
      throw err;
    }
  }
  return researchProjectPatients;
}

export async function GetResearchProjectBiobanks(researchProjectId: string, accessToken: string): Promise<Biobank[]> {
  let biobanks: Biobank[] = [];
  if (accessToken) {
    try {
      const response = await fetch(
        `${appSettings.api.endpoint}/api/v2/ResearchProjectCohort/${researchProjectId}/Biobanks`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      if (response.ok) {
        biobanks = await response.json();
      } else {
        let message = await response.text();
        throw new Error(message);
      }
    } catch (err) {
      throw err;
    }
  }
  return biobanks;
}

export async function UpdateResearchProjectCohort(
  cohortType: CohortType,
  samplesToInclude: ResearchProjectSamplesToInclude,
  file: File,
  accessToken?: string
): Promise<void> {
  const cohortTypeToUpdate = cohortType === 'patient' ? 'Patients' : 'Samples';

  let params = new URLSearchParams();
  params.append('researchProjectSamplesToInclude', samplesToInclude);

  const formData = new FormData();
  formData.append('file', file);
  formData.append('filename', file.name);

  let url = `${appSettings.api.endpoint}/api/v1/ResearchProjectCohort/${cohortTypeToUpdate}`;

  return await BasePutVoid((url += '?' + params), formData, accessToken);
}
