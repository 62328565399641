import { Box, Switch, Typography } from '@mui/material';
import { ChangeEvent } from 'react';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { LightTooltip } from './grid/cell/GridCellTooltip';

export interface CustomizableSwitchProps {
  title: string;
  informationText?: string;
  checked: boolean;
  onChange: ((event: ChangeEvent<HTMLInputElement>, checked: boolean) => void) | undefined;
}

export const CustomizableSwitch = ({ title, informationText, checked, onChange }: CustomizableSwitchProps) => {
  return (
    <Box sx={{ marginLeft: '5px', marginRight: '5px', alignItems: 'center', flexDirection: 'row', display: 'flex' }}>
      <Switch checked={checked} onChange={onChange} inputProps={{ 'aria-label': title }} />
      <Typography fontSize={'12px'} variant='button'>
        {title}
      </Typography>
      {informationText && (
        <LightTooltip title={informationText}>
          <HelpOutlineIcon sx={{ marginLeft: 0.5 }} />
        </LightTooltip>
      )}
    </Box>
  );
};
