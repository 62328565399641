import { appSettings } from 'AppSettings';
import { BaseGet } from './BaseData';

export interface Lab {
  labId: string;
  name: string;
  address1?: string;
  address2?: string;
  address3?: string;
  city?: string;
  state?: string;
  postalCode?: string;
  country?: string;
  phone?: string;
  primaryEmail?: string;
  primaryPhone?: string;
  primaryContactName?: string;
  selected?: boolean;
}

export async function GetLabs(accessToken?: string): Promise<Lab[]> {
  return await BaseGet(`${appSettings.api.endpoint}/api/v2/Labs`, accessToken);
}

export async function GetLabsWithSamples(
  accessToken?: string,
  pageStart: number = 0,
  pageSize: number = 100
): Promise<Lab[]> {
  if (accessToken) {
    try {
      let url = `${appSettings.api.endpoint}/api/v2/labs/query`;
      let params = new URLSearchParams();
      params.append('onlyLabsWithSamples', 'true');
      params.append('pageStart', pageStart.toString());
      params.append('pageSize', pageSize.toString());

      const response = await fetch((url += '?' + params), {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      if (response.ok) {
        return await response.json();
      }
    } catch (err) {
      console.dir(err);
    }
  }

  return [];
}
