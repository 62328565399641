import useAuth from '../../auth/UseAuth';
import { GetOrganizations, Organization } from '../../data/ReferenceData';
import { useEffect, useState } from 'react';

const useOrganizations = (): ReadonlyArray<Organization> => {
  const { accessToken } = useAuth();
  const [organizations, setOrganizations] = useState<ReadonlyArray<Organization>>([]);

  useEffect(() => {
    async function getData() {
      const data = await GetOrganizations(accessToken);
      setOrganizations(data);
    }

    getData();
  }, [accessToken]);

  return organizations;
};

export default useOrganizations;
