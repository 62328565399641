import { forEach } from 'lodash';

export interface Dictionary<T> {
  [Key: string]: T;
}

export class CaseInsensitiveMap<T, U> extends Map<T, U> {
  set(key: T, value: U): this {
    if (typeof key === 'string') {
      key = key.toLowerCase() as any as T;
    }
    return super.set(key, value);
  }

  get(key: T): U | undefined {
    if (typeof key === 'string') {
      key = key.toLowerCase() as any as T;
    }

    return super.get(key);
  }

  has(key: T): boolean {
    if (typeof key === 'string') {
      key = key.toLowerCase() as any as T;
    }

    return super.has(key);
  }
}

export function createCaseInsensitiveMap<U>(map?: Dictionary<U>): CaseInsensitiveMap<string, U> {
  const caseInsensitiveMap = new CaseInsensitiveMap<string, U>();

  if (map) {
    forEach(map, (value, key) => {
      caseInsensitiveMap.set(key, value);
    });
  }

  return caseInsensitiveMap;
}

export type UseStateSetter<T> = (value: ((prevState: T) => T) | T) => void;

export type UseState<T> = [items: T, setter: UseStateSetter<T>];
