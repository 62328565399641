import React, { CSSProperties } from 'react';
import { DialogOpenButton } from '../DialogOpenButton';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';

export interface GridCellCallBackProps {
  text: string;

  callBack(): void;

  disabled?(): boolean;

  textStyle?: CSSProperties;
  sx?: SxProps<Theme>;
}

export const GridCellCallBack = ({
  text,
  callBack,
  disabled = () => false,
  textStyle = {},
  sx,
}: GridCellCallBackProps) => {
  return (
    <DialogOpenButton disabled={disabled()} fontWeight={'inherit'} onClick={callBack} sx={sx}>
      <span style={{ pointerEvents: 'all', ...textStyle }}>{text}</span>
    </DialogOpenButton>
  );
};
