import { GridColDef } from '@mui/x-data-grid-pro';
import React, { useMemo } from 'react';
import {
  enteredAt,
  exitedAt,
  foreignHash,
  qualityCheckStatus,
  sampleAvailabilityTypeId,
  sampleBbid,
  sampleId,
  sampleIdentifier,
  sampleTypeId,
  transition,
} from '../../util/Constants';
import { makeStyles } from '@mui/styles';
import { Transition, TransitionAndTransitionSample } from '../../data/SampleTrackingTransitionData';
import useMemoTranslation from '../../hooks/UseMemoTranslation';
import { Box } from '@mui/material';
import { Dictionary, UseStateSetter } from '../../util/TypeUtil';
import { TransitionSample } from '../../data/SampleTrackingData';
import {
  dateComparator,
  dateFormatter,
  formatSampleAvailabilityTypeById,
  formatSampleType,
} from '../../util/grid/TableUtils';
import { GridCellCallBack } from '../../components/grid/GridCellCallback';
import { keyBy, orderBy } from 'lodash';
import { Sample, truncateForeignHash } from '../../data/SampleData';
import { SampleJourneyTransitionInformation } from '../../data/SampleJourneyData';
import useSampleAvailabilityTypes from '../../components/hooks/UseSampleAvailabilityTypes';
import { SampleAvailabilityType } from '../../data/ReferenceData';
import { CompactGridWrapper } from '../../components/grid/CompactGridWrapper';
import useSampleTypes from '../../components/hooks/UseSampleTypes';
import { SampleType } from '../../data/SampleTypeData';
import { ResearchProjectTransitionPath } from '../../data/ResearchProjectTransitionPathData';

export interface SampleJourneyHistoryGridProps {
  identifiersBySampleId: Dictionary<string>;
  transitionSample: ReadonlyArray<TransitionAndTransitionSample>;
  transitionInformation: ReadonlyArray<SampleJourneyTransitionInformation>;
  selectedSampleIds: ReadonlyArray<string>;
  setSelectedSampleIds: UseStateSetter<ReadonlyArray<string>>;
  filterOutInputOrders: boolean;
  samples: ReadonlyArray<Sample>;
  transitionPaths: ReadonlyArray<ResearchProjectTransitionPath>;
}

export type Row = {
  id: number;
  sampleIdentifier: string;
  transitionSampleCreatedIn?: string;
  qualityCheckCreatedAt?: Date;
} & Transition &
  TransitionSample &
  Sample;

const useStyles = makeStyles({
  highlightedRow: {
    backgroundColor: 'rgb(231, 221, 255)',
  },
  noHover: {
    '&:hover': {
      backgroundColor: 'inherit !important',
      pointerEvents: 'none',
    },
  },
});

export const SampleJourneyHistoryGrid = ({
  identifiersBySampleId,
  transitionSample,
  transitionInformation,
  selectedSampleIds,
  setSelectedSampleIds,
  filterOutInputOrders,
  samples,
  transitionPaths,
}: SampleJourneyHistoryGridProps) => {
  const classes = useStyles();

  const sampleAvailabilityTypes = useSampleAvailabilityTypes();
  const sampleTypes = useSampleTypes();

  const columns = useColumns(
    selectedSampleIds,
    identifiersBySampleId,
    setSelectedSampleIds,
    sampleAvailabilityTypes,
    sampleTypes
  );
  const rows = useRows(
    transitionSample,
    identifiersBySampleId,
    filterOutInputOrders,
    transitionInformation,
    samples,
    transitionPaths
  );

  return (
    <Box mb={1} display='flex' flexDirection={'row'} height={'100%'}>
      <CompactGridWrapper
        rows={rows}
        columns={columns}
        initialState={{
          columns: {
            columnVisibilityModel: {
              foreignHash: false,
              sampleBbid: false,
            },
          },
          sorting: {
            sortModel: [{ field: enteredAt, sort: 'asc' }],
          },
        }}
        density='compact'
        defaultGroupingExpansionDepth={100}
        disableRowSelectionOnClick
        getRowClassName={params =>
          selectedSampleIds && selectedSampleIds.includes(params.row.sampleId)
            ? classes.highlightedRow
            : classes.noHover
        }
      />
    </Box>
  );
};

const useRows = (
  transitionSampleData: ReadonlyArray<TransitionAndTransitionSample>,
  identifiersBySampleId: Dictionary<string>,
  filterOutInputOrders: boolean,
  transitionInformation: ReadonlyArray<SampleJourneyTransitionInformation>,
  samples: ReadonlyArray<Sample>,
  transitionPaths: ReadonlyArray<ResearchProjectTransitionPath>
): ReadonlyArray<Row> => {
  return useMemo(() => {
    if (transitionSampleData.length === 0) {
      return [];
    }

    const informationBySampleId = keyBy(transitionInformation, t => t.sampleId);
    const samplesBySampleId = keyBy(samples, t => t.sampleId);
    const transitionById = keyBy(transitionPaths, i => i.configuredTransitionId);

    const latestTransition = orderBy(transitionSampleData, i => i.transitionSample.enteredAt, 'desc')[0];

    const data: Row[] = transitionSampleData
      .map((r, index) => {
        const information = informationBySampleId[r.transitionSample.sampleId];
        const sample = samplesBySampleId[r.transitionSample.sampleId];

        return {
          id: index,
          sampleIdentifier: identifiersBySampleId[r.transitionSample.sampleId],
          transitionSampleCreatedIn: information.transitionSampleCreatedIn?.transitionSampleId,
          qualityCheckCreatedAt: information.transitionSampleCreatedIn?.qualityCheckCreatedAt,
          ...r.transitionSample,
          ...r.transition,
          ...sample,
          transition: transitionById[r.transition.configuredTransitionId ?? '']?.displayName,
        };
      })
      // Show all transitions the same has exited. The current. The root.
      .filter(
        i =>
          // If filter is disabled then pass all through
          !filterOutInputOrders ||
          // if sample is root then show
          !i.transitionSampleCreatedIn ||
          // if sample has exited order then show
          i.exitedAt ||
          // if sample is the latest transition then show
          i.transitionSampleId === latestTransition.transitionSample.transitionSampleId
      );

    return data;
  }, [
    transitionSampleData,
    identifiersBySampleId,
    filterOutInputOrders,
    transitionInformation,
    samples,
    transitionPaths,
  ]);
};

const useColumns = (
  selectedSampleIds: ReadonlyArray<string>,
  identifiersBySampleId: Dictionary<string>,
  setSelectedSampleIds: UseStateSetter<ReadonlyArray<string>>,
  sampleAvailabilityTypes: ReadonlyArray<SampleAvailabilityType>,
  sampleTypes: ReadonlyArray<SampleType>
): GridColDef[] => {
  const { t } = useMemoTranslation();

  return useMemo(
    () => [
      {
        field: sampleId,
        headerName: t(sampleIdentifier),
        headerAlign: 'left',
        align: 'left',
        minWidth: 250,
        cellClassName: 'monospace-font',
        renderCell: params => {
          const { value } = params;
          const text = identifiersBySampleId[value ?? ''] ?? '';

          return (
            <Box>
              {selectedSampleIds.includes(value) ? (
                <span style={{ fontFamily: 'monospace' }}>{text}</span>
              ) : (
                <GridCellCallBack
                  text={text}
                  callBack={() => {
                    setSelectedSampleIds(_ => [value]);
                  }}
                  sx={{ paddingLeft: 0, minWidth: 0, fontFamily: 'monospace' }}
                />
              )}
            </Box>
          );
        },
      },
      {
        field: transition,
        headerName: t(transition),
        headerAlign: 'left',
        align: 'left',
        width: 150,
        valueFormatter: ({ value }) => value && t(value),
      },
      {
        field: qualityCheckStatus,
        headerName: t(qualityCheckStatus),
        headerAlign: 'left',
        align: 'left',
        width: 150,
        valueFormatter: ({ value }) => value && t(value),
      },
      {
        field: enteredAt,
        headerName: t(enteredAt),
        headerAlign: 'left',
        align: 'left',
        minWidth: 100,
        sortComparator: dateComparator,
        valueFormatter: dateFormatter,
      },
      {
        field: exitedAt,
        headerName: t(exitedAt),
        headerAlign: 'left',
        align: 'left',
        minWidth: 100,
        sortComparator: dateComparator,
        valueFormatter: dateFormatter,
      },
      {
        field: sampleTypeId,
        headerName: t(sampleTypeId),
        headerAlign: 'left',
        align: 'left',
        width: 150,
        valueFormatter: ({ value }) => value && formatSampleType(sampleTypes, value),
      },
      {
        field: foreignHash,
        headerName: t(foreignHash),
        headerAlign: 'left',
        align: 'left',
        minWidth: 300,
        valueFormatter: ({ value }) => value && truncateForeignHash(value),
      },
      {
        field: sampleBbid,
        headerName: t(sampleBbid),
        headerAlign: 'left',
        align: 'left',
        minWidth: 300,
      },
      {
        field: sampleAvailabilityTypeId,
        headerName: t(sampleAvailabilityTypeId),
        headerAlign: 'left',
        align: 'left',
        width: 200,
        valueFormatter: ({ value }) => value && formatSampleAvailabilityTypeById(sampleAvailabilityTypes, value),
      },
    ],
    [t, selectedSampleIds, identifiersBySampleId, setSelectedSampleIds, sampleAvailabilityTypes, sampleTypes]
  );
};
