import { Button } from '@mui/material';
import { useState } from 'react';
import { ConfirmationDialog } from 'components/ConfirmationDialog';

export interface VerifyInformaticsTransitionModalProps {
  buttonTitle: string;
  numSamples: number;
  onVerify: Function;
  disableSubmitButton: boolean;
}

export const VerifyInformaticsTransitionModal = ({
  buttonTitle,
  numSamples,
  onVerify,
  disableSubmitButton,
}: VerifyInformaticsTransitionModalProps) => {
  const [open, setOpen] = useState<boolean>(false);

  const handleVeryify = async () => {
    setOpen(false);
    onVerify();
  };

  return (
    <>
      <Button onClick={() => setOpen(true)} disabled={disableSubmitButton}>
        {buttonTitle}
      </Button>
      <ConfirmationDialog
        open={open}
        onConfirm={handleVeryify}
        onClose={() => setOpen(false)}
        message={`Are you sure you want to submit order for ${numSamples} Sample(s)?\nThis expensive operation cannot be undone.`}
        confirmBtnText='Submit'
      />
    </>
  );
};
