import { AuthorizedPage } from 'auth/AuthorizedPage';
import useAuth from 'auth/UseAuth';
import { Box, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { LoadingStatus } from 'components/LoadingStatus';
import { Page } from 'components/Page';
import { GetAllResearchProjects, ResearchProject } from 'data/ResearchProjectData';
import { GetLlCustomers } from 'data/LlCustomerData';
import { LlCustomerOption } from 'researchProjectManagement/ResearchProjectAutocompleteOptions';
import { CreateResearchProjectModal } from 'researchProjectManagement/CreateResearchProjectModal';
import { UpdateResearchProjectModal } from 'researchProjectManagement/UpdateResearchProjectModal';
import { GetSampleTrackingCountsBySampleType, SampleTrackingCounts } from '../data/SampleTrackingData';
import useMemoTranslation from '../hooks/UseMemoTranslation';
import { FlexTableBox } from '../components/FlexTableBox';
import { PageContent } from '../components/PageContent';
import { Dictionary } from 'lodash';
import { CustomizableSwitch } from '../components/CustomizableSwitch';
import { FlexBox } from '../components/FlexBox';
import { ResearchProjectsGrid } from '../researchProjectManagement/projectsGrid/ResearchProjectsGrid';

export const ResearchProjectManagementPage = () => {
  const { accessToken } = useAuth();
  const { t } = useMemoTranslation();

  const [status, setStatus] = useState<LoadingStatus>('NotStarted');
  const [researchProjects, setResearchProjects] = useState<ResearchProject[]>();
  const [researchProjectCounts, setResearchProjectsCounts] = useState<Dictionary<ReadonlyArray<SampleTrackingCounts>>>(
    {}
  );
  const [customerOptions, setCustomerOptions] = useState<LlCustomerOption[]>([]);
  const [openResearchProjectUpdateModal, setOpenResearchProjectUpdateModal] = useState<boolean>(false);
  const [researchProjectToUpdate, setResearchProjectToUpdate] = useState<ResearchProject>();
  const [countInputsWithWorkLeft, setCountInputsWithWorkLeft] = useState<boolean>(true);

  useEffect(() => {
    async function getState(accessToken?: string) {
      setStatus('Loading');
      if (accessToken) {
        const projects = await GetAllResearchProjects(accessToken);
        setResearchProjects(projects);

        const dict: Dictionary<ReadonlyArray<SampleTrackingCounts>> = {};
        const promises = projects.flatMap(p => [
          GetSampleTrackingCountsBySampleType(p.researchProjectId, accessToken).then(data => {
            dict[p.researchProjectId] = data;
          }),
        ]);

        await Promise.all(promises);
        setResearchProjectsCounts(dict);

        const customers = await GetLlCustomers(accessToken);
        setCustomerOptions(
          customers.map(c => {
            return { label: c.name, customer: c };
          })
        );

        setStatus('Complete');
      }
    }
    getState(accessToken);
  }, [accessToken]);

  async function handleResearchProjectUpdate() {
    setStatus('Loading');
    if (accessToken) {
      setResearchProjects(await GetAllResearchProjects(accessToken));
      setStatus('Complete');
    }
  }

  async function handleCustomerCreate() {
    if (accessToken) {
      const customers = await GetLlCustomers(accessToken);
      setCustomerOptions(
        customers.map(c => {
          return { label: c.name, customer: c };
        })
      );
    }
  }

  async function handleOnUpdateResearchProjectClick(researchProject: ResearchProject) {
    setResearchProjectToUpdate(researchProject);
    setOpenResearchProjectUpdateModal(true);
  }

  async function handleUpdateResearchProjectModalClose() {
    setResearchProjectToUpdate(undefined);
    setOpenResearchProjectUpdateModal(false);
  }

  return (
    <AuthorizedPage>
      <Page title='Research Project Management'>
        <PageContent>
          <Box display='flex' flexDirection='row' alignItems='center' justifyContent={'space-between'}>
            <Typography variant='h3'>Research Projects</Typography>
            {status === 'Loading' && (
              <Box display='flex' flex={1} marginLeft={10}>
                <Spinner animation='border' />
              </Box>
            )}
            <FlexBox>
              <CustomizableSwitch
                title={t('countInputsWithWorkLeft')}
                informationText={t('countInputsWithWorkLeftInfo')}
                checked={!countInputsWithWorkLeft}
                onChange={() => {
                  setCountInputsWithWorkLeft(!countInputsWithWorkLeft);
                }}
              />
              <CreateResearchProjectModal
                customerOptions={customerOptions}
                onResearchProjectCreate={handleResearchProjectUpdate}
                onCustomerCreate={handleCustomerCreate}
                title={t('newResearchProject')}
              />
            </FlexBox>
            {researchProjectToUpdate && (
              <UpdateResearchProjectModal
                open={openResearchProjectUpdateModal}
                researchProject={researchProjectToUpdate}
                customerOptions={customerOptions}
                onResearchProjectUpdate={handleResearchProjectUpdate}
                onCustomerCreate={handleCustomerCreate}
                onClose={handleUpdateResearchProjectModalClose}
              />
            )}
          </Box>
          <FlexTableBox sx={{ marginTop: 2 }}>
            <ResearchProjectsGrid
              researchProjects={researchProjects ?? []}
              researchProjectCounts={researchProjectCounts}
              onUpdateResearchProjectClick={handleOnUpdateResearchProjectClick}
              countInputsWithWorkLeft={countInputsWithWorkLeft}
            />
          </FlexTableBox>
        </PageContent>
      </Page>
    </AuthorizedPage>
  );
};
