import { FC } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Button } from 'antd';
import { ExperimentTwoTone } from '@ant-design/icons';

export const LoginButton: FC = () => {
  const { loginWithRedirect } = useAuth0();

  return (
    <Button icon={<ExperimentTwoTone />} onClick={() => loginWithRedirect()}>
      Log In
    </Button>
  );
};
