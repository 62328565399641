import { GridColDef, useGridApiRef } from '@mui/x-data-grid-pro';
import { SampleTrackingCheckByFastqValidationResultPayload } from '../../data/SampleTrackingData';
import { compactGridHeaderClassName, file, sequencingLab, sequencingLabId } from '../../util/Constants';
import React, { useEffect, useMemo, useState } from 'react';
import useMemoTranslation from '../../hooks/UseMemoTranslation';
import { CompactGridWrapper } from '../../components/grid/CompactGridWrapper';
import useOrganizations from '../../components/hooks/UseOrganizations';
import { Organization } from '../../data/ReferenceData';
import { Dictionary } from '../../util/TypeUtil';
import { keyBy } from 'lodash';
import { FlexBox } from '../../components/FlexBox';
import { GridExportButton } from '../../components/GridExportButton';
import { FlexTableBox } from '../../components/FlexTableBox';

type Row = {
  id: number;
  file: string;
  sequencingLabId: string;
};

export const CheckByAvailableSequenceFilesGrid = ({
  data,
}: {
  data: ReadonlyArray<SampleTrackingCheckByFastqValidationResultPayload>;
}) => {
  const sequenceFilesGridApiRef = useGridApiRef();
  const { t } = useMemoTranslation();

  const organizations = useOrganizations();
  const [organizationById, setOrganizationById] = useState<Dictionary<Organization>>({});

  const columns = useColumns(organizationById);
  const rows = useRows(data);

  useEffect(() => {
    setOrganizationById(keyBy(organizations, i => i.id));
  }, [organizations]);

  return (
    <FlexTableBox>
      <FlexBox flexDirection={'row-reverse'}>
        <GridExportButton apiRef={sequenceFilesGridApiRef.current} fileName={t('sequenceFilesName')} />
      </FlexBox>
      <CompactGridWrapper
        apiRef={sequenceFilesGridApiRef}
        rows={rows}
        columns={columns}
        disableColumnFilter={true}
        disableColumnMenu={true}
        density='compact'
        disableRowSelectionOnClick
      />
    </FlexTableBox>
  );
};

const useColumns = (organizationById: Dictionary<Organization>): GridColDef[] => {
  const { t } = useMemoTranslation();

  return useMemo(
    () => [
      {
        field: sequencingLabId,
        headerName: t(sequencingLab),
        headerClassName: compactGridHeaderClassName,
        headerAlign: 'left',
        align: 'left',
        minWidth: 150,
        valueFormatter: ({ value }) => (value && organizationById[value]?.name) ?? '',
      },
      {
        field: file,
        headerName: t(file),
        headerClassName: compactGridHeaderClassName,
        headerAlign: 'left',
        align: 'left',
        minWidth: 200,
        flex: 1,
      },
    ],
    [t, organizationById]
  );
};

const useRows = (data: ReadonlyArray<SampleTrackingCheckByFastqValidationResultPayload>) => {
  return useMemo(() => {
    let index = 0;

    const rows: Row[] = data.flatMap(r => {
      return r.possibleFiles.map(f => {
        return {
          id: index++,
          file: f,
          sequencingLabId: r.validated[0]?.sequencingLabId,
        };
      });
    });

    return rows;
  }, [data]);
};
