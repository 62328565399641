import React from 'react';
import { Page } from 'components/Page';
import { useAuth0 } from '@auth0/auth0-react';

export const AuthorizedPage: React.FC = ({ children }) => {
  const { isAuthenticated } = useAuth0();
  if (isAuthenticated) {
    return <>{children}</>;
  } else {
    return <Page title='You do not have access to this page'>{null}</Page>;
  }
};
