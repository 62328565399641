import React from 'react';
import { Layout } from 'layout/Layout';
import { PageHeader } from './PageHeader';
import { Box } from '@mui/material';

interface Props {
  title?: string;
  subtitle?: string;
  goBackUrl?: string;
  children: React.ReactNode;
}

export const Page = ({ title, subtitle, children, goBackUrl }: Props) => {
  return (
    <Layout>
      <PageHeader title={title ?? ''} subtitle={subtitle} goBackUrl={goBackUrl} />
      <Box mx={2} mb={2} flexGrow={1} height={'80vh'}>
        {children}
      </Box>
    </Layout>
  );
};
