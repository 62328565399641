/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const gray1 = '#383737';
export const gray2 = '#5c5a5a';
export const gray3 = '#857c81';
export const gray4 = '#b9b9b9';
export const gray5 = '#e3e2e2';
export const gray6 = '#f7f8fa';
export const primary1 = '#681c41';
export const primary2 = '#824c67';
export const accent1 = '#dbb365';
export const accent2 = '#efd197';
export const fontFamily = "'Segoe UI', 'Helvetica Neue',sans-serif";
export const fontSize = '16px';

export const Fieldset = styled.fieldset`
  margin: 10px auto 0 auto;
  padding: 30px;
  width: 350px;
  background-color: ${gray6};
  border-radius: 4px;
  border: 1px solid ${gray5};
  box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.16);
`;

export const FieldContainer = styled.div`
  margin-bottom: 10px;
`;

const baseFieldCSS = css`
  box-sizing: border-box;
  font-family: ${fontFamily};
  font-size: ${fontSize};
  margin-bottom: 5px;
  padding: 8px 10px;
  border: 1px solid ${gray5};
  border-radius: 3px;
  color: ${gray2};
  background-color: white;
  width: 100%;
  :focus {
    outline-color: ${gray5};
  }
  :disabled {
    background-color: ${gray6};
  }
`;

export const FieldInput = styled.input`
  ${baseFieldCSS}
`;

export const FieldTextArea = styled.textarea`
  ${baseFieldCSS} height: 100px;
`;

export const FieldError = styled.div`
  font-size: 12px;
  color: red;
`;

export const FlexContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const FormButtonContainer = styled.div`
  margin: 30px 0px 0px 0px;
  padding: 20px 0px 0px 0px;
  border-top: 1px solid ${gray5};
`;

export const MonospaceSpan = styled.span`
  font-family: monospace, monospace;
`;

type ScrollableDivProps = {
  width?: string;
  height?: string;
};

export const ScrollableDiv = styled.div<ScrollableDivProps>`
  width: ${props => props.width ?? '200px'};
  height: ${props => props.height ?? '200px'};
  overflow-x: auto;
  overflow-y: scroll;
  border: 1px solid black;
`;

type SelectableErrorTextProps = {
  error?: boolean;
};

export const SelectableErrorText = styled.div<SelectableErrorTextProps>`
  color: ${props => (props.error ?? true ? 'red' : 'inherited')};
`;

export const StatusText = styled.div`
  text-align: center;
`;

export const SubmissionSuccess = styled.div`
  margin-top: 10px;
  color: green;
`;

export const SubmissionFailure = styled.div`
  margin-top: 10px;
  color: red;
`;

export const UlNoBullets = styled.div`
  list-style-type: none;
  padding-left: 5px;
`;

export const UserIcon = styled.img`
  padding-left: 10px;
  width: 30px;
`;
