import React, { useState } from 'react';

export function PressableIconWrapper({ children }: { children: React.ReactNode }) {
  const [pressed, setPressed] = useState(false);

  const handleClick = () => {
    setPressed(true);
    setTimeout(() => setPressed(false), 200);
  };

  return (
    <div
      onClick={handleClick}
      style={{
        transform: pressed ? 'translateY(1px)' : 'none',
        transition: 'transform 0.2s ease-out',
      }}
    >
      {children}
    </div>
  );
}
