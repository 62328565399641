import {
  GRID_CHECKBOX_SELECTION_FIELD,
  GRID_TREE_DATA_GROUPING_FIELD,
  GridColDef,
  GridComparatorFn,
  GridRowSelectionModel,
  useGridApiRef,
} from '@mui/x-data-grid-pro';
import useAuth from 'auth/UseAuth';
import React, { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import {
  GetSampleTrackingTransitionSamples,
  SampleTrackingTableData,
  TransitionSample,
  TransitionType,
} from '../../data/SampleTrackingData';
import { CreateSampleTrackingTransitionModal } from './CreateSampleTrackingTransitionModal';
import { AuthorizedSection } from '../../auth/AuthorizedSection';
import { formatSampleType, generateWidthFromProperty } from '../../util/grid/TableUtils';
import {
  biobankId,
  checkByFailedReason,
  compactGridHeaderClassName,
  enteredAt,
  exitedAt,
  foreignHash,
  inputForeignHash,
  inputId,
  inputLabAssignedId,
  inputSampleBbid,
  inputSampleId,
  labAssignedSampleId,
  labId,
  multiQc,
  newForeignHash,
  newId,
  newLabAssignedId,
  newSampleBbid,
  newSampleId,
  patientId,
  qualityCheckStatus,
  r1FastQLocation,
  r2FastQLocation,
  sampleBbid,
  sampleId,
  sampleTypeId,
  selectionStatus,
  sequenceRunId,
  sequenceType,
  transitionId,
} from '../../util/Constants';
import { getTransitionSampleIdentifier, Sample, truncateNoDashGuid } from '../../data/SampleData';
import { CustomizableSwitch } from '../../components/CustomizableSwitch';
import { SampleTrackingRowSelectionModal } from './SampleTrackingRowSelectionModal';
import { Dictionary, find, flatMap, keyBy, keys, map, pick, uniq, values } from 'lodash';
import { renderSampleHierarchyGridCell } from '../../components/grid/cell/SampleHierarchyGridCell';
import {
  GetOrderScopedSampleTrackingTableData,
  GetQualityCheckGroupScopedSampleTrackingTableData,
} from '../../data/SampleTrackingTransitionData';
import { grey } from '@mui/material/colors';
import { makeStyles } from '@mui/styles';
import { LoadingState, LoadState } from '../../components/LoadingStateUtil';
import { LoadingIndicator } from '../../components/LoadingIndicator';
import { ErrorIndicator } from '../../components/ErrorIndicator';
import { InformaticsQcModal } from './InformaticsQcModal';
import { renderCellMultiQcDownloadButton } from 'components/grid/GridCellMultiQcDownloadButton';
import { FlexTableBox } from 'components/FlexTableBox';
import { CompactGridWrapper } from '../../components/grid/CompactGridWrapper';
import useMemoTranslation from 'hooks/UseMemoTranslation';
import useOrganizations from 'components/hooks/UseOrganizations';
import { CheckByFailedReason, Organization } from 'data/ReferenceData';
import { SampleTrackingExhaustModal } from './SampleTrackingExhaustModal';
import {
  GetSampleSequencingJourneyDetails,
  SampleSequencingJourneyDetails,
  SequencingFastQ,
} from 'data/SampleJourneyData';
import { InformaticsQcMetrics } from 'data/InformaticsQcData';
import { GridColumnVisibilityModel } from '@mui/x-data-grid/hooks/features/columns/gridColumnsInterfaces';
import { SampleTrackingOrderGridDetailsModal } from './orders/SampleTrackingOrderDetailsModal';
import { SampleTrackingOrderRow } from './orders/SampleTrackingOrderGrid';
import useSampleTypes from '../../components/hooks/UseSampleTypes';
import { SampleType } from '../../data/SampleTypeData';
import { FlexBox } from '../../components/FlexBox';
import { GridExportButton } from '../../components/GridExportButton';
import { renderGuidCellTooltip } from '../../components/grid/cell/GuidGridCellTooltip';
import { CheckByModal } from './CheckByModal';
import useCheckByFailedReasons from '../../components/hooks/UseCheckByFailedReasons';
import { useTransitionEnum } from '../../components/hooks/UseTransitionEnums';

interface BaseGridProps {
  researchProjectId: string;
  transitionType: TransitionType;
}

export interface TransitionRecordGridProps extends BaseGridProps {
  sampleGroup?: string;
  configuredTransitionId: string;
}

export interface TransitionSampleOrderScopedGridProps extends BaseGridProps {
  transitionIds: ReadonlyArray<string>;
  orderDetails: SampleTrackingOrderRow;
}

export interface TransitionSampleQualityCheckGroupScopedGridProps extends BaseGridProps {
  qualityCheckGroupId: string;
}

interface GridProps {
  researchProjectId: string;
  transitionType: TransitionType;
  configuredTransitionId?: string;
  getData: (accessToken: string, useDecryptedHashes: boolean) => Promise<ReadonlyArray<SampleTrackingTableData>>;
  disableActions?: boolean;
  filterInactiveTransitionSamples?: boolean;
  columnsToHide?: GridColumnVisibilityModel;
  customActions?: ReactNode;
}

type Row = Sample &
  TransitionSample &
  SampleSequencingJourneyDetails &
  InformaticsQcMetrics & {
    groupByOrderId?: string[];
    groupByParent?: string[];
    id: number;
    newForeignHash?: string;
    newId?: string;
    newLabAssignedId?: string;
    newSampleBbid?: string;
    newSampleId?: string;
    inputForeignHash?: string;
    inputId: string;
    inputLabAssignedId?: string;
    inputSampleBbid?: string;
    inputSampleId?: string;
    filterId: string;
    selectionStatus?: string;
  };

const useStyles = makeStyles({
  disabledRow: {
    backgroundColor: grey[200],
  },
});

export const TransitionSampleGrid = ({
  researchProjectId,
  transitionType,
  sampleGroup,
  configuredTransitionId,
}: TransitionRecordGridProps) => {
  return (
    <>
      <Grid
        researchProjectId={researchProjectId}
        transitionType={transitionType}
        getData={async (accessToken: string, useDecryptedHashes: boolean) => {
          return await GetSampleTrackingTransitionSamples(
            researchProjectId,
            accessToken,
            configuredTransitionId,
            useDecryptedHashes,
            sampleGroup
          );
        }}
        configuredTransitionId={configuredTransitionId}
        columnsToHide={{
          exitedAt: false,
        }}
      />
    </>
  );
};

export const TransitionSampleOrderScopedGrid = ({
  researchProjectId,
  transitionType,
  transitionIds,
  orderDetails,
}: TransitionSampleOrderScopedGridProps) => {
  return (
    <>
      <Grid
        researchProjectId={researchProjectId}
        transitionType={transitionType}
        getData={async (accessToken: string, useDecryptedHashes: boolean) => {
          const data = await Promise.all(
            transitionIds.map(
              async id =>
                await GetOrderScopedSampleTrackingTableData(id, researchProjectId, useDecryptedHashes, accessToken)
            )
          );

          return flatMap(data);
        }}
        disableActions={true}
        filterInactiveTransitionSamples={true}
        columnsToHide={{
          exitedAt: false,
          enteredAt: false,
          qualityCheckStatus: false,
          checkByFailedReason: false,
          transitionId: false,
        }}
        customActions={<SampleTrackingOrderGridDetailsModal row={orderDetails} transitionType={transitionType} />}
      />
    </>
  );
};

export const TransitionSampleQualityCheckGroupScopedGrid = ({
  researchProjectId,
  transitionType,
  qualityCheckGroupId,
}: TransitionSampleQualityCheckGroupScopedGridProps) => {
  return (
    <>
      <Grid
        researchProjectId={researchProjectId}
        transitionType={transitionType}
        getData={async (accessToken: string, useDecryptedHashes: boolean) =>
          await GetQualityCheckGroupScopedSampleTrackingTableData(
            qualityCheckGroupId,
            researchProjectId,
            useDecryptedHashes,
            accessToken
          )
        }
        disableActions={true}
        filterInactiveTransitionSamples={true}
      />
    </>
  );
};

export const Grid = ({
  researchProjectId,
  transitionType,
  getData,
  disableActions = false,
  filterInactiveTransitionSamples = false,
  columnsToHide,
  customActions,
  configuredTransitionId,
}: GridProps) => {
  const { accessToken } = useAuth();
  const classes = useStyles();
  const apiRef = useGridApiRef();
  const { t } = useMemoTranslation();

  const [loadingState, setLoadingState] = useState<LoadingState>({ status: 'NotStarted' });
  const [refreshTrigger, setRefreshTrigger] = useState<boolean>(true);

  const [data, setData] = useState<ReadonlyArray<SampleTrackingTableData>>([]);

  const organizations = useOrganizations();
  const sampleTypes = useSampleTypes();

  const [selectedSampleIds, setSelectedSampleIds] = useState<string[]>([]);
  const [useDecryptedHashes, setUseDecryptedHashes] = useState<boolean>(false);
  const [useFilterOutOriginal, setUseFilterOutOriginal] = useState<boolean>(true);
  const [groupByParent, setGroupByParent] = useState<boolean>(transitionType === 'Subsetting');
  const [groupByOrderId, setGroupByOrderId] = useState<boolean>(transitionType !== 'Subsetting');
  const [supportsGroupByParent] = useState<boolean>(transitionType === 'Subsetting');
  const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>();
  const [organizationById, setOrganizationById] = useState<Dictionary<Organization>>({});
  const [sampleSequencingJourneyDetails, setSampleSequencingJourneyDetails] = useState<
    ReadonlyArray<SampleSequencingJourneyDetails>
  >([]);

  const transitionEnum = useTransitionEnum(transitionType);
  const checkByFailedReasons = useCheckByFailedReasons(transitionEnum?.transitionEnumId);

  const rows = useRows(data, sampleSequencingJourneyDetails, filterInactiveTransitionSamples, useFilterOutOriginal);
  const columns = useColumns(transitionType, data, organizationById, sampleTypes, checkByFailedReasons);

  useEffect(() => {
    setOrganizationById(keyBy(organizations, i => i.id));
  }, [organizations]);

  useEffect(() => {
    return LoadState(setLoadingState, async () => {
      if (!(accessToken && researchProjectId !== undefined)) {
        return;
      }

      const rows = await getData(accessToken, useDecryptedHashes);
      setData(rows);

      const sampleSequencingJourneyDetailsData = await GetSampleSequencingJourneyDetails(
        map(rows, i => i.sample.sampleId),
        accessToken
      );
      setSampleSequencingJourneyDetails(sampleSequencingJourneyDetailsData);
    });
  }, [accessToken, researchProjectId, refreshTrigger, useDecryptedHashes, getData]);

  useEffect(() => {
    if (selectionModel) {
      const sampleIds: string[] = [];
      selectionModel.forEach(selected => {
        const id = apiRef.current?.getRow(selected)?.sampleId;
        if (id) {
          sampleIds.push(id);
        }
      });

      setSelectedSampleIds(uniq(sampleIds));
    }
  }, [selectionModel, apiRef]);

  function selectRows(ids: ReadonlyArray<string>) {
    const normalizedIds = ids.map(id => id.toLowerCase().trim());
    const selectedRows: number[] = [];

    apiRef.current.getSortedRows().forEach(row => {
      const rowTyped = row as Row;
      const propertiesToCheck = pick(rowTyped, [foreignHash, sampleId, labAssignedSampleId, sampleBbid]);

      normalizedIds.forEach(id => {
        values(propertiesToCheck).forEach(val => {
          if (val.toLowerCase().trim() === id) {
            selectedRows.push(rowTyped.id);
          }
        });
      });
    });

    setSelectionModel(selectedRows);
  }

  function refreshData(hasChanges: boolean) {
    if (!hasChanges) {
      return;
    }

    setRefreshTrigger(currentState => !currentState);
    setSelectionModel([]);
    setSelectedSampleIds([]);
  }

  const getInformaticsCheckByTableData = useCallback(() => {
    return map(rows, i => {
      return {
        sampleId: i.sampleId ?? '',
        sampleIdentifier: i.inputId ?? '',
        selectionStatus: i.selectionStatus ?? '',
        qualityCheckStatus: i.qualityCheckStatus ?? '',
        sequenceType: i.sequenceType ?? '',
      };
    });
  }, [rows]);

  return (
    <FlexTableBox>
      <AuthorizedSection requiredRole='ll-green-side-user'>
        <FlexBox flexDirection='row' alignItems='center' mb={1} justifyContent='space-between'>
          <FlexBox flexDirection='row' alignItems='center' flexWrap={'wrap'}>
            <CustomizableSwitch
              title={t('decryptedForeignHashes')}
              checked={useDecryptedHashes}
              onChange={() => {
                setUseDecryptedHashes(!useDecryptedHashes);
              }}
            />
            {!disableActions && (
              <CustomizableSwitch
                title={t('filterOutInputsWithAnOutput')}
                informationText={t('filterOutInputsWithAnOutputInfo')}
                checked={useFilterOutOriginal}
                onChange={() => {
                  setUseFilterOutOriginal(!useFilterOutOriginal);
                }}
              />
            )}
            {supportsGroupByParent && !disableActions && (
              <CustomizableSwitch
                title={t('groupByParent')}
                checked={groupByParent}
                onChange={() => {
                  setGroupByParent(!groupByParent);
                  if (!groupByParent) {
                    setGroupByOrderId(false);
                  }
                }}
              />
            )}
            {!disableActions && (
              <CustomizableSwitch
                title={t('groupByOrderId')}
                checked={groupByOrderId}
                onChange={() => {
                  setGroupByOrderId(!groupByOrderId);
                  if (!groupByOrderId) {
                    setGroupByParent(false);
                  }
                }}
              />
            )}
          </FlexBox>
          <FlexBox flexDirection='row-reverse' alignItems='center' flexWrap={'wrap'}>
            {!disableActions && (
              <>
                <SampleTrackingExhaustModal selectedSampleIds={selectedSampleIds} onClose={refreshData} />
                <CreateSampleTrackingTransitionModal
                  researchProjectId={researchProjectId}
                  selectedSamples={map(selectedSampleIds, i => {
                    const item = rows.find(j => j.sampleId === i);

                    return { ...item, sampleIdentifier: item?.newId ?? item?.inputId ?? '' } as any;
                  })}
                  onClose={refreshData}
                />
                {transitionType === 'Informatics' && configuredTransitionId && !!rows && (
                  <InformaticsQcModal
                    researchProjectId={researchProjectId}
                    data={getInformaticsCheckByTableData()}
                    onClose={refreshData}
                    configuredTransitionId={configuredTransitionId}
                  />
                )}
                {transitionType !== 'Informatics' && configuredTransitionId && (
                  <CheckByModal
                    researchProjectId={researchProjectId}
                    transitionType={transitionType}
                    configuredTransitionId={configuredTransitionId}
                    onClose={refreshData}
                  />
                )}
                <SampleTrackingRowSelectionModal onSubmit={selectRows} />
              </>
            )}
            <GridExportButton
              apiRef={apiRef?.current}
              fileName={t('sampleTrackingFileName') + transitionType}
              fieldsToNotExport={keys(columnsToHide)}
            />
            {customActions ?? <></>}
          </FlexBox>
        </FlexBox>
      </AuthorizedSection>
      <CompactGridWrapper
        apiRef={apiRef ?? {}}
        rows={rows}
        columns={columns}
        initialState={{
          columns: {
            columnVisibilityModel: {
              inputSampleBbid: false,
              inputForeignHash: false,
              inputLabAssignedId: false,
              newSampleBbid: false,
              newForeignHash: false,
              newLabAssignedId: false,
              patientId: false,
              inputSampleId: false,
              newSampleId: false,
              labId: false,
              biobankId: false,
              newId: transitionType !== 'Informatics',
              ...columnsToHide,
            },
          },
          pinnedColumns: { left: [GRID_CHECKBOX_SELECTION_FIELD, GRID_TREE_DATA_GROUPING_FIELD] },
          sorting: {
            sortModel: [
              { field: sequenceRunId, sort: 'desc' },
              { field: qualityCheckStatus, sort: 'asc' },
              { field: enteredAt, sort: 'asc' },
            ],
          },
        }}
        density='compact'
        checkboxSelection={!disableActions}
        getTreeDataPath={row => {
          if (groupByOrderId) {
            return row?.groupByOrderId;
          }

          if (groupByParent) {
            return row?.groupByParent;
          }

          return row?.groupByParent;
        }}
        treeData={groupByOrderId || groupByParent}
        groupingColDef={() => {
          if (groupByOrderId) {
            return {
              headerName: t('byOrderId'),
              minWidth: 150,
              valueFormatter: params => {
                const node = params.api.getRowNode(params.id!)!;
                if (node.type !== 'group') {
                  return '';
                }

                return truncateNoDashGuid(node.groupingKey?.toString() ?? '');
              },
            };
          }

          if (groupByParent) {
            return {
              headerName: t('byParent'),
              minWidth: 300,
              valueFormatter: params => {
                const node = params.api.getRowNode(params.id!)!;
                if (node.type !== 'group') {
                  return '';
                }

                return node.groupingKey;
              },
            };
          }

          return {
            headerName: t('unknown'),
            minWidth: 300,
          };
        }}
        defaultGroupingExpansionDepth={1}
        disableRowSelectionOnClick
        onRowSelectionModelChange={newSelectionModel => setSelectionModel(newSelectionModel)}
        rowSelectionModel={selectionModel}
        getRowClassName={params => (!params.row.exitedAt ? '' : classes.disabledRow)}
      />
      <LoadingIndicator type={'Linear'} loadingState={loadingState} margin={'T'} />
      <ErrorIndicator loadingState={loadingState} />
    </FlexTableBox>
  );
};

const useRows = (
  data: ReadonlyArray<SampleTrackingTableData>,
  sampleSequencingJourneyDetails: ReadonlyArray<SampleSequencingJourneyDetails>,
  filterInactiveTransitionSamples: boolean,
  useFilterOutOriginal: boolean
) => {
  return useMemo(() => {
    const dataByTransitionId = keyBy(data, r => r.transitionSample.transitionSampleId);
    const sampleSequencingJourneyBySampleId = keyBy(sampleSequencingJourneyDetails, r => r.sampleId);

    const originalIdsToFilter: string[] = uniq(
      data.map(i => dataByTransitionId[i.parentTransitionSampleId ?? '']?.sample.sampleId).filter(i => i)
    );

    const rows: Row[] = data
      .filter(r => filterInactiveTransitionSamples || !r.transitionSample.exitedAt)
      .map((r, index) => {
        // Check if sample has an antecedent
        const originalRow = dataByTransitionId[r.parentTransitionSampleId ?? ''];

        const identifiers = !originalRow
          ? {
              filterId: r.sample.sampleId,
              inputSampleId: r.sample.sampleId,
              inputId: getTransitionSampleIdentifier(r.transitionSample, r.sample),
              inputSampleBbid: r.sample.sampleBbid,
              inputForeignHash: r.sample.foreignHash,
              inputLabAssignedId: r.transitionSample.labAssignedSampleId ?? '',
              groupByParent: [getTransitionSampleIdentifier(r.transitionSample, r.sample)],
              groupByOrderId: [r.transitionSample.transitionId, r.transitionSample.transitionSampleId],
            }
          : {
              filterId: '',
              inputSampleId: originalRow.sample.sampleId,
              newSampleId: r.sample.sampleId,
              inputId: getTransitionSampleIdentifier(originalRow.transitionSample, originalRow.sample),
              newId: getTransitionSampleIdentifier(r.transitionSample, r.sample),
              inputSampleBbid: originalRow.sample.sampleBbid,
              inputForeignHash: originalRow.sample.foreignHash,
              inputLabAssignedId: originalRow.transitionSample.labAssignedSampleId ?? '',
              newSampleBbid: r.sample.sampleBbid,
              newForeignHash: r.sample.foreignHash,
              newLabAssignedId: r.transitionSample.labAssignedSampleId,
              groupByParent: [
                getTransitionSampleIdentifier(originalRow.transitionSample, originalRow.sample),
                getTransitionSampleIdentifier(r.transitionSample, r.sample),
              ],
              groupByOrderId: [r.transitionSample.transitionId, r.transitionSample.transitionSampleId],
            };

        // check if antecedent is in the same order
        return {
          id: index,
          ...sampleSequencingJourneyBySampleId[r.sample.sampleId ?? ''],
          ...identifiers,
          ...r.sample,
          ...r.transitionSample,
          ...r.researchProjectSample,
        };
      })
      .filter(r => !useFilterOutOriginal || !originalIdsToFilter.includes(r.filterId));

    return rows;
  }, [data, filterInactiveTransitionSamples, useFilterOutOriginal, sampleSequencingJourneyDetails]);
};

const useColumns = (
  transitionType: TransitionType,
  data: ReadonlyArray<SampleTrackingTableData>,
  organizationById: Dictionary<Organization>,
  sampleTypes: ReadonlyArray<SampleType>,
  checkByFailedReasons: ReadonlyArray<CheckByFailedReason>
): GridColDef[] => {
  const { t } = useMemoTranslation();

  return useMemo(() => {
    const sequencingCols: GridColDef[] =
      transitionType === 'Sequencing' || transitionType === 'Informatics'
        ? [
            {
              field: r1FastQLocation,
              headerName: t(r1FastQLocation),
              headerAlign: 'left',
              align: 'left',
              minWidth: 200,
              flex: 2,
              valueGetter: params =>
                find(params.row.fastQs, i => (i as SequencingFastQ).type.name === 'r1')?.file.location,
            },
            {
              field: r2FastQLocation,
              headerName: t(r2FastQLocation),
              headerAlign: 'left',
              align: 'left',
              minWidth: 200,
              flex: 2,
              valueGetter: params =>
                find(params.row.fastQs, i => (i as SequencingFastQ).type.name === 'r2')?.file.location,
            },
          ]
        : [];

    const informaticsCols: GridColDef[] =
      transitionType === 'Informatics'
        ? [
            {
              field: sequenceType,
              headerName: t(sequenceType),
              headerClassName: compactGridHeaderClassName,
              width: 100,
              headerAlign: 'center',
              align: 'center',
              valueFormatter: ({ value }) => (value ? value.toUpperCase() : value),
            },
            {
              field: sequenceRunId,
              headerName: t(multiQc),
              headerClassName: compactGridHeaderClassName,
              headerAlign: 'center',
              align: 'center',
              width: 100,
              filterable: false,
              sortable: false,
              renderCell: renderCellMultiQcDownloadButton,
              sortComparator: sequenceRunIdComparator,
            },
          ]
        : [];

    return [
      {
        field: inputId,
        headerName: t(inputId),
        headerClassName: compactGridHeaderClassName,
        headerAlign: 'left',
        align: 'left',
        width: 250,
        renderCell: params =>
          renderSampleHierarchyGridCell({
            ...params,
            sampleId: params.row.inputSampleId,
            displayValue: <span>{params.value}</span>,
          }),
      },
      {
        field: newId,
        headerName: t(newId),
        headerClassName: compactGridHeaderClassName,
        headerAlign: 'left',
        align: 'left',
        width: 250,
        renderCell: params =>
          renderSampleHierarchyGridCell({
            ...params,
            sampleId: params.row.newSampleId,
            displayValue: <span>{params.value}</span>,
          }),
      },
      {
        field: inputSampleBbid,
        headerName: t(inputSampleBbid),
        headerClassName: compactGridHeaderClassName,
        headerAlign: 'left',
        align: 'left',
        width: 300,
      },
      {
        field: inputForeignHash,
        headerName: t(inputForeignHash),
        headerClassName: compactGridHeaderClassName,
        headerAlign: 'left',
        align: 'left',
        width: 300,
      },
      {
        field: inputLabAssignedId,
        headerName: t(inputLabAssignedId),
        headerClassName: compactGridHeaderClassName,
        headerAlign: 'left',
        align: 'left',
        width: 300,
      },
      {
        field: inputSampleId,
        headerName: t(inputSampleId),
        headerClassName: compactGridHeaderClassName,
        headerAlign: 'left',
        align: 'left',
        width: 300,
      },
      {
        field: newSampleBbid,
        headerName: t('daughterSampleBbid'),
        headerClassName: compactGridHeaderClassName,
        headerAlign: 'left',
        align: 'left',
        width: 300,
      },
      {
        field: newForeignHash,
        headerName: t('daughterForeignHash'),
        headerClassName: compactGridHeaderClassName,
        headerAlign: 'left',
        align: 'left',
        width: 300,
      },
      {
        field: newLabAssignedId,
        headerName: t('daughterLabAssignedId'),
        headerClassName: compactGridHeaderClassName,
        headerAlign: 'left',
        align: 'left',
        width: 300,
      },
      {
        field: newSampleId,
        headerName: t('daughterSampleId'),
        headerClassName: compactGridHeaderClassName,
        headerAlign: 'left',
        align: 'left',
        width: 300,
      },
      ...informaticsCols,
      ...sequencingCols,
      {
        field: qualityCheckStatus,
        headerName: t(qualityCheckStatus),
        headerClassName: compactGridHeaderClassName,
        headerAlign: 'center',
        align: 'center',
        width: 200,
        valueFormatter: ({ value }) => value && t(value),
      },
      {
        field: checkByFailedReason,
        headerName: t(checkByFailedReason),
        headerClassName: compactGridHeaderClassName,
        headerAlign: 'left',
        align: 'left',
        width: generateWidthFromProperty(
          data ?? [],
          200,
          (row: SampleTrackingTableData) => row.transitionSample.checkByFailedReason
        ),
        valueFormatter: ({ value }) => value && find(checkByFailedReasons, i => i.name === value)?.displayName,
      },
      {
        field: selectionStatus,
        headerName: t(selectionStatus),
        headerClassName: compactGridHeaderClassName,
        headerAlign: 'left',
        align: 'left',
        width: 150,
        valueFormatter: ({ value }) => value && t(value),
      },
      {
        field: sampleTypeId,
        headerName: t(sampleTypeId),
        headerClassName: compactGridHeaderClassName,
        headerAlign: 'left',
        align: 'left',
        width: generateWidthFromProperty(data ?? [], 130, (row: SampleTrackingTableData) => row.sample.sampleTypeId),
        valueFormatter: ({ value }) => value && formatSampleType(sampleTypes, value),
      },
      {
        field: enteredAt,
        headerName: t(enteredAt),
        headerClassName: compactGridHeaderClassName,
        headerAlign: 'center',
        align: 'center',
        minWidth: 200,
        valueFormatter: ({ value }) => value && new Date(value).toLocaleString(),
      },
      {
        field: exitedAt,
        headerName: t(exitedAt),
        headerClassName: compactGridHeaderClassName,
        headerAlign: 'center',
        align: 'center',
        minWidth: 200,
        valueFormatter: ({ value }) => value && new Date(value).toLocaleString(),
      },
      {
        field: transitionId,
        headerName: t(transitionId),
        headerClassName: compactGridHeaderClassName,
        headerAlign: 'center',
        align: 'center',
        width: 150,
        renderCell: renderGuidCellTooltip,
      },
      {
        field: labId,
        headerName: t('originLab'),
        headerAlign: 'center',
        align: 'center',
        width: 200,
        valueFormatter: ({ value }) => (value && organizationById[value]?.name) ?? '',
      },
      {
        field: biobankId,
        headerName: t('originBiobank'),
        headerAlign: 'center',
        align: 'center',
        width: 200,
        valueFormatter: ({ value }) => (value && organizationById[value]?.name) ?? '',
      },
      {
        field: patientId,
        headerName: t(patientId),
        sortable: false,
        width: 130,
        align: 'left',
        renderCell: renderGuidCellTooltip,
      },
    ];
  }, [t, transitionType, data, organizationById, sampleTypes, checkByFailedReasons]);
};

const sequenceRunIdComparator: GridComparatorFn = (v1, v2) => {
  if (!v1 && v2) {
    return -1;
  } else if (v1 && !v2) {
    return 1;
  } else {
    return 0;
  }
};
