import { Button, styled } from '@mui/material';

export const ButtonWrapper = styled(Button)(
  ({ theme }) => `
      padding: ${theme.spacing(2, 3)};
      display: flex;
      justify-content: space-between;
      border-top-left-radius: 0;
      border-top-right-radius: 0;

      &:hover {
          background: ${theme.colors.alpha.black[5]};
          color: ${theme.colors.alpha.black[100]};
      }
  `
);
