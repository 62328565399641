import { appSettings } from 'AppSettings';
import { BaseDeleteVoid, BaseGet, BasePostVoid } from './BaseData';

export interface PathogenGroup {
  pathogenGroupId: string;
  name: string;
  snomedClinicalTermIds: string[];
  snomedConceptIds: string[];
}

export async function GetPathogenGroups(accessToken?: string): Promise<PathogenGroup[]> {
  return await BaseGet(`${appSettings.api.endpoint}/api/v2/PathogenGroups`, accessToken);
}

export async function CreatePathogenGroup(pathogenGroup: PathogenGroup, accessToken?: string): Promise<void> {
  return await BasePostVoid(`${appSettings.api.endpoint}/api/v2/PathogenGroups`, pathogenGroup, accessToken);
}

export async function DeletePathogenGroup(pathogenGroup: PathogenGroup, accessToken?: string): Promise<void> {
  return await BaseDeleteVoid(
    `${appSettings.api.endpoint}/api/v2/PathogenGroups/${pathogenGroup.pathogenGroupId}`,
    accessToken
  );
}
