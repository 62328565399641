import { GridColDef } from '@mui/x-data-grid-pro';
import React, { useMemo } from 'react';
import {
  currentStage,
  foreignHash,
  outputOf,
  sampleAvailabilityTypeId,
  sampleBbid,
  sampleId,
  sampleTypeId,
} from '../../util/Constants';
import { Sample, truncateForeignHash } from '../../data/SampleData';
import { SampleJourneyTransitionInformation, SampleJourneyTree } from '../../data/SampleJourneyData';
import { makeStyles } from '@mui/styles';
import { keyBy } from 'lodash';
import useMemoTranslation from '../../hooks/UseMemoTranslation';
import { Box } from '@mui/material';
import { GridCellCallBack } from '../../components/grid/GridCellCallback';
import { Dictionary, UseStateSetter } from '../../util/TypeUtil';
import useSampleAvailabilityTypes from '../../components/hooks/UseSampleAvailabilityTypes';
import { SampleAvailabilityType } from '../../data/ReferenceData';
import { formatSampleAvailabilityTypeById, formatSampleType } from '../../util/grid/TableUtils';
import { CompactGridWrapper } from '../../components/grid/CompactGridWrapper';
import useSampleTypes from '../../components/hooks/UseSampleTypes';
import { SampleType } from '../../data/SampleTypeData';
import { ResearchProjectTransitionPath } from '../../data/ResearchProjectTransitionPathData';

export interface SampleJourneyHierarchyGridProps {
  identifiersBySampleId: Dictionary<string>;
  transitionInformation: ReadonlyArray<SampleJourneyTransitionInformation>;
  hierarchyTreeData: ReadonlyArray<SampleJourneyTree>;
  selectedSampleIds: ReadonlyArray<string>;
  setSelectedSampleIds: UseStateSetter<ReadonlyArray<string>>;
  transitionPaths: ReadonlyArray<ResearchProjectTransitionPath>;
}

export type Row = Omit<SampleJourneyTree, 'descendant'> & {
  id: number;
  path: string[];
  qualityCheckStatus?: string;
  outputOf: string;
  currentStage: string;
} & Sample &
  SampleJourneyTransitionInformation;

const useStyles = makeStyles({
  highlightedRow: {
    backgroundColor: 'rgb(231, 221, 255)',
  },
  noHover: {
    '&:hover': {
      backgroundColor: 'inherit !important',
      pointerEvents: 'none',
    },
  },
});

export const SampleJourneyHierarchyGrid = ({
  identifiersBySampleId,
  transitionInformation,
  hierarchyTreeData,
  selectedSampleIds,
  setSelectedSampleIds,
  transitionPaths,
}: SampleJourneyHierarchyGridProps) => {
  const { t } = useMemoTranslation();
  const sampleTypes = useSampleTypes();
  const classes = useStyles();

  const sampleAvailabilityTypes = useSampleAvailabilityTypes();

  const columns = useColumns(sampleAvailabilityTypes, sampleTypes);
  const rows = useRows(hierarchyTreeData, transitionInformation, transitionPaths);

  return (
    <CompactGridWrapper
      rows={rows}
      columns={columns}
      initialState={{
        columns: {
          columnVisibilityModel: {
            sampleId: false,
            foreignHash: false,
            sampleBbid: false,
          },
        },
        sorting: {
          sortModel: [{ field: sampleId, sort: 'asc' }],
        },
      }}
      density='compact'
      getTreeDataPath={(row: Row) => row?.path}
      treeData
      groupingColDef={{
        headerAlign: 'left',
        headerName: t('sampleIdentifier'),
        width: 500,
        hideDescendantCount: true,
        cellClassName: 'monospace-font',
        renderCell: params => {
          const { row, rowNode } = params;
          const value = row.sampleId;
          const text = identifiersBySampleId[value ?? ''] ?? '';

          return (
            <Box sx={{ ml: rowNode.depth * 4 }}>
              {selectedSampleIds.includes(value) ? (
                <span style={{ fontFamily: 'monospace' }}>{text}</span>
              ) : (
                <GridCellCallBack
                  text={text}
                  callBack={() => {
                    setSelectedSampleIds(_ => [value]);
                  }}
                  sx={{ paddingLeft: 0, minWidth: 0, fontFamily: 'monospace' }}
                />
              )}
            </Box>
          );
        },
      }}
      defaultGroupingExpansionDepth={100}
      disableRowSelectionOnClick
      getRowClassName={params =>
        selectedSampleIds && selectedSampleIds.includes(params.row.sampleId) ? classes.highlightedRow : classes.noHover
      }
    />
  );
};

const useRows = (
  trees: ReadonlyArray<SampleJourneyTree>,
  transitionInformation: ReadonlyArray<SampleJourneyTransitionInformation>,
  transitionPaths: ReadonlyArray<ResearchProjectTransitionPath>
): ReadonlyArray<Row> => {
  return useMemo(() => {
    const informationBySampleId = keyBy(transitionInformation, t => t.sampleId);
    const transitionById = keyBy(transitionPaths, i => i.configuredTransitionId);

    let id = 0;

    function getNodes(tree: SampleJourneyTree) {
      const information = informationBySampleId[tree.ancestor.sampleId] ?? {};

      const nodes: Row[] = [
        {
          id: id++,
          ancestor: tree.ancestor,
          depth: tree.depth,
          path: tree.path,
          ...tree.ancestor,
          ...information,
          outputOf: transitionById[information?.transitionCreatedIn?.configuredTransitionId ?? '']?.displayName,
          currentStage: transitionById[information?.currentTransition?.configuredTransitionId ?? '']?.displayName,
        },
      ];

      for (const child of tree.descendant) {
        nodes.push(...getNodes(child));
      }

      nodes.forEach(node => (node.id = id++));

      return nodes;
    }

    return trees.flatMap(tree => getNodes(tree));
  }, [trees, transitionInformation, transitionPaths]);
};

const useColumns = (
  sampleAvailabilityTypes: ReadonlyArray<SampleAvailabilityType>,
  sampleTypes: ReadonlyArray<SampleType>
): GridColDef[] => {
  const { t } = useMemoTranslation();

  return useMemo(
    () => [
      {
        field: sampleId,
        headerName: '',
        headerAlign: 'left',
        align: 'left',
        width: 300,
        maxWidth: 300,
      },
      {
        field: outputOf,
        headerName: t(outputOf),
        headerAlign: 'left',
        align: 'left',
        width: 150,
        valueFormatter: ({ value }) => value && t(value),
      },
      {
        field: currentStage,
        headerName: t(currentStage),
        headerAlign: 'left',
        align: 'left',
        width: 150,
        valueFormatter: ({ value }) => value && t(value),
      },
      {
        field: sampleTypeId,
        headerName: t(sampleTypeId),
        headerAlign: 'left',
        align: 'left',
        width: 150,
        valueFormatter: ({ value }) => value && formatSampleType(sampleTypes, value),
      },
      {
        field: foreignHash,
        headerName: t(foreignHash),
        headerAlign: 'left',
        align: 'left',
        minWidth: 300,
        valueFormatter: ({ value }) => value && truncateForeignHash(value),
      },
      {
        field: sampleBbid,
        headerName: t(sampleBbid),
        headerAlign: 'left',
        align: 'left',
        minWidth: 300,
      },
      {
        field: sampleAvailabilityTypeId,
        headerName: t(sampleAvailabilityTypeId),
        headerAlign: 'left',
        align: 'left',
        width: 200,
        valueFormatter: ({ value }) => value && formatSampleAvailabilityTypeById(sampleAvailabilityTypes, value),
      },
    ],
    [t, sampleAvailabilityTypes, sampleTypes]
  );
};
