import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import React from 'react';
import { DialogOpenButton } from '../../DialogOpenButton';
import DownloadIcon from '@mui/icons-material/Download';

interface DownloadButtonGridCellProps extends GridRenderCellParams {
  downloadOperation: () => void;
}

export function renderDownloadButtonGridCell(params: DownloadButtonGridCellProps) {
  return <MemoizedDownloadButtonGridCell {...params} />;
}

const DownloadButtonGridCell = ({ value, downloadOperation }: DownloadButtonGridCellProps) => {
  return (
    <span>
      {value && (
        <>
          <DialogOpenButton fontWeight={'inherit'} onClick={downloadOperation}>
            <DownloadIcon />
          </DialogOpenButton>
        </>
      )}
    </span>
  );
};

const MemoizedDownloadButtonGridCell = React.memo(DownloadButtonGridCell, (prevProps, nextProps) => {
  return prevProps.value === nextProps.value;
});
