import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Input,
  LinearProgress,
  Radio,
  RadioGroup,
  Typography,
  useTheme,
} from '@mui/material';
import useAuth from 'auth/UseAuth';
import { ChangeEvent, useState } from 'react';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import { CohortType, UpdateResearchProjectCohort } from 'data/ResearchProjectCohortData';
import { DialogCloseButton } from '../components/DialogCloseButton';
import UseMemoTranslation from 'hooks/UseMemoTranslation';
import { SamplesToIncludeCheckBoxes } from './SamplesToIncludeCheckboxes';
import { FileProcessingState, UploadFile } from 'helpers/FileProcessing';
import { getSamplesToInclude } from 'data/ResearchProjectPatientData';

export interface ResearchProjectPatientExcelUploaderProps {
  onExcelUpload: Function;
}

export const UpdateResearchProjectCohortModal = ({ onExcelUpload }: ResearchProjectPatientExcelUploaderProps) => {
  const { accessToken } = useAuth();
  const theme = useTheme();
  const { t } = UseMemoTranslation();

  const requiredPatientColumns = ['Research Project Id', 'Patient Id', 'Selection Status', 'Patient Notes'];
  const requiredSampleColumns = ['Research Project Id', 'Sample Id', 'Selection Status', 'Sample Notes'];

  const [open, setOpen] = useState<boolean>(false);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [cohortTypeToUpdate, setCohortTypeToUpdate] = useState<CohortType>('patient');
  const [includeBiobankedSamples, setIncludeBiobankedSamples] = useState<boolean>(false);
  const [includeNonBiobankedSamples, setIncludeNonBiobankedSamples] = useState<boolean>(false);
  const [fileProcessingState, setFileProcessingState] = useState<FileProcessingState>({ status: 'NotStarted' });

  const handleModalButtonClick = () => {
    setOpen(true);
  };

  const handleClose = () => {
    clearSelectedState();
    setOpen(false);
  };

  const clearSelectedState = () => {
    setSelectedFile(null);
    setCohortTypeToUpdate('patient');
    setIncludeBiobankedSamples(false);
    setIncludeNonBiobankedSamples(false);
    setFileProcessingState({ status: 'NotStarted' });
  };

  const handleFileSelection = async (e: ChangeEvent<HTMLInputElement>) => {
    setFileProcessingState({ status: 'NotStarted' });
    if (e.target.files) {
      setSelectedFile(e.target.files[0]);
    }
  };

  const handleCohortTypeChange = async (ev: ChangeEvent<HTMLInputElement>) => {
    ev.persist();

    let cohortType = ev.target.value as CohortType;

    if (cohortType === 'sample') {
      setIncludeBiobankedSamples(true);
      setIncludeNonBiobankedSamples(true);
    }
    setCohortTypeToUpdate(cohortType);
  };

  const handleUpdateButtonClick = async () => {
    const samplesToInclude = getSamplesToInclude(includeBiobankedSamples, includeNonBiobankedSamples);
    if (accessToken && selectedFile) {
      await UploadFile(setFileProcessingState, () =>
        UpdateResearchProjectCohort(cohortTypeToUpdate, samplesToInclude, selectedFile, accessToken)
      );
      onExcelUpload();
    } else {
      setFileProcessingState({ status: 'NotStarted' });
    }
  };

  return (
    <>
      <Button
        onClick={handleModalButtonClick}
        disabled={fileProcessingState.status === 'Uploading'}
        sx={[
          {
            color: theme.colors.alpha.black[100],
            '&:hover': {
              backgroundColor: 'transparent',
            },
          },
        ]}
      >
        <ModeEditOutlineOutlinedIcon />
        <Typography variant='button' ml={1}>
          Update Cohort
        </Typography>
      </Button>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>
          <DialogCloseButton onClick={handleClose} />
          <Typography variant='h3'>Update Cohort</Typography>
        </DialogTitle>
        <DialogContent>
          <Box component='form'>
            <FormControl>
              <RadioGroup row name='research-project-cohort-type-radio-buttons-group'>
                <FormControlLabel
                  value='research-project-patient-radio-button'
                  label='Patients'
                  control={
                    <Radio
                      checked={cohortTypeToUpdate === 'patient'}
                      value='patient'
                      onChange={handleCohortTypeChange}
                    />
                  }
                />
                <FormControlLabel
                  value='research-project-sample-radio-button'
                  label='Samples'
                  control={
                    <Radio checked={cohortTypeToUpdate === 'sample'} value='sample' onChange={handleCohortTypeChange} />
                  }
                />
              </RadioGroup>
              <SamplesToIncludeCheckBoxes
                disableCheckboxes={cohortTypeToUpdate === 'sample'}
                includeBiobankedSamples={includeBiobankedSamples}
                includeNonBiobankedSamples={includeNonBiobankedSamples}
                onIncludeBiobankedSamplesChange={() => {
                  setIncludeBiobankedSamples(!includeBiobankedSamples);
                }}
                onIncludeNonBiobankedSamplesChange={() => {
                  setIncludeNonBiobankedSamples(!includeNonBiobankedSamples);
                }}
                helpText={t('cohortSamplesToInclude')}
              />
            </FormControl>
            <Typography variant='body1' mt={2}>
              {`Upload a .xlsx file with these required columns: ${
                cohortTypeToUpdate === 'patient' ? requiredPatientColumns.join(', ') : requiredSampleColumns.join(', ')
              }.`}
            </Typography>

            <Input type='file' onChange={handleFileSelection} sx={{ mt: 2, minWidth: 500 }} />
          </Box>
          {fileProcessingState.status === 'Error' && (
            <DialogContentText display='flex' alignItems='center' mt={2} color='error'>
              <ErrorOutlineIcon sx={{ mr: 1 }} />
              {fileProcessingState.errorMessage}
            </DialogContentText>
          )}
        </DialogContent>
        {fileProcessingState.status === 'Uploading' && <LinearProgress />}
        <DialogActions>
          <Button
            onClick={handleUpdateButtonClick}
            sx={{ ml: 2 }}
            disabled={
              fileProcessingState.status === 'Uploading' ||
              (cohortTypeToUpdate === 'patient' && !includeBiobankedSamples && !includeNonBiobankedSamples)
            }
          >
            Update
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
