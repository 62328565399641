import { Box, Dialog, DialogContent, DialogTitle } from '@mui/material';
import React, { useState } from 'react';
import { DialogCloseButton } from '../../../components/DialogCloseButton';
import {
  SupplementalFilesQualityCheckGroupScopedGrid,
  SupplementalFilesTransitionScopedGrid,
} from './SupplementalFilesGrid';
import {
  CreateSupplementalFilesModalForQualityCheckGroup,
  CreateSupplementalFilesModalForTransition,
} from './CreateSupplementalFilesModal';
import { FlexTableBox } from '../../../components/FlexTableBox';

export interface BaseModalProps {
  id: string;
  title: string;
  onClose: () => void;
}

export interface ModalProps {
  title: string;
  onClose: () => void;
  children: React.ReactNode;
  createSupplementalFileModal: React.ReactNode;
}

export const SupplementalFilesTransitionScopedModal = ({ id, onClose, title }: BaseModalProps) => {
  const [refreshTrigger, setRefreshTrigger] = useState<boolean>(true);

  return (
    <Modal
      onClose={onClose}
      title={title}
      createSupplementalFileModal={
        <CreateSupplementalFilesModalForTransition
          id={id}
          onClose={(hasChanges: boolean) => {
            if (hasChanges) {
              setRefreshTrigger(!refreshTrigger);
            }
          }}
        />
      }
    >
      <SupplementalFilesTransitionScopedGrid id={id} refreshTrigger={refreshTrigger} />
    </Modal>
  );
};

export const SupplementalFilesQualityCheckGroupScopedModal = ({ id, onClose, title }: BaseModalProps) => {
  const [refreshTrigger, setRefreshTrigger] = useState<boolean>(true);

  return (
    <Modal
      onClose={onClose}
      title={title}
      createSupplementalFileModal={
        <CreateSupplementalFilesModalForQualityCheckGroup
          id={id}
          onClose={(hasChanges: boolean) => {
            if (hasChanges) {
              setRefreshTrigger(!refreshTrigger);
            }
          }}
        />
      }
    >
      <SupplementalFilesQualityCheckGroupScopedGrid id={id} refreshTrigger={refreshTrigger} />
    </Modal>
  );
};

const Modal = ({ title, onClose, children, createSupplementalFileModal }: ModalProps) => {
  const [modalOpen, setModalOpen] = useState<boolean>(true);

  const handleClose = async () => {
    setModalOpen(false);
    onClose();
  };

  return (
    <>
      <Dialog maxWidth={'md'} fullWidth onClose={handleClose} open={modalOpen}>
        <DialogTitle sx={{ minWidth: '300px', paddingBottom: 0 }}>
          <DialogCloseButton onClick={() => handleClose()} />
          {title}
        </DialogTitle>
        <DialogContent sx={{ height: '30vh' }}>
          <FlexTableBox>
            <Box mb={1} display='flex' justifyContent='right'>
              {createSupplementalFileModal}
            </Box>
            {children}
          </FlexTableBox>
        </DialogContent>
      </Dialog>
    </>
  );
};
