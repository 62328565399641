import { appSettings } from 'AppSettings';
import useAuth from 'auth/UseAuth';
import { useState } from 'react';
import { PatientCountType } from '../components/grid/GridCountType';
import { DownloadFile, FileProcessingState } from 'helpers/FileProcessing';
import { Button, Typography, useTheme } from '@mui/material';
import DownloadDoneIcon from '@mui/icons-material/DownloadDone';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import { BaseDownloadFile } from 'data/BaseData';
import { ValidatePatientCountParameters } from './PatientRecordValidation';
import { ItemOfResearchType, TimeInterval } from 'data/ResearchProjectPatientData';

export interface PatientRecordExportButtonProps {
  itemOfResearchId?: string;
  itemOfResearchType: ItemOfResearchType;
  timeInterval: TimeInterval;
  biobankId?: string;
  labId?: string;
  sampleTypeId?: string;
  numRecords: number;
  decrypted: boolean;
  countType: PatientCountType;
  disabled: boolean;
}

export const PatientRecordExportButton = ({
  itemOfResearchId,
  itemOfResearchType,
  timeInterval,
  biobankId,
  labId,
  sampleTypeId,
  countType,
  disabled,
}: PatientRecordExportButtonProps) => {
  ValidatePatientCountParameters(countType, biobankId, labId, sampleTypeId);

  const { accessToken } = useAuth();
  const theme = useTheme();

  const [buttonDisabled, setButtonDisabled] = useState<boolean>(disabled);
  const [fileProcessingState, setFileProcessingState] = useState<FileProcessingState>({ status: 'NotStarted' });

  const invokeGenerator = async () => {
    setButtonDisabled(true);
    setFileProcessingState({ status: 'Downloading' });

    let url = `${appSettings.api.endpoint}/api/`;
    let params = itemOfResearchType === 'PathogenGroup' ? `?timeInterval=${timeInterval}` : '';

    url +=
      itemOfResearchType === 'DiseaseArea'
        ? `v2/DiseaseAreas/${itemOfResearchId}/PatientRecords`
        : `v2/PathogenGroups/${itemOfResearchId}/PatientRecords`;

    switch (countType) {
      case 'all':
        url += '/ExcelExport';
        break;
      case 'byBiobank':
        url += `/ByBiobank/biobank/${biobankId}/ExcelExport`;
        break;
      case 'byLab':
        url += `/ByLab/lab/${labId}/ExcelExport`;
        break;
      case 'bySampleType':
        url += `/BySampleType/sampleType/${sampleTypeId}/ExcelExport`;
        break;
    }

    DownloadFile(setFileProcessingState, () => BaseDownloadFile(url + params, accessToken));
    setButtonDisabled(disabled);
  };

  return (
    <Button
      id='patient-record-export-button'
      onClick={invokeGenerator}
      disabled={buttonDisabled}
      sx={{
        color: theme.colors.alpha.black[100],
        ml: 5,
      }}
    >
      {fileProcessingState.status === 'NotStarted' && <SaveAltIcon />}
      {fileProcessingState.status === 'Downloading' && <HourglassBottomIcon />}
      {fileProcessingState.status === 'Complete' && <DownloadDoneIcon />}
      {fileProcessingState.status === 'Error' && <ErrorOutlineIcon />}

      <Typography variant='button' ml={1}>
        Export
      </Typography>
    </Button>
  );
};
