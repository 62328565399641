import { LlCustomerOption } from 'researchProjectManagement/ResearchProjectAutocompleteOptions';
import useAuth from '../../auth/UseAuth';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { GetLlCustomers } from 'data/LlCustomerData';

export const useCustomerOptions = (): [LlCustomerOption[], boolean, Dispatch<SetStateAction<boolean>>] => {
  const { accessToken } = useAuth();
  const [customerOptions, setCustomerOptions] = useState<LlCustomerOption[]>([]);
  const [refresh, refreshOptions] = useState<boolean>(true);

  useEffect(() => {
    async function getData() {
      const customers = await GetLlCustomers(accessToken);
      setCustomerOptions(
        customers.map(c => {
          return { label: c.name, customer: c };
        })
      );
    }

    getData();
  }, [refresh, accessToken]);

  return [customerOptions, refresh, refreshOptions];
};
