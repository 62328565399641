import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import React, { useState } from 'react';
import { DialogOpenButton } from '../../DialogOpenButton';
import { ConfirmationDialog } from '../../ConfirmationDialog';
import { PostUndoTransition } from '../../../data/SampleTrackingTransitionData';
import useAuth from '../../../auth/UseAuth';
import { truncateGuid } from '../../../data/SampleData';
import useMemoTranslation from '../../../hooks/UseMemoTranslation';
import UndoIcon from '@mui/icons-material/Undo';
import { OnDemandTextDialog } from '../../OnDemandTextDialog';
import { GetUndoableTransitionReason } from '../../../data/SampleTrackingQualityCheckGroupData';
import { useParams } from 'react-router-dom';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

interface SampleTrackingTransitionUndoGridCellProps extends GridRenderCellParams {
  onClose?: () => void;
}

export function renderSampleTrackingTransitionUndoGridCell(params: SampleTrackingTransitionUndoGridCellProps) {
  return <MemoizedSampleTrackingTransitionUndoGridCell {...params} />;
}

const SampleTrackingTransitionUndoGridCell = ({
  value,
  row,
  onClose = () => {},
}: SampleTrackingTransitionUndoGridCellProps) => {
  const { accessToken } = useAuth();
  const { t } = useMemoTranslation();
  let { researchProjectId } = useParams() as { researchProjectId: string };

  const [modalOpen, setModalOpen] = useState<boolean>(false);

  async function handleConfirm() {
    if (value) {
      await PostUndoTransition(value, accessToken);
    }

    handleClose();
    onClose();
  }

  function handleClose() {
    setModalOpen(false);
  }

  return (
    <span>
      {value ? (
        <>
          <DialogOpenButton fontWeight={'inherit'} onClick={() => setModalOpen(true)}>
            <UndoIcon />
          </DialogOpenButton>
          <ConfirmationDialog
            open={modalOpen}
            onConfirm={handleConfirm}
            onClose={handleClose}
            title={t('confirmOrderUndo')}
            subtitle={`${row.transition} ${t('orderId')}: ${truncateGuid(row.transitionId)}`}
            message={`${t('orderUndoInfo')}`}
            confirmBtnText={t('undoOrder')}
          />
        </>
      ) : (
        <>
          <DialogOpenButton fontWeight={'inherit'} onClick={() => setModalOpen(true)}>
            <InfoOutlinedIcon />
          </DialogOpenButton>
          <OnDemandTextDialog
            open={modalOpen}
            onClose={handleClose}
            title={t('confirmOrderUndo')}
            subtitle={`${row.transition} ${t('orderId')}: ${truncateGuid(row.transitionId)}`}
            getText={async () =>
              (await GetUndoableTransitionReason(researchProjectId, row.transitionId, accessToken)).message
            }
          />
        </>
      )}
    </span>
  );
};

const MemoizedSampleTrackingTransitionUndoGridCell = React.memo(
  SampleTrackingTransitionUndoGridCell,
  (prevProps, nextProps) => {
    return prevProps.value === nextProps.value;
  }
);
