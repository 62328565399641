import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import React, { useState } from 'react';
import { SampleTrackingTransitionSampleOrderScopedModal } from '../../../researchProjectManagement/sampleTracking/SampleTrackingTransitionSampleModal';
import { DialogOpenButton } from '../../DialogOpenButton';
import { GuidGridCellTooltip } from './GuidGridCellTooltip';
import { LightTooltip } from './GridCellTooltip';
import { truncateGuid } from '../../../data/SampleData';
import useMemoTranslation from '../../../hooks/UseMemoTranslation';

interface SampleTrackingOrderSampleGridCellProps extends GridRenderCellParams {
  onClose?: () => void;
}

export function renderSampleTrackingOrderSampleGridCell(params: SampleTrackingOrderSampleGridCellProps) {
  return <MemoizedSampleTrackingOrderSampleGridCell {...params} />;
}

const SampleTrackingOrderSampleGridCell = ({
  value,
  row,
  onClose = () => {},
}: SampleTrackingOrderSampleGridCellProps) => {
  const { t } = useMemoTranslation();

  const [modalOpen, setModalOpen] = useState<boolean>(false);

  function handleClose() {
    onClose();
    setModalOpen(false);
  }

  function getLabel() {
    const value = row.transitionId;

    const isArray = Array.isArray(value);

    if (!isArray) {
      return <GuidGridCellTooltip guid={value} />;
    }

    const length = value.length;

    if (isArray && length === 1) {
      return <GuidGridCellTooltip guid={value[0]} />;
    }

    const asArray = [...value];

    return (
      <LightTooltip title={asArray.map(s => truncateGuid(s)).join('\n')}>
        <div style={{ overflow: 'hidden' }}>{t('multiple')}</div>
      </LightTooltip>
    );
  }

  return (
    <span>
      {value && (
        <>
          <DialogOpenButton fontWeight={'inherit'} onClick={() => setModalOpen(true)}>
            {getLabel()}
          </DialogOpenButton>
          {modalOpen && (
            <SampleTrackingTransitionSampleOrderScopedModal
              researchProjectId={row.researchProjectId}
              transitionIds={Array.isArray(row.transitionId) ? row.transitionId : [row.transitionId]}
              transitionType={row.transition}
              onClose={handleClose}
              title={Array.isArray(row.transitionId) ? t('multiple') : row.transitionId}
              orderDetails={row}
            />
          )}
        </>
      )}
    </span>
  );
};

const MemoizedSampleTrackingOrderSampleGridCell = React.memo(
  SampleTrackingOrderSampleGridCell,
  (prevProps, nextProps) => {
    return prevProps.value === nextProps.value;
  }
);
