import { GridRenderCellParams } from '@mui/x-data-grid-pro';

export function renderCellDateTime(params: GridRenderCellParams) {
  if (!params.value) {
    return <></>;
  }

  return <GridCellDateTime date={params.value} />;
}

interface GridCellDateTimeProps {
  date?: Date;
}

const GridCellDateTime = ({ date }: GridCellDateTimeProps) => {
  return (
    <span>{date ? date.toLocaleTimeString('en-US', { year: 'numeric', month: '2-digit', day: 'numeric' }) : ''}</span>
  );
};
