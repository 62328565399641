import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

export function DialogCloseButton(props: { onClick: () => void }) {
  return (
    <IconButton
      aria-label='close'
      onClick={props.onClick}
      sx={{
        position: 'absolute',
        right: 8,
        top: 8,
        color: theme => theme.palette.grey[500],
      }}
    >
      <CloseIcon />
    </IconButton>
  );
}
