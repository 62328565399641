import { Button, useTheme } from '@mui/material';
import { DiseaseArea } from 'data/DiseaseAreaData';
import { PatientCountType } from 'components/grid/GridCountType';

export interface TotalCountGridCellProps {
  diseaseArea: DiseaseArea;
  onTotalCountClick(
    count: number,
    diseaseArea: DiseaseArea,
    patientRecordCountType: PatientCountType,
    biobankId?: string,
    sampleTypeId?: string
  ): void;
}

export const TotalCountGridCell = ({ diseaseArea, onTotalCountClick }: TotalCountGridCellProps) => {
  const theme = useTheme();
  const count = diseaseArea.patientCount ?? 0;

  const handleTotalCountButtonClick = async () => {
    onTotalCountClick(count, diseaseArea, 'all', undefined, undefined);
  };

  return (
    <Button
      disabled={count === 0}
      onClick={handleTotalCountButtonClick}
      sx={[
        {
          fontWeight: 'inherit',
          '&:hover': {
            backgroundColor: 'transparent',
          },
          '&:disabled': {
            color: `${theme.colors.primary.dark}`,
          },
        },
      ]}
    >
      {count.toLocaleString() ?? 0}
    </Button>
  );
};
