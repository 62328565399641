import { appSettings } from 'AppSettings';
import { ItemOfResearchType, TimeInterval } from './ResearchProjectPatientData';
import { BaseGet } from './BaseData';

export type PatientRecordOrderBy =
  | 'PatientId'
  | 'Deidentity'
  | 'SampleCount'
  | 'Biobanks'
  | 'Labs'
  | 'Bbids'
  | 'ForeignHashes'
  | 'SampleTypes'
  | 'SampleComments'
  | 'LatestSampleCollectionDate'
  | 'Gender'
  | 'YearOfBirth'
  | 'Race'
  | 'Ethnicity'
  | 'Icd9AndIcd10Codes'
  | 'SnomedConceptIds';

export interface PatientRecord {
  patientId: string;
  deidentity: string;
  sampleCount: number;
  biobanks: string[];
  labs: string[];
  bbids: string[];
  foreignHashes: string[];
  sampleTypes: string[];
  sampleComments: string[];
  latestSampleCollectionDate: Date;
  gender: string;
  yearOfBirth: string;
  race: string;
  ethnicity: string;
  icd9AndIcd10Codes: string[];
  snomedConceptIds: string[];
}

export async function GetPatientRecords(
  itemOfResearchId: string,
  itemOfResearchType: ItemOfResearchType,
  timeInterval: TimeInterval,
  orderBy: PatientRecordOrderBy,
  sortOrder: string,
  pageSize: number,
  pageStart: number,
  decrypted: boolean,
  accessToken: string
): Promise<PatientRecord[]> {
  let url = `${appSettings.api.endpoint}/api/`;
  url +=
    itemOfResearchType === 'DiseaseArea'
      ? `v2/DiseaseAreas/${itemOfResearchId}/PatientRecords`
      : `v2/PathogenGroups/${itemOfResearchId}/PatientRecords`;

  let params = new URLSearchParams();
  params.append('timeInterval', timeInterval);
  params.append('orderBy', orderBy);
  params.append('sortOrder', sortOrder);
  params.append('pageSize', pageSize.toString());
  params.append('pageStart', pageStart.toString());
  params.append('decrypted', decrypted.toString());

  return await BaseGet((url += '?' + params), accessToken);
}

export async function GetDiseaseAreaPatientRecordsByBiobank(
  diseaseAreaId: string,
  biobankId: string,
  orderBy: PatientRecordOrderBy,
  sortOrder: string,
  pageSize: number,
  pageStart: number,
  decrypted: boolean,
  accessToken: string
): Promise<PatientRecord[]> {
  let url = `${appSettings.api.endpoint}/api/v2/DiseaseAreas/${diseaseAreaId}/PatientRecords/ByBiobank/biobank/${biobankId}`;
  let params = new URLSearchParams();
  params.append('orderBy', orderBy);
  params.append('sortOrder', sortOrder);
  params.append('pageSize', pageSize.toString());
  params.append('pageStart', pageStart.toString());
  params.append('decrypted', decrypted.toString());

  return await BaseGet((url += '?' + params), accessToken);
}

export async function GetPathogenGroupPatientRecordsByLab(
  pathogenGroupId: string,
  timeInterval: TimeInterval,
  labId: string,
  orderBy: PatientRecordOrderBy,
  sortOrder: string,
  pageSize: number,
  pageStart: number,
  decrypted: boolean,
  accessToken: string
): Promise<PatientRecord[]> {
  let url = `${appSettings.api.endpoint}/api/v2/PathogenGroups/${pathogenGroupId}/PatientRecords/ByLab/lab/${labId}`;
  let params = new URLSearchParams();
  params.append('timeInterval', timeInterval);
  params.append('orderBy', orderBy);
  params.append('sortOrder', sortOrder);
  params.append('pageSize', pageSize.toString());
  params.append('pageStart', pageStart.toString());
  params.append('decrypted', decrypted.toString());

  return await BaseGet((url += '?' + params), accessToken);
}

export async function GetPatientRecordsBySampleType(
  itemOfResearchId: string,
  itemOfResearchType: ItemOfResearchType,
  timeInterval: TimeInterval,
  sampleTypeId: string,
  orderBy: PatientRecordOrderBy,
  sortOrder: string,
  pageSize: number,
  pageStart: number,
  decrypted: boolean,
  accessToken: string
): Promise<PatientRecord[]> {
  let itemOfResearch = itemOfResearchType === 'DiseaseArea' ? 'v2/DiseaseAreas' : 'v2/PathogenGroups';
  let url = `${appSettings.api.endpoint}/api/${itemOfResearch}/${itemOfResearchId}/PatientRecords/BySampleType/sampleType/${sampleTypeId}`;
  let params = new URLSearchParams();
  params.append('timeInterval', timeInterval);
  params.append('orderBy', orderBy);
  params.append('sortOrder', sortOrder);
  params.append('pageSize', pageSize.toString());
  params.append('pageStart', pageStart.toString());
  params.append('decrypted', decrypted.toString());

  return await BaseGet((url += '?' + params), accessToken);
}
