import './DatavantDatasets.css';
import React, { FC, ReactNode, useState } from 'react';
import { DatavantSftpFile, DownloadFile } from 'data/DatavantTokenData';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { TreeItem, TreeView } from '@mui/lab';
import useAuth from '../auth/UseAuth';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
import DownloadDoneIcon from '@mui/icons-material/DownloadDone';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

type DatavantSftpTreeProps = {
  files: DatavantSftpFile[];
};
export const DatavantSftpTree: FC<DatavantSftpTreeProps> = (props: DatavantSftpTreeProps) => {
  const { accessToken } = useAuth();
  const [fileIcon, setFileIcon] = useState<React.ReactNode>(<SaveAltIcon />);

  const renderDataset = (dataset: DatavantSftpFile, nodeId: number): ReactNode => {
    if (dataset.isDirectory) {
      return (
        <TreeItem key={nodeId} nodeId={dataset.name} label={dataset.name}>
          {dataset.children.map(child => renderDataset(child, nodeId + 1))}
        </TreeItem>
      );
    }

    return (
      <TreeItem
        key={nodeId}
        classes={{ selected: 'dataset-file-tree-item' }}
        onClick={async () => {
          await downloadFile(dataset.fullPath);
        }}
        icon={fileIcon}
        nodeId={dataset.name}
        label={dataset.name}
      />
    );
  };

  const downloadFile = async (path: string) => {
    if (accessToken) {
      setFileIcon(<HourglassBottomIcon />);
      try {
        const response = await DownloadFile(path, accessToken);
        if (response.ok) {
          setFileIcon(<DownloadDoneIcon />);
        } else {
          setFileIcon(<ErrorOutlineIcon />);
        }
      } catch (err) {
        setFileIcon(<ErrorOutlineIcon />);
      }
    }
  };

  return (
    <TreeView
      defaultCollapseIcon={<ExpandMoreIcon />}
      defaultExpandIcon={<ChevronRightIcon />}
      sx={{ flexGrow: 1, overflowY: 'auto' }}
    >
      {props.files.map(dataset => renderDataset(dataset, 1))}
    </TreeView>
  );
};
