export function ConvertCodeToLLFormat(standardIcd10Code: string) {
  return standardIcd10Code.replace('*', '%').replace('.', '');
}

export function ConvertAllCodesToLLFormat(standardIcd10Codes: string[]) {
  return standardIcd10Codes.map(code => (code = ConvertCodeToLLFormat(code)));
}

export function ConvertCodeToUserFormat(llIcd10Code: string) {
  let standardCode = llIcd10Code.replace('%', '*');
  if (standardCode.length > 3 && standardCode.charAt(3) !== '*') {
    standardCode = standardCode.substring(0, 3) + '.' + standardCode.substring(3);
  }
  return standardCode;
}

export function ConvertAllCodesToUserFormat(llIcd10Codes: string[]) {
  return llIcd10Codes.map(code => (code = ConvertCodeToUserFormat(code)));
}
