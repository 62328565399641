import { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useGlobalState } from 'components/GlobalStateProvider';
import jwtDecode, { JwtPayload } from 'jwt-decode';

export default function useAuth() {
  const { isAuthenticated, isLoading, user, getAccessTokenSilently, getIdTokenClaims } = useAuth0();
  const { globalState, setGlobalState } = useGlobalState();
  const [gettingAuth, setGettingAuth] = useState<boolean>(false);

  useEffect(() => {
    async function getAccessToken() {
      if (!gettingAuth) {
        // mutex so we don't accidentally call multiple times
        setGettingAuth(true);

        let accessToken: string | undefined = globalState.accessToken;

        if (
          globalState.isAuthenticated === true &&
          (globalState.accessTokenExpired === undefined || globalState.accessTokenExpired(accessToken))
        ) {
          accessToken = await getAccessTokenSilently();
          // console.log(`*** getting credentials - ${accessToken.substring(0, 10)}`);
        }

        if (
          accessToken !== globalState.accessToken ||
          isAuthenticated !== globalState.isAuthenticated ||
          isLoading !== globalState.isLoading
        ) {
          // console.log(
          //   `*** setting global state: auth=${(accessToken ?? '').substring(
          //     0,
          //     10
          //   )} user=${user} isAuth=${isAuthenticated} isLoading=${isLoading}`
          // );
          setGlobalState(_ => ({
            user: user,
            accessToken: accessToken,
            isAuthenticated: isAuthenticated,
            isLoading: isLoading,
            accessTokenExpired: token => {
              if (token) {
                const jwt = jwtDecode<JwtPayload>(token);
                const age = new Date().getTime() - (jwt.exp ?? 0) * 1000;
                return age >= 36000 * 1000;
              } else {
                return true;
              }
            },
          }));
        }
        setGettingAuth(false);
      }
    }

    getAccessToken();
  }, [
    getAccessTokenSilently,
    user,
    globalState,
    setGlobalState,
    isAuthenticated,
    isLoading,
    gettingAuth,
    getIdTokenClaims,
  ]);

  return globalState;
}
