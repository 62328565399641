import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import React, { useState } from 'react';
import { SampleTrackingTransitionSampleQualityCheckGroupScopedModal } from '../../../researchProjectManagement/sampleTracking/SampleTrackingTransitionSampleModal';
import { DialogOpenButton } from '../../DialogOpenButton';
import { GuidGridCellTooltip } from './GuidGridCellTooltip';

interface SampleTrackingTransitionSampleQualityCheckGroupScopedModalCellProps extends GridRenderCellParams {
  onClose?: () => void;
}

export function renderSampleTrackingTransitionSampleQualityCheckGroupScopedModalCell(
  params: SampleTrackingTransitionSampleQualityCheckGroupScopedModalCellProps
) {
  return <MemoizedSampleTrackingTransitionSampleQualityCheckGroupScopedModalCell {...params} />;
}

const SampleTrackingTransitionSampleQualityCheckGroupScopedModalCell = ({
  value,
  row,
  onClose = () => {},
}: SampleTrackingTransitionSampleQualityCheckGroupScopedModalCellProps) => {
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  function handleClose() {
    onClose();
    setModalOpen(false);
  }

  return (
    <span>
      {value && (
        <>
          <DialogOpenButton fontWeight={'inherit'} onClick={() => setModalOpen(true)}>
            <GuidGridCellTooltip guid={row.transitionQualityCheckGroupId} />
          </DialogOpenButton>
          {modalOpen && (
            <SampleTrackingTransitionSampleQualityCheckGroupScopedModal
              researchProjectId={row.researchProjectId}
              qualityCheckGroupId={row.transitionQualityCheckGroupId}
              transitionType={row.sampleTrackingTransition}
              onClose={handleClose}
              title={value}
            />
          )}
        </>
      )}
    </span>
  );
};

const MemoizedSampleTrackingTransitionSampleQualityCheckGroupScopedModalCell = React.memo(
  SampleTrackingTransitionSampleQualityCheckGroupScopedModalCell,
  (prevProps, nextProps) => {
    return prevProps.value === nextProps.value;
  }
);
