import { appSettings } from 'AppSettings';
import { BaseGet } from './BaseData';

export interface SnomedClinicalTermPatientCount {
  snomedClinicalTermId: string;
  conceptId: string;
  term: string;
  patientCount: number;
  patientCountDetected: number;
}

export async function GetSnomedClinicalTermPatientCounts(
  accessToken?: string
): Promise<SnomedClinicalTermPatientCount[]> {
  return await BaseGet(
    `${appSettings.api.endpoint}/api/v2/SnomedClinicalTermMetrics/PatientCounts/Detected`,
    accessToken
  );
}
