import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import React, { useState } from 'react';
import { DialogOpenButton } from '../../DialogOpenButton';
import { ConfirmationDialog } from '../../ConfirmationDialog';
import useAuth from '../../../auth/UseAuth';
import { truncateGuid } from '../../../data/SampleData';
import { PostUndoQualityCheckGroup } from '../../../data/SampleTrackingTransitionData';
import useMemoTranslation from '../../../hooks/UseMemoTranslation';
import UndoIcon from '@mui/icons-material/Undo';
import { OnDemandTextDialog } from '../../OnDemandTextDialog';
import { GetUndoableQualityCheckGroupReason } from '../../../data/SampleTrackingQualityCheckGroupData';
import { useParams } from 'react-router-dom';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

interface SampleTrackingQualityCheckGroupUndoGridCellProps extends GridRenderCellParams {
  onClose?: () => void;
}

export function renderSampleTrackingQualityCheckGroupUndoGridCell(
  params: SampleTrackingQualityCheckGroupUndoGridCellProps
) {
  return <MemoizedSampleTrackingQualityCheckGroupUndoGridCell {...params} />;
}

const SampleTrackingQualityCheckGroupUndoGridCell = ({
  value,
  row,
  onClose = () => {},
}: SampleTrackingQualityCheckGroupUndoGridCellProps) => {
  const { accessToken } = useAuth();
  const { t } = useMemoTranslation();
  let { researchProjectId } = useParams() as { researchProjectId: string };

  const [modalOpen, setModalOpen] = useState<boolean>(false);

  async function handleConfirm() {
    if (value) {
      await PostUndoQualityCheckGroup(value, accessToken);
    }

    handleClose();
    onClose();
  }

  function handleClose() {
    setModalOpen(false);
  }

  return (
    <span>
      {value ? (
        <>
          <DialogOpenButton fontWeight={'inherit'} onClick={() => setModalOpen(true)}>
            <UndoIcon />
          </DialogOpenButton>
          <ConfirmationDialog
            open={modalOpen}
            onConfirm={handleConfirm}
            onClose={handleClose}
            title={t('confirmQualityCheckGroupUndo')}
            subtitle={truncateGuid(row.qualityCheckGroupId)}
            message={t('qualityCheckGroupUndoInfo')}
            confirmBtnText={t('undoQualityCheckGroup')}
          />
        </>
      ) : (
        <>
          <DialogOpenButton fontWeight={'inherit'} onClick={() => setModalOpen(true)}>
            <InfoOutlinedIcon />
          </DialogOpenButton>
          <OnDemandTextDialog
            open={modalOpen}
            onClose={handleClose}
            title={t('confirmQualityCheckGroupUndo')}
            subtitle={truncateGuid(row.qualityCheckGroupId)}
            getText={async () =>
              (await GetUndoableQualityCheckGroupReason(researchProjectId, row.qualityCheckGroupId, accessToken))
                .message
            }
          />
        </>
      )}
    </span>
  );
};

const MemoizedSampleTrackingQualityCheckGroupUndoGridCell = React.memo(
  SampleTrackingQualityCheckGroupUndoGridCell,
  (prevProps, nextProps) => {
    return prevProps.value === nextProps.value;
  }
);
