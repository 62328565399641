import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import React from 'react';
import { ResearchProject } from '../../../data/ResearchProjectData';
import { Button } from '@mui/material';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';

interface EditResearchProjectGridCellProps extends GridRenderCellParams {
  researchProject: ResearchProject;
  onUpdateResearchProjectClick: Function;
}

export function renderEditResearchProjectGridCell(params: EditResearchProjectGridCellProps) {
  return <MemoizedEditResearchProjectGridCell {...params} />;
}

export const EditResearchProjectGridCell = ({
  researchProject,
  onUpdateResearchProjectClick,
}: EditResearchProjectGridCellProps) => {
  const handleUpdateResearchProjectButtonClick = async () => {
    onUpdateResearchProjectClick(researchProject);
  };

  return (
    <Button
      onClick={handleUpdateResearchProjectButtonClick}
      sx={[
        {
          fontWeight: 'inherit',
          padding: 0,
          margin: 0,
          '&:hover': {
            backgroundColor: 'transparent',
          },
        },
      ]}
    >
      <ModeEditOutlineOutlinedIcon />
    </Button>
  );
};

const MemoizedEditResearchProjectGridCell = React.memo(EditResearchProjectGridCell, (prevProps, nextProps) => {
  return prevProps.value === nextProps.value;
});
