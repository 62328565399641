import { appSettings } from 'AppSettings';
import { orderBy } from 'lodash';

export interface DiseaseAreaCountByBiobank {
  diseaseAreaId: string;
  biobankId: string;
  patientCount: number;
  updatedAt: Date;
}

export interface DiseaseAreaCountBySampleType {
  diseaseAreaId: string;
  sampleTypeId: string;
  patientCount: number;
  updatedAt: Date;
}

export async function GetDiseaseAreaCountByBiobank(accessToken: string): Promise<DiseaseAreaCountByBiobank[]> {
  let counts: DiseaseAreaCountByBiobank[] = [];
  if (accessToken) {
    try {
      const response = await fetch(`${appSettings.api.endpoint}/api/v2/DiseaseAreaMetrics/Counts/countBy/byBiobank`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      if (response.ok) {
        const values: DiseaseAreaCountByBiobank[] = await response.json();
        counts = [...orderBy(values, ['diseaseAreaId', 'biobankId'], ['asc', 'asc'])];
      }
    } catch (err) {
      console.dir(err);
    }
  }
  return counts;
}

export async function GetDiseaseAreaCountBySampleType(accessToken: string): Promise<DiseaseAreaCountBySampleType[]> {
  let counts: DiseaseAreaCountBySampleType[] = [];
  if (accessToken) {
    try {
      const response = await fetch(
        `${appSettings.api.endpoint}/api/v2/DiseaseAreaMetrics/Counts/countBy/bySampleType`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      if (response.ok) {
        const values: DiseaseAreaCountBySampleType[] = await response.json();
        counts = [...orderBy(values, ['diseaseAreaId', 'sampleTypeId'], ['asc', 'asc'])];
      }
    } catch (err) {
      console.dir(err);
    }
  }
  return counts;
}

export async function UpdateDiseaseAreaCounts(accessToken: string): Promise<string | null> {
  if (accessToken) {
    try {
      const response = await fetch(`${appSettings.api.endpoint}/api/v2/Tasks/UpdateDiseaseAreaCounts`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      if (response.ok) {
        const jobId: string = await response.json();
        return jobId;
      }
    } catch (err) {
      console.dir(err);
    }
  }
  return null;
}
