import useAuth from '../../auth/UseAuth';
import { useEffect, useState } from 'react';
import { SampleType } from '../../data/SampleTypeData';
import { GetSampleTypes } from '../../data/ReferenceData';

const useSampleTypes = (): ReadonlyArray<SampleType> => {
  const { accessToken } = useAuth();
  const [sampleTypes, setSampleTypes] = useState<ReadonlyArray<SampleType>>([]);

  useEffect(() => {
    async function getData() {
      const data = await GetSampleTypes(accessToken);
      setSampleTypes(data);
    }

    getData();
  }, [accessToken]);

  return sampleTypes;
};

export default useSampleTypes;
