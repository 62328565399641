import useAuth from '../../auth/UseAuth';
import { GetTransitionEnum, TransitionEnum } from '../../data/ReferenceData';
import { useEffect, useState } from 'react';
import { find, orderBy } from 'lodash';
import { TransitionType } from '../../data/SampleTrackingData';

const useTransitionEnums = (): ReadonlyArray<TransitionEnum> => {
  const { accessToken } = useAuth();
  const [transitionEnums, setTransitionEnums] = useState<ReadonlyArray<TransitionEnum>>([]);

  useEffect(() => {
    async function getData() {
      const data = await GetTransitionEnum(accessToken);
      setTransitionEnums(orderBy(data, i => i.name));
    }

    getData();
  }, [accessToken]);

  return transitionEnums;
};

export const useTransitionEnum = (transitionType: TransitionType): TransitionEnum | undefined => {
  const { accessToken } = useAuth();
  const [transitionEnum, setTransitionEnum] = useState<TransitionEnum | undefined>();

  useEffect(() => {
    let isCancelled = false;

    GetTransitionEnum(accessToken).then(data => {
      if (isCancelled) {
        return;
      }

      setTransitionEnum(find(data, i => i.name === transitionType));
    });

    return () => {
      isCancelled = true;
    };
  }, [accessToken, transitionType]);

  return transitionEnum;
};

export default useTransitionEnums;
