import { appSettings } from 'AppSettings';

export interface SampleSelectionStatus {
  sampleSelectionStatus: string;
  description: string;
  selected: boolean;
}

export async function GetSampleSelectionStatuses(accessToken: string): Promise<SampleSelectionStatus[]> {
  let researchProjects: SampleSelectionStatus[] = [];

  if (accessToken) {
    try {
      const response = await fetch(`${appSettings.api.endpoint}/api/v2/ResearchProjectCohort/SelectionStatus/Sample`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      if (response.ok) {
        researchProjects = await response.json();
      }
    } catch (err) {
      console.dir(err);
    }
  }
  return researchProjects;
}

export function CamelCaseToLowerCaseWithSpace(researchProjectStatus: string) {
  return researchProjectStatus.replace(/[A-Z]/g, ' $&').toLowerCase();
}
