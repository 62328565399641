import useAuth from '../../auth/UseAuth';
import { useEffect, useState } from 'react';
import {
  GetResearchProjectTransitionPath,
  ResearchProjectTransitionPath,
} from '../../data/ResearchProjectTransitionPathData';
import { LoadingState, LoadState } from '../LoadingStateUtil';
import { Dictionary, UseStateSetter } from '../../util/TypeUtil';

export const useResearchProjectTransitionPath = (
  researchProjectId: string,
  setLoadingState: UseStateSetter<LoadingState>
): [ReadonlyArray<ResearchProjectTransitionPath>, () => void] => {
  const { accessToken } = useAuth();
  const [researchProjectTransitionPath, setResearchProjectTransitionPath] = useState<
    ReadonlyArray<ResearchProjectTransitionPath>
  >([]);
  const [refresh, refreshTrigger] = useState<boolean>(true);

  useEffect(() => {
    LoadState(setLoadingState, async () => {
      const data = await GetResearchProjectTransitionPath(researchProjectId, accessToken);
      setResearchProjectTransitionPath(data);
    });
  }, [refresh, accessToken, researchProjectId, setLoadingState]);

  function refreshCallBack() {
    refreshTrigger(!refresh);
  }

  return [researchProjectTransitionPath, refreshCallBack];
};

export const useResearchProjectsTransitionPath = (
  researchProjectIds: ReadonlyArray<string>,
  setLoadingState: UseStateSetter<LoadingState>
): [Dictionary<ReadonlyArray<ResearchProjectTransitionPath>>, () => void] => {
  const { accessToken } = useAuth();
  const [researchProjectTransitionPath, setResearchProjectTransitionPath] = useState<
    Dictionary<ReadonlyArray<ResearchProjectTransitionPath>>
  >({});
  const [refresh, refreshTrigger] = useState<boolean>(true);

  useEffect(() => {
    LoadState(setLoadingState, async () => {
      const dict: Dictionary<ReadonlyArray<ResearchProjectTransitionPath>> = {};
      const promises: Promise<void>[] = [];

      for (const i of researchProjectIds) {
        promises.push(
          GetResearchProjectTransitionPath(i, accessToken).then(data => {
            dict[i] = data;
          })
        );
      }

      await Promise.all(promises);

      setResearchProjectTransitionPath(dict);
    });
  }, [refresh, accessToken, researchProjectIds, setLoadingState]);

  function refreshCallBack() {
    refreshTrigger(!refresh);
  }

  return [researchProjectTransitionPath, refreshCallBack];
};
