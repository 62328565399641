import { useEffect, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import { LoadingIndicator } from './LoadingIndicator';
import useMemoTranslation from '../hooks/UseMemoTranslation';
import { LoadingState, LoadState } from './LoadingStateUtil';
import { ErrorIndicator } from './ErrorIndicator';

type OnDemandTextDialogProps = {
  onClose: () => void;
  getText: () => Promise<string>;
  title?: string;
  subtitle?: string;
  open: boolean;
};

export const OnDemandTextDialog = ({ getText, onClose, title, subtitle, open }: OnDemandTextDialogProps) => {
  const { t } = useMemoTranslation();

  const [loadingState, setLoadingState] = useState<LoadingState>({ status: 'NotStarted' });
  const [body, setBody] = useState<string>('');

  function handleClose() {
    onClose();
    setLoadingState({ status: 'NotStarted' });
  }

  useEffect(() => {
    return LoadState(setLoadingState, async () => {
      if (!open) {
        return;
      }

      setBody(await getText());
    });
  }, [open, getText]);

  return (
    <Dialog open={open} onClose={handleClose} maxWidth={'xs'}>
      <DialogTitle>
        {title}
        <Typography variant='subtitle1'>{subtitle}</Typography>
      </DialogTitle>
      <DialogContent>
        <Typography sx={{ whiteSpace: 'pre-line' }}>{body}</Typography>
      </DialogContent>
      <LoadingIndicator loadingState={loadingState} margin={'LR'} />
      <ErrorIndicator loadingState={loadingState} />
      <DialogActions>
        <Button disabled={loadingState.status === 'Loading'} onClick={handleClose}>
          {t('close')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
