import { BasePostVoid } from './BaseData';
import { appSettings } from '../AppSettings';

export async function DiscardSamples(sampleIds: ReadonlyArray<string>, accessToken: string): Promise<void> {
  await BasePostVoid(
    `${appSettings.api.endpoint}/api/v2/Exhaustion?sampleAvailabilityType=Discarded`,
    sampleIds,
    accessToken
  );
}
