import { AuthorizedPage } from 'auth/AuthorizedPage';
import { Page } from 'components/Page';
import useAuth from 'auth/UseAuth';
import { useEffect, useState } from 'react';
import { GetLabHistoricCounts, GetLabPatientTotal, GetLabSampleTotal, HistoricCount } from 'data/HistoricCountData';
import { HistoricCountLineChart } from 'historicCounts/HistoricCountLineChart';
import { CountType } from 'historicCounts/CountTypeSelector';
import { DateAggregate } from 'historicCounts/DateAggregate';
import { HistoricCountFilters } from 'historicCounts/HistoricCountFilters';
import 'historicCounts/HistoricCounts.css';
import { Box, Card } from '@mui/material';
import { Spinner } from 'react-bootstrap';
import { GetLabsWithSamples, Lab } from 'data/LabData';
import { orderBy } from 'lodash';

type StatusType = 'Loading' | 'Complete' | 'InsufficientData';

export const LabMetricsPage = () => {
  const { accessToken } = useAuth();
  const defaultStartDate = new Date();
  defaultStartDate.setMonth(defaultStartDate.getUTCMonth() - 24);

  const [chartDataStatus, setChartDataStatus] = useState<StatusType>('Loading');
  const [startDate, setStartDate] = useState<Date>(defaultStartDate);
  const [endDate, setEndDate] = useState<Date>(new Date());
  const [dateAggregate, setDateAggregate] = useState<DateAggregate>('month');
  const [countType, setCountType] = useState<CountType>('patient');
  const [labs, setLabs] = useState<Lab[]>([]);
  const [labCounts, setLabCounts] = useState<HistoricCount[]>([]);
  const [totalInLabs, setTotalInLabs] = useState<number>();

  useEffect(() => {
    async function getState() {
      if (accessToken) {
        const values: Lab[] = await GetLabsWithSamples(accessToken);
        values.forEach(value => (value.selected = true));
        setLabs([...orderBy(values, [(b: Lab) => b.name.toLowerCase()], ['asc'])]);
      }
    }
    getState();
  }, [accessToken]);

  useEffect(() => {
    async function getState() {
      if (accessToken && labs.length > 0) {
        setChartDataStatus('Loading');
        if (countType === 'patient') {
          setTotalInLabs(
            await GetLabPatientTotal(
              labs.map(lab => lab.labId),
              accessToken
            )
          );
        }
        if (countType === 'sample') {
          setTotalInLabs(
            await GetLabSampleTotal(
              labs.map(lab => lab.labId),
              accessToken
            )
          );
        }
        const counts = await GetLabHistoricCounts(
          accessToken,
          startDate,
          endDate,
          dateAggregate,
          labs.filter(lab => lab.selected === true).map(lab => lab.labId),
          countType
        );
        setLabCounts([...counts]);
        setChartDataStatus(counts.length > 0 ? 'Complete' : 'InsufficientData');
      }
    }
    getState();
  }, [accessToken, startDate, endDate, dateAggregate, labs, countType]);

  return (
    <AuthorizedPage>
      <Page
        title='Historic Counts by Lab'
        subtitle={`Total From Labs: ${totalInLabs ? totalInLabs.toLocaleString() : ''}`}
      >
        <Box display='flex' flexDirection='row'>
          <Box mr={3}>
            <Card>
              <Box p={2}>
                <HistoricCountFilters
                  countType={countType}
                  dateAggregate={dateAggregate}
                  startDate={startDate}
                  endDate={endDate}
                  labs={labs}
                  onCountTypeSelect={setCountType}
                  onDateAggregateSelect={setDateAggregate}
                  onStartDateSelect={setStartDate}
                  onEndDateSelect={setEndDate}
                  onLabSelect={setLabs}
                />
              </Box>
            </Card>
          </Box>
          <Box pb={3}>
            <Card sx={{ width: 1075, height: 650 }}>
              <Box p={4} justifyContent='center' alignItems='center'>
                {chartDataStatus === 'Loading' && <Spinner animation='border' />}
                {chartDataStatus === 'Complete' && (
                  <div>
                    <HistoricCountLineChart
                      entities={labs.filter(lab => lab.selected === true).map(lab => lab.name)}
                      historicCounts={labCounts}
                      dateAggregate={dateAggregate}
                      width='100%'
                      height='600px'
                      chartAreaWidth='70%'
                    />
                  </div>
                )}
                {chartDataStatus === 'InsufficientData' && (
                  <div className='historic-counts-loading'>No counts were found for selected filters.</div>
                )}
              </Box>
            </Card>
          </Box>
        </Box>
      </Page>
    </AuthorizedPage>
  );
};
