import { Box } from '@mui/material';
import { Biobank } from 'data/BiobankData';
import { InventoryCheckRequest } from 'data/InventoryCheckRequest';
import { InventoryCheckRequestSamplesExportButton } from './InventoryCheckRequestSamplesExportButton';

export interface InventoryCheckRequestBiobankCellProps {
  inventoryCheckRequest: InventoryCheckRequest;
  biobank: Biobank;
}

export const InventoryCheckRequestBiobankCell = ({
  inventoryCheckRequest,
  biobank,
}: InventoryCheckRequestBiobankCellProps) => {
  return (
    <>
      <Box display='flex' alignItems='center' justifyContent='space-between' width='100%'>
        {biobank.name}
        <Box display='flex' justifyContent='right'>
          <InventoryCheckRequestSamplesExportButton
            inventoryCheckRequestId={inventoryCheckRequest.inventoryCheckRequestId}
          />
        </Box>
      </Box>
    </>
  );
};
