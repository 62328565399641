import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { TFunction } from 'i18next';

/**
 * Memorize Translation function to prevent re-renders due to the translation object state updating. This is needed
 * for testing purposes where Jest mocks of i18n cause infinite re-renders.
 */
const useMemoTranslation = (): { t: TFunction } => {
  const { t: _t } = useTranslation();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const t = useMemo(() => _t, []); // _t should not be in the dependency
  return { t };
};

export default useMemoTranslation;
