import { appSettings } from 'AppSettings';
import { BaseGet, BasePostVoid } from './BaseData';

export async function RefreshPatientEnrichments(researchProjectId: string, accessToken?: string): Promise<void> {
  return await BasePostVoid(
    `${appSettings.api.endpoint}/api/v2/Enrichments/ResearchProjects/${researchProjectId}`,
    undefined,
    accessToken
  );
}

export async function GetResearchProjectEnrichmentUpdatedAtAsync(
  researchProjectId: string,
  accessToken?: string
): Promise<Date> {
  return await BaseGet(
    `${appSettings.api.endpoint}/api/v2/Enrichments/ResearchProjects/${researchProjectId}/UpdatedAt`,
    accessToken
  );
}
