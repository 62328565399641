import { appSettings } from 'AppSettings';
import { orderBy } from 'lodash';

export interface Biobank {
  biobankId: string;
  name: string;
  selected: boolean;
}

export async function GetBiobanks(accessToken?: string): Promise<Biobank[]> {
  let biobanks: Biobank[] = [];
  if (accessToken) {
    try {
      const response = await fetch(`${appSettings.api.endpoint}/api/v2/biobanks/query`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      if (response.ok) {
        const values: Biobank[] = await response.json();
        values.forEach(value => (value.selected = true));
        biobanks = [...orderBy(values, [(b: Biobank) => b.name.toLowerCase()], ['asc'])];
      }
    } catch (err) {
      console.dir(err);
    }
  }
  return biobanks;
}
