import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import { Button } from '@mui/material';
import { appSettings } from 'AppSettings';
import useAuth from 'auth/UseAuth';
import { FileProcessingStatus, GenerateFileDownload } from 'helpers/FileProcessing';
import { useState } from 'react';

export interface InventoryCheckRequestSamplesExportButtonProps {
  inventoryCheckRequestId: string;
}

export const InventoryCheckRequestSamplesExportButton = ({
  inventoryCheckRequestId,
}: InventoryCheckRequestSamplesExportButtonProps) => {
  const { accessToken } = useAuth();

  const [status, setStatus] = useState<FileProcessingStatus>('NotStarted');
  const [buttonDisabled, setButtonDisabled] = useState<boolean>(false);

  const invokeGenerator = async () => {
    try {
      if (accessToken) {
        setButtonDisabled(true);
        setStatus('Downloading');

        const response = await fetch(
          `${appSettings.api.endpoint}/api/v2/InventoryCheckRequests/${inventoryCheckRequestId}/Samples?exportType=xlsx`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );

        if (response.ok) {
          await GenerateFileDownload(response);
        } else {
          setStatus('Error');
        }
        setStatus('Complete');
      }
    } catch (err) {
      setStatus('Error');
    }
    setButtonDisabled(false);
  };

  return (
    <Button
      id='disease-count-export-button'
      onClick={invokeGenerator}
      disabled={buttonDisabled}
      sx={[
        {
          '&:hover': {
            backgroundColor: 'transparent',
          },
        },
      ]}
    >
      {status === 'NotStarted' && <SaveAltIcon />}
      {status === 'Downloading' && <HourglassBottomIcon />}
      {status === 'Complete' && <SaveAltIcon />}
      {status === 'Error' && <ErrorOutlineIcon />}
    </Button>
  );
};
