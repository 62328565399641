import './DatavantDatasets.css';
import React, { FC, useEffect, useState } from 'react';
import { DatavantSftpFile, GetSftpFiles } from 'data/DatavantTokenData';
import useAuth from 'auth/UseAuth';
import { Box } from '@mui/material';
import { DatavantSftpTree } from './DatavantSftpTree';
import { Spinner } from 'react-bootstrap';

export const DatavantOverlaps: FC = () => {
  const { accessToken } = useAuth();
  const [, setIsSpinnerActive] = useState<boolean>(false);
  const [overlaps, setOverlaps] = useState<DatavantSftpFile[]>([]);
  useEffect(() => {
    async function getState() {
      if (accessToken) {
        setIsSpinnerActive(false);
        setOverlaps(await GetSftpFiles('overlaps', accessToken));
      }
    }

    setIsSpinnerActive(true);
    getState();
  }, [accessToken]);

  if (!overlaps.length) {
    return <Spinner animation='border' />;
  }
  return (
    <Box>
      <DatavantSftpTree files={overlaps} />
    </Box>
  );
};
