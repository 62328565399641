import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import {
  Autocomplete,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  LinearProgress,
  TextField,
  Typography,
} from '@mui/material';
import { ChangeEvent, useEffect, useState } from 'react';
import { CreateCustomerModal } from './CreateCustomerModal';
import { LlCustomer } from 'data/LlCustomerData';
import { LlCustomerOption, ResearchProjectStatusOption } from './ResearchProjectAutocompleteOptions';
import { LoadingStatus } from 'components/LoadingStatus';
import useAuth from 'auth/UseAuth';
import { appSettings } from 'AppSettings';
import { ResearchProject } from 'data/ResearchProjectData';
import { DialogCloseButton } from '../components/DialogCloseButton';
import { DialogOpenButton } from '../components/DialogOpenButton';
import { useResearchProjectStatusOptions } from 'components/hooks/UseResearchProjectStatusOptions';
import { CancelButton } from 'components/CancelButton';
import { PrimaryButton } from 'components/PrimaryButton';

export interface CreateResearchProjectModalProps {
  initialCustomerOption?: LlCustomerOption | null;
  customerOptions: LlCustomerOption[];
  onCustomerCreate: Function;
  onResearchProjectCreate(newResearchProject: ResearchProject): void;
  onInitialCustomerChange?(newCustomerOoption: LlCustomerOption | null): void;
  title?: string;
}

export const CreateResearchProjectModal = ({
  initialCustomerOption: initialCustomer,
  customerOptions,
  onResearchProjectCreate,
  onCustomerCreate,
  onInitialCustomerChange,
  title,
}: CreateResearchProjectModalProps) => {
  const { accessToken } = useAuth();
  const defaultProjectName = '';
  const [researchProjectStatusOptions] = useResearchProjectStatusOptions();

  const [open, setOpen] = useState<boolean>(false);
  const [status, setStatus] = useState<LoadingStatus>('NotStarted');
  const [errorMessage, setErrorMessage] = useState<string>();
  const [selectedCustomer, setSelectedCustomer] = useState<LlCustomerOption | null>(null);
  const [selectedProjectName, setSelectedProjectName] = useState<string>(defaultProjectName);
  const [selectedExpectedCohortSize, setSelectedExpectedCohortSize] = useState<number>();
  const [selectedResearchProjectStatus, setSelectedResearchProjectStatus] = useState<string>();
  const [selectedSalesforceLink, setSelectedSalesforceLink] = useState<string | undefined>();
  const [selectedMondayLink, setSelectedMondayLink] = useState<string | undefined>();

  useEffect(() => {
    function getState() {
      if (initialCustomer !== undefined) {
        setSelectedCustomer(initialCustomer);
      }
    }
    getState();
  }, [initialCustomer]);

  const handleButtonClick = async () => {
    setOpen(true);
  };

  const handleClose = () => {
    clearSelectedState();
    setOpen(false);
  };

  const handleSave = async () => {
    setStatus('Loading');

    if (!selectedCustomer) {
      setErrorMessage('Please select a customer.');
      setStatus('Error');
    } else if (!selectedProjectName) {
      setErrorMessage('Please enter a project name.');
      setStatus('Error');
    } else if (!selectedResearchProjectStatus) {
      setErrorMessage('Please select a project status.');
      setStatus('Error');
    } else {
      let newResearchProject = {
        name: selectedProjectName,
        customer: selectedCustomer.customer,
        expectedCohortSize: selectedExpectedCohortSize,
        status: selectedResearchProjectStatus,
        salesforceLink: selectedSalesforceLink,
        mondayLink: selectedMondayLink,
      };

      try {
        if (accessToken) {
          const response = await fetch(`${appSettings.api.endpoint}/api/v2/ResearchProjects`, {
            method: 'POST',
            headers: {
              Authorization: `Bearer ${accessToken}`,
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(newResearchProject),
          });

          if (response.ok) {
            const researchProject = await response.json();
            onResearchProjectCreate(researchProject);
            setStatus('Complete');
            handleClose();
          } else {
            let responseBody: { message: string } = await response.json();
            setStatus('Error');

            if (responseBody.message) {
              setErrorMessage(responseBody.message);
            } else if (response.status === 401) {
              setErrorMessage('Error: unauthorized user.');
            } else {
              setErrorMessage('Unknown error.');
            }
          }
        }
      } catch (err) {
        setErrorMessage('Unknown error.');
        setStatus('Error');
      }
    }
  };

  const clearSelectedState = () => {
    setStatus('NotStarted');
    setErrorMessage(undefined);
    setSelectedCustomer(null);
    setSelectedProjectName(defaultProjectName);
    setSelectedExpectedCohortSize(undefined);
    setSelectedResearchProjectStatus(undefined);
    setSelectedSalesforceLink(undefined);
    setSelectedMondayLink(undefined);
  };

  const handleProjectNameChange = (ev: ChangeEvent<HTMLInputElement>) => {
    setSelectedProjectName(ev.target.value);
  };

  const handleLlCustomerChange = (llCustomer?: LlCustomerOption) => {
    setSelectedCustomer(llCustomer ?? null);
    if (onInitialCustomerChange) {
      onInitialCustomerChange(llCustomer ?? null);
    }
  };

  async function handleCreateLlCustomer(newCustomer: LlCustomer) {
    let newCustomerOption: LlCustomerOption = { label: newCustomer.name, customer: newCustomer };
    setSelectedCustomer(newCustomerOption);
    onCustomerCreate();
  }

  const handleExpectedCohortSizeChange = (ev: ChangeEvent<HTMLInputElement>) => {
    setSelectedExpectedCohortSize(ev.target.value ? Number(ev.target.value) : undefined);
  };

  const handleResearchProjectStatusChange = (researchProjectStatus?: ResearchProjectStatusOption) => {
    setSelectedResearchProjectStatus(researchProjectStatus?.status);
  };

  const handleSalesForceLinkChange = (ev: ChangeEvent<HTMLInputElement>) => {
    setSelectedSalesforceLink(ev.target.value);
  };

  const handleMondayLinkChange = (ev: ChangeEvent<HTMLInputElement>) => {
    setSelectedMondayLink(ev.target.value);
  };

  return (
    <>
      <DialogOpenButton title={title} onClick={handleButtonClick} />
      <Dialog open={open} onClose={() => setOpen(false)} scroll='body'>
        <DialogTitle>
          <DialogCloseButton onClick={handleClose} />
          <Typography variant='h4'>Create New Research Project</Typography>
        </DialogTitle>
        <DialogContent>
          <Box component='form' sx={{ marginTop: 2 }}>
            <Box display='flex' flexDirection='row' alignItems='center'>
              <Autocomplete
                id='create-research-project-customer-input'
                fullWidth
                value={selectedCustomer}
                options={customerOptions}
                renderInput={params => <TextField {...params} label='Customer *' margin='normal' />}
                onChange={(event, value) => handleLlCustomerChange(value ?? undefined)}
              />
              <CreateCustomerModal onCustomerCreate={handleCreateLlCustomer} />
            </Box>
            <TextField
              id='create-research-project-project-name-input'
              fullWidth
              margin='normal'
              label='Project Name *'
              onChange={handleProjectNameChange}
              type='text'
              value={selectedProjectName}
            />
            <TextField
              id='create-research-project-cohort-size-input'
              fullWidth
              margin='normal'
              label='Expected Cohort Size'
              onChange={handleExpectedCohortSizeChange}
              type='number'
              value={selectedExpectedCohortSize}
            />
            <Autocomplete
              id='create-research-project-status-input'
              fullWidth
              options={researchProjectStatusOptions}
              renderInput={params => <TextField {...params} label='Execution Status *' margin='normal' />}
              onChange={(event, value) => handleResearchProjectStatusChange(value ?? undefined)}
            />
            <TextField
              id='create-research-project-salesforce-input'
              fullWidth
              margin='normal'
              label='Salesforce Link'
              onChange={handleSalesForceLinkChange}
              type='text'
              value={selectedSalesforceLink}
            />
            <TextField
              id='create-research-project-proejct-management-input'
              fullWidth
              margin='normal'
              label='Project Management Link'
              onChange={handleMondayLinkChange}
              type='text'
              value={selectedMondayLink}
            />
          </Box>
          {status === 'Error' && (
            <DialogContentText display='flex' alignItems='center' mt={1} color='error'>
              <ErrorOutlineIcon sx={{ mr: 1 }} />
              {errorMessage}
            </DialogContentText>
          )}
        </DialogContent>
        {status === 'Loading' && <LinearProgress />}
        <DialogActions>
          <CancelButton onClick={handleClose} disabled={status === 'Loading'}>
            Cancel
          </CancelButton>
          <PrimaryButton onClick={handleSave} disabled={status === 'Loading'}>
            Save
          </PrimaryButton>
        </DialogActions>
      </Dialog>
    </>
  );
};
