import { Box, CircularProgress, LinearProgress } from '@mui/material';
import { LoadingState } from './LoadingStateUtil';

export type LoadingIndicatorMargin = 'All' | 'LR' | 'LRB' | 'LRT' | 'None' | 'T';
export type LoadingIndicatorType = 'Circular' | 'Linear';

export interface LoadingIndicatorProps {
  loadingState?: LoadingState;
  margin?: LoadingIndicatorMargin;
  type?: LoadingIndicatorType;
}

export const LoadingIndicator = ({
  loadingState = { status: 'Loading' },
  margin = 'All',
  type = 'Linear',
}: LoadingIndicatorProps) => {
  function GetMargin() {
    switch (margin) {
      case 'All':
        return { margin: 2 };
      case 'LR':
        return { marginLeft: 2, marginRight: 2 };
      case 'LRB':
        return { marginLeft: 2, marginRight: 2, marginBottom: 2 };
      case 'LRT':
        return { marginLeft: 2, marginRight: 2, marginTop: 2 };
      case 'T':
        return { marginTop: 2 };
      case 'None':
        return { margin: 0 };
    }
  }

  if (loadingState.status !== 'Loading') {
    return <></>;
  }

  if (type === 'Circular') {
    return (
      <Box display='flex' alignItems='center' justifyContent='center'>
        <CircularProgress data-testid={'loading-indicator'} sx={GetMargin()} />
      </Box>
    );
  }

  return <LinearProgress data-testid={'loading-indicator'} sx={GetMargin()} />;
};
