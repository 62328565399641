import { GRID_CHECKBOX_SELECTION_FIELD, GridColDef, GridRowSelectionModel, useGridApiRef } from '@mui/x-data-grid-pro';
import useAuth from 'auth/UseAuth';
import React, { useEffect, useMemo, useState } from 'react';
import { Box } from '@mui/material';
import { GetSampleTrackingSelectedSamples, SampleTrackingSelectedTableData } from '../../data/SampleTrackingData';
import { getSampleIdentifier, Sample, truncateForeignHash } from '../../data/SampleData';
import { CreateSampleTrackingTransitionModal } from './CreateSampleTrackingTransitionModal';
import { AuthorizedSection } from '../../auth/AuthorizedSection';
import { FlexTableBox } from '../../components/FlexTableBox';
import {
  biobankId,
  collectionDate,
  currentStage,
  foreignHash,
  labId,
  patientId,
  patientNotes,
  patientProjectCreatedAt,
  sampleAvailabilityTypeId,
  sampleBbid,
  sampleId,
  sampleIdentifier,
  sampleNotes,
  sampleProjectCreatedAt,
  sampleTypeId,
  selectionStatus,
} from '../../util/Constants';
import { CustomizableSwitch } from '../../components/CustomizableSwitch';
import { keyBy, map, pick, uniq, values } from 'lodash';
import { SampleTrackingRowSelectionModal } from './SampleTrackingRowSelectionModal';
import { LoadingIndicator } from '../../components/LoadingIndicator';
import { ErrorIndicator } from '../../components/ErrorIndicator';
import { LoadingState, LoadState } from '../../components/LoadingStateUtil';
import useMemoTranslation from '../../hooks/UseMemoTranslation';
import { renderSampleHierarchyGridCell } from '../../components/grid/cell/SampleHierarchyGridCell';
import { SampleTrackingExhaustModal } from './SampleTrackingExhaustModal';
import useSampleAvailabilityTypes from '../../components/hooks/UseSampleAvailabilityTypes';
import { Organization, SampleAvailabilityType } from '../../data/ReferenceData';
import {
  dateComparator,
  dateFormatter,
  formatSampleAvailabilityTypeById,
  formatSampleType,
} from '../../util/grid/TableUtils';
import useOrganizations from '../../components/hooks/UseOrganizations';
import { Dictionary } from '../../util/TypeUtil';
import { CompactGridWrapper } from '../../components/grid/CompactGridWrapper';
import useSampleTypes from '../../components/hooks/UseSampleTypes';
import { SampleType } from '../../data/SampleTypeData';
import { GridExportButton } from '../../components/GridExportButton';
import { useResearchProjectTransitionPath } from '../../components/hooks/UseResearchProjectTransitionPaths';
import { ResearchProjectTransitionPath } from '../../data/ResearchProjectTransitionPathData';
import { renderGuidCellTooltip } from '../../components/grid/cell/GuidGridCellTooltip';

type Row = {
  id: number;
  sampleIdentifier: string;
  currentStage?: string;
  originLab?: string;
  originBiobank?: string;
} & Sample;

export interface SampleTrackingSelectedSampleGridProps {
  researchProjectId: string;
  sampleGroup?: string;
}

export const SampleTrackingSelectedSampleGrid = ({
  researchProjectId,
  sampleGroup,
}: SampleTrackingSelectedSampleGridProps) => {
  const { t } = useMemoTranslation();
  const { accessToken } = useAuth();
  const apiRef = useGridApiRef();

  const [loadingState, setLoadingState] = useState<LoadingState>({ status: 'Loading' });
  const [refreshTrigger, setRefreshTrigger] = useState<boolean>(true);

  const sampleAvailabilityTypes = useSampleAvailabilityTypes();
  const sampleTypes = useSampleTypes();
  const organizations = useOrganizations();

  const [rawData, setRawData] = useState<ReadonlyArray<SampleTrackingSelectedTableData>>([]);
  const [selectedSampleIds, setSelectedSampleIds] = useState<string[]>([]);
  const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>();
  const [useDecryptedHashes, setUseDecryptedHashes] = useState<boolean>(false);
  const [organizationById, setOrganizationById] = useState<Dictionary<Organization>>({});
  const [transitionPaths] = useResearchProjectTransitionPath(researchProjectId, setLoadingState);

  const columns = useColumns(sampleAvailabilityTypes, organizationById, sampleTypes);
  const rows = useRows(rawData, transitionPaths);

  useEffect(() => {
    setOrganizationById(keyBy(organizations, i => i.id));
  }, [organizations]);

  useEffect(() => {
    return LoadState(setLoadingState, async () => {
      if (!accessToken || !researchProjectId) {
        return;
      }

      const data = await GetSampleTrackingSelectedSamples(
        researchProjectId,
        accessToken,
        useDecryptedHashes,
        sampleGroup
      );

      setRawData(data);
    });
  }, [accessToken, researchProjectId, useDecryptedHashes, sampleGroup, refreshTrigger]);

  useEffect(() => {
    if (!selectionModel) {
      return;
    }

    const sampleIds: string[] = [];
    selectionModel.forEach(selected => {
      const id = apiRef.current?.getRow(selected)?.sampleId;
      if (id) {
        sampleIds.push(id);
      }
    });

    setSelectedSampleIds(uniq(sampleIds));
  }, [selectionModel, apiRef]);

  function SelectRows(ids: ReadonlyArray<string>) {
    const normalizedIds = ids.map(id => id.toLowerCase().trim());
    const selectedRows: number[] = [];

    apiRef.current.getSortedRows().forEach(row => {
      const rowTyped = row as Row;
      const propertiesToCheck = pick(rowTyped, [foreignHash, sampleId, sampleBbid]);

      normalizedIds.forEach(id => {
        values(propertiesToCheck).forEach(val => {
          if (val.toLowerCase().trim() === id) {
            selectedRows.push(rowTyped.id);
          }
        });
      });
    });

    setSelectionModel(selectedRows);
  }
  function refreshData(hasChanges: boolean) {
    if (!hasChanges) {
      return;
    }

    setRefreshTrigger(currentState => !currentState);
    setSelectionModel([]);
  }

  return (
    <>
      <FlexTableBox>
        <AuthorizedSection requiredRole='ll-green-side-user'>
          <Box display='flex' flexDirection='row' alignItems='center' mb={2} justifyContent='space-between'>
            <Box display='flex' flexDirection='row' alignItems='center'>
              <CustomizableSwitch
                title={t('decryptedForeignHashes')}
                checked={useDecryptedHashes}
                onChange={() => setUseDecryptedHashes(!useDecryptedHashes)}
              />
            </Box>
            <Box display='flex' flexDirection='row-reverse' alignItems='center'>
              <CreateSampleTrackingTransitionModal
                researchProjectId={researchProjectId}
                selectedSamples={map(selectedSampleIds, i => {
                  const item = rows.find(j => j.sampleId === i);

                  return { ...item, sampleIdentifier: item?.sampleIdentifier ?? '' } as any;
                })}
                onClose={refreshData}
              />
              <SampleTrackingExhaustModal selectedSampleIds={selectedSampleIds} onClose={refreshData} />
              <SampleTrackingRowSelectionModal onSubmit={SelectRows} />
              <GridExportButton apiRef={apiRef?.current} fileName={t('sampleTrackingSelectedFileName')} />
            </Box>
          </Box>
        </AuthorizedSection>
        <CompactGridWrapper
          apiRef={apiRef}
          rows={rows}
          columns={columns}
          initialState={{
            columns: {
              columnVisibilityModel: {
                sampleBbid: false,
                foreignHash: false,
                sampleId: false,
                patientId: false,
              },
            },
            pinnedColumns: { left: [GRID_CHECKBOX_SELECTION_FIELD] },
          }}
          density='compact'
          checkboxSelection
          disableRowSelectionOnClick
          onRowSelectionModelChange={newSelectionModel => setSelectionModel(newSelectionModel)}
          rowSelectionModel={selectionModel}
        />
        <LoadingIndicator type={'Circular'} loadingState={loadingState} margin={'LR'} />
        <ErrorIndicator loadingState={loadingState} />
      </FlexTableBox>
    </>
  );
};

const useColumns = (
  sampleAvailabilityTypes: ReadonlyArray<SampleAvailabilityType>,
  organizationById: Dictionary<Organization>,
  sampleTypes: ReadonlyArray<SampleType>
): GridColDef[] => {
  const { t } = useMemoTranslation();

  return useMemo(
    () => [
      {
        field: sampleIdentifier,
        headerName: t(sampleIdentifier),
        headerAlign: 'left',
        align: 'left',
        width: 350,
        renderCell: params =>
          renderSampleHierarchyGridCell({
            ...params,
            sampleId: params.row.sampleId,
            displayValue: <span>{params.value}</span>,
          }),
      },
      {
        field: sampleBbid,
        headerName: t(sampleBbid),
        headerAlign: 'center',
        align: 'center',
        minWidth: 300,
        renderCell: params =>
          renderSampleHierarchyGridCell({
            ...params,
            sampleId: params.row.sampleId,
            displayValue: <span>{params.value}</span>,
          }),
      },
      {
        field: foreignHash,
        headerName: t(foreignHash),
        headerAlign: 'center',
        align: 'center',
        minWidth: 300,
        renderCell: params =>
          renderSampleHierarchyGridCell({
            ...params,
            sampleId: params.row.sampleId,
            displayValue: <span>{truncateForeignHash(params.value)}</span>,
          }),
      },
      {
        field: currentStage,
        headerName: t(currentStage),
        headerAlign: 'left',
        align: 'left',
        width: 200,
        valueGetter: ({ value }) => value && t(value),
      },

      {
        field: labId,
        headerName: t('originLab'),
        headerAlign: 'left',
        align: 'left',
        minWidth: 300,
        valueFormatter: ({ value }) => (value && organizationById[value]?.name) ?? '',
      },
      {
        field: biobankId,
        headerName: t('biobank'),
        headerAlign: 'left',
        align: 'left',
        minWidth: 300,
        valueFormatter: ({ value }) => (value && organizationById[value]?.name) ?? '',
      },
      {
        field: selectionStatus,
        headerName: t(selectionStatus),
        headerAlign: 'left',
        align: 'left',
        width: 150,
        valueFormatter: ({ value }) => value && t(value),
      },
      {
        field: collectionDate,
        headerName: t(collectionDate),
        sortable: true,
        width: 130,
        align: 'left',
        sortComparator: dateComparator,
        valueFormatter: dateFormatter,
      },
      {
        field: sampleTypeId,
        headerName: t(sampleTypeId),
        headerAlign: 'left',
        align: 'left',
        width: 200,
        valueFormatter: ({ value }) => value && formatSampleType(sampleTypes, value),
      },
      {
        field: sampleAvailabilityTypeId,
        headerName: t(sampleAvailabilityTypeId),
        headerAlign: 'left',
        align: 'left',
        width: 200,
        valueFormatter: ({ value }) => value && formatSampleAvailabilityTypeById(sampleAvailabilityTypes, value),
      },
      {
        field: sampleNotes,
        headerName: t(sampleNotes),
        headerAlign: 'left',
        align: 'left',
        minWidth: 150,
        flex: 1.2,
      },
      {
        field: patientNotes,
        headerName: t(patientNotes),
        headerAlign: 'left',
        align: 'left',
        minWidth: 150,
        flex: 1.2,
      },
      {
        field: patientId,
        headerName: t(patientId),
        sortable: false,
        width: 130,
        align: 'left',
        headerAlign: 'left',
        renderCell: renderGuidCellTooltip,
      },
      {
        field: sampleId,
        headerName: t(sampleId),
        sortable: false,
        width: 130,
        align: 'left',
        headerAlign: 'left',
        renderCell: renderGuidCellTooltip,
      },
      {
        field: patientProjectCreatedAt,
        headerName: t(patientProjectCreatedAt),
        sortable: true,
        width: 130,
        align: 'left',
        headerAlign: 'left',
        sortComparator: dateComparator,
        valueFormatter: dateFormatter,
      },
      {
        field: sampleProjectCreatedAt,
        headerName: t(sampleProjectCreatedAt),
        sortable: true,
        width: 130,
        align: 'left',
        headerAlign: 'left',
        sortComparator: dateComparator,
        valueFormatter: dateFormatter,
      },
    ],
    [t, sampleAvailabilityTypes, organizationById, sampleTypes]
  );
};

const useRows = (
  samples: ReadonlyArray<SampleTrackingSelectedTableData>,
  transitionPaths: ReadonlyArray<ResearchProjectTransitionPath>
): ReadonlyArray<Row> => {
  return useMemo(() => {
    const transitionById = keyBy(transitionPaths, i => i.configuredTransitionId);

    return samples.map((r, index) => {
      return {
        id: index++,
        ...r.sample,
        sampleIdentifier: getSampleIdentifier(r.sample, r.transitionSample),
        currentStage: transitionById[r.transition?.configuredTransitionId ?? '']?.displayName,
        selectionStatus: r.researchProjectSample?.selectionStatus,
        originLab: r.sample.labId,
        originBiobank: r.sample.biobankId,
        sampleProjectCreatedAt: r.researchProjectSample?.createdAt,
        patientProjectCreatedAt: r.researchProjectPatient?.createdAt,
        sampleNotes: r.researchProjectSample?.notes,
        patientNotes: r.researchProjectPatient?.notes,
      };
    });
  }, [samples, transitionPaths]);
};
