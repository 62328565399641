import { appSettings } from 'AppSettings';
import { orderBy } from 'lodash';

export interface SampleTypeSelection {
  sampleTypeId: string;
  displayName: string;
  selected: boolean;
}
export interface SampleType {
  sampleTypeId: string;
  displayName: string;
  ordinal: number;
  groupName: string;
  groupDisplayName: string;
}

export async function GetSampleTypesInBiobanks(accessToken: string): Promise<SampleTypeSelection[]> {
  let sampleTypes: SampleTypeSelection[] = [];
  if (accessToken) {
    try {
      const response = await fetch(`${appSettings.api.endpoint}/api/v2/SampleTypes`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      if (response.ok) {
        const values: SampleTypeSelection[] = await response.json();
        values.forEach(value => (value.selected = true));
        sampleTypes = [...orderBy(values, [(s: SampleTypeSelection) => s.displayName.toLowerCase()], ['asc'])];
      }
    } catch (err) {
      console.dir(err);
    }
  }
  return sampleTypes;
}
