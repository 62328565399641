import { styled } from '@mui/material';
import { Link } from 'react-router-dom';
import OvationO from 'components/OvationO.png';

export const SidebarLogo = () => {
  return (
    <LogoWrapper to=''>
      <img src={OvationO} alt='Logo' className='ll-header-logo' />
    </LogoWrapper>
  );
};

const LogoWrapper = styled(Link)(
  ({ theme }) => `
        color: ${theme.palette.text.primary};
        display: flex;
        text-decoration: none;
        width: 53px;
        margin: 0px auto 20px auto;
        font-weight: ${theme.typography.fontWeightBold};
`
);
