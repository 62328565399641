import { GridColDef, GridComparatorFn, GridRenderCellParams } from '@mui/x-data-grid-pro';
import { orderBy } from 'lodash';
import { DiseaseArea } from 'data/DiseaseAreaData';
import { Biobank } from 'data/BiobankData';
import { SampleType } from 'data/SampleTypeData';
import { DiseaseAreaCountByBiobank, DiseaseAreaCountBySampleType } from 'data/DiseaseAreaCountData';
import { DiseaseAreaGridCell } from './DiseaseAreaGridCell';
import { BiobankCountGridCell, BiobankCountGridCellProps } from './BiobankCountGridCell';
import { SampleTypeCountGridCell, SampleTypeCountGridCellProps } from './SampleTypeCountGridCell';
import { TotalCountGridCell, TotalCountGridCellProps } from './TotalCountGridCell';
import { PatientCountType } from '../../components/grid/GridCountType';
import { ZeroCountGridCell } from '../../components/grid/cell/ZeroCountGridCell';
import { renderCellTooltip } from 'components/grid/cell/GridCellTooltip';

export async function GetDiseaseAreaCountByBiobankColumns(
  diseaseAreas: DiseaseArea[],
  biobanks: Biobank[],
  counts: DiseaseAreaCountByBiobank[],
  onBiobankCountClick: (
    count: number,
    diseaseArea: DiseaseArea,
    patientRecordCountType: PatientCountType,
    biobankId?: string,
    sampleTypeId?: string
  ) => void,
  onDiseaseAreaChange: Function,
  accessToken: string
): Promise<GridColDef[]> {
  const columns: GridColDef[] = getFirstColumns(diseaseAreas, 'byBiobank', onBiobankCountClick, onDiseaseAreaChange);

  if (accessToken) {
    const columnFields = await GetBiobankColumnFields(counts);

    columnFields.forEach(biobank => {
      let biobankName = biobanks.find(b => b.biobankId === biobank.biobankId)?.name;

      columns.push({
        field: biobank.biobankId,
        headerName: biobankName ?? '',
        width: 150,
        headerAlign: 'center',
        align: 'center',
        filterable: false,
        renderCell: (params: GridRenderCellParams<BiobankCountGridCellProps>) => {
          if (params.value) {
            return (
              <BiobankCountGridCell
                diseaseArea={params.value.diseaseArea}
                biobankId={params.value.biobankId ?? ''}
                biobankName={biobankName}
                count={params.value.count ?? 0}
                onBiobankCountClick={onBiobankCountClick}
              />
            );
          } else {
            return <ZeroCountGridCell />;
          }
        },
        sortComparator: byBiobankCountComparator,
      });
    });
  }

  return columns.concat(getLastColumns());
}

export async function GetDiseaseAreaCountBySampleTypeColumns(
  diseaseAreas: DiseaseArea[],
  sampleTypes: ReadonlyArray<SampleType>,
  counts: DiseaseAreaCountBySampleType[],
  onSampleTypeCountClick: (
    count: number,
    diseaseArea: DiseaseArea,
    patientRecordCountType: PatientCountType,
    biobankId?: string,
    sampleTypeId?: string
  ) => void,
  onDiseaseAreaChange: Function,
  accessToken: string
): Promise<GridColDef[]> {
  const columns: GridColDef[] = getFirstColumns(
    diseaseAreas,
    'bySampleType',
    onSampleTypeCountClick,
    onDiseaseAreaChange
  );

  if (accessToken) {
    const columnFields = await GetSampleTypeColumnFields(counts);

    columnFields.forEach(sampleType => {
      let sampleTypeDescription = sampleTypes.find(s => s.sampleTypeId === sampleType.sampleTypeId)?.displayName;

      columns.push({
        field: sampleType.sampleTypeId,
        headerName: sampleTypeDescription ?? '',
        width: 200,
        headerAlign: 'center',
        align: 'center',
        filterable: false,
        renderCell: (params: GridRenderCellParams<SampleTypeCountGridCellProps>) => {
          if (params.value) {
            return (
              <SampleTypeCountGridCell
                diseaseArea={params.value.diseaseArea}
                sampleTypeId={params.value.sampleTypeId ?? ''}
                sampleTypeDescription={sampleTypeDescription}
                count={params.value.count ?? 0}
                onSampleTypeCountClick={onSampleTypeCountClick}
              />
            );
          } else {
            return <ZeroCountGridCell />;
          }
        },
        sortComparator: bySampleTypeComparator,
      });
    });
  }

  return columns.concat(getLastColumns());
}

function getFirstColumns(
  diseaseAreas: DiseaseArea[],
  countType: PatientCountType,
  onTotalCountClick: (
    count: number,
    diseaseArea: DiseaseArea,
    patientRecordCountType: PatientCountType,
    biobankId?: string,
    sampleTypeId?: string
  ) => void,
  onDiseaseAreaChange: Function
) {
  const colWidths = getDiseaseAreaColumnWidths(diseaseAreas);

  const columns: GridColDef[] = [
    {
      field: 'diseaseArea',
      headerName: 'Disease Area',
      width: colWidths.diseaseAreaColWidth,
      align: 'left',
      filterable: false,
      renderCell: (params: GridRenderCellParams<DiseaseArea>) => {
        if (params.value) {
          return <DiseaseAreaGridCell diseaseArea={params.value} onDiseaseAreaChange={onDiseaseAreaChange} />;
        }
      },
      sortComparator: diseaseAreaComparator,
    },
    {
      field: 'icd10Codes',
      headerName: 'ICD10 Codes',
      width: colWidths.icd10ColWidth,
      renderCell: renderCellTooltip,
    },
    {
      field: 'total',
      headerName: 'Total',
      type: 'number',
      width: colWidths.totalCountColWidth,
      headerAlign: 'center',
      align: 'center',
      filterable: false,
      renderCell: (params: GridRenderCellParams<TotalCountGridCellProps>) => {
        if (params.value) {
          return <TotalCountGridCell diseaseArea={params.value.diseaseArea} onTotalCountClick={onTotalCountClick} />;
        } else {
          return <ZeroCountGridCell />;
        }
      },
      sortComparator: totalCountComparator,
    },
  ];
  return columns;
}

function getLastColumns() {
  const columns: GridColDef[] = [
    {
      field: 'dashboard',
      headerName: 'Dashboard',
      type: 'boolean',
      width: 110,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params: GridRenderCellParams) => (params.value ? 'x' : ''),
    },
    {
      field: 'lastUpdated',
      headerName: 'Last Updated',
      type: 'date',
      width: 150,
      headerAlign: 'center',
      align: 'center',
      filterable: false,
      renderCell: (params: GridRenderCellParams<Date>) => (
        <span title={params.value ? params.value.toString() : ''}>
          {params.value
            ? params.value.toLocaleString('en-US', { year: 'numeric', month: 'short', day: 'numeric' })
            : ''}
        </span>
      ),
    },
  ];
  return columns;
}

function GetBiobankColumnFields(counts: DiseaseAreaCountByBiobank[]) {
  let totals = new Map<string, number>();

  counts.forEach(count => {
    let biobank = count.biobankId;
    let biobankCount = count.patientCount;

    if (totals.has(biobank)) {
      let biobankTotal = totals.get(biobank) ?? 0;
      totals.set(biobank, biobankTotal + biobankCount);
    } else {
      totals.set(biobank, biobankCount);
    }
  });

  let totalArray = Array.from(totals, ([biobankId, totalPatientCount]) => ({ biobankId, totalPatientCount }));
  let result = orderBy(totalArray, ['totalPatientCount', 'biobankId'], ['desc', 'asc']);

  return result.filter(biobankPatientCount => biobankPatientCount.totalPatientCount > 0);
}

function GetSampleTypeColumnFields(counts: DiseaseAreaCountBySampleType[]) {
  let totals = new Map<string, number>();

  counts.forEach(count => {
    let sampleType = count.sampleTypeId;
    let sampleTypeCount = count.patientCount;

    if (totals.has(sampleType)) {
      let sampleTotal = totals.get(sampleType) ?? 0;
      totals.set(sampleType, sampleTotal + sampleTypeCount);
    } else {
      totals.set(sampleType, sampleTypeCount);
    }
  });

  let totalArray = Array.from(totals, ([sampleTypeId, totalPatientCount]) => ({ sampleTypeId, totalPatientCount }));
  let result = orderBy(totalArray, ['totalPatientCount', 'sampleTypeId'], ['desc', 'asc']);

  return result.filter(sampleTypePatientCount => sampleTypePatientCount.totalPatientCount > 0);
}

const diseaseAreaComparator: GridComparatorFn = (v1, v2) => {
  if ((v1 as DiseaseArea).name < (v2 as DiseaseArea).name) {
    return -1;
  } else if ((v1 as DiseaseArea).name > (v2 as DiseaseArea).name) {
    return 1;
  } else {
    return 0;
  }
};

const byBiobankCountComparator: GridComparatorFn = (v1, v2) => {
  let val1 = v1 ?? 0;
  let val2 = v2 ?? 0;
  return (val1 as BiobankCountGridCellProps).count - (val2 as BiobankCountGridCellProps).count;
};

const bySampleTypeComparator: GridComparatorFn = (v1, v2) => {
  let val1 = v1 ?? 0;
  let val2 = v2 ?? 0;
  return (val1 as SampleTypeCountGridCellProps).count - (val2 as SampleTypeCountGridCellProps).count;
};

const totalCountComparator: GridComparatorFn = (v1, v2) => {
  let val1 = v1 ?? 0;
  let val2 = v2 ?? 0;
  return (
    ((val1 as TotalCountGridCellProps).diseaseArea.patientCount ?? 0) -
    ((val2 as TotalCountGridCellProps).diseaseArea.patientCount ?? 0)
  );
};

interface DiseaseAreaColWidths {
  diseaseAreaColWidth: number;
  icd10ColWidth: number;
  totalCountColWidth: number;
}

function getDiseaseAreaColumnWidths(diseaseAreas: DiseaseArea[]) {
  let maxNameLength = 0;
  let maxCountLength = 5;

  diseaseAreas.forEach(d => {
    maxNameLength = d.name.length > maxNameLength ? d.name.length : maxNameLength;

    let countString = d.patientCount?.toString() ?? '';
    maxCountLength = countString.length > maxCountLength ? countString.length : maxCountLength;
  });

  let colWiths: DiseaseAreaColWidths = {
    diseaseAreaColWidth: maxNameLength * 9,
    icd10ColWidth: 200,
    totalCountColWidth: maxCountLength * 25,
  };

  return colWiths;
}
