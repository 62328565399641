import { appSettings } from 'AppSettings';
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  LinearProgress,
  TextField,
} from '@mui/material';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { DiseaseArea } from 'data/DiseaseAreaData';
import { ConvertAllCodesToUserFormat } from 'data/conversions/Icd10CodeConversions';
import { LoadingStatus } from 'components/LoadingStatus';
import { ChangeEvent, useState } from 'react';
import useAuth from 'auth/UseAuth';
import { DialogCloseButton } from '../../components/DialogCloseButton';
import { CancelButton } from 'components/CancelButton';
import { PrimaryButton } from 'components/PrimaryButton';

export interface Props {
  diseaseArea: DiseaseArea;
  onDiseaseAreaChange: Function;
}

export const DiseaseAreaGridCell = ({ diseaseArea, onDiseaseAreaChange }: Props) => {
  const { accessToken } = useAuth();

  const [open, setOpen] = useState<boolean>(false);
  const [status, setStatus] = useState<LoadingStatus>('NotStarted');
  const [errorMessage, setErrorMessage] = useState<string>();
  const [selectedName, setSelectedName] = useState<string>(diseaseArea.name);
  const [selectedIcd10Codes, setSelectedIcd10Codes] = useState<string>(
    ConvertAllCodesToUserFormat(diseaseArea.icd10Codes).join(', ')
  );
  const [selectedAvailableOnDashboard, setSelectedAvailableOnDashboard] = useState<boolean>(
    diseaseArea.availableToShowOnDashboard
  );
  const [selectedDefaultOnDashboard, setSelectedDefaultOnDashboard] = useState<boolean>(
    diseaseArea.showOnDashboardByDefault
  );

  const handleButtonClick = () => {
    setOpen(true);
  };

  const handleSave = async () => {
    setStatus('Loading');

    let updatedDiseaseArea: DiseaseArea = {
      diseaseAreaId: diseaseArea.diseaseAreaId ?? '',
      name: selectedName,
      icd10Codes: selectedIcd10Codes.split(','),
      availableToShowOnDashboard: selectedAvailableOnDashboard,
      showOnDashboardByDefault: selectedDefaultOnDashboard,
    };

    try {
      if (accessToken) {
        const response = await fetch(`${appSettings.api.endpoint}/api/v2/DiseaseAreas`, {
          method: 'PUT',
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(updatedDiseaseArea),
        });

        if (response.ok) {
          setStatus('Complete');
          handleClose();
          onDiseaseAreaChange();
        } else {
          let message = await response.text();
          setStatus('Error');

          if (message.length > 0) {
            setErrorMessage(message);
          } else if (response.status === 401) {
            setErrorMessage('Error: unauthorized user.');
          } else {
            setErrorMessage('Unknown error.');
          }
        }
      }
    } catch (err) {
      setStatus('Error');
      setErrorMessage('Unknown error.');
    }
  };

  const handleDelete = async () => {
    try {
      if (accessToken) {
        const response = await fetch(`${appSettings.api.endpoint}/api/v2/DiseaseAreas/${diseaseArea.diseaseAreaId}`, {
          method: 'DELETE',
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
          },
        });
        if (response.ok) {
          setStatus('Complete');
          handleClose();
          onDiseaseAreaChange();
        } else {
          let message = await response.text();
          setStatus('Error');
          setErrorMessage(message.length > 0 ? message : 'Unknown error.');
        }
      }
    } catch (err) {
      setStatus('Error');
      setErrorMessage('Unknown error.');
    }

    clearSelectedState();
  };

  const handleClose = () => {
    clearSelectedState();
    setOpen(false);
    setErrorMessage(undefined);
  };

  const handleNameChange = (ev: ChangeEvent<HTMLInputElement>) => {
    setSelectedName(ev.target.value);
  };

  const handleIcd10CodesChange = (ev: ChangeEvent<HTMLInputElement>) => {
    setSelectedIcd10Codes(ev.target.value);
  };

  const clearSelectedState = () => {
    setSelectedName(diseaseArea.name);
    setSelectedIcd10Codes(ConvertAllCodesToUserFormat(diseaseArea.icd10Codes).join(', '));
    setSelectedAvailableOnDashboard(diseaseArea.availableToShowOnDashboard);
    setSelectedDefaultOnDashboard(diseaseArea.showOnDashboardByDefault);
    setStatus('NotStarted');
  };

  return (
    <>
      <Box display='flex' justifyContent='space-between' width='100%'>
        <Button
          onClick={handleButtonClick}
          sx={[
            {
              fontWeight: 'inherit',
              justifyContent: 'left',
              padding: 0,

              '&:hover': {
                backgroundColor: 'transparent',
              },
            },
          ]}
        >
          {diseaseArea.name}
        </Button>
        <Button
          onClick={handleButtonClick}
          sx={[
            {
              fontWeight: 'inherit',
              justifyContent: 'right',
              padding: 0,
              margin: '0px 3px 0px 5px',

              '&:hover': {
                backgroundColor: 'transparent',
              },
            },
          ]}
        >
          <ModeEditOutlineOutlinedIcon />
        </Button>
      </Box>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>
          <DialogCloseButton onClick={handleClose} />
          {`Edit Disease Area: ${diseaseArea.name}`}
        </DialogTitle>
        <DialogContent>
          <Box component='form'>
            <TextField
              id='disease-area-name-input'
              fullWidth
              label='Name'
              margin='normal'
              onChange={handleNameChange}
              type='text'
              value={selectedName}
              variant='outlined'
            />

            <TextField
              id='icd10-codes-input'
              fullWidth
              label='ICD10 Codes'
              margin='normal'
              maxRows={4}
              multiline={true}
              onChange={handleIcd10CodesChange}
              type='text'
              value={selectedIcd10Codes}
              variant='outlined'
            />
            <FormControlLabel
              label='Available to show on dashboard'
              control={
                <Checkbox
                  checked={selectedAvailableOnDashboard}
                  onChange={() => {
                    setSelectedAvailableOnDashboard(!selectedAvailableOnDashboard);
                  }}
                />
              }
            />
            <FormControlLabel
              label='Default on dashboard'
              control={
                <Checkbox
                  checked={selectedAvailableOnDashboard && selectedDefaultOnDashboard}
                  onChange={() => {
                    setSelectedDefaultOnDashboard(!selectedDefaultOnDashboard);
                  }}
                  disabled={!selectedAvailableOnDashboard}
                />
              }
            />
          </Box>
          {status === 'Error' && (
            <DialogContentText display='flex' alignItems='center' mt={1} color='error'>
              <ErrorOutlineIcon sx={{ mr: 1 }} />
              {errorMessage}
            </DialogContentText>
          )}
        </DialogContent>
        {status === 'Loading' && <LinearProgress />}
        <DialogActions>
          <CancelButton onClick={handleClose} disabled={status === 'Loading'}>
            Cancel
          </CancelButton>
          <Button onClick={handleDelete} disabled={status === 'Loading'}>
            Delete
          </Button>
          <PrimaryButton onClick={handleSave} disabled={status === 'Loading'}>
            Save
          </PrimaryButton>
        </DialogActions>
      </Dialog>
    </>
  );
};
