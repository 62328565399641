import { useHistory } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { Box, Button, Typography, useTheme } from '@mui/material';
import LockOpenTwoToneIcon from '@mui/icons-material/LockOpenTwoTone';
import { SidebarLogo } from './SidebarLogo';

export const SidebarTopSection = () => {
  const theme = useTheme();
  const history = useHistory();
  const { user, logout } = useAuth0();

  const handleLogout = async (): Promise<void> => {
    try {
      await logout();
      history.push('signout');
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <Box
      sx={{
        textAlign: 'center',
        mx: 2,
        pt: 3,
        position: 'relative',
      }}
    >
      <SidebarLogo />
      {user !== undefined && (
        <Typography
          variant='h4'
          sx={{
            color: `${theme.colors.alpha.trueWhite[100]}`,
          }}
        >
          {user.name}
        </Typography>
      )}
      <Box m={1}>
        <Button color='primary' fullWidth onClick={handleLogout}>
          <LockOpenTwoToneIcon
            sx={{
              mr: 1,
            }}
          />
          {'Sign out'}
        </Button>
      </Box>
    </Box>
  );
};
