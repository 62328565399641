export type FileProcessingStatus = 'NotStarted' | 'Downloading' | 'Uploading' | 'Complete' | 'Error';

/**
 * Utility method to allow caller to automatically set file processing state while running functionality.
 *
 * @param setFileProcessingStatus
 * @param func
 * @constructor
 */
export async function UploadFile(
  setFileProcessingStatus: (
    value: ((prevState: FileProcessingState) => FileProcessingState) | FileProcessingState
  ) => void,
  func: () => Promise<void>
) {
  setFileProcessingStatus({ status: 'Uploading' });

  await func()
    .then(() => {
      if (!setFileProcessingStatus) {
        return;
      }

      setFileProcessingStatus({ status: 'Complete' });
    })
    .catch((e: Error) => {
      if (!setFileProcessingStatus) {
        return;
      }

      setFileProcessingStatus({ status: 'Error', errorMessage: e.message });
    });

  return;
}

/**
 * Utility method to allow caller to automatically set file processing state while running functionality.
 *
 * @param setFileProcessingStatus
 * @param func
 * @constructor
 */
export function DownloadFile(
  setFileProcessingStatus: (
    value: ((prevState: FileProcessingState) => FileProcessingState) | FileProcessingState
  ) => void,
  func: () => Promise<void>
) {
  setFileProcessingStatus({ status: 'Downloading' });

  func()
    .then(() => {
      if (!setFileProcessingStatus) {
        return;
      }

      setFileProcessingStatus({ status: 'Complete' });
    })
    .catch((e: Error) => {
      if (!setFileProcessingStatus) {
        return;
      }

      setFileProcessingStatus({ status: 'Error', errorMessage: e.message });
    });

  return;
}

export async function GenerateFileDownload(response: Response) {
  const blob = await response.blob();

  // This code is a bit of a hack to download a fetched result.
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.style.display = 'none';
  a.href = url;
  a.download = response.headers.get('Content-Disposition')?.split('filename=')[1].split(';')[0] ?? '';
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  window.URL.revokeObjectURL(url);
}

export interface FileProcessingState {
  status: FileProcessingStatus;
  errorMessage?: string;
}
