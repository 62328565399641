import React from 'react';
import { Autocomplete } from '@mui/material';
import { AutocompleteProps } from '@mui/material/Autocomplete/Autocomplete';

export function AutocompleteWrapper<
  T,
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined
>(props: AutocompleteProps<T, Multiple, DisableClearable, FreeSolo> & { waitForDefaultValue?: boolean }) {
  const { waitForDefaultValue: _, ...reducedProps } = props;

  const hasDefaultValue = !!props.defaultValue;
  const hasOptions = props.options && props.options.length > 0;

  return (
    <>
      {/*If default value is not present when component is mounted then component does not use the value once present.
      Therefore fully re-render the component if we are waiting for the default value to be computed*/}
      {props.waitForDefaultValue && ((hasDefaultValue && hasOptions) || hasOptions) && (
        <Autocomplete {...reducedProps} />
      )}

      {!(props?.waitForDefaultValue ?? false) && hasOptions && <Autocomplete {...reducedProps} />}

      {!hasOptions && <Autocomplete disabled={true} loading={true} {...reducedProps} />}
    </>
  );
}
