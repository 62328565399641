import { Button, Typography } from '@mui/material';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import React from 'react';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';

export interface CommonButtonProps {
  title?: string;
  disabled?: boolean;
  children?: React.ReactNode;
  fontWeight?: 'inherit' | 'bold' | '';
  padding?: 'inherit' | 'bold' | '';
  fontSize?: 'inherit' | 'bold' | '';
  onClick: ((event: React.MouseEvent<HTMLElement>) => void) | undefined;
  sx?: SxProps<Theme>;
  testId?: string;
}

export function ActionButton(props: CommonButtonProps) {
  return <DialogOpenButton {...props} />;
}

export function DialogOpenButton({
  title,
  disabled,
  onClick,
  padding,
  fontWeight,
  fontSize,
  children = <AddCircleRoundedIcon />,
  sx = [],
  testId,
}: CommonButtonProps) {
  return (
    <Button
      disabled={disabled ?? false}
      onClick={onClick}
      sx={{
        ...sx,
        ...{
          '&:hover': {
            backgroundColor: 'transparent',
          },
        },
        ...{
          fontWeight: !fontWeight ? 'inherit' : fontWeight,
          padding: !padding ? '' : padding,
          fontSize: !fontSize ? 'inherit' : fontSize,
        },
      }}
      data-testid={testId}
    >
      {children}
      {title && (
        <Typography variant='button' ml={1} fontSize={'12px'}>
          {title}
        </Typography>
      )}
    </Button>
  );
}
