import { Box, Card, Divider } from '@mui/material';
import ChevronRightTwoToneIcon from '@mui/icons-material/ChevronRightTwoTone';
import { ButtonWrapper } from './ButtonWrapper';
import { useHistory } from 'react-router-dom';

export const ResearchProjectBlock = () => {
  const history = useHistory();

  const handleButtonClick = async (): Promise<void> => {
    history.push('research-project');
  };

  return (
    <Card>
      <Box display='flex' alignItems='center' p={2}></Box>
      <Divider />
      <ButtonWrapper fullWidth endIcon={<ChevronRightTwoToneIcon />} onClick={handleButtonClick}>
        {'Research Project Management'}
      </ButtonWrapper>
    </Card>
  );
};
