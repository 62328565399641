import { styled, Tooltip, tooltipClasses, TooltipProps } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { truncateGuid } from '../../../data/SampleData';

export function renderGuidCellTooltip(params: GridRenderCellParams) {
  return <GuidGridCellTooltip guid={params.value ?? ''} />;
}

interface GuidGridCellTooltipProps {
  guid: string;
}

export const GuidGridCellTooltip = ({ guid }: GuidGridCellTooltipProps) => {
  return (
    <LightTooltip title={guid}>
      <div style={{ overflow: 'hidden' }}>{truncateGuid(guid)}</div>
    </LightTooltip>
  );
};

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} placement='bottom-start' classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 14,
    maxWidth: 'none',
  },
}));
