import { Migration } from 'data/MigrationsData';
import { MonospaceSpan } from './Styles';

interface Props {
  migration: Migration;
}

const dateFormat: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
};

export const MigrationSpan = ({ migration }: Props) => {
  if (migration.category === 'Pending') {
    return (
      <MonospaceSpan>
        {migration.version} {migration.description}
      </MonospaceSpan>
    );
  } else {
    const dateStr =
      migration.installedOn !== undefined
        ? `(${new Date(migration.installedOn).toLocaleDateString('en-US', dateFormat)})`
        : '';

    return (
      <MonospaceSpan>
        {migration.version} {dateStr} {migration.description}
      </MonospaceSpan>
    );
  }
};
