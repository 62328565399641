import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import { DatavantToken, GetDatavantTokens, GetVendors, TransformTokens, TransformType } from 'data/DatavantTokenData';
import useAuth from 'auth/UseAuth';
import {
  Autocomplete,
  Box,
  Button,
  Divider,
  FormControlLabel,
  Input,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';
import { Spinner } from 'react-bootstrap';
import { FileProcessingStatus, GenerateFileDownload } from 'helpers/FileProcessing';
import { appSettings } from '../AppSettings';
import CopyTextField from '../components/CopyTextField';
import { ErrorManagement, LoadingState, LoadState } from '../components/LoadingStateUtil';
import { LoadingIndicator } from '../components/LoadingIndicator';
import { ErrorIndicator } from '../components/ErrorIndicator';

export const DatavantTokenTransforms: FC = () => {
  const { accessToken } = useAuth();
  const [patientId, setPatientId] = useState<string>('');
  const [vendor, setVendor] = useState<string>('datavant');
  const [transformType, setTransformType] = useState<TransformType>('to');
  const [tokens, setTokens] = useState<string[]>(['', '', '', '']);
  const [loadingState, setLoadingState] = useState<LoadingState>({ status: 'NotStarted' });
  const [transformedTokens, setTransformedTokens] = useState<string[]>([]);
  const [vendors, setVendors] = useState<string[]>([]);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [fileProcessingStatus, setFileProcessingStatus] = useState<FileProcessingStatus>('NotStarted');

  useEffect(() => {
    return LoadState(setLoadingState, async () => {
      if (!accessToken) {
        return;
      }

      setVendors(await GetVendors(accessToken));
    });
  }, [accessToken]);

  const updatePatientId = (ev: ChangeEvent<HTMLInputElement>) => {
    setPatientId(ev.target.value);
  };

  const updateTokens = (token: string, index: number) => {
    let newTokens = [...tokens];
    newTokens[index] = token;
    setTokens([...newTokens]);
  };

  const hasTransformedTokens = () => {
    return transformedTokens.some(t => !!t);
  };

  const transformToken = async () => {
    ErrorManagement('Loading', setLoadingState, async () => {
      let token: DatavantToken = {
        patientId: patientId || 'empty ui placeholder',
        token1: tokens[0] || 'empty ui placeholder',
        token2: tokens[1] || 'empty ui placeholder',
        token3: tokens[2] || 'empty ui placeholder',
        token4: tokens[3] || 'empty ui placeholder',
      };
      let respTokens = await TransformTokens([token], transformType, vendor, accessToken);
      let respToken = respTokens[0];

      setTransformedTokens([respToken.token1, respToken.token2, respToken.token3, respToken.token4]);

      setLoadingState({ status: 'Complete' });
    });
  };

  const getDatavantTokens = async () => {
    ErrorManagement('Loading', setLoadingState, async () => {
      if (!accessToken) {
        return;
      }

      let respToken = await GetDatavantTokens(patientId, accessToken);
      setTokens([respToken.token1, respToken.token2, respToken.token3, respToken.token4]);

      setLoadingState({ status: 'Complete' });
    });
  };

  const handleFileSelection = async (e: ChangeEvent<HTMLInputElement>) => {
    setFileProcessingStatus('NotStarted');
    if (e.target.files) {
      setSelectedFile(e.target.files[0]);
    }
  };

  const handleUpdaterSaveButtonClick = async () => {
    setFileProcessingStatus('Uploading');

    if (accessToken && selectedFile) {
      try {
        const formData = new FormData();
        formData.append('file', selectedFile);
        formData.append('filename', selectedFile.name);

        const response = await fetch(
          `${appSettings.api.endpoint}/api/v2/Datavant/bulkTransform?transformType=${transformType}&vendor=${vendor}&delimiter=|`,
          {
            method: 'POST',
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
            body: formData,
          }
        );

        if (response.ok) {
          setFileProcessingStatus('Complete');
          await GenerateFileDownload(response);
        } else {
          await response.json();
          setFileProcessingStatus('Error');
        }
      } catch (err) {
        setFileProcessingStatus('Error');
      }
    } else {
      setFileProcessingStatus('NotStarted');
    }
  };

  return (
    <Box p={3}>
      <h4>Transform Token</h4>
      {loadingState.status === 'Loading' && <Spinner animation='border' />}
      <Box display='flex' alignItems='center' justifyContent='flex-start'>
        <RadioGroup row name='transform-type-radio-buttons-group'>
          <FormControlLabel
            value='transform-type-to-radio-button'
            label='To'
            control={
              <Radio
                checked={transformType === 'to'}
                value={'to'}
                onChange={(ev: ChangeEvent<HTMLInputElement>) => {
                  setTransformType(ev.target.value as TransformType);
                }}
              />
            }
          />
          <FormControlLabel
            value='transform-type-from-radio-button'
            label='From'
            control={
              <Radio
                checked={transformType === 'from'}
                value='from'
                onChange={(ev: ChangeEvent<HTMLInputElement>) => {
                  setTransformType(ev.target.value as TransformType);
                }}
              />
            }
          />
        </RadioGroup>

        <TextField
          id='vendor-input'
          label='Vendor Free Text'
          margin='normal'
          onChange={(ev: ChangeEvent<HTMLInputElement>) => {
            setVendor(ev.target.value);
          }}
          type='text'
          value={vendor}
          variant='outlined'
          size='small'
        />
        <Autocomplete
          options={vendors}
          renderInput={params => <TextField {...params} label='Vendor Pick List' margin='normal' />}
          onChange={(event, value) => setVendor(value ?? '')}
          size='small'
          sx={{ width: 200, marginLeft: 1 }}
          value={vendor}
        />
      </Box>
      <Box display='flex' alignItems='center' justifyContent='space-between'>
        <CopyTextField
          id='patient-id-input'
          label='PatientId'
          margin='normal'
          onChange={updatePatientId}
          type='text'
          value={patientId}
          variant='outlined'
          size='small'
          fullWidth
          sx={{ margin: 1, marginLeft: 0 }}
        />

        {tokens.map((token, i) => (
          <CopyTextField
            id={`token-${i}-input`}
            key={i}
            label={`Token${i + 1}`}
            margin='normal'
            onChange={(ev: ChangeEvent<HTMLInputElement>) => {
              updateTokens(ev.target.value, i);
            }}
            type='text'
            value={tokens[i]}
            variant='outlined'
            size='small'
            fullWidth
            sx={{ margin: 1 }}
          />
        ))}
      </Box>

      <Box display='flex' alignItems='center' justifyContent='space-between' sx={{ marginTop: 1 }}>
        {hasTransformedTokens() && (
          <CopyTextField
            id='patient-id-input'
            label='PatientId'
            margin='normal'
            type='text'
            value={patientId}
            variant='outlined'
            size='small'
            fullWidth
            sx={{ margin: 1, marginLeft: 0 }}
          />
        )}

        {hasTransformedTokens() &&
          transformedTokens.map((token, i) => (
            <CopyTextField
              id={`transformedToken-${i}-input`}
              key={i}
              label={`Token${i + 1}`}
              margin='normal'
              type='text'
              value={transformedTokens[i]}
              variant='outlined'
              disabled={true}
              size='small'
              fullWidth
              sx={{ margin: 1 }}
            />
          ))}
      </Box>

      <Button onClick={getDatavantTokens} disabled={loadingState.status === 'Loading'}>
        Get Datavant Tokens
      </Button>
      <Button onClick={transformToken} disabled={loadingState.status === 'Loading'}>
        Transform
      </Button>
      <Divider />
      <p />
      <h4>Bulk Transform</h4>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
        }}
        component='form'
        mt={2}
      >
        <Typography variant='h5' mt={2}>
          Upload tokens files
        </Typography>
        <Input type='file' onChange={handleFileSelection} sx={{ mt: 2, minWidth: 500 }} />
        <Button
          sx={{
            paddingTop: '20px',
          }}
          onClick={handleUpdaterSaveButtonClick}
          disabled={fileProcessingStatus === 'Uploading'}
        >
          Bulk Transform
        </Button>
      </Box>
      <LoadingIndicator loadingState={loadingState} margin={'All'} />
      <ErrorIndicator loadingState={loadingState} />
    </Box>
  );
};
