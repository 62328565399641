import { Button, useTheme } from '@mui/material';
import { DiseaseArea } from 'data/DiseaseAreaData';
import { PatientCountType } from 'components/grid/GridCountType';

export interface SampleTypeCountGridCellProps {
  diseaseArea: DiseaseArea;
  sampleTypeId: string;
  sampleTypeDescription?: string;
  count: number;
  onSampleTypeCountClick(
    count: number,
    diseaseArea: DiseaseArea,
    patientRecordCountType: PatientCountType,
    biobankId?: string,
    sampleTypeId?: string
  ): void;
}

export const SampleTypeCountGridCell = ({
  diseaseArea,
  sampleTypeId,
  sampleTypeDescription,
  count,
  onSampleTypeCountClick,
}: SampleTypeCountGridCellProps) => {
  const theme = useTheme();

  const handleSampleTypeCountButtonClick = async () => {
    onSampleTypeCountClick(count, diseaseArea, 'bySampleType', undefined, sampleTypeId);
  };

  return (
    <Button
      disabled={count === 0}
      onClick={handleSampleTypeCountButtonClick}
      sx={[
        {
          fontWeight: 'inherit',
          '&:hover': {
            backgroundColor: 'transparent',
          },
          '&:disabled': {
            color: `${theme.colors.primary.dark}`,
          },
        },
      ]}
    >
      {count.toLocaleString() ?? 0}
    </Button>
  );
};
