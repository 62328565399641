import { useState } from 'react';

export type CountType = 'patient' | 'sample';

export interface Props {
  defaultCountType: CountType;
  onCountTypeClick: Function;
}

export const CountTypeSelector = ({ defaultCountType, onCountTypeClick }: Props) => {
  const [countType, setCountType] = useState<CountType>(defaultCountType);

  const handleCountTypeSelection = async (ev: any) => {
    onCountTypeClick(ev.target.value as CountType);
    setCountType(ev.target.value as CountType);
  };

  return (
    <div className='historic-counts-filters-radio-buttons'>
      <input
        type='radio'
        id='sample'
        name='counttype'
        value='sample'
        checked={countType === 'sample'}
        onChange={handleCountTypeSelection}
      />
      <label htmlFor='sample'>Sample</label>

      <input
        type='radio'
        id='patient'
        name='counttype'
        value='patient'
        checked={countType === 'patient'}
        onChange={handleCountTypeSelection}
      />
      <label htmlFor='patient'>Patient</label>
    </div>
  );
};
