import { useState } from 'react';
import { DateAggregate } from 'historicCounts/DateAggregate';

export interface Props {
  defaultAggregate: DateAggregate;
  onAggregateClick: Function;
}

export const DateAggregateSelector = ({ defaultAggregate, onAggregateClick }: Props) => {
  const [aggregateType, setAggregateType] = useState<DateAggregate>(defaultAggregate);

  const handleDateAggregateSelection = async (ev: any) => {
    onAggregateClick(ev.target.value as DateAggregate);
    setAggregateType(ev.target.value as DateAggregate);
  };

  return (
    <div className='historic-counts-filters-radio-buttons'>
      <input
        type='radio'
        id='day'
        name='aggregatetype'
        value='day'
        checked={aggregateType === 'day'}
        onChange={handleDateAggregateSelection}
      />
      <label htmlFor='day'>Day</label>

      <input
        type='radio'
        id='week'
        name='aggregatetype'
        value='week'
        checked={aggregateType === 'week'}
        onChange={handleDateAggregateSelection}
      />
      <label htmlFor='week'>Week</label>

      <input
        type='radio'
        id='month'
        name='aggregatetype'
        value='month'
        checked={aggregateType === 'month'}
        onChange={handleDateAggregateSelection}
      />
      <label htmlFor='month'>Month</label>

      <input
        type='radio'
        id='year'
        name='aggregatetype'
        value='year'
        checked={aggregateType === 'year'}
        onChange={handleDateAggregateSelection}
      />
      <label htmlFor='year'>Year</label>
    </div>
  );
};
