import React from 'react';
import { Box } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';

interface Props {
  children: React.ReactNode;
  padding?: number;
  flexGrow?: number;
  sx?: SxProps<Theme>;
}

export const FlexTableBox = ({ children, padding, flexGrow, sx }: Props) => {
  return (
    <Box
      p={padding ?? 0}
      display={'flex'}
      flexDirection={'column'}
      flexGrow={flexGrow}
      height={'100%'}
      sx={sx}
      overflow={'auto'}
    >
      {children}
    </Box>
  );
};
