import { appSettings } from 'AppSettings';

export interface LongRunningTask {
  longRunningTaskId: string;
  name: string;
  taskClass: string;
  status: LongRunningTaskStatus;
  statusText: string;
  s3BasePath: string;
}

export type LongRunningTaskStatus = 'running' | 'complete' | 'punted' | 'error';

export async function GetLongRunningTask(
  longRunningTaskId: string,
  accessToken: string
): Promise<LongRunningTask | null> {
  if (accessToken) {
    try {
      const response = await fetch(`${appSettings.api.endpoint}/api/v2/Tasks/${longRunningTaskId}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      if (response.ok) {
        const longRunningTask: LongRunningTask = await response.json();
        return longRunningTask;
      }
    } catch (err) {
      console.dir(err);
    }
  }
  return null;
}

export async function GetLongRunningTasks(
  taskClass: string,
  status: LongRunningTaskStatus,
  accessToken: string
): Promise<LongRunningTask[]> {
  let longRunningTasks: LongRunningTask[] = [];

  if (accessToken) {
    try {
      const response = await fetch(`${appSettings.api.endpoint}/api/v2/Tasks?taskClass=${taskClass}&status=${status}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      if (response.ok) {
        longRunningTasks = await response.json();
      }
    } catch (err) {
      console.dir(err);
    }
  }
  return longRunningTasks;
}
