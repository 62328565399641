import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  LinearProgress,
  TextField,
  Typography,
} from '@mui/material';
import { ChangeEvent, useState } from 'react';
import { LoadingStatus } from 'components/LoadingStatus';
import { LlCustomer } from 'data/LlCustomerData';
import useAuth from 'auth/UseAuth';
import { appSettings } from 'AppSettings';
import { DialogCloseButton } from '../components/DialogCloseButton';
import { DialogOpenButton } from '../components/DialogOpenButton';
import { CancelButton } from 'components/CancelButton';
import { PrimaryButton } from 'components/PrimaryButton';

export interface CreateCustomerModalProps {
  onCustomerCreate(newCustomer: LlCustomer): void;
}

export const CreateCustomerModal = ({ onCustomerCreate }: CreateCustomerModalProps) => {
  const { accessToken } = useAuth();
  const defaultStatus = 'NotStarted';
  const defaultErrorMessage = '';
  const defaultCustomerName = '';

  const [open, setOpen] = useState<boolean>(false);
  const [status, setStatus] = useState<LoadingStatus>(defaultStatus);
  const [errorMessage, setErrorMessage] = useState<string>(defaultErrorMessage);
  const [selectedName, setSelctedName] = useState<string>(defaultCustomerName);

  const handleButtonClick = async () => {
    setOpen(true);
  };

  const handleSave = async () => {
    setStatus('Loading');

    if (!selectedName) {
      setErrorMessage('Please enter a customer name.');
      setStatus('Error');
    } else {
      const newCustomer = {
        name: selectedName,
      };

      try {
        if (accessToken) {
          const response = await fetch(`${appSettings.api.endpoint}/api/v2/Customers`, {
            method: 'POST',
            headers: {
              Authorization: `Bearer ${accessToken}`,
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(newCustomer),
          });

          if (response.ok) {
            const customerResponse: LlCustomer = await response.json();

            setStatus('Complete');
            handleClose();
            if (onCustomerCreate !== undefined) onCustomerCreate(customerResponse);
          } else {
            setStatus('Error');

            if (response.status === 409) {
              setErrorMessage(`Customer with name '${selectedName}' already exists.`);
            } else if (response.status === 401) {
              setErrorMessage('Error: unauthorized user.');
            } else {
              setErrorMessage('Unknown error.');
            }
          }
        }
      } catch (err) {
        setStatus('Error');
        setErrorMessage('Unknown error.');
      }
    }
  };

  const handleClose = () => {
    clearSelectedState();
    setOpen(false);
    setErrorMessage(defaultErrorMessage);
  };

  const clearSelectedState = () => {
    setSelctedName(defaultCustomerName);
    setStatus(defaultStatus);
  };

  const handleNameChange = (ev: ChangeEvent<HTMLInputElement>) => {
    setSelctedName(ev.target.value);
  };

  return (
    <>
      <DialogOpenButton onClick={handleButtonClick} />
      <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth='xs'>
        <DialogTitle>
          <DialogCloseButton onClick={handleClose} />
          <Typography variant='h4'>Create New Customer</Typography>
        </DialogTitle>
        <DialogContent>
          <Box component='form'>
            <TextField
              id='disease-area-name-input'
              fullWidth
              label='Name'
              margin='normal'
              onChange={handleNameChange}
              type='text'
              value={selectedName}
              variant='outlined'
            />
          </Box>
          {status === 'Error' && (
            <DialogContentText display='flex' alignItems='center' mt={1} color='error'>
              <ErrorOutlineIcon sx={{ mr: 1 }} />
              {errorMessage}
            </DialogContentText>
          )}
        </DialogContent>
        {status === 'Loading' && <LinearProgress />}
        <DialogActions>
          <CancelButton onClick={handleClose} disabled={status === 'Loading'}>
            Cancel
          </CancelButton>
          <PrimaryButton onClick={handleSave} disabled={status === 'Loading'}>
            Save
          </PrimaryButton>
        </DialogActions>
      </Dialog>
    </>
  );
};
