import React, { useState } from 'react';
import { DialogOpenButton } from '../../components/DialogOpenButton';
import useMemoTranslation from '../../hooks/UseMemoTranslation';
import DeleteIcon from '@mui/icons-material/Delete';
import { ConfirmationDialog } from '../../components/ConfirmationDialog';
import { DiscardSamples } from '../../data/SampleExhaustionData';
import useAuth from '../../auth/UseAuth';

export interface SampleTrackingExhaustModalProps {
  selectedSampleIds: ReadonlyArray<string>;
  onClose?: (hasChanges: boolean) => void;
}

export const SampleTrackingExhaustModal = ({
  selectedSampleIds,
  onClose = () => {},
}: SampleTrackingExhaustModalProps) => {
  const { t } = useMemoTranslation();
  const { accessToken } = useAuth();

  const [modalOpen, setModalOpen] = useState<boolean>(false);

  function handleClose() {
    setModalOpen(false);
    onClose(false);
  }

  async function handleConfirm() {
    if (!accessToken) {
      return;
    }

    await DiscardSamples(selectedSampleIds, accessToken);
    setModalOpen(false);
    onClose(true);
  }

  return (
    <>
      <DialogOpenButton
        title={t('discardRows')}
        onClick={() => setModalOpen(true)}
        disabled={selectedSampleIds.length === 0}
      >
        <DeleteIcon />
      </DialogOpenButton>
      <ConfirmationDialog
        open={modalOpen}
        onConfirm={handleConfirm}
        onClose={handleClose}
        title={t('discardRows')}
        subtitle={selectedSampleIds.length + ' Sample(s)'}
        message={t('discardRowsInfo')}
        confirmBtnText={t('discardRows')}
      />
    </>
  );
};
