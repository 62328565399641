import { GridColDef, GridComparatorFn, GridRenderCellParams, GridValueGetterParams } from '@mui/x-data-grid-pro';
import { renderCellLinkWithLaunchIcon } from 'components/grid/GridCellLink';
import { ResearchProject } from 'data/ResearchProjectData';
import { CamelCaseToLowerCaseWithSpace } from 'data/ResearchProjectStatusData';
import React, { useMemo, useState } from 'react';
import { find, orderBy } from 'lodash';
import {
  actualCohortSize,
  customer,
  dashDash,
  editResearchProject,
  expectedCohortSize,
  mondayLink,
  nameField,
  project,
  salesforceLink,
  selectedSampleCount,
  statusField,
} from '../../util/Constants';
import {
  formatPatientSampleCountAsFlex,
  formatPatientSampleCountWithDisplayName,
  generateWidthFromProperty,
} from '../../util/grid/TableUtils';
import { SampleTrackingCounts } from '../../data/SampleTrackingData';
import useMemoTranslation from '../../hooks/UseMemoTranslation';
import { CompactGridWrapper } from '../../components/grid/CompactGridWrapper';
import { ResearchProjectGridCell, ResearchProjectGridCellProps } from './ResearchProjectGridCell';
import { renderEditResearchProjectGridCell } from '../../components/grid/cell/EditResearchProjectGridCell';
import { useResearchProjectsTransitionPath } from '../../components/hooks/UseResearchProjectTransitionPaths';
import { LoadingState } from '../../components/LoadingStateUtil';
import { ResearchProjectTransitionPath } from '../../data/ResearchProjectTransitionPathData';
import { createCaseInsensitiveMap, Dictionary } from '../../util/TypeUtil';
import { TransitionIconWithTooltip } from '../../util/TransitionUtil';
import { LinkButton } from '../../components/LinkButton';
import { LoadingIndicator } from '../../components/LoadingIndicator';
import { ErrorIndicator } from '../../components/ErrorIndicator';
import { TransitionTypeExtended } from '../sampleTracking/SampleTrackingGrid';

export interface ResearchProjectsGridProps {
  researchProjects: ResearchProject[];
  researchProjectCounts: Dictionary<ReadonlyArray<SampleTrackingCounts>>;
  onUpdateResearchProjectClick(researchProject: ResearchProject, open: boolean): void;
  baseUrl?: string;
  countInputsWithWorkLeft: boolean;
}

export const ResearchProjectsGrid = ({
  researchProjects,
  researchProjectCounts,
  onUpdateResearchProjectClick,
  baseUrl = '/research-project',
  countInputsWithWorkLeft,
}: ResearchProjectsGridProps) => {
  const [loadingState, setLoadingState] = useState<LoadingState>({ status: 'Loading' });

  const [transitionPathsByResearchProject] = useResearchProjectsTransitionPath(
    useMemo(() => researchProjects.map(i => i.researchProjectId), [researchProjects]),
    setLoadingState
  );

  const columns = useColumns(researchProjects, onUpdateResearchProjectClick, transitionPathsByResearchProject, baseUrl);
  const rows = useRows(researchProjects, researchProjectCounts, onUpdateResearchProjectClick, countInputsWithWorkLeft);

  return (
    <>
      <CompactGridWrapper
        rows={rows}
        columns={columns}
        density='compact'
        hideFooterSelectedRowCount={true}
        initialState={{
          columns: {
            columnVisibilityModel: {
              expectedCohortSize: false,
              salesforceLink: false,
              mondayLink: false,
              name: false,
            },
          },
          pinnedColumns: { left: [customer, project] },
          sorting: {
            sortModel: [{ field: 'name', sort: 'asc' }],
          },
          filter: {
            filterModel: {
              items: [{ field: statusField, operator: 'equals', value: 'active' }],
            },
          },
        }}
      />
      <LoadingIndicator loadingState={loadingState} margin={'LRB'} />
      <ErrorIndicator loadingState={loadingState} />
    </>
  );
};

const useColumns = (
  researchProjects: ResearchProject[],
  onUpdateResearchProjectClick: (researchProject: ResearchProject, open: boolean) => void,
  transitionPathsByResearchProject: Dictionary<ReadonlyArray<ResearchProjectTransitionPath>>,
  baseUrl: string | undefined
): GridColDef[] => {
  const { t } = useMemoTranslation();

  const generatedColumns: GridColDef[] = useMemo(() => {
    function getTransitionIconWithTooltip(
      transitionType: TransitionTypeExtended,
      displayName: string,
      params: GridRenderCellParams
    ) {
      return (
        <TransitionIconWithTooltip
          transitionType={transitionType}
          children={
            <LinkButton
              url={`${baseUrl}/${params?.row.project.researchProject.researchProjectId}`}
              sx={{
                paddingLeft: 0,
                paddingRight: 0,
                textWrap: 'wrap',
                lineHeight: 'normal',
                width: '100%',
                minWidth: '0',
              }}
            >
              {formatPatientSampleCountAsFlex(params?.row, displayName, dashDash)}
            </LinkButton>
          }
          tooltip={displayName}
        />
      );
    }

    let maxNumOfColumns = 1;
    Object.keys(transitionPathsByResearchProject).forEach(
      k => (maxNumOfColumns = Math.max(maxNumOfColumns, transitionPathsByResearchProject[k].length))
    );

    let columns: GridColDef[] = [
      {
        field: selectedSampleCount,
        headerName: t(selectedSampleCount),
        headerAlign: 'center',
        align: 'left',
        minWidth: 50,
        maxWidth: 200,
        flex: 1.25,
        valueGetter: params => formatPatientSampleCountWithDisplayName(params?.row, 'Selected', dashDash),
        renderCell: params => getTransitionIconWithTooltip('Selected', t('Selected'), params),
      },
    ];

    const colName = 'pos';
    for (let i = 0; i < maxNumOfColumns; i++) {
      columns.push({
        field: `${colName}_${i.toString()}`,
        headerName: '',
        headerAlign: 'center',
        align: 'left',
        minWidth: 50,
        maxWidth: 200,
        flex: 1,
        valueGetter: params => {
          const pathData = orderBy(
            transitionPathsByResearchProject[params?.row.researchProjectId] ?? [],
            i => i.transitionOrder
          );

          return formatPatientSampleCountWithDisplayName(params?.row, pathData[i]?.displayName, dashDash);
        },
        renderCell: params => {
          const pathData = orderBy(
            transitionPathsByResearchProject[params?.row.researchProjectId] ?? [],
            i => i.transitionOrder
          );
          const data = pathData[i];

          if (!data) {
            return <></>;
          }

          return getTransitionIconWithTooltip(data?.transitionEnum.name, data?.displayName, params);
        },
      });
    }

    return columns;
  }, [t, transitionPathsByResearchProject, baseUrl]);

  return useMemo(
    () => [
      {
        field: customer,
        headerName: t(customer),
        width: generateWidthFromProperty(researchProjects ?? [], 0, (row: ResearchProject) => row.customer.name),
      },
      {
        field: project,
        headerName: t(project),
        width: generateWidthFromProperty(researchProjects ?? [], 0, (row: ResearchProject) => row.name),
        renderCell: (params: GridRenderCellParams<ResearchProjectGridCellProps>) => {
          if (params.value) {
            return (
              <ResearchProjectGridCell
                researchProject={params.value.researchProject}
                url={`${baseUrl}/${params.value.researchProject.researchProjectId}`}
              />
            );
          }
        },
        sortComparator: byProjectComparator,
      },
      {
        field: expectedCohortSize,
        headerName: t(expectedCohortSize),
        headerAlign: 'center',
        align: 'center',
        width: generateWidthFromProperty(researchProjects ?? [], 75, (row: ResearchProject) =>
          (row.expectedCohortSize || 0).toString()
        ),
        type: 'number',
      },
      {
        field: actualCohortSize,
        headerName: t(actualCohortSize),
        headerAlign: 'center',
        align: 'center',
        width: generateWidthFromProperty(researchProjects ?? [], 75, (row: ResearchProject) =>
          (row.actualCohortSize || 0).toString()
        ),
        type: 'number',
      },
      {
        field: statusField,
        headerName: t(statusField),
        headerAlign: 'center',
        align: 'center',
        width: generateWidthFromProperty(researchProjects ?? [], 75, (row: ResearchProject) => row.status),
      },
      {
        field: nameField,
        headerName: t(nameField),
        headerAlign: 'center',
        align: 'center',
        width: generateWidthFromProperty(researchProjects ?? [], 75, (row: ResearchProject) => row.name),
      },
      {
        field: salesforceLink,
        headerName: t(salesforceLink),
        headerAlign: 'center',
        align: 'center',
        width: generateWidthFromProperty(researchProjects ?? [], 150, (row: ResearchProject) => row.salesforceLink),
        sortable: false,
        renderCell: renderCellLinkWithLaunchIcon,
      },
      {
        field: mondayLink,
        headerName: t(mondayLink),
        headerAlign: 'center',
        align: 'center',
        width: generateWidthFromProperty(researchProjects ?? [], 150, (row: ResearchProject) => row.mondayLink),
        sortable: false,
        renderCell: renderCellLinkWithLaunchIcon,
      },
      ...generatedColumns,
      {
        field: editResearchProject,
        headerName: t(editResearchProject),
        headerAlign: 'center',
        align: 'center',
        width: 30,
        renderCell: (params: GridValueGetterParams) =>
          renderEditResearchProjectGridCell({
            ...params,
            researchProject: params.row.project.researchProject,
            onUpdateResearchProjectClick: onUpdateResearchProjectClick,
          }),
      },
    ],
    [t, researchProjects, onUpdateResearchProjectClick, baseUrl, generatedColumns]
  ) as GridColDef[];
};

const useRows = (
  researchProjects: ResearchProject[],
  researchProjectCounts: Dictionary<ReadonlyArray<SampleTrackingCounts>>,
  onUpdateResearchProjectClick: (researchProject: ResearchProject, open: boolean) => void,
  countInputsWithWorkLeft: boolean
) => {
  return useMemo(() => {
    const rows: any[] = [];
    let id = 1;

    researchProjects.forEach(r => {
      let project = {
        researchProject: r,
        onUpdateResearchProjectClick: onUpdateResearchProjectClick,
      };

      rows.push({
        id: id++,
        ...r,
        project: project,
        customer: r.customer.name,
        status: CamelCaseToLowerCaseWithSpace(r.status),
        patientCounts: createCaseInsensitiveMap(
          find(researchProjectCounts[r.researchProjectId], i => i.sampleGroup === 'all')?.patientCounts
        )?.get(String(countInputsWithWorkLeft)),
        sampleCounts: createCaseInsensitiveMap(
          find(researchProjectCounts[r.researchProjectId], i => i.sampleGroup === 'all')?.sampleCounts
        )?.get(String(countInputsWithWorkLeft)),
      });
    });

    return rows;
  }, [researchProjects, researchProjectCounts, onUpdateResearchProjectClick, countInputsWithWorkLeft]);
};

const byProjectComparator: GridComparatorFn = (v1, v2) => {
  if ((v1 as ResearchProject).name < (v2 as ResearchProject).name) {
    return -1;
  } else if ((v1 as ResearchProject).name > (v2 as ResearchProject).name) {
    return 1;
  } else {
    return 0;
  }
};
