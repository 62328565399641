import { GridColDef, GridRowsProp } from '@mui/x-data-grid-pro';
import { Box } from '@mui/material';
import { DiseaseArea } from 'data/DiseaseAreaData';
import { DiseaseAreaCountByBiobank, DiseaseAreaCountBySampleType } from 'data/DiseaseAreaCountData';
import { PatientCountType } from 'components/grid/GridCountType';
import { GetDiseaseAreaCountByBiobankColumns, GetDiseaseAreaCountBySampleTypeColumns } from './DiseaseAreaCountColumns';
import { GetDiseaseAreaCountByBiobankRows, GetDiseaseAreaCountBySampleTypeRows } from './DiseaseAreaCountRows';
import { LoadingStatus } from 'components/LoadingStatus';
import { Spinner } from 'react-bootstrap';
import useAuth from 'auth/UseAuth';
import { useEffect, useState } from 'react';
import { Biobank } from 'data/BiobankData';
import { SampleType } from 'data/SampleTypeData';
import { CompactGridWrapper } from 'components/grid/CompactGridWrapper';

export interface Props {
  diseaseAreas: DiseaseArea[];
  biobanks: Biobank[];
  sampleTypes: ReadonlyArray<SampleType>;
  countType: PatientCountType;
  byBiobankCounts: DiseaseAreaCountByBiobank[];
  bySampleTypeCounts: DiseaseAreaCountBySampleType[];
  onDiseaseAreaChange: Function;
  onCountClick(
    count: number,
    diseaseArea: DiseaseArea,
    patientRecordCountType: PatientCountType,
    biobankId?: string,
    sampleTypeId?: string
  ): void;
}

export const DiseaseAreaCountGrid = ({
  diseaseAreas,
  biobanks,
  sampleTypes,
  countType,
  byBiobankCounts,
  bySampleTypeCounts,
  onDiseaseAreaChange,
  onCountClick,
}: Props) => {
  const { accessToken } = useAuth();

  const [status, setStatus] = useState<LoadingStatus>('NotStarted');
  const [columns, setColumns] = useState<GridColDef[]>([]);
  const [rows, setRows] = useState<GridRowsProp>([]);

  useEffect(() => {
    async function getState() {
      if (accessToken) {
        setStatus('Loading');
        if (countType === 'byBiobank') {
          setColumns(
            await GetDiseaseAreaCountByBiobankColumns(
              diseaseAreas,
              biobanks,
              byBiobankCounts,
              onCountClick,
              onDiseaseAreaChange,
              accessToken
            )
          );
          setRows(await GetDiseaseAreaCountByBiobankRows(diseaseAreas, byBiobankCounts, onCountClick));
        } else if (countType === 'bySampleType') {
          setColumns(
            await GetDiseaseAreaCountBySampleTypeColumns(
              diseaseAreas,
              sampleTypes,
              bySampleTypeCounts,
              onCountClick,
              onDiseaseAreaChange,
              accessToken
            )
          );
          setRows(await GetDiseaseAreaCountBySampleTypeRows(diseaseAreas, bySampleTypeCounts, onCountClick));
        }
        setStatus('Complete');
      } else {
        setStatus('Error');
      }
    }

    getState();
  }, [
    diseaseAreas,
    biobanks,
    sampleTypes,
    countType,
    byBiobankCounts,
    bySampleTypeCounts,
    onCountClick,
    onDiseaseAreaChange,
    accessToken,
  ]);

  return (
    <>
      {status === 'Complete' && (
        <>
          <CompactGridWrapper
            rows={rows}
            columns={columns}
            initialState={{ pinnedColumns: { left: ['diseaseArea', 'icd10Codes', 'total'] } }}
            disableColumnResize={false}
            pagination={false}
            hideFooterRowCount
            hideFooterSelectedRowCount
            disableRowSelectionOnClick
          />
          <Box fontSize='14px' fontStyle='italic' padding='15px 0px 7px 0px'>
            Please note: digital assets are not included in counts.
          </Box>
        </>
      )}
      {status === 'Loading' && (
        <Box display='flex' justifyContent='center' marginTop='100px'>
          <Spinner animation='border' />
        </Box>
      )}
    </>
  );
};
