import { useTheme } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import LaunchIcon from '@mui/icons-material/Launch';

export function renderCellLinkWithLaunchIcon(params: GridRenderCellParams) {
  if (params.value === undefined) {
    return <></>;
  }

  return <GridCellLinkWithLaunchIcon url={params.value ?? ''} />;
}

interface LinkGridCellProps {
  url: string;
}

const GridCellLinkWithLaunchIcon = ({ url }: LinkGridCellProps) => {
  const theme = useTheme();

  let href = url;
  if (href.indexOf('http') < 0 || href.indexOf('https') < 0) {
    href = 'http://' + href.replace('wwww.', '');
  }

  return (
    <a href={href} target='_blank' rel='noopener noreferrer' style={{ color: `${theme.colors.primary.dark}` }}>
      <LaunchIcon />
    </a>
  );
};
