import { appSettings } from 'AppSettings';
import { GenerateFileDownload } from '../helpers/FileProcessing';
import { BaseGet, BasePost } from './BaseData';

export interface DatavantToken {
  patientId: string;
  token1: string;
  token2: string;
  token3: string;
  token4: string;
}

export interface DatavantSftpFile {
  name: string;
  fullPath: string;
  isDirectory: string;
  children: DatavantSftpFile[];
}

export type TransformType = 'to' | 'from';
type SftpDir = 'datasets' | 'overlaps';

export async function TransformTokens(
  tokens: DatavantToken[],
  transformType: TransformType,
  vendor: string,
  accessToken?: string
): Promise<DatavantToken[]> {
  return await BasePost(
    `${appSettings.api.endpoint}/api/v2/Datavant/transform`,
    {
      tokens: tokens,
      transformType: transformType,
      vendor: vendor,
    },
    accessToken
  );
}

export async function GetDatavantTokens(patientId: string, accessToken?: string): Promise<DatavantToken> {
  return await BaseGet(`${appSettings.api.endpoint}/api/v2/Datavant/Tokens/${patientId}`, accessToken);
}

export async function GetVendors(accessToken?: string): Promise<string[]> {
  return await BaseGet(`${appSettings.api.endpoint}/api/v2/Datavant/Vendors`, accessToken);
}

export async function GetSftpFiles(sftpDir: SftpDir, accessToken: string): Promise<DatavantSftpFile[]> {
  let files: DatavantSftpFile[] = [];
  try {
    const response = await fetch(`${appSettings.api.endpoint}/api/v2/Datavant/${sftpDir}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
    });
    if (response.ok) {
      files = await response.json();
    }
  } catch (err) {
    console.dir(err);
  }

  return files;
}

export async function DownloadFile(path: string, accessToken: string): Promise<Response> {
  const response = await fetch(`${appSettings.api.endpoint}/api/v2/Datavant/download?filePath=${encodeURI(path)}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  if (response.ok) {
    await GenerateFileDownload(response);
  }
  return response;
}
