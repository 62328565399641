import {
  Autocomplete,
  Breakpoint,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from '@mui/material';
import useAuth from 'auth/UseAuth';
import { useCallback, useEffect, useState } from 'react';
import { TransitionType } from '../../data/SampleTrackingData';
import { DialogCloseButton } from '../../components/DialogCloseButton';
import { DialogOpenButton } from '../../components/DialogOpenButton';
import { find, map, orderBy, sortBy } from 'lodash';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import {
  ExtractionKit,
  ExtractionPlatform,
  ExtractionType,
  GALATEA_BIO_PICKLIST_NAME,
  GetExtractionKit,
  GetExtractionPlatform,
  GetExtractionType,
  GetLibraryKit,
  getOrganizationLabel,
  GetOrganizations,
  GetSequencingExpectedCoverage,
  GetSequencingPlatform,
  GetShippingCarrier,
  GetShippingContainer,
  GetShippingTemperature,
  GetSubsetType,
  GetVolumeUnit,
  LibraryKit,
  Organization,
  SequencingExpectedCoverage,
  SequencingPlatform,
  ShippingCarrier,
  ShippingContainer,
  ShippingTemperature,
  SubsetType,
  VolumeUnit,
  ZYMO_RESEARCH_PICKLIST_NAME,
} from '../../data/ReferenceData';
import {
  CreateSampleTrackingExtractionTransition,
  CreateSampleTrackingInformaticsTransition,
  CreateSampleTrackingLibraryPrepTransition,
  CreateSampleTrackingReceivingTransition,
  CreateSampleTrackingSequencingTransition,
  CreateSampleTrackingShippingTransition,
  CreateSampleTrackingSubsettingTransition,
  CreateSampleTrackingTransition,
  ExportTransitionManifest,
} from '../../data/SampleTrackingTransitionData';
import { ErrorManagement, LoadingProps, LoadingState, LoadState } from '../../components/LoadingStateUtil';
import { LoadingIndicator } from '../../components/LoadingIndicator';
import { ErrorIndicator } from '../../components/ErrorIndicator';
import useMemoTranslation from '../../hooks/UseMemoTranslation';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { CreateInformaticsTransitionGrid } from './CreateInformaticsTransitionGrid';
import { VerifyInformaticsTransitionModal } from './VerifyInformaticsTransitionModal';
import { FlexTableBox } from 'components/FlexTableBox';
import { CancelButton } from '../../components/CancelButton';
import { PrimaryButton } from '../../components/PrimaryButton';
import { FlexBox } from '../../components/FlexBox';
import { useResearchProjectTransitionPath } from '../../components/hooks/UseResearchProjectTransitionPaths';
import { ResearchProjectTransitionPath } from '../../data/ResearchProjectTransitionPathData';
import { GetPipelines, InformaticsPipeline } from '../../data/InformaticsData';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { UseState, UseStateSetter } from '../../util/TypeUtil';
import { GetSampleSequencingJourneyDetails, SampleSequencingJourneyDetails } from '../../data/SampleJourneyData';
import { AutocompleteWrapper } from '../../components/AutocompleteWrapper';

const TransitionsNotRequiringPerformingOrganization: TransitionType[] = [
  'Shipping',
  'Receiving',
  'Informatics',
  'ReadyForDelivery',
  'Delivered',
  'ReBiobanking',
  'Generic',
  'GenericWithSampleOutput',
];

const DoNotRenderBaseOptions: TransitionType[] = ['Shipping', 'Receiving'];

export type SelectedSampleInfo = {
  sampleId: string;
  sampleIdentifier: string;
  inputId: string;
  selectionStatus: string;
};

export interface CreateSampleTrackingTransitionModalProps {
  researchProjectId: string;
  selectedSamples: SelectedSampleInfo[];
  onClose?: (hasChanges: boolean) => void;
}

type SaveFunction = (
  currentTransitionType: TransitionType,
  researchProjectId: string,
  orderedAt: Date,
  selectedTransitionPath: ResearchProjectTransitionPath,
  selectedSampleIds: ReadonlyArray<string>
) => Promise<string | undefined>;

type SaveFunctionWrapper = () => SaveFunction;

export const CreateSampleTrackingTransitionModal = ({
  researchProjectId,
  selectedSamples,
  onClose = () => {},
}: CreateSampleTrackingTransitionModalProps) => {
  const { accessToken } = useAuth();
  const { t } = useMemoTranslation();

  const [loadingState, setLoadingState] = useState<LoadingState>({ status: 'NotStarted' });
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [modalMaxWidth, setModalMaxWidth] = useState<Breakpoint>('xs');

  const [transitionPaths] = useResearchProjectTransitionPath(researchProjectId, setLoadingState);
  const selectedSampleIds = map(selectedSamples, s => s.sampleId);

  const [disableSubmitButton, setDisableSubmitButton] = useState<boolean>(true);
  const [saveFunction, setSaveFunction] = useState<SaveFunctionWrapper>(() => () => Promise.resolve(''));

  // Reference Data
  const [organizations, setOrganizations] = useState<ReadonlyArray<Organization>>([]);
  const [extractionKits, setExtractionKits] = useState<ReadonlyArray<ExtractionKit>>([]);
  const [extractionPlatforms, setExtractionPlatforms] = useState<ReadonlyArray<ExtractionPlatform>>([]);
  const [extractionTypes, setExtractionTypes] = useState<ReadonlyArray<ExtractionType>>([]);
  const [subsetTypes, setSubsetTypes] = useState<ReadonlyArray<SubsetType>>([]);
  const [libraryKits, setLibraryKits] = useState<ReadonlyArray<LibraryKit>>([]);
  const [sequencingExpectedCoverages, setSequencingExpectedCoverages] = useState<
    ReadonlyArray<SequencingExpectedCoverage>
  >([]);
  const [sequencingPlatforms, setSequencingPlatforms] = useState<ReadonlyArray<SequencingPlatform>>([]);

  // Transition Data
  const [selectedTransition, setSelectedTransition] = useState<TransitionType>();
  const [selectedTransitionPath, setSelectedTransitionPath] = useState<ResearchProjectTransitionPath>();
  const [orderedAt, setOrderedAt] = useState<Date>(new Date());
  const [selectedOrganization, setSelectedOrganization] = useState<string>(ZYMO_RESEARCH_PICKLIST_NAME);

  // Subsetting
  const [selectedSubsetType, setSelectedSubsetType] = useState<string>();

  // Extraction
  const [selectedExtractionKit, setSelectedExtractionKit] = useState<string>();
  const [selectedExtractionPlatform, setSelectedExtractionPlatform] = useState<string>();
  const [selectedExtractionType, setSelectedExtractionType] = useState<string>();

  // Library Prep
  const [selectedLibraryKit, setSelectedLibraryKit] = useState<string>();

  // Sequencing
  const [selectedSequencingExpectedCoverage, setSelectedSequencingExpectedCoverage] = useState<string>();
  const [selectedSequencingPlatform, setSelectedSequencingPlatform] = useState<string>();

  // Informatics
  const [sampleSequencingJourneyDetails, setSampleSequencingJourneyDetails] = useState<
    ReadonlyArray<SampleSequencingJourneyDetails>
  >([]);
  const [selectedInformaticsPipelineId, setSelectedInformaticsPipelineId] = useState<string>('');
  const [selectedInformaticsReferenceIds, setSelectedInformaticsReferenceIds] = useState<ReadonlyArray<number>>([]);

  function handleClose(hasChanges: boolean) {
    onClose(hasChanges);
    setModalOpen(false);
    setSelectedTransition(undefined);
    setSelectedTransitionPath(undefined);
    setModalMaxWidth('xs');
    setLoadingState({ status: 'NotStarted' });
  }

  function handleTransitionTypeChange(value: any) {
    const transitionPath = value as ResearchProjectTransitionPath;

    if (!transitionPath) {
      return;
    }

    const newTransitionType = transitionPath.transitionEnum.name;

    let width = 'xs';
    if (newTransitionType === 'Informatics') {
      width = 'xl';
    } else if (newTransitionType === 'Shipping') {
      width = 'md';
    } else if (newTransitionType === 'Receiving') {
      width = 'md';
    }

    setModalMaxWidth(width as Breakpoint);
    setSelectedTransition(newTransitionType);
    setSelectedTransitionPath(transitionPath);
  }

  const setupData = useCallback(async () => {
    const organization = await GetOrganizations(accessToken);

    setOrganizations(sortBy(organization, o => o.name));
  }, [accessToken]);

  const setupSubsettingData = useCallback(async () => {
    const subsetType = await GetSubsetType(accessToken);

    setSubsetTypes(sortBy(subsetType, e => e.name));
  }, [accessToken]);

  const setupExtractionData = useCallback(async () => {
    const extractionKits = await GetExtractionKit(accessToken);
    const extractionPlatform = await GetExtractionPlatform(accessToken);
    const extractionType = await GetExtractionType(accessToken);

    setExtractionKits(sortBy(extractionKits, e => e.name));
    setExtractionPlatforms(sortBy(extractionPlatform, e => e.name));
    setExtractionTypes(sortBy(extractionType, e => e.name));
  }, [accessToken]);

  const setupLibraryPrepData = useCallback(async () => {
    const libraryKit = await GetLibraryKit(accessToken);

    setLibraryKits(sortBy(libraryKit, e => e.name));
  }, [accessToken]);

  const setupSequencingData = useCallback(async () => {
    const sequencingExpectedCoverages = await GetSequencingExpectedCoverage(accessToken);
    const sequencingPlatforms = await GetSequencingPlatform(accessToken);

    setSequencingExpectedCoverages(sortBy(sequencingExpectedCoverages, e => e.name));
    setSequencingPlatforms(sortBy(sequencingPlatforms, e => e.name));
  }, [accessToken]);

  useEffect(() => {
    if (!(accessToken && modalOpen)) {
      return;
    }

    return LoadState(setLoadingState, async () => {
      await setupData();
    });
  }, [accessToken, modalOpen, setupData]);

  useEffect(() => {
    if (!(accessToken && modalOpen)) {
      return;
    }

    return LoadState(setLoadingState, async () => {
      switch (selectedTransition) {
        case 'Subsetting':
          await setupSubsettingData();
          break;

        case 'Extraction':
          await setupExtractionData();
          break;

        case 'LibraryPrep':
          await setupLibraryPrepData();
          break;

        case 'Sequencing':
          await setupSequencingData();
          break;
      }
    });
  }, [
    accessToken,
    modalOpen,
    selectedTransition,

    setupSubsettingData,
    setupExtractionData,
    setupLibraryPrepData,
    setupSequencingData,
  ]);

  const DisableSubmitBtn = useCallback(() => {
    // Component should be slowly rewritten to take advantage of react components instead of a global component/state
    if (disableSubmitButton) {
      return true;
    }

    if (!selectedTransition || !orderedAt) {
      return true;
    }

    if (!selectedOrganization && !TransitionsNotRequiringPerformingOrganization.includes(selectedTransition)) {
      return true;
    }

    switch (selectedTransition) {
      case 'Subsetting':
        return !selectedSubsetType;

      case 'Extraction':
        return !selectedExtractionKit || !selectedExtractionPlatform || !selectedExtractionType;

      case 'LibraryPrep':
        return !selectedLibraryKit;

      case 'Sequencing':
        return !selectedSequencingExpectedCoverage || !selectedSequencingPlatform;

      case 'Informatics':
        return (
          !selectedInformaticsPipelineId ||
          selectedInformaticsReferenceIds.length === 0 ||
          sampleSequencingJourneyDetails.length === 0
        );
    }

    return false;
  }, [
    disableSubmitButton,
    selectedTransition,
    orderedAt,
    selectedOrganization,
    selectedSubsetType,
    selectedExtractionKit,
    selectedExtractionPlatform,
    selectedExtractionType,
    selectedLibraryKit,
    selectedSequencingExpectedCoverage,
    selectedSequencingPlatform,
    selectedInformaticsPipelineId,
    selectedInformaticsReferenceIds,
    sampleSequencingJourneyDetails,
  ]);

  async function CreateTransition(exportManifest = false) {
    ErrorManagement('Loading', setLoadingState, async () => {
      if (DisableSubmitBtn()) {
        setLoadingState({ status: 'Error' });
      }

      const organizationId = organizations.find(o => getOrganizationLabel(o) === selectedOrganization)?.id;

      if (!selectedTransition || !accessToken || !selectedTransitionPath) {
        setLoadingState({ status: 'Error', errorMessage: t('missingInfo') });
        return;
      }

      let transitionId = undefined;

      switch (selectedTransition) {
        case 'Shipping':
        case 'Receiving':
          transitionId = await saveFunction()(
            selectedTransition,
            researchProjectId,
            orderedAt,
            selectedTransitionPath,
            selectedSampleIds
          );

          if (!transitionId) {
            setLoadingState({ status: 'Error' });
            return;
          }

          break;

        case 'ReBiobanking':
        case 'Delivered':
        case 'Generic':
        case 'GenericWithSampleOutput':
        case 'ReadyForDelivery':
          transitionId = await CreateSampleTrackingTransition(
            {
              researchProjectId: researchProjectId,
              orderedAt: orderedAt.toJSON(),
              configuredTransitionId: selectedTransitionPath.configuredTransitionId,
            },
            selectedSampleIds,
            selectedTransition,
            accessToken
          );
          break;

        case 'Subsetting':
          if (!selectedSubsetType || !organizationId) {
            setLoadingState({ status: 'Error', errorMessage: t('missingInfo') });
            return;
          }

          const subsetTypeName = subsetTypes.find(e => e.displayName === selectedSubsetType)?.name;

          if (!subsetTypeName) {
            setLoadingState({ status: 'Error', errorMessage: t('missingInfo') });
            return;
          }

          transitionId = await CreateSampleTrackingSubsettingTransition(
            {
              researchProjectId: researchProjectId,
              orderedAt: orderedAt.toJSON(),
              organizationId: organizationId,
              subsettingType: subsetTypeName,
              configuredTransitionId: selectedTransitionPath.configuredTransitionId,
            },
            selectedSampleIds,
            accessToken
          );
          break;

        case 'Extraction':
          if (!selectedExtractionKit || !selectedExtractionPlatform || !selectedExtractionType || !organizationId) {
            setLoadingState({ status: 'Error', errorMessage: `${t('missingInfo')} for Extraction` });
            return;
          }

          const extractionKitId = extractionKits.find(e => e.name === selectedExtractionKit)?.extractionKitId;
          const extractionTypeName = extractionTypes.find(e => e.displayName === selectedExtractionType)?.name;
          const extractionPlatformId = extractionPlatforms.find(
            e => e.name === selectedExtractionPlatform
          )?.extractionPlatformId;

          if (!extractionKitId || !extractionPlatformId || !extractionTypeName) {
            setLoadingState({ status: 'Error', errorMessage: `${t('missingInfo')} for Extraction` });
            return;
          }

          transitionId = await CreateSampleTrackingExtractionTransition(
            {
              researchProjectId: researchProjectId,
              extractionKitId: extractionKitId,
              extractionPlatformId: extractionPlatformId,
              extractionType: extractionTypeName,
              orderedAt: orderedAt.toJSON(),
              organizationId: organizationId,
              configuredTransitionId: selectedTransitionPath.configuredTransitionId,
            },
            selectedSampleIds,
            accessToken
          );
          break;

        case 'LibraryPrep':
          if (!selectedLibraryKit || !organizationId) {
            setLoadingState({ status: 'Error', errorMessage: `${t('missingInfo')} for Library Prep` });
            return;
          }

          const libraryKitId = libraryKits.find(e => e.name === selectedLibraryKit)?.libraryKitId;

          if (!libraryKitId || !organizationId) {
            setLoadingState({ status: 'Error', errorMessage: `${t('missingInfo')} for Library Prep` });
            return;
          }

          transitionId = await CreateSampleTrackingLibraryPrepTransition(
            {
              researchProjectId: researchProjectId,
              libraryKitId: libraryKitId,
              orderedAt: orderedAt.toJSON(),
              organizationId: organizationId,
              configuredTransitionId: selectedTransitionPath.configuredTransitionId,
            },
            selectedSampleIds,
            accessToken
          );
          break;

        case 'Sequencing':
          if (!selectedSequencingExpectedCoverage || !selectedSequencingPlatform || !organizationId) {
            setLoadingState({ status: 'Error', errorMessage: `${t('missingInfo')} for Sequencing` });
            return;
          }

          const sequencingPlatformId = sequencingPlatforms.find(
            e => e.name === selectedSequencingPlatform
          )?.sequencingPlatformId;

          if (!sequencingPlatformId) {
            setLoadingState({ status: 'Error', errorMessage: `${t('missingInfo')} for Sequencing` });
            return;
          }

          transitionId = await CreateSampleTrackingSequencingTransition(
            {
              researchProjectId: researchProjectId,
              orderedAt: orderedAt.toJSON(),
              organizationId: organizationId,
              sequencingExpectedCoverage: selectedSequencingExpectedCoverage,
              sequencingPlatformId: sequencingPlatformId,
              configuredTransitionId: selectedTransitionPath.configuredTransitionId,
            },
            selectedSampleIds,
            accessToken
          );
          break;

        case 'Informatics':
          if (!selectedInformaticsPipelineId || !selectedInformaticsReferenceIds) {
            setLoadingState({ status: 'Error', errorMessage: `${t('missingInfo')} for Informatics` });
            return;
          }

          transitionId = await CreateSampleTrackingInformaticsTransition(
            {
              researchProjectId: researchProjectId,
              orderedAt: orderedAt.toJSON(),
              sampleJourneyDetails: sampleSequencingJourneyDetails,
              configuredTransitionId: selectedTransitionPath.configuredTransitionId,
              pipelineId: selectedInformaticsPipelineId,
              referenceTypeIds: selectedInformaticsReferenceIds,
            },
            selectedSampleIds,
            accessToken
          );
          break;

        default:
          setLoadingState({ status: 'Error', errorMessage: `Unknown Transition Type: ${selectedTransition}` });
          return;
      }

      if (exportManifest) {
        await ExportTransitionManifest(researchProjectId, transitionId, accessToken);
      }

      handleClose(true);
    });
  }

  return (
    <>
      <DialogOpenButton
        title={t('createOrder')}
        disabled={selectedSampleIds.length === 0}
        onClick={() => setModalOpen(true)}
      />
      <Dialog
        fullWidth
        maxWidth={modalMaxWidth}
        open={modalOpen}
        scroll={selectedTransition === 'Informatics' ? 'paper' : 'body'}
        PaperProps={{
          sx:
            selectedTransition === 'Informatics'
              ? { minHeight: '90%', display: 'flex', flexGrow: 1, flexDirection: 'column' }
              : {},
        }}
      >
        <DialogTitle>
          <DialogCloseButton onClick={() => handleClose(false)} />
          {t('createOrder')}
          <Typography variant='subtitle1'>{t('createOrderInfo')}</Typography>
          <Typography variant='subtitle1'>
            {selectedSampleIds.length} {t('samplesPlural')}
          </Typography>
        </DialogTitle>
        <DialogContent sx={{ display: 'flex', flexGrow: 1, flexDirection: 'column' }}>
          <FlexTableBox
            sx={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              height: '100%',
              justifyContent: 'space-between',
              gap: 0,
              flexGrow: 1,
            }}
          >
            <FlexBox flexDirection={modalMaxWidth === 'xs' ? 'column' : 'row'} gap={'1'}>
              {!DoNotRenderBaseOptions.includes(selectedTransition ?? ('' as any)) && (
                <BaseOptions
                  {...{ transitionPaths }}
                  orderedAtProps={[orderedAt, setOrderedAt]}
                  width={modalMaxWidth === 'xs' ? '100%' : '25%'}
                  orderTypesMarginRight={modalMaxWidth === 'xs' ? 0 : 1}
                  fullWidth={modalMaxWidth === 'xs'}
                  handleTransitionTypeChange={handleTransitionTypeChange}
                  selectedTransitionPathProps={[selectedTransitionPath, setSelectedTransitionPath]}
                />
              )}
            </FlexBox>

            {selectedTransition && !TransitionsNotRequiringPerformingOrganization.includes(selectedTransition) && (
              <>
                <AutocompleteWrapper
                  data-testid='create-transition-organization'
                  fullWidth
                  options={organizations.map(o => getOrganizationLabel(o))}
                  renderInput={params => <TextField {...params} label='Performing Organization *' margin='normal' />}
                  onChange={(event, value) => setSelectedOrganization(value ?? '')}
                  defaultValue={getOrganizationLabel(
                    find(organizations, i => getOrganizationLabel(i) === selectedOrganization ?? '')
                  )}
                />
              </>
            )}

            {selectedTransition === 'Shipping' && (
              <>
                <ShippingOptions
                  {...{ transitionPaths }}
                  loadingProps={[loadingState, setLoadingState]}
                  orderedAtProps={[orderedAt, setOrderedAt]}
                  handleTransitionTypeChange={handleTransitionTypeChange}
                  selectedTransitionPathProps={[selectedTransitionPath, setSelectedTransitionPath]}
                  disableSubmitButtonProps={[disableSubmitButton, setDisableSubmitButton]}
                  saveFunctionProps={[saveFunction, setSaveFunction]}
                />
              </>
            )}

            {selectedTransition === 'Receiving' && (
              <>
                <ReceivingOptions
                  {...{ transitionPaths }}
                  loadingProps={[loadingState, setLoadingState]}
                  orderedAtProps={[orderedAt, setOrderedAt]}
                  handleTransitionTypeChange={handleTransitionTypeChange}
                  selectedTransitionPathProps={[selectedTransitionPath, setSelectedTransitionPath]}
                  disableSubmitButtonProps={[disableSubmitButton, setDisableSubmitButton]}
                  saveFunctionProps={[saveFunction, setSaveFunction]}
                />
              </>
            )}

            {selectedTransition === 'Subsetting' && (
              <>
                <Autocomplete
                  data-testid='create-transition-subset-types'
                  fullWidth
                  options={subsetTypes.map(e => e.displayName)}
                  renderInput={params => <TextField {...params} label='Subset Type *' margin='normal' />}
                  onChange={(event, value) => setSelectedSubsetType(value ?? '')}
                />
              </>
            )}

            {selectedTransition === 'Extraction' && (
              <>
                <Autocomplete
                  data-testid='create-transition-extraction-types'
                  fullWidth
                  options={extractionTypes.map(e => e.displayName)}
                  renderInput={params => <TextField {...params} label='Extraction Type *' margin='normal' />}
                  onChange={(event, value) => setSelectedExtractionType(value ?? '')}
                />
                <Autocomplete
                  data-testid='create-transition-extraction-kits'
                  fullWidth
                  options={extractionKits.map(e => e.name)}
                  renderInput={params => <TextField {...params} label='Extraction Kit *' margin='normal' />}
                  onChange={(event, value) => setSelectedExtractionKit(value ?? '')}
                />
                <Autocomplete
                  data-testid='create-transition-extraction-platforms'
                  fullWidth
                  options={extractionPlatforms.map(e => e.name)}
                  renderInput={params => <TextField {...params} label='Extraction Platform *' margin='normal' />}
                  onChange={(event, value) => setSelectedExtractionPlatform(value ?? '')}
                />
              </>
            )}

            {selectedTransition === 'LibraryPrep' && (
              <>
                <Autocomplete
                  data-testid='create-transition-library-kits'
                  fullWidth
                  options={libraryKits.map(e => e.name)}
                  renderInput={params => <TextField {...params} label='Library Kit *' margin='normal' />}
                  onChange={(event, value) => setSelectedLibraryKit(value ?? '')}
                />
              </>
            )}

            {selectedTransition === 'Sequencing' && (
              <>
                <Autocomplete
                  data-testid='create-transition-sequencing-platforms'
                  fullWidth
                  options={sequencingPlatforms.map(e => e.name)}
                  renderInput={params => <TextField {...params} label='Sequencing Platform *' margin='normal' />}
                  onChange={(event, value) => setSelectedSequencingPlatform(value ?? '')}
                />
                <Autocomplete
                  data-testid='create-transition-sequencing-expected-coverage'
                  fullWidth
                  options={sequencingExpectedCoverages.map(e => e.name)}
                  renderInput={params => <TextField {...params} label='Expected Coverage *' margin='normal' />}
                  onChange={(event, value) => setSelectedSequencingExpectedCoverage(value ?? '')}
                />
              </>
            )}

            {selectedTransition === 'Informatics' && (
              <InformaticsOptions
                {...{ selectedSamples, modalMaxWidth }}
                loadingProps={[loadingState, setLoadingState]}
                informaticsPipelineProps={[selectedInformaticsPipelineId, setSelectedInformaticsPipelineId]}
                informaticsReferenceProps={[selectedInformaticsReferenceIds, setSelectedInformaticsReferenceIds]}
                sampleSequencingJourneyDetailsProps={[
                  sampleSequencingJourneyDetails,
                  setSampleSequencingJourneyDetails,
                ]}
              />
            )}

            {selectedTransition === 'ReadyForDelivery' && <></>}

            {selectedTransition === 'Delivered' && <></>}
          </FlexTableBox>
        </DialogContent>
        <LoadingIndicator loadingState={loadingState} margin={'LR'} />
        <ErrorIndicator loadingState={loadingState} />
        <DialogActions>
          <CancelButton onClick={() => handleClose(false)} />
          {selectedTransition !== 'Informatics' && (
            <>
              <PrimaryButton
                onClick={() => CreateTransition(false)}
                disabled={loadingState.status === 'Loading' || DisableSubmitBtn()}
              >
                {t('submitOrder')}
              </PrimaryButton>
              <PrimaryButton
                onClick={() => CreateTransition(true)}
                disabled={loadingState.status === 'Loading' || DisableSubmitBtn()}
              >
                {t('submitAndExportOrder')}
              </PrimaryButton>
            </>
          )}
          {selectedTransition === 'Informatics' && (
            <>
              <VerifyInformaticsTransitionModal
                buttonTitle={t('submitOrder')}
                numSamples={selectedSampleIds.length}
                onVerify={() => CreateTransition(false)}
                disableSubmitButton={loadingState.status === 'Loading' || DisableSubmitBtn()}
              />
              <VerifyInformaticsTransitionModal
                buttonTitle={t('submitAndExportOrder')}
                numSamples={selectedSampleIds.length}
                onVerify={() => CreateTransition(true)}
                disableSubmitButton={loadingState.status === 'Loading' || DisableSubmitBtn()}
              />
            </>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

const InformaticsOptions = ({
  selectedSamples,
  loadingProps,
  modalMaxWidth,
  informaticsPipelineProps,
  informaticsReferenceProps,
  sampleSequencingJourneyDetailsProps,
}: {
  selectedSamples: ReadonlyArray<SelectedSampleInfo>;
  loadingProps: LoadingProps;
  modalMaxWidth: Breakpoint;
  informaticsPipelineProps: UseState<string>;
  informaticsReferenceProps: UseState<ReadonlyArray<number>>;
  sampleSequencingJourneyDetailsProps: UseState<ReadonlyArray<SampleSequencingJourneyDetails>>;
}) => {
  const { t } = useMemoTranslation();
  const { accessToken } = useAuth();
  const [, setLoadingState] = loadingProps;

  const [informaticsPipelines, setInformaticsPipelines] = useState<ReadonlyArray<InformaticsPipeline>>([]);

  const [, setSelectedInformaticsPipelineId] = informaticsPipelineProps;
  const [selectedInformaticsPipeline, setSelectedInformaticsPipeline] = useState<InformaticsPipeline | null>();
  const [, setSelectedInformaticsReferences] = informaticsReferenceProps;
  const [sampleSequencingJourneyDetails, setSampleSequencingJourneyDetails] = sampleSequencingJourneyDetailsProps;

  useEffect(() => {
    ErrorManagement('Loading', setLoadingState, async () => {
      const [pipelines, sampleSequencingJourneyDetailsData] = await Promise.all([
        await GetPipelines(accessToken),
        await GetSampleSequencingJourneyDetails(
          map(selectedSamples, i => i.sampleId),
          accessToken
        ),
      ]);

      setInformaticsPipelines(pipelines);
      setSampleSequencingJourneyDetails(sampleSequencingJourneyDetailsData);
    });
  }, [accessToken, selectedSamples, setSampleSequencingJourneyDetails, setLoadingState]);

  useEffect(() => {
    setSelectedInformaticsReferences([]);
  }, [selectedInformaticsPipeline, setSelectedInformaticsReferences]);

  return (
    <>
      <FlexBox gap={1}>
        <Autocomplete
          data-testid='create-transition-informatics-pipelines'
          fullWidth={modalMaxWidth === 'xs'}
          options={informaticsPipelines}
          getOptionLabel={option => `${option.organismTypeName} - ${option.name}`}
          renderInput={params => <TextField {...params} label='Pipeline *' margin='normal' />}
          onChange={(event, value) => {
            setSelectedInformaticsPipelineId(value?.pipelineId ?? '');
            setSelectedInformaticsPipeline(value);
          }}
          sx={{
            width: modalMaxWidth === 'xs' ? '100%' : '25%',
          }}
          isOptionEqualToValue={(option, value) => option.pipelineId === value.pipelineId}
        />
        <Autocomplete
          data-testid='create-transition-informatics-references'
          fullWidth={modalMaxWidth === 'xs'}
          options={selectedInformaticsPipeline?.supportedReferences ?? []}
          multiple
          getOptionLabel={option => option.name}
          onChange={(event, value) => setSelectedInformaticsReferences(map(value ?? [], i => i.referenceTypeId))}
          sx={{
            width: modalMaxWidth === 'xs' ? '100%' : '25%',
          }}
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <Checkbox
                icon={<CheckBoxOutlineBlankIcon fontSize='small' />}
                checkedIcon={<CheckBoxIcon fontSize='small' />}
                style={{ marginRight: 8, padding: 0 }}
                checked={selected}
              />
              {option.name}
            </li>
          )}
          renderInput={params => <TextField {...params} label='References *' margin='normal' />}
          isOptionEqualToValue={(option, value) => option.referenceTypeId === value.referenceTypeId}
          disabled={selectedInformaticsPipeline === undefined}
        />
      </FlexBox>
      <FlexTableBox sx={{ mt: 1.5 }} flexGrow={1}>
        <CreateInformaticsTransitionGrid selectedSamples={selectedSamples} data={sampleSequencingJourneyDetails} />
      </FlexTableBox>
      <Typography display='flex' alignItems='center' mt={2} color='error'>
        <WarningAmberIcon />
        <Typography ml={1}>
          Creating order for {selectedSamples.length} {t('samplesPlural')}. This expensive operation cannot be undone.
        </Typography>
      </Typography>
    </>
  );
};

const BaseOptions = ({
  orderedAtProps,
  selectedTransitionPathProps,
  handleTransitionTypeChange,
  transitionPaths,
  fullWidth,
  width,
  orderTypesMarginRight,
}: {
  orderedAtProps: UseState<Date>;
  selectedTransitionPathProps: UseState<ResearchProjectTransitionPath | undefined>;
  handleTransitionTypeChange: (value: any) => void;
  transitionPaths: ReadonlyArray<ResearchProjectTransitionPath>;
  fullWidth: boolean;
  width: string;
  orderTypesMarginRight: number;
}) => {
  const [orderedAt, setOrderedAt] = orderedAtProps;
  const [selectedTransitionPath] = selectedTransitionPathProps;

  return (
    <>
      <Autocomplete
        data-testid='create-transition-order-types'
        fullWidth={fullWidth}
        options={orderBy(transitionPaths, i => i.transitionOrder)}
        getOptionLabel={option => option.displayName}
        renderInput={params => <TextField {...params} label='Order Type *' margin='normal' />}
        onChange={(event, value) => handleTransitionTypeChange(value)}
        sx={{
          width: width,
          marginRight: orderTypesMarginRight,
        }}
        isOptionEqualToValue={(option, value) => option?.configuredTransitionId === value?.configuredTransitionId}
        value={
          find(transitionPaths, i => i.configuredTransitionId === selectedTransitionPath?.configuredTransitionId) ??
          null
        }
      />
      <DatePicker
        onChange={value => setOrderedAt(value ?? orderedAt)}
        renderInput={params => (
          <TextField
            {...params}
            data-testid='create-transition-ordered-at'
            label='Ordered At *'
            margin='normal'
            sx={{ width: width }}
          />
        )}
        value={orderedAt}
      />
    </>
  );
};

const ShippingOptions = ({
  loadingProps,
  orderedAtProps,
  selectedTransitionPathProps,
  transitionPaths,
  handleTransitionTypeChange,
  disableSubmitButtonProps,
  saveFunctionProps,
}: {
  loadingProps: LoadingProps;
  orderedAtProps: UseState<Date>;
  selectedTransitionPathProps: UseState<ResearchProjectTransitionPath | undefined>;
  transitionPaths: ReadonlyArray<ResearchProjectTransitionPath>;
  handleTransitionTypeChange: (value: any) => void;
  disableSubmitButtonProps: UseState<boolean>;
  saveFunctionProps: UseState<SaveFunctionWrapper>;
}) => {
  const { t } = useMemoTranslation();
  const { accessToken } = useAuth();
  const [, setLoadingState] = loadingProps;
  const [, setDisableSubmitButton] = disableSubmitButtonProps;
  const [, setSaveFunction] = saveFunctionProps;

  const [shippingCarriers, setShippingCarriers] = useState<ReadonlyArray<ShippingCarrier>>([]);
  const [shippingTemperatures, setShippingTemperatures] = useState<ReadonlyArray<ShippingTemperature>>([]);
  const [volumeUnits, setVolumeUnits] = useState<ReadonlyArray<VolumeUnit>>([]);
  const [shippingContainers, setShippingContainers] = useState<ReadonlyArray<ShippingContainer>>([]);
  const [organizations, setOrganizations] = useState<ReadonlyArray<Organization>>([]);

  const [selectedShippingSender, setSelectedShippingSender] = useState<string>();
  const [selectedShippingReceiver, setSelectedShippingReceiver] = useState<string>();
  const [selectedShipByDate, setSelectedShipByDate] = useState<Date | null>(null);
  const [selectedShippingCarrier, setSelectedShippingCarrier] = useState<string>();
  const [selectedTrackingNumber, setSelectedTrackingNumber] = useState<string>();
  const [selectedShippingStorageTemperature, setSelectedShippingStorageTemperature] = useState<string>();
  const [selectedDestinationStorageTemperature, setSelectedDestinationStorageTemperature] = useState<string>();
  const [selectedMinimumSampleVolume, setSelectedMinimumSampleVolume] = useState<number>();
  const [selectedMinimumSampleVolumeUnit, setSelectedMinimumSampleVolumeUnit] = useState<string>();
  const [selectedShippingPrimaryContainer, setSelectedShippingPrimaryContainer] = useState<string>();
  const [selectedShippingComment, setSelectedShippingComment] = useState<string>();

  useEffect(() => {
    ErrorManagement('Loading', setLoadingState, async () => {
      const [carriers, temperatures, units, containers, orgs] = await Promise.all([
        await GetShippingCarrier(accessToken),
        await GetShippingTemperature(accessToken),
        await GetVolumeUnit(accessToken),
        await GetShippingContainer(accessToken),
        await GetOrganizations(accessToken),
      ]);

      setShippingCarriers(carriers);
      setShippingTemperatures(temperatures);
      setVolumeUnits(units);
      setShippingContainers(containers);
      setOrganizations(sortBy(orgs, o => o.name));
    });
  }, [accessToken, setLoadingState]);

  useEffect(() => {
    setDisableSubmitButton(!selectedShippingSender || !selectedShippingReceiver);
  }, [selectedShippingSender, selectedShippingReceiver, setDisableSubmitButton]);

  useEffect(() => {
    const functionToReturn: SaveFunction = async (
      currentTransitionType,
      researchProjectId,
      orderedAt,
      selectedTransitionPath,
      selectedSampleIds
    ) => {
      if (currentTransitionType !== 'Shipping') {
        setLoadingState({ status: 'Error' });
        return;
      }

      if (!accessToken || !selectedShippingSender || !selectedShippingReceiver) {
        setLoadingState({ status: 'Error', errorMessage: t('missingInfo') });
        return;
      }

      return await CreateSampleTrackingShippingTransition(
        {
          researchProjectId: researchProjectId,
          orderedAt: orderedAt.toJSON(),
          configuredTransitionId: selectedTransitionPath.configuredTransitionId,
          comment: selectedShippingComment,
          destinationStorageTemperatureId: selectedDestinationStorageTemperature,
          minimumSampleVolume: selectedMinimumSampleVolume,
          minimumSampleVolumeUnitId: selectedMinimumSampleVolumeUnit,
          primaryShippingContainerId: selectedShippingPrimaryContainer,
          receiverOrganizationId: selectedShippingReceiver,
          senderOrganizationId: selectedShippingSender,
          shipByDate: selectedShipByDate?.toJSON(),
          shippingCarrierId: selectedShippingCarrier,
          shippingStorageTemperatureId: selectedShippingStorageTemperature,
          trackingNumber: selectedTrackingNumber,
        },
        selectedSampleIds,
        accessToken
      );
    };

    setSaveFunction(() => () => functionToReturn);
  }, [
    setSaveFunction,
    setLoadingState,
    accessToken,
    t,
    selectedShippingComment,
    selectedDestinationStorageTemperature,
    selectedMinimumSampleVolume,
    selectedMinimumSampleVolumeUnit,
    selectedShippingPrimaryContainer,
    selectedShippingReceiver,
    selectedShippingSender,
    selectedShipByDate,
    selectedShippingCarrier,
    selectedShippingStorageTemperature,
    selectedTrackingNumber,
  ]);

  const getDefaultOrganization = useCallback(
    (setter: UseStateSetter<string | undefined>, currentValue: string | undefined) => {
      const defaultValue = find(organizations, i => getOrganizationLabel(i) === GALATEA_BIO_PICKLIST_NAME ?? '');
      if (currentValue !== undefined || currentValue === defaultValue?.id) {
        return defaultValue;
      }

      setter(defaultValue?.id);
      return defaultValue;
    },
    [organizations]
  );

  return (
    <>
      <FlexBox gap={1} flexDirection={'row'} flexWrap={'wrap'}>
        <BaseOptions
          {...{ transitionPaths, orderedAtProps, selectedTransitionPathProps }}
          width={'49%'}
          orderTypesMarginRight={0}
          fullWidth={false}
          handleTransitionTypeChange={handleTransitionTypeChange}
        />
        <AutocompleteWrapper
          data-testid='create-transition-shipping-sender'
          options={organizations}
          getOptionLabel={option => `${option.name} (${option.type})`}
          renderInput={params => <TextField {...params} label={t('sendingOrganizationRequired')} margin='normal' />}
          onChange={(event, value) => {
            setSelectedShippingSender(value?.id ?? undefined);
          }}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          sx={{ width: '49%' }}
          defaultValue={getDefaultOrganization(setSelectedShippingSender, selectedShippingSender)}
          waitForDefaultValue={true}
        />
        <AutocompleteWrapper
          data-testid='create-transition-shipping-receiver'
          options={organizations}
          getOptionLabel={option => `${option.name} (${option.type})`}
          renderInput={params => <TextField {...params} label={t('receivingOrganizationRequired')} margin='normal' />}
          onChange={(event, value) => {
            setSelectedShippingReceiver(value?.id ?? undefined);
          }}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          sx={{ width: '49%' }}
          defaultValue={getDefaultOrganization(setSelectedShippingReceiver, selectedShippingReceiver)}
          waitForDefaultValue={true}
        />
        <AutocompleteWrapper
          data-testid='create-transition-shipping-carrier'
          options={shippingCarriers}
          getOptionLabel={option => option.name}
          renderInput={params => <TextField {...params} label={t('shippingCarrier')} margin='normal' />}
          onChange={(event, value) => {
            setSelectedShippingCarrier(value?.shippingCarrierId ?? undefined);
          }}
          isOptionEqualToValue={(option, value) => option.shippingCarrierId === value.shippingCarrierId}
          sx={{ width: '49%' }}
        />
        <DatePicker
          onChange={value => setSelectedShipByDate(value ?? selectedShipByDate)}
          renderInput={params => (
            <TextField
              {...params}
              data-testid='create-transition-shipping-ship-by-date'
              label={t('shipByDate')}
              margin='normal'
              sx={{ width: '49%' }}
            />
          )}
          value={selectedShipByDate}
        />
        <TextField
          data-testid='create-transition-shipping-minimum-sample-volume'
          label={t('minimumSampleVolume')}
          onChange={event => {
            setSelectedMinimumSampleVolume(Number(event.target.value));
          }}
          type='number'
          margin='normal'
          sx={{ width: '49%' }}
        />
        <AutocompleteWrapper
          data-testid='create-transition-shipping-minimum-sample-volume-unit'
          options={volumeUnits}
          getOptionLabel={option => `${option.name} (${option.unitOfMeasure})`}
          renderInput={params => <TextField {...params} label={t('minimumSampleVolumeUnit')} margin='normal' />}
          onChange={(event, value) => {
            setSelectedMinimumSampleVolumeUnit(value?.volumeUnitId ?? undefined);
          }}
          isOptionEqualToValue={(option, value) => option.volumeUnitId === value.volumeUnitId}
          sx={{ width: '49%' }}
        />
        <AutocompleteWrapper
          data-testid='create-transition-shipping-storage-temperature'
          options={shippingTemperatures}
          getOptionLabel={option => option.name}
          renderInput={params => <TextField {...params} label={t('shippingStorageTemperature')} margin='normal' />}
          onChange={(event, value) => {
            setSelectedShippingStorageTemperature(value?.shippingTemperatureId ?? undefined);
          }}
          isOptionEqualToValue={(option, value) => option.shippingTemperatureId === value.shippingTemperatureId}
          sx={{ width: '49%' }}
        />
        <AutocompleteWrapper
          data-testid='create-transition-shipping-destination-storage-temperature'
          options={shippingTemperatures}
          getOptionLabel={option => option.name}
          renderInput={params => <TextField {...params} label={t('destinationStorageTemperature')} margin='normal' />}
          onChange={(event, value) => {
            setSelectedDestinationStorageTemperature(value?.shippingTemperatureId ?? undefined);
          }}
          isOptionEqualToValue={(option, value) => option.shippingTemperatureId === value.shippingTemperatureId}
          sx={{ width: '49%' }}
        />
        <AutocompleteWrapper
          data-testid='create-transition-shipping-primary-container'
          options={shippingContainers}
          getOptionLabel={option => option.name}
          renderInput={params => <TextField {...params} label={t('primaryContainer')} margin='normal' />}
          onChange={(event, value) => {
            setSelectedShippingPrimaryContainer(value?.shippingContainerId ?? undefined);
          }}
          isOptionEqualToValue={(option, value) => option.shippingContainerId === value.shippingContainerId}
          sx={{ width: '49%' }}
        />
        <TextField
          data-testid='create-transition-shipping-tracking-number'
          label={t('trackingNumber')}
          onChange={event => {
            setSelectedTrackingNumber(event.target.value);
          }}
          type='text'
          margin='normal'
          sx={{ width: '49%' }}
        />
        <TextField
          data-testid='create-transition-shipping-comment'
          label={t('comments')}
          onChange={event => {
            setSelectedShippingComment(event.target.value);
          }}
          type='text'
          margin='normal'
          sx={{ width: '100%' }}
        />
      </FlexBox>
    </>
  );
};

const ReceivingOptions = ({
  loadingProps,
  orderedAtProps,
  selectedTransitionPathProps,
  transitionPaths,
  handleTransitionTypeChange,
  disableSubmitButtonProps,
  saveFunctionProps,
}: {
  loadingProps: LoadingProps;
  orderedAtProps: UseState<Date>;
  selectedTransitionPathProps: UseState<ResearchProjectTransitionPath | undefined>;
  transitionPaths: ReadonlyArray<ResearchProjectTransitionPath>;
  handleTransitionTypeChange: (value: any) => void;
  disableSubmitButtonProps: UseState<boolean>;
  saveFunctionProps: UseState<SaveFunctionWrapper>;
}) => {
  const { t } = useMemoTranslation();
  const { accessToken } = useAuth();
  const [, setLoadingState] = loadingProps;
  const [, setDisableSubmitButton] = disableSubmitButtonProps;
  const [, setSaveFunction] = saveFunctionProps;

  const [shippingCarriers, setShippingCarriers] = useState<ReadonlyArray<ShippingCarrier>>([]);
  const [shippingTemperatures, setShippingTemperatures] = useState<ReadonlyArray<ShippingTemperature>>([]);
  const [organizations, setOrganizations] = useState<ReadonlyArray<Organization>>([]);

  const [selectedReceivingSender, setSelectedReceivingSender] = useState<string>();
  const [selectedReceivingReceiver, setSelectedReceivingReceiver] = useState<string>();
  const [selectedReceivingCarrier, setSelectedReceivingCarrier] = useState<string>();
  const [selectedTrackingNumber, setSelectedTrackingNumber] = useState<string>();
  const [selectedShippingStorageTemperature, setSelectedShippingStorageTemperature] = useState<string>();
  const [selectedDestinationStorageTemperature, setSelectedDestinationStorageTemperature] = useState<string>();
  const [selectedReceivingComment, setSelectedReceivingComment] = useState<string>();
  const [selectedReceivingDate, setSelectedReceivingDate] = useState<Date | null>(null);

  useEffect(() => {
    ErrorManagement('Loading', setLoadingState, async () => {
      const [carriers, temperatures, orgs] = await Promise.all([
        await GetShippingCarrier(accessToken),
        await GetShippingTemperature(accessToken),
        await GetOrganizations(accessToken),
      ]);

      setShippingCarriers(carriers);
      setShippingTemperatures(temperatures);
      setOrganizations(sortBy(orgs, o => o.name));
    });
  }, [accessToken, setLoadingState]);

  useEffect(() => {
    setDisableSubmitButton(!selectedReceivingSender || !selectedReceivingReceiver);
  }, [selectedReceivingSender, selectedReceivingReceiver, setDisableSubmitButton]);

  useEffect(() => {
    const functionToReturn: SaveFunction = async (
      currentTransitionType,
      researchProjectId,
      orderedAt,
      selectedTransitionPath,
      selectedSampleIds
    ) => {
      if (currentTransitionType !== 'Receiving') {
        setLoadingState({ status: 'Error' });
        return;
      }

      if (!accessToken || !selectedReceivingSender || !selectedReceivingReceiver) {
        setLoadingState({ status: 'Error', errorMessage: t('missingInfo') });
        return;
      }

      return await CreateSampleTrackingReceivingTransition(
        {
          researchProjectId: researchProjectId,
          orderedAt: orderedAt.toJSON(),
          configuredTransitionId: selectedTransitionPath.configuredTransitionId,
          comment: selectedReceivingComment,
          destinationStorageTemperatureId: selectedDestinationStorageTemperature,
          receiverOrganizationId: selectedReceivingReceiver,
          senderOrganizationId: selectedReceivingSender,
          receivingCarrierId: selectedReceivingCarrier,
          shippingStorageTemperatureId: selectedShippingStorageTemperature,
          trackingNumber: selectedTrackingNumber,
          receivingDate: selectedReceivingDate?.toJSON(),
        },
        selectedSampleIds,
        accessToken
      );
    };

    setSaveFunction(() => () => functionToReturn);
  }, [
    setSaveFunction,
    setLoadingState,
    accessToken,
    t,
    selectedReceivingComment,
    selectedDestinationStorageTemperature,
    selectedReceivingReceiver,
    selectedReceivingSender,
    selectedReceivingCarrier,
    selectedShippingStorageTemperature,
    selectedTrackingNumber,
    selectedReceivingDate,
  ]);

  const getDefaultOrganization = useCallback(
    (setter: UseStateSetter<string | undefined>, currentValue: string | undefined) => {
      const defaultValue = find(organizations, i => getOrganizationLabel(i) === GALATEA_BIO_PICKLIST_NAME ?? '');
      if (currentValue !== undefined || currentValue === defaultValue?.id) {
        return defaultValue;
      }

      setter(defaultValue?.id);
      return defaultValue;
    },
    [organizations]
  );
  return (
    <>
      <FlexBox gap={1} flexDirection={'row'} flexWrap={'wrap'}>
        <BaseOptions
          {...{ transitionPaths, orderedAtProps, selectedTransitionPathProps }}
          width={'49%'}
          orderTypesMarginRight={0}
          fullWidth={false}
          handleTransitionTypeChange={handleTransitionTypeChange}
        />
        <AutocompleteWrapper
          data-testid='create-transition-receiving-sender'
          options={organizations}
          getOptionLabel={option => `${option.name} (${option.type})`}
          renderInput={params => <TextField {...params} label={t('sendingOrganizationRequired')} margin='normal' />}
          onChange={(event, value) => {
            setSelectedReceivingSender(value?.id ?? undefined);
          }}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          sx={{ width: '49%' }}
          defaultValue={getDefaultOrganization(setSelectedReceivingSender, selectedReceivingSender)}
          waitForDefaultValue={true}
        />
        <AutocompleteWrapper
          data-testid='create-transition-receiving-receiver'
          options={organizations}
          getOptionLabel={option => `${option.name} (${option.type})`}
          renderInput={params => <TextField {...params} label={t('receivingOrganizationRequired')} margin='normal' />}
          onChange={(event, value) => {
            setSelectedReceivingReceiver(value?.id ?? undefined);
          }}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          sx={{ width: '49%' }}
          defaultValue={getDefaultOrganization(setSelectedReceivingReceiver, selectedReceivingReceiver)}
          waitForDefaultValue={true}
        />{' '}
        <AutocompleteWrapper
          data-testid='create-transition-receiving-carrier'
          options={shippingCarriers}
          getOptionLabel={option => option.name}
          renderInput={params => <TextField {...params} label={t('shippingCarrier')} margin='normal' />}
          onChange={(event, value) => {
            setSelectedReceivingCarrier(value?.shippingCarrierId ?? undefined);
          }}
          isOptionEqualToValue={(option, value) => option.shippingCarrierId === value.shippingCarrierId}
          sx={{ width: '49%' }}
        />
        <TextField
          data-testid='create-transition-receiving-tracking-number'
          label={t('trackingNumber')}
          onChange={event => {
            setSelectedTrackingNumber(event.target.value);
          }}
          type='text'
          margin='normal'
          sx={{ width: '49%' }}
        />
        <AutocompleteWrapper
          data-testid='create-transition-receiving-storage-temperature'
          options={shippingTemperatures}
          getOptionLabel={option => option.name}
          renderInput={params => <TextField {...params} label={t('shippingStorageTemperature')} margin='normal' />}
          onChange={(event, value) => {
            setSelectedShippingStorageTemperature(value?.shippingTemperatureId ?? undefined);
          }}
          isOptionEqualToValue={(option, value) => option.shippingTemperatureId === value.shippingTemperatureId}
          sx={{ width: '49%' }}
        />
        <AutocompleteWrapper
          data-testid='create-transition-receiving-destination-storage-temperature'
          options={shippingTemperatures}
          getOptionLabel={option => option.name}
          renderInput={params => <TextField {...params} label={t('destinationStorageTemperature')} margin='normal' />}
          onChange={(event, value) => {
            setSelectedDestinationStorageTemperature(value?.shippingTemperatureId ?? undefined);
          }}
          isOptionEqualToValue={(option, value) => option.shippingTemperatureId === value.shippingTemperatureId}
          sx={{ width: '49%' }}
        />
        <DatePicker
          onChange={value => setSelectedReceivingDate(value ?? selectedReceivingDate)}
          renderInput={params => (
            <TextField
              {...params}
              data-testid='create-transition-shipping-receiving-date'
              label={t('receivingDate')}
              margin='normal'
              sx={{ width: '49%' }}
            />
          )}
          value={selectedReceivingDate}
        />
        <TextField
          data-testid='create-transition-receiving-comment'
          label={t('comments')}
          onChange={event => {
            setSelectedReceivingComment(event.target.value);
          }}
          type='text'
          margin='normal'
          sx={{ width: '100%' }}
        />
      </FlexBox>
    </>
  );
};
