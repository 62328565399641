import { appSettings } from 'AppSettings';
import { orderBy } from 'lodash';

export interface Migration {
  id?: number;
  version: string;
  category: 'Pending' | 'Complete' | 'Ignored' | 'EmptySchema' | null;
  description: string;
  installedBy?: string;
  installedOn?: string;
  success?: boolean;
  checksum?: string;
}

export async function GetMigrations(accessToken?: string): Promise<Migration[]> {
  let migrations: Migration[] = [];
  if (accessToken) {
    try {
      const response = await fetch(`${appSettings.api.endpoint}/api/v2/Migration`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      if (response.ok) {
        const values: Migration[] = await response.json();
        migrations = [...orderBy(values, ['id', 'version'], ['asc', 'asc'])];
      }
    } catch (err) {
      console.dir(err);
    }
  }
  return migrations;
}
