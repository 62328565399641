import { SampleTrackingIdMapping } from '../../data/SampleTrackingData';

export interface CheckByConfiguration {
  inputTextKey: string;
  maxParsedValues: number;
  rowBuilder: (row: string[], index: number) => SampleTrackingIdMapping;
  requireLabAssignedId: boolean;
  isSequencing: boolean;
  supportsCheckByFailedReason: boolean;
}

export const DefaultConfiguration: CheckByConfiguration = {
  inputTextKey: 'idMappingHelperTextDefault',
  maxParsedValues: 3,
  rowBuilder: BuildRowDefault,
  requireLabAssignedId: true,
  isSequencing: false,
  supportsCheckByFailedReason: false,
};
export const OutputsNotSupportConfiguration: CheckByConfiguration = {
  inputTextKey: 'idMappingHelperTextNotSupported',
  maxParsedValues: 2,
  rowBuilder: BuildRowOutputsNotSupportConfiguration,
  requireLabAssignedId: false,
  isSequencing: false,
  supportsCheckByFailedReason: false,
};
export const DefaultSupportsCheckByIssueConfiguration: CheckByConfiguration = {
  inputTextKey: 'idMappingHelperTextDefaultSupportsCheckByIssue',
  maxParsedValues: 4,
  rowBuilder: BuildRowSupportsCheckByIssueDefault,
  requireLabAssignedId: true,
  isSequencing: false,
  supportsCheckByFailedReason: true,
};
export const OutputsNotSupportedAndSupportsCheckByIssueConfiguration: CheckByConfiguration = {
  inputTextKey: 'idMappingHelperTextNotSupportedSupportsCheckByIssue',
  maxParsedValues: 3,
  rowBuilder: BuildRowOutputsNotSupportedAndSupportsCheckByIssueConfiguration,
  requireLabAssignedId: false,
  isSequencing: false,
  supportsCheckByFailedReason: true,
};
export const SequencingConfiguration: CheckByConfiguration = {
  inputTextKey: 'idMappingHelperTextSequencing',
  maxParsedValues: 5,
  rowBuilder: BuildRowSequencing,
  requireLabAssignedId: true,
  isSequencing: true,
  supportsCheckByFailedReason: false,
};

enum ParseIndex {
  sampleIdentifier = 0,
  LabAssignedSampleId = 1,
  QualityCheckStatus = 2,
  CheckByFailedReason = 3,
  R1FastqLocation = 4,
  R2FastqLocation = 5,
}

function BuildRowDefault(row: string[], index: number): SampleTrackingIdMapping {
  return {
    index: index.toString(),
    sampleIdentifier: row[ParseIndex.sampleIdentifier],
    labAssignedSampleId: row[ParseIndex.LabAssignedSampleId],
    qualityCheckStatus: row[ParseIndex.QualityCheckStatus],
  };
}
function BuildRowOutputsNotSupportConfiguration(row: string[], index: number): SampleTrackingIdMapping {
  return {
    index: index.toString(),
    sampleIdentifier: row[ParseIndex.sampleIdentifier],
    labAssignedSampleId: undefined,
    // Shifted over to left by 1
    qualityCheckStatus: row[ParseIndex.QualityCheckStatus - 1],
  };
}
function BuildRowSupportsCheckByIssueDefault(row: string[], index: number): SampleTrackingIdMapping {
  const checkByFailedReasonUnParsed = row[ParseIndex.CheckByFailedReason - 1];
  const checkByFailedReasonUn = checkByFailedReasonUnParsed?.replace(/\s/g, '');

  return {
    index: index.toString(),
    sampleIdentifier: row[ParseIndex.sampleIdentifier],
    labAssignedSampleId: row[ParseIndex.LabAssignedSampleId],
    qualityCheckStatus: row[ParseIndex.QualityCheckStatus],
    checkByFailedReason: checkByFailedReasonUn,
  };
}
function BuildRowOutputsNotSupportedAndSupportsCheckByIssueConfiguration(
  row: string[],
  index: number
): SampleTrackingIdMapping {
  const checkByFailedReasonUnParsed = row[ParseIndex.CheckByFailedReason - 1];
  const checkByFailedReasonUn = checkByFailedReasonUnParsed?.replace(/\s/g, '');

  return {
    index: index.toString(),
    sampleIdentifier: row[ParseIndex.sampleIdentifier],
    labAssignedSampleId: undefined,
    // Shifted over to left by 1
    qualityCheckStatus: row[ParseIndex.QualityCheckStatus - 1],
    checkByFailedReason: checkByFailedReasonUn,
  };
}

function BuildRowSequencing(row: string[], index: number): SampleTrackingIdMapping {
  return {
    index: index.toString(),
    sampleIdentifier: row[ParseIndex.sampleIdentifier],
    labAssignedSampleId: row[ParseIndex.LabAssignedSampleId],
    qualityCheckStatus: row[ParseIndex.QualityCheckStatus],
    // Shifted over to left by 1
    r1FastQLocation: row[ParseIndex.R1FastqLocation - 1],
    r2FastQLocation: row[ParseIndex.R2FastqLocation - 1],
  };
}
