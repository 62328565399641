import { GridColDef } from '@mui/x-data-grid-pro';
import {
  SampleTrackingCheckByFastqValidationResultPayload,
  SampleTrackingCheckByValidationResult,
} from '../../data/SampleTrackingData';
import {
  compactGridHeaderClassName,
  errorMessage,
  existingSequenceRun,
  hasExistingSequenceRun,
  labAssignedSampleId,
  mappedFromCurrentTransition,
  mappedFromSampleIdentifier,
  mappedToSampleIdentifier,
  qualityCheckStatus,
  r1FastQLocation,
  r1FastQLocationRecommendation,
  r2FastQLocation,
  r2FastQLocationRecommendation,
} from '../../util/Constants';
import React, { MutableRefObject, useMemo } from 'react';
import { LightTooltip } from '../../components/grid/cell/GridCellTooltip';
import { Typography } from '@mui/material';
import useMemoTranslation from '../../hooks/UseMemoTranslation';
import { renderSampleHierarchyGridCell } from '../../components/grid/cell/SampleHierarchyGridCell';
import { CompactGridWrapper } from '../../components/grid/CompactGridWrapper';
import { renderSampleTrackingFastQResolutionGridCellCell } from '../../components/grid/cell/SampleTrackingFastQResolutionGridCell';
import { Dictionary } from '../../util/TypeUtil';
import { find, keyBy, values } from 'lodash';
import { TransitionEnum } from '../../data/ReferenceData';
import { GridApiPro } from '@mui/x-data-grid-pro/models/gridApiPro';

export type FastQUpdateFunction = (outputId: string, r1FastQ: string, r2FastQ?: string) => void;
export type SetValidityFunction = (id: string, isValid: boolean) => void;

export interface SampleTrackingIdMappingConfirmationGridProps {
  transitionEnum: TransitionEnum;
  data: ReadonlyArray<SampleTrackingCheckByValidationResult>;
  fastQValidationData: Dictionary<SampleTrackingCheckByFastqValidationResultPayload>;
  fastQData: ReadonlyArray<FastQData>;
  onFastQUpdate: FastQUpdateFunction;
  setValidity: SetValidityFunction;
  apiRef: MutableRefObject<GridApiPro>;
}

type Row = SampleTrackingCheckByValidationResult &
  FastQData & {
    id: number;
    outputId: string;
  };

export type FastQData = {
  r1FastQLocation: string;
  r2FastQLocation?: string;
  outputId: string;
};

export const SampleTrackingIdMappingConfirmationGrid = ({
  transitionEnum,
  data,
  fastQValidationData,
  fastQData,
  onFastQUpdate,
  setValidity,
  apiRef,
}: SampleTrackingIdMappingConfirmationGridProps) => {
  const columns = useColumns(transitionEnum, fastQValidationData, onFastQUpdate, setValidity);
  const rows = useRows(data, fastQValidationData, fastQData);

  return (
    <CompactGridWrapper
      apiRef={apiRef}
      rows={rows}
      columns={columns}
      disableColumnFilter={true}
      disableColumnMenu={true}
      initialState={{
        sorting: {
          sortModel: [{ field: errorMessage, sort: 'asc' }],
        },
        columns: {
          columnVisibilityModel: {
            r1FastQLocationRecommendation: false,
            r2FastQLocationRecommendation: false,
            existingSequenceRun: false,
          },
        },
      }}
      density='compact'
      disableRowSelectionOnClick
    />
  );
};

const useColumns = (
  transitionEnum: TransitionEnum,
  fastQValidationData: Dictionary<SampleTrackingCheckByFastqValidationResultPayload>,
  onFastQUpdate: FastQUpdateFunction,
  setValidity: SetValidityFunction
): GridColDef[] => {
  const { t } = useMemoTranslation();

  return useMemo(() => {
    const sequencingCols: GridColDef[] =
      transitionEnum.name === 'Sequencing'
        ? [
            {
              field: r1FastQLocation,
              headerName: t(r1FastQLocation),
              headerAlign: 'left',
              align: 'left',
              minWidth: 200,
              flex: 2,
              renderCell: params =>
                renderSampleTrackingFastQResolutionGridCellCell({
                  ...params,
                  fastQValidationData: fastQValidationData,
                  type: 'R1',
                  onFastQUpdate: onFastQUpdate,
                  setValidity: setValidity,
                }),
              valueFormatter: ({ value }) => value,
            },
            {
              field: r2FastQLocation,
              headerName: t(r2FastQLocation),
              headerAlign: 'left',
              align: 'left',
              minWidth: 200,
              flex: 2,
              renderCell: params =>
                renderSampleTrackingFastQResolutionGridCellCell({
                  ...params,
                  fastQValidationData: fastQValidationData,
                  type: 'R2',
                  onFastQUpdate: onFastQUpdate,
                  setValidity: setValidity,
                }),
              valueFormatter: ({ value }) => value,
            },
            {
              field: r1FastQLocationRecommendation,
              headerName: t(r1FastQLocationRecommendation),
              headerAlign: 'left',
              align: 'left',
              minWidth: 200,
              flex: 2,
              valueFormatter: ({ value }) => value,
            },
            {
              field: r2FastQLocationRecommendation,
              headerName: t(r2FastQLocationRecommendation),
              headerAlign: 'left',
              align: 'left',
              minWidth: 200,
              flex: 2,
              valueFormatter: ({ value }) => value,
            },
            {
              field: existingSequenceRun,
              headerName: t(hasExistingSequenceRun),
              headerAlign: 'left',
              align: 'left',
              minWidth: 200,
              flex: 2,
              valueFormatter: ({ value }) => !!value,
            },
          ]
        : [];

    return [
      {
        field: mappedFromSampleIdentifier,
        headerName: t('inputId'),
        headerClassName: compactGridHeaderClassName,
        headerAlign: 'left',
        align: 'left',
        minWidth: 300,
        flex: 0.9,
        editable: true,
        renderCell: params => {
          const errorMessages = params.row.errorMessage;

          if ((errorMessages ?? []).length > 0) {
            return (
              <LightTooltip
                title={
                  Array.isArray(errorMessages) ? errorMessages.map((s: any) => t(s)).join('\n') : errorMessages ?? ''
                }
              >
                <Typography variant={'h5'} sx={{ fontSize: 'inherit' }}>
                  {Array.isArray(errorMessages) ? errorMessages.length : 1} Error(s)
                </Typography>
              </LightTooltip>
            );
          }

          return renderSampleHierarchyGridCell({
            ...params,
            sampleId: params.value?.sampleId,
            displayValue: <span>{params.value?.labAssignedSampleId ?? params.value?.sampleBbid ?? 'None'}</span>,
          });
        },
        valueFormatter: params => params.value?.labAssignedSampleId ?? params.value?.sampleBbid ?? 'None',
      },
      {
        field: labAssignedSampleId,
        headerName: t('outputId'),
        headerClassName: compactGridHeaderClassName,
        headerAlign: 'left',
        align: 'left',
        minWidth: 200,
      },
      {
        field: qualityCheckStatus,
        headerName: t(qualityCheckStatus),
        headerClassName: compactGridHeaderClassName,
        headerAlign: 'left',
        align: 'left',
        minWidth: 120,
      },
      ...sequencingCols,
      {
        field: mappedFromCurrentTransition,
        headerName: t(mappedFromCurrentTransition),
        headerClassName: compactGridHeaderClassName,
        headerAlign: 'left',
        align: 'left',
        width: 150,
        valueGetter: ({ row }) =>
          row?.mappedFromSampleCurrentTransition?.transition && t(row?.mappedFromSampleCurrentTransition?.transition),
        valueFormatter: ({ value }) => value && t(value),
      },
      {
        field: mappedToSampleIdentifier,
        headerName: t(mappedToSampleIdentifier),
        headerClassName: compactGridHeaderClassName,
        headerAlign: 'left',
        align: 'left',
        minWidth: 300,
        flex: 0.9,
        editable: true,
        renderCell: params =>
          renderSampleHierarchyGridCell({
            ...params,
            sampleId: params.value?.sampleId,
            displayValue: <span>{params.value?.labAssignedSampleId ?? params.value?.sampleBbid ?? 'None'}</span>,
          }),
        valueFormatter: params => params.value?.labAssignedSampleId ?? params.value?.sampleBbid ?? 'None',
      },
    ];
  }, [transitionEnum, fastQValidationData, onFastQUpdate, setValidity, t]);
};

const useRows = (
  tableData: ReadonlyArray<SampleTrackingCheckByValidationResult>,
  fastQValidationData: Dictionary<SampleTrackingCheckByFastqValidationResultPayload>,
  fastQData: ReadonlyArray<FastQData>
) => {
  return useMemo(() => {
    const fastQBySampleId = keyBy(fastQData, i => i.outputId);
    const rows: Row[] = tableData.map((r, index) => {
      const updated = fastQBySampleId[r.labAssignedSampleId ?? ''];
      const original = find(
        values(fastQValidationData).flatMap(i => i.validated),
        j => j.labAssignedSampleId === r.labAssignedSampleId
      );

      return {
        id: index,
        ...original,
        ...updated,
        ...r,
      };
    });

    return rows;
  }, [tableData, fastQValidationData, fastQData]);
};
