import { appSettings } from 'AppSettings';

export interface InventoryCheckRequest {
  inventoryCheckRequestId: string;
  researchProjectId: string;
  biobankId: string;
  numSamplesRequested: number;
  numSamplesAvailable: number;
  numSamplesNotAvailable: number;
  requestComplete: boolean;
  createdAt: Date;
  updatedAt: Date;
}

export interface InvenvtoryCheckRequestUploadResults {
  inventoryCheckRequest: InventoryCheckRequest;
  numYesAvailable: number;
  numNoAvailable: number;
  numNullAvailable: number;
  numInvalidSampleIds: number;
}

export async function GetInventoryCheckRequests(
  researchProjectId: string,
  accessToken: string
): Promise<InventoryCheckRequest[]> {
  let inventoryCheckRequests: InventoryCheckRequest[] = [];
  if (accessToken) {
    try {
      const response = await fetch(
        `${appSettings.api.endpoint}/api/v2/InventoryCheckRequests?researchProjectId=${researchProjectId}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      if (response.ok) {
        inventoryCheckRequests = await response.json();
      }
    } catch (err) {
      throw err;
    }
  }
  return inventoryCheckRequests;
}
