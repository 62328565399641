import { appSettings } from 'AppSettings';
import {
  Box,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  LinearProgress,
  TextField,
} from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import useAuth from 'auth/UseAuth';
import { ChangeEvent, useState } from 'react';
import { DiseaseArea } from 'data/DiseaseAreaData';
import { LoadingStatus } from 'components/LoadingStatus';
import { DialogCloseButton } from '../../components/DialogCloseButton';
import { DialogOpenButton } from '../../components/DialogOpenButton';
import { CancelButton } from 'components/CancelButton';
import { PrimaryButton } from 'components/PrimaryButton';

export interface Props {
  onDiseaseAreaCreate: Function;
}

export const CreateDiseaseAreaModal = ({ onDiseaseAreaCreate }: Props) => {
  const { accessToken } = useAuth();

  const defaultName = '';
  const defaultIcd10Codes = '';
  const defaultAvailableOnDashboard = false;
  const defaultShowOnDashboardByDfault = false;

  const [open, setOpen] = useState<boolean>(false);
  const [status, setStatus] = useState<LoadingStatus>('NotStarted');
  const [errorMessage, setErrorMessage] = useState<string>();
  const [selectedName, setSelectedName] = useState<string>(defaultName);
  const [selectedIcd10Codes, setSelectedIcd10Codes] = useState<string>(defaultIcd10Codes);
  const [selectedAvailableOnDashboard, setSelectedAvailableOnDashboard] =
    useState<boolean>(defaultAvailableOnDashboard);
  const [selectedDefaultOnDashboard, setSelectedDefaultOnDashboard] = useState<boolean>(defaultShowOnDashboardByDfault);

  const handleButtonClick = async () => {
    setOpen(true);
  };

  const handleSave = async () => {
    setStatus('Loading');

    let newDiseaseArea: DiseaseArea = {
      diseaseAreaId: '00000000-0000-0000-0000-000000000000',
      name: selectedName,
      icd10Codes: selectedIcd10Codes.split(','),
      availableToShowOnDashboard: selectedAvailableOnDashboard,
      showOnDashboardByDefault: selectedDefaultOnDashboard,
    };

    try {
      if (accessToken) {
        const response = await fetch(`${appSettings.api.endpoint}/api/v2/DiseaseAreas`, {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(newDiseaseArea),
        });

        if (response.ok) {
          setStatus('Complete');
          handleClose();
          onDiseaseAreaCreate();
        } else {
          let message = await response.text();
          setStatus('Error');

          if (message.length > 0) {
            setErrorMessage(message);
          } else if (response.status === 401) {
            setErrorMessage('Error: unauthorized user.');
          } else {
            setErrorMessage('Unknown error.');
          }
        }
      }
    } catch (err) {
      setStatus('Error');
      setErrorMessage('Unknown error.');
    }
  };

  const handleClose = () => {
    clearSelectedState();
    setOpen(false);
    setErrorMessage(undefined);
  };

  const clearSelectedState = () => {
    setSelectedName(defaultName);
    setSelectedIcd10Codes(defaultIcd10Codes);
    setSelectedAvailableOnDashboard(defaultAvailableOnDashboard);
    setSelectedDefaultOnDashboard(defaultShowOnDashboardByDfault);
    setStatus('NotStarted');
  };

  const handleNameChange = (ev: ChangeEvent<HTMLInputElement>) => {
    setSelectedName(ev.target.value);
  };

  const handleIcd10CodesChange = (ev: ChangeEvent<HTMLInputElement>) => {
    setSelectedIcd10Codes(ev.target.value);
  };

  return (
    <>
      <DialogOpenButton title={'New Disease Area'} onClick={handleButtonClick} />
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>
          <DialogCloseButton onClick={handleClose} />
          Create New Disease Area
        </DialogTitle>
        <DialogContent>
          <Box component='form'>
            <TextField
              id='disease-area-name-input'
              fullWidth
              label='Name'
              margin='normal'
              onChange={handleNameChange}
              type='text'
              value={selectedName}
              variant='outlined'
            />
            <TextField
              id='icd10-codes-input'
              fullWidth
              label='ICD10 Codes'
              margin='normal'
              maxRows={4}
              multiline={true}
              onChange={handleIcd10CodesChange}
              type='text'
              value={selectedIcd10Codes}
              variant='outlined'
            />
            <FormControlLabel
              label='Available to show on dashboard'
              control={
                <Checkbox
                  checked={selectedAvailableOnDashboard}
                  onChange={() => {
                    setSelectedAvailableOnDashboard(!selectedAvailableOnDashboard);
                  }}
                />
              }
            />
            <FormControlLabel
              label='Default on dashboard'
              control={
                <Checkbox
                  checked={selectedAvailableOnDashboard && selectedDefaultOnDashboard}
                  onChange={() => {
                    setSelectedDefaultOnDashboard(!selectedDefaultOnDashboard);
                  }}
                  disabled={!selectedAvailableOnDashboard}
                />
              }
            />
          </Box>
          {status === 'Error' && (
            <DialogContentText display='flex' alignItems='center' mt={1} color='error'>
              <ErrorOutlineIcon sx={{ mr: 1 }} />
              {errorMessage}
            </DialogContentText>
          )}
        </DialogContent>
        {status === 'Loading' && <LinearProgress />}
        <DialogActions>
          <CancelButton onClick={handleClose} disabled={status === 'Loading'}>
            Cancel
          </CancelButton>
          <PrimaryButton onClick={handleSave} disabled={status === 'Loading'}>
            Save
          </PrimaryButton>
        </DialogActions>
      </Dialog>
    </>
  );
};
