import { styled, Tooltip, tooltipClasses, TooltipProps } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';

export function renderSampleCommentCellTooltip(params: GridRenderCellParams) {
  return <SampleCommentGridCellTooltip value={params.value ?? ''} width={params.colDef.computedWidth} />;
}

interface SampleCommentGridCellTooltipProps {
  value: string;
  width: number;
}

const SampleCommentGridCellTooltip = ({ value }: SampleCommentGridCellTooltipProps) => {
  let comments = value.replace('- ', '').split('- ');

  return (
    <LightTooltip
      title={
        comments.length >= 1 && comments[0] !== 'multiple' && comments[0] !== '' ? (
          <ul style={{ margin: 0, paddingLeft: 18 }}>
            {comments.map((c, index) => {
              if (c !== '') {
                return <li key={index}>{c}</li>;
              } else {
                return <></>;
              }
            })}
          </ul>
        ) : (
          value
        )
      }
    >
      <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
        {value.replace('- ', '')}
      </div>
    </LightTooltip>
  );
};

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} placement='bottom-start' classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 14,
    whiteSpace: 'pre-line',
  },
}));
