import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import React, { useState } from 'react';
import { SampleHierarchyDialog } from '../../../researchProjectManagement/sampleJourney/SampleHierarchyDialog';
import { DialogOpenButton } from '../../DialogOpenButton';

interface SampleHierarchyGridCellProps extends GridRenderCellParams {
  sampleId?: string;
  displayValue: React.ReactNode;
}

export function renderSampleHierarchyGridCell(params: SampleHierarchyGridCellProps) {
  return <MemoizedSampleHierarchyGridCell {...params} />;
}

export const SampleHierarchyGridCell = ({ sampleId, displayValue }: SampleHierarchyGridCellProps) => {
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  return (
    <span>
      {sampleId && (
        <>
          <DialogOpenButton
            sx={{ paddingLeft: 0, minWidth: 0 }}
            fontWeight={'inherit'}
            onClick={() => setModalOpen(true)}
          >
            <span style={{ fontFamily: 'monospace' }}>{displayValue}</span>
          </DialogOpenButton>
          {modalOpen && (
            <SampleHierarchyDialog sampleIds={[sampleId]} open={modalOpen} onClose={() => setModalOpen(false)} />
          )}
        </>
      )}
    </span>
  );
};

const MemoizedSampleHierarchyGridCell = React.memo(SampleHierarchyGridCell, (prevProps, nextProps) => {
  return prevProps.value === nextProps.value;
});
