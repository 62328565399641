import { Box, Tab, Tabs, Typography, useTheme } from '@mui/material';
import { AuthorizedPage } from 'auth/AuthorizedPage';
import { AuthorizedSection } from 'auth/AuthorizedSection';
import useAuth from 'auth/UseAuth';
import { LoadingStatus } from 'components/LoadingStatus';
import { Page } from 'components/Page';
import { Biobank, GetBiobanks } from 'data/BiobankData';
import { GetInventoryCheckRequests, InventoryCheckRequest } from 'data/InventoryCheckRequest';
import { GetResearchProject, ResearchProject } from 'data/ResearchProjectData';
import { orderBy } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { ResearchProjectCohortGrid } from 'researchProjectManagement/cohortGrid/ResearchProjectCohortGrid';
import { CreateInventoryCheckRequestModal } from 'researchProjectManagement/inventoryCheckRequests/CreateInventoryCheckRequestModal';
import { InventoryCheckRequestGrid } from 'researchProjectManagement/inventoryCheckRequests/InventoryCheckRequestGrid';
import { UpdateInventoryCheckRequestModal } from 'researchProjectManagement/inventoryCheckRequests/UpdateInventoryCheckRequestModal';
import { ResearchProjectCohortExportButton } from 'researchProjectManagement/projectsGrid/ResearchProjectCohortExportButton';
import { UpdateResearchProjectCohortModal } from 'researchProjectManagement/UpdateResearchProjectCohortModal';
import { SampleTrackingGrid } from '../researchProjectManagement/sampleTracking/SampleTrackingGrid';
import { PageContent } from '../components/PageContent';
import { FlexTableBox } from '../components/FlexTableBox';
import { SampleTrackingOrderGrid } from '../researchProjectManagement/sampleTracking/orders/SampleTrackingOrderGrid';
import { SampleTrackingQualityCheckGrid } from '../researchProjectManagement/sampleTracking/SampleTrackingQualityCheckGrid';
import useMemoTranslation from '../hooks/UseMemoTranslation';
import { GetResearchProjectEnrichmentUpdatedAtAsync } from '../data/EnrichmentsData';
import { RefreshClinicalDataButton } from '../researchProjectManagement/projectsGrid/RefreshClinicalDataButton';

type PageTabs = 'patientCohort' | 'inventoryChecks' | 'sampleTracking' | 'orders' | 'qualityChecks';

export const ResearchProjectCohortPage = () => {
  const { accessToken } = useAuth();
  let { researchProjectId } = useParams() as { researchProjectId: string };
  const theme = useTheme();
  const { t } = useMemoTranslation();

  const [status, setStatus] = useState<LoadingStatus>('NotStarted');
  const [selectedTab, setSelectedTab] = useState<PageTabs>('sampleTracking');
  const [researchProject, setResearchProject] = useState<ResearchProject>();
  const [inventoryCheckRequests, setInventoryCheckRequests] = useState<InventoryCheckRequest[]>([]);
  const [biobankFacilites, setBiobankFacilites] = useState<Biobank[]>([]);
  const [enrichmentUpdatedAt, setEnrichmentUpdatedAt] = useState<Date>();

  useEffect(() => {
    async function getState() {
      if (accessToken) {
        setStatus('Loading');

        setResearchProject(await GetResearchProject(researchProjectId, accessToken));

        const values = await GetInventoryCheckRequests(researchProjectId, accessToken);
        setInventoryCheckRequests([...orderBy(values, [(irc: InventoryCheckRequest) => irc.createdAt], ['desc'])]);
        setBiobankFacilites(await GetBiobanks(accessToken));
        const updatedAt = await GetResearchProjectEnrichmentUpdatedAtAsync(researchProjectId, accessToken);
        setEnrichmentUpdatedAt(updatedAt);
        setStatus('Complete');
      }
    }

    getState();
  }, [researchProjectId, accessToken]);

  const handleTabChange = async (event: React.SyntheticEvent, newValue: PageTabs) => {
    setSelectedTab(newValue);
  };

  async function handleResearchProjectCohortUpdate() {
    if (accessToken) {
      setStatus('Loading');
      setResearchProject(await GetResearchProject(researchProjectId, accessToken));
      setStatus('Complete');
    }
  }

  async function onInventoryCheckRequestUpdate() {
    if (accessToken) {
      setStatus('Loading');
      const values = await GetInventoryCheckRequests(researchProjectId, accessToken);
      setInventoryCheckRequests([...orderBy(values, [(irc: InventoryCheckRequest) => irc.createdAt], ['desc'])]);
      setStatus('Complete');
    }
  }

  function GetEnrichmentUpdatedAt() {
    if (!enrichmentUpdatedAt) {
      return;
    }
    return new Date(enrichmentUpdatedAt).toLocaleString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: '2-digit',
    });
  }

  return (
    <AuthorizedPage>
      <Page
        title='Research Project Management'
        subtitle={`${researchProject?.customer.name ?? ''} - ${researchProject?.name ?? ''}`}
        goBackUrl={'/research-project'}
      >
        <PageContent>
          <Box mb={1}>
            <Tabs value={selectedTab} onChange={handleTabChange}>
              <Tab value='sampleTracking' label='Sample Tracking' sx={{ color: theme.colors.alpha.black[100] }} />
              <Tab value='patientCohort' label='Cohort' sx={{ color: theme.colors.alpha.black[100] }} />
              <Tab value='inventoryChecks' label='Inventory Checks' sx={{ color: theme.colors.alpha.black[100] }} />
              <Tab value='orders' label='Orders' sx={{ color: theme.colors.alpha.black[100] }} />
              <Tab value='qualityChecks' label={t('checkBys')} sx={{ color: theme.colors.alpha.black[100] }} />
            </Tabs>
          </Box>
          {selectedTab === 'patientCohort' && (
            <FlexTableBox>
              <AuthorizedSection requiredRole='ll-green-side-user'>
                <Box mb={1} display='flex' justifyContent='right'>
                  <RefreshClinicalDataButton researchProjectId={researchProjectId} />
                  <ResearchProjectCohortExportButton
                    researchProjectId={researchProjectId}
                    cohortType='patient'
                    useDecryptedHashes={true}
                  />
                  <ResearchProjectCohortExportButton
                    researchProjectId={researchProjectId}
                    cohortType='sample'
                    useDecryptedHashes={true}
                  />
                  <UpdateResearchProjectCohortModal onExcelUpload={handleResearchProjectCohortUpdate} />
                </Box>
              </AuthorizedSection>
              {status === 'Complete' && (
                <ResearchProjectCohortGrid researchProjectId={researchProjectId} autoHeight={false} />
              )}
            </FlexTableBox>
          )}

          {selectedTab === 'inventoryChecks' && (
            <FlexTableBox>
              <Box mb={1} display='flex' justifyContent='right'>
                <UpdateInventoryCheckRequestModal onUpdateInventoryCheckRequest={onInventoryCheckRequestUpdate} />
                <CreateInventoryCheckRequestModal
                  researchProjectId={researchProjectId}
                  onCreateInventoryCheckRequest={onInventoryCheckRequestUpdate}
                />
              </Box>

              {status === 'Loading' && (
                <Box display='flex' alignItems='center' justifyContent='center'>
                  <Spinner animation='border' />{' '}
                </Box>
              )}
              {status === 'Complete' && (
                <InventoryCheckRequestGrid
                  inventoryCheckRequests={inventoryCheckRequests}
                  biobankFacilites={biobankFacilites}
                  onInventoryCheckRequestUpdate={onInventoryCheckRequestUpdate}
                  autoHeight={false}
                />
              )}
            </FlexTableBox>
          )}

          {selectedTab === 'sampleTracking' && (
            <FlexTableBox>
              <SampleTrackingGrid researchProjectId={researchProjectId} />
            </FlexTableBox>
          )}

          {selectedTab === 'orders' && (
            <FlexTableBox>
              <SampleTrackingOrderGrid researchProjectId={researchProjectId} />
            </FlexTableBox>
          )}

          {selectedTab === 'qualityChecks' && (
            <FlexTableBox>
              <SampleTrackingQualityCheckGrid researchProjectId={researchProjectId} />
            </FlexTableBox>
          )}

          {selectedTab === 'patientCohort' && enrichmentUpdatedAt && (
            <Typography mt={2}>{`Clinical Data Refreshed At: ${GetEnrichmentUpdatedAt()}`}</Typography>
          )}
        </PageContent>
      </Page>
    </AuthorizedPage>
  );
};
