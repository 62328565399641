import { useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import { LoadingIndicator } from './LoadingIndicator';
import { LoadingState, LoadState } from './LoadingStateUtil';
import { ErrorIndicator } from './ErrorIndicator';
import { CancelButton } from './CancelButton';
import { PrimaryButton } from './PrimaryButton';

type ConfirmationDialogProps = {
  onClose: () => void;
  onConfirm: () => void;
  title?: string;
  subtitle?: string;
  message: string;
  confirmBtnText: string;
  open: boolean;
};

export const ConfirmationDialog = ({
  onConfirm,
  onClose,
  title,
  subtitle,
  message,
  confirmBtnText,
  open,
}: ConfirmationDialogProps) => {
  const [loadingState, setLoadingState] = useState<LoadingState>({ status: 'NotStarted' });

  function handleClose() {
    onClose();
    setLoadingState({ status: 'NotStarted' });
  }

  async function handleConfirm() {
    LoadState(setLoadingState, async () => {
      await onConfirm();
    });
  }

  return (
    <Dialog open={open} onClose={handleClose} maxWidth={'xs'}>
      <DialogTitle>
        {title}
        <Typography variant='subtitle1'>{subtitle}</Typography>
      </DialogTitle>
      <DialogContent>
        <Typography sx={{ whiteSpace: 'pre-line' }}>{message}</Typography>
      </DialogContent>
      <LoadingIndicator loadingState={loadingState} margin={'LR'} />
      <ErrorIndicator loadingState={loadingState} />
      <DialogActions>
        <CancelButton onClick={handleClose} />
        <PrimaryButton disabled={loadingState.status === 'Loading'} onClick={handleConfirm}>
          {confirmBtnText}
        </PrimaryButton>
      </DialogActions>
    </Dialog>
  );
};
