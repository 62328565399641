import React, { FC, useEffect, useState } from 'react';
import { Page } from 'components/Page';
import LoadingOverlay from 'react-loading-overlay-ts';
import useAuth from 'auth/UseAuth';
import { AuthorizedPage } from 'auth/AuthorizedPage';
import { Box, Card, Tab, Tabs, useTheme } from '@mui/material';
import { DatavantDatasets } from '../tokens/DatavantDatasets';
import { DatavantOverlaps } from '../tokens/DatavantOverlaps';
import { DatavantTokenTransforms } from '../tokens/DatavantTokenTransforms';

type TabSelection = 'datavant' | 'lexisnexus';
type SubtabSelection = 'transform' | 'datasets' | 'overlaps';

export const TokenizationPage: FC = () => {
  const { accessToken } = useAuth();
  const theme = useTheme();
  const [isSpinnerActive, setIsSpinnerActive] = useState<boolean>(false);
  const [tabSelection, setTabSelection] = useState<TabSelection>('datavant');
  const [subtabSelection, setSubtabSelection] = useState<SubtabSelection>('transform');

  useEffect(() => {
    async function getState() {
      if (accessToken) {
        setIsSpinnerActive(false);
      }
    }

    setIsSpinnerActive(true);
    getState();
  }, [accessToken]);

  return (
    <AuthorizedPage>
      <Page title='Tokenization'>
        <LoadingOverlay active={isSpinnerActive} spinner>
          <Card sx={{ width: '100%', marginBottom: 3 }}>
            <Box p={3}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  marginBottom: '40px',
                  width: '100%',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <Tabs
                    value={tabSelection}
                    onChange={(event: React.SyntheticEvent, newValue: TabSelection) => {
                      setTabSelection(newValue);
                    }}
                  >
                    <Tab value='datavant' label='Datavant' sx={{ color: theme.colors.alpha.black[100] }} />
                  </Tabs>
                  {tabSelection === 'datavant' && (
                    <Tabs
                      sx={{ position: 'absolute', right: 0 }}
                      value={subtabSelection}
                      onChange={(event: React.SyntheticEvent, newValue: SubtabSelection) => {
                        setSubtabSelection(newValue);
                      }}
                    >
                      <Tab value='transform' label='Transform' sx={{ color: theme.colors.alpha.black[100] }} />
                      <Tab value='datasets' label='Datasets' sx={{ color: theme.colors.alpha.black[100] }} />
                      <Tab value='overlaps' label='Overlaps' sx={{ color: theme.colors.alpha.black[100] }} />
                    </Tabs>
                  )}
                </Box>
              </Box>
              {tabSelection === 'datavant' && subtabSelection === 'transform' && <DatavantTokenTransforms />}
              {tabSelection === 'datavant' && subtabSelection === 'datasets' && <DatavantDatasets />}
              {tabSelection === 'datavant' && subtabSelection === 'overlaps' && <DatavantOverlaps />}
            </Box>
          </Card>
        </LoadingOverlay>
      </Page>
    </AuthorizedPage>
  );
};
