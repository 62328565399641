import { Biobank } from './BiobankData';
import { Lab } from './LabData';

export interface ResearchProjectPatient {
  researchProjectId: string;
  patientId: string;
  selectionStatus: string;
  sampleTypes?: string[];
  biobanks?: Biobank[];
  labs?: Lab[];
  numOtherProjects?: number;
  notes?: string;
}

export type ItemOfResearchType = 'DiseaseArea' | 'PathogenGroup';

export type TimeInterval = 'Week' | 'Month' | 'ThreeMonths' | 'Year' | 'AllTime';

export type ResearchProjectSamplesToInclude = 'All' | 'Biobanked' | 'NonBiobanked';

export interface CreateResearchProjectPatientsDto {
  researchProjectId: string;
  itemOfResearchId: string;
  itemOfResearchType: ItemOfResearchType;
  timeInterval: TimeInterval;
  patientSelectionStatus: string;
  samplesToInclude: ResearchProjectSamplesToInclude;
  biobankId?: string;
  labId?: string;
  sampleTypeId?: string;
}

export interface ResearchProjectPatientCreateResults {
  researchProjectId: string;
  patientId: string;
  numberAttemptedPatients: number;
  numberInsertedPatients: number;
  numberConflictingPatients: number;
}

export function getSamplesToInclude(includeBiobankedSamples: boolean, includeNonBiobankedSamples: boolean) {
  if (includeBiobankedSamples && includeNonBiobankedSamples) {
    return 'All';
  } else if (includeBiobankedSamples) {
    return 'Biobanked';
  } else {
    return 'NonBiobanked';
  }
}
