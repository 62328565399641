import * as React from 'react';
import { Draggable } from 'react-beautiful-dnd';

import { makeStyles } from '@mui/styles';
import { Avatar, ListItem, ListItemAvatar, ListItemText } from '@mui/material';
import { DraggableItem } from './DraggableList';

const useStyles = makeStyles({
  draggingListItem: {
    background: 'rgb(235,235,235)',
  },
});

export type DraggableListItemProps = {
  item: DraggableItem;
  index: number;
};

const DraggableListItem = ({ item, index }: DraggableListItemProps) => {
  const classes = useStyles();
  return (
    <Draggable draggableId={item.id} index={index}>
      {(provided, snapshot) => (
        <ListItem
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          className={snapshot.isDragging ? classes.draggingListItem : ''}
          sx={{ paddingLeft: 0, paddingRight: 0, paddingTop: 0.5, paddingBottom: 0.5 }}
        >
          <ListItemAvatar>
            <Avatar sx={{ backgroundColor: 'transparent' }}>{item.icon}</Avatar>
          </ListItemAvatar>
          <ListItemText primary={item.primary} secondary={item.secondary} />
          {item.children}
        </ListItem>
      )}
    </Draggable>
  );
};

export default DraggableListItem;
