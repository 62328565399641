import { FC } from 'react';
import { Page } from 'components/Page';
import { appSettings } from 'AppSettings';
import { HomePageGrid } from 'homePage/HomePageGrid';

export const HomePage: FC = () => {
  return (
    <Page title='Data Services Operations' subtitle={`Backend URL: ${appSettings.api.endpoint}`}>
      <HomePageGrid />
    </Page>
  );
};
