import { getGridNumericOperators, GridCellParams, GridFilterItem, GridFilterOperator } from '@mui/x-data-grid-pro';
import { InformaticsQcThresholdEnum, Thresholds } from 'data/InformaticsQcData';
import { ThresholdInputValue } from './GridThresholdFilterInput';
import {
  medianCoverage,
  percentDuplication,
  percentGcContent,
  rawTotalSequences,
  readsAligned,
  readsMapped,
  readsMappedPercent,
  readsProperlyPairedPercent,
  totalReads,
  trimmedR1PercentGc,
  trimmedR2PercentGc,
  uniquelyMapped,
  uniquelyMappedPercent,
} from 'util/Constants';

const NumericFilterOperators = getGridNumericOperators().filter(
  operator => operator.value !== 'isEmpty' && operator.value !== 'isNotEmpty' && operator.value !== 'isAnyOf'
);

export const GridThresholdFilterOperators: GridFilterOperator[] = [
  {
    label: 'threshold',
    value: 'threshold',
    getApplyFilterFn: (filterItem: GridFilterItem) => {
      if (!filterItem.field || !filterItem.value || !filterItem.operator) {
        return null;
      }

      return (params: GridCellParams): boolean => {
        const paramsValue = convertParamValueToThresholdEnum(params);

        if (
          (filterItem.value === InformaticsQcThresholdEnum.Fail && paramsValue === InformaticsQcThresholdEnum.Fail) ||
          (filterItem.value === InformaticsQcThresholdEnum.Warn && paramsValue === InformaticsQcThresholdEnum.Warn) ||
          (filterItem.value === InformaticsQcThresholdEnum.Pass && paramsValue === InformaticsQcThresholdEnum.Pass)
        ) {
          return true;
        }

        return false;
      };
    },
    InputComponent: ThresholdInputValue,
    InputComponentProps: { type: 'number' },
    getValueAsString: (value: number) => {
      switch (value) {
        case InformaticsQcThresholdEnum.Fail:
          return 'Fail';
        case InformaticsQcThresholdEnum.Warn:
          return 'Warn';
        case InformaticsQcThresholdEnum.Pass:
          return 'Pass';
        default:
          return '';
      }
    },
  },
  ...NumericFilterOperators,
];

function convertParamValueToThresholdEnum(params: GridCellParams) {
  switch (params.field) {
    case percentDuplication:
      return Thresholds.percentDuplication.getThresholdEnum(Number(params.value));
    case medianCoverage:
      return Thresholds.medianCoverage.getThresholdEnum(Number(params.value));
    case rawTotalSequences:
      return Thresholds.rawTotalSequences.getThresholdEnum(Number(params.value));
    case readsMapped:
      return Thresholds.readsMapped.getThresholdEnum(Number(params.value));
    case readsMappedPercent:
      return Thresholds.readsMappedPercent.getThresholdEnum(Number(params.value));
    case percentGcContent:
      return Thresholds.percentGcContent.getThresholdEnum(Number(params.value));
    case readsProperlyPairedPercent:
      return Thresholds.readsProperlyPairedPercent.getThresholdEnum(Number(params.value));
    case totalReads:
      return Thresholds.totalReads.getThresholdEnum(Number(params.value));
    case readsAligned:
      return Thresholds.readsAligned.getThresholdEnum(Number(params.value));
    case uniquelyMapped:
      return Thresholds.uniquelyMapped.getThresholdEnum(Number(params.value));
    case uniquelyMappedPercent:
      return Thresholds.uniquelyMappedPercent.getThresholdEnum(Number(params.value));
    case trimmedR1PercentGc:
      return Thresholds.trimmedR1PercentGc.getThresholdEnum(Number(params.value));
    case trimmedR2PercentGc:
      return Thresholds.trimmedR2PercentGc.getThresholdEnum(Number(params.value));
  }
}
