import { PatientCountType } from 'components/grid/GridCountType';

export function ValidatePatientCountParameters(
  countType: PatientCountType,
  biobankId?: string,
  labId?: string,
  sampleTypeId?: string
) {
  if (countType === 'byBiobank' && biobankId === undefined) {
    throw new Error(`(countType selected was ${countType}, but no biobankId provided.`);
  } else if (countType === 'byLab' && labId === undefined) {
    throw new Error(`(countType selected was ${countType}, but no labId provided.`);
  } else if (countType === 'bySampleType' && sampleTypeId === undefined) {
    throw new Error(`(countType selected was ${countType}, but no sampleTypeId provided.`);
  }
}
