import { createCaseInsensitiveMap, Dictionary } from '../TypeUtil';
import { GridValueFormatterParams } from '@mui/x-data-grid/models/params/gridCellParams';
import { SampleAvailabilityType } from '../../data/ReferenceData';
import { SampleType } from '../../data/SampleTypeData';
import { TFunction } from 'i18next';
import { FlexBox } from '../../components/FlexBox';
import React from 'react';

export function generateWidthFromProperty(
  data: ReadonlyArray<any>,
  minWidth: number,
  getProperty: (row: any) => string | undefined,
  buffer = 9
) {
  let width = minWidth;

  data.forEach(row => (width = Math.max(width, (getProperty(row)?.length ?? 0) * buffer)));

  return width;
}

export function formatPatientSampleCount(
  row: {
    sampleCounts: Dictionary<number>;
    patientCounts: Dictionary<number>;
  },
  field: string,
  defaultValue: string = ''
) {
  const patientCount = createCaseInsensitiveMap(row?.patientCounts ?? {})?.get(field) ?? 0;
  const sampleCount = createCaseInsensitiveMap(row?.sampleCounts ?? {})?.get(field) ?? 0;

  if (patientCount === 0 && sampleCount === 0) {
    return defaultValue;
  }

  return `${sampleCount.toLocaleString()} (${patientCount.toLocaleString()}p)`;
}

export function formatPatientSampleCountAsFlex(
  row: {
    sampleCounts: Dictionary<number>;
    patientCounts: Dictionary<number>;
  },
  field: string,
  defaultValue: string = ''
): React.ReactNode {
  const patientCount = createCaseInsensitiveMap(row?.patientCounts ?? {})?.get(field) ?? 0;
  const sampleCount = createCaseInsensitiveMap(row?.sampleCounts ?? {})?.get(field) ?? 0;

  if (patientCount === 0 && sampleCount === 0) {
    return <div>{defaultValue}</div>;
  }

  return (
    <FlexBox flexDirection={'row'} flexWrap={'wrap'}>
      <div>{sampleCount.toLocaleString()}</div>
      <div style={{ marginLeft: 2 }}>{` (${patientCount.toLocaleString()}p)`}</div>
    </FlexBox>
  );
}

export function formatPatientSampleCountWithDisplayName(
  row: {
    sampleCounts: Dictionary<number>;
    patientCounts: Dictionary<number>;
  },
  field: string,
  defaultValue: string = ''
) {
  if (field === undefined) {
    return '';
  }

  const patientCount = createCaseInsensitiveMap(row?.patientCounts ?? {})?.get(field) ?? 0;
  const sampleCount = createCaseInsensitiveMap(row?.sampleCounts ?? {})?.get(field) ?? 0;

  if (patientCount === 0 && sampleCount === 0) {
    return `${field} | ${defaultValue}`;
  }

  return `${field} | ${sampleCount.toLocaleString()} (${patientCount.toLocaleString()}p)`;
}

export function ExtractDisplayValueFromPossibleArray(value: any, t: TFunction) {
  if (!value) {
    return t(value);
  }

  if (Array.isArray(value) && value.length > 1) {
    return t('multiple');
  }

  if (Array.isArray(value)) {
    return t(value[0]);
  }

  return t(value);
}

export const dateComparator = (a: string, b: string) => {
  const dateA = new Date(a);
  const dateB = new Date(b);

  if (dateA < dateB) {
    return -1;
  } else if (dateA > dateB) {
    return 1;
  } else {
    return 0;
  }
};

export const formatDate = (value: string) =>
  value &&
  new Date(value).toLocaleString('en-US', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  });
export const dateFormatter = (params: GridValueFormatterParams) => params && formatDate(params.value);
export const formatSampleAvailabilityTypeById = (values: ReadonlyArray<SampleAvailabilityType>, id: string) => {
  const type = values.find(i => i.sampleAvailabilityTypeId === id);

  return type?.isExhaustedState ? type.name : '' ?? '';
};

export const formatSampleType = (values: ReadonlyArray<SampleType>, id: string) => {
  return values.find(i => i.sampleTypeId === id)?.displayName ?? '';
};
