import { AuthorizedPage } from 'auth/AuthorizedPage';
import { CreateDiseaseAreaModal } from 'diseaseAreas/diseaseAreasGrid/CreateDiseaseAreaModal';
import { PatientCountExportButton } from 'patientRecords/PatientCountExportButton';
import { DiseaseAreaCountGrid } from 'diseaseAreas/diseaseAreasGrid/DiseaseAreaCountGrid';
import { PatientCountType } from 'components/grid/GridCountType';
import { Page } from 'components/Page';
import 'diseaseAreas/DiseaseAreas.css';
import { useEffect, useState } from 'react';
import { DiseaseArea, GetDiseaseAreas } from 'data/DiseaseAreaData';
import useAuth from 'auth/UseAuth';
import {
  DiseaseAreaCountByBiobank,
  DiseaseAreaCountBySampleType,
  GetDiseaseAreaCountByBiobank,
  GetDiseaseAreaCountBySampleType,
  UpdateDiseaseAreaCounts,
} from 'data/DiseaseAreaCountData';
import { Spinner } from 'react-bootstrap';
import { Box, Button, Tab, Tabs, Typography, useTheme } from '@mui/material';
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
import RefreshIcon from '@mui/icons-material/Refresh';
import { Biobank, GetBiobanks } from 'data/BiobankData';
import { SampleType } from 'data/SampleTypeData';
import { GetLongRunningTask, GetLongRunningTasks, LongRunningTask } from 'data/LongRunningTaskData';
import { orderBy } from 'lodash';
import { PageContent } from '../components/PageContent';
import { FlexTableBox } from '../components/FlexTableBox';
import { PatientRecordModal } from 'patientRecords/PatientRecordModal';
import { GetSampleTypes, Organization, OrganizationType } from 'data/ReferenceData';
import { LoadingState } from 'components/LoadingStateUtil';
import { LoadingIndicator } from 'components/LoadingIndicator';
import { ErrorIndicator } from 'components/ErrorIndicator';

export const DiseaseAreasPage = () => {
  const { accessToken } = useAuth();
  const theme = useTheme();

  const [loadingState, setLoadingState] = useState<LoadingState>({ status: 'NotStarted' });
  const [gridCountType, setGridCountType] = useState<PatientCountType>('byBiobank');
  const [diseaseAreas, setDiseaseAreas] = useState<DiseaseArea[]>();
  const [biobanks, setBiobanks] = useState<Biobank[]>();
  const [sampleTypes, setSampleTypes] = useState<ReadonlyArray<SampleType>>();
  const [byBiobankCounts, setByBiobankCounts] = useState<DiseaseAreaCountByBiobank[]>();
  const [bySampleTypeCounts, setBySampleTypeCounts] = useState<DiseaseAreaCountBySampleType[]>();
  const [isUpdatingCounts, setIsUpdatingCounts] = useState<boolean>(false);
  const [updateDiseaseAreaCountsTaskId, setUpdateDiseaseAreaCountsTaskId] = useState<string>();

  const [selectedDiseaseArea, setSelectedDiseaseArea] = useState<DiseaseArea>();
  const [selectedBiobank, setSelectedBiobank] = useState<Organization>();
  const [selectedSampleTypeId, setSelectedSampleTypeId] = useState<string>();
  const [selectedCount, setSelectedCount] = useState<number>();

  const [diseaseAreaPatientRecordModalOpen, setDiseaseAreaPatientRecordModalOpen] = useState<boolean>(false);
  const [patientRecordCountType, setPatientRecordCountType] = useState<PatientCountType>();

  useEffect(() => {
    async function getState() {
      setLoadingState({ status: 'Loading' });
      if (accessToken) {
        setDiseaseAreas(await GetDiseaseAreas(accessToken));
        setBiobanks(await GetBiobanks(accessToken));
        setSampleTypes(await GetSampleTypes(accessToken));
        setByBiobankCounts(await GetDiseaseAreaCountByBiobank(accessToken));
        setBySampleTypeCounts(await GetDiseaseAreaCountBySampleType(accessToken));
        setLoadingState({ status: 'Complete' });
      }
    }
    getState();
  }, [accessToken]);

  useEffect(() => {
    async function getState() {
      if (isUpdatingCounts && updateDiseaseAreaCountsTaskId && accessToken) {
        let task: LongRunningTask | null;

        do {
          await new Promise(r => setTimeout(r, 30 * 1000));
          task = await GetLongRunningTask(updateDiseaseAreaCountsTaskId, accessToken);
        } while (task && task.status === 'running');

        if (task && task.status === 'complete') {
          setLoadingState({ status: 'Loading' });
          setDiseaseAreas(await GetDiseaseAreas(accessToken));
          setByBiobankCounts(await GetDiseaseAreaCountByBiobank(accessToken));
          setBySampleTypeCounts(await GetDiseaseAreaCountBySampleType(accessToken));
          setLoadingState({ status: 'Complete' });
        }

        setIsUpdatingCounts(false);
      }
    }
    getState();
  }, [accessToken, isUpdatingCounts, updateDiseaseAreaCountsTaskId]);

  const handleCountTypeTabChange = async (event: React.SyntheticEvent, newValue: PatientCountType) => {
    setGridCountType(newValue);
  };

  const handleRefreshButtonClick = async () => {
    if (accessToken) {
      // If an UpdateDiseaseAreaCounts is already running, no need to start another task of this type.
      // Grab the most recently created task and use that to update the UI
      let diseaseAreaCountTasks = await GetLongRunningTasks('UpdateDiseaseAreaCounts', 'running', accessToken);
      diseaseAreaCountTasks = [...orderBy(diseaseAreaCountTasks, ['createdAt'], ['desc'])];

      const taskId =
        diseaseAreaCountTasks.length > 0
          ? diseaseAreaCountTasks[0].longRunningTaskId
          : await UpdateDiseaseAreaCounts(accessToken);

      if (taskId) {
        setIsUpdatingCounts(true);
        setUpdateDiseaseAreaCountsTaskId(taskId);
      }
    }
  };

  const handleDiseaseAreaPatientRecordModalClose = async () => {
    setSelectedDiseaseArea(undefined);
    setSelectedBiobank(undefined);
    setSelectedSampleTypeId(undefined);
    setPatientRecordCountType(undefined);
    setDiseaseAreaPatientRecordModalOpen(false);
  };

  function handleGridCountClick(
    count: number,
    diseaseArea: DiseaseArea,
    patientRecordCountType: PatientCountType,
    biobankId?: string,
    sampleTypeId?: string
  ) {
    setSelectedCount(count);
    setSelectedDiseaseArea(diseaseArea);

    let biobank = biobanks?.find(b => b.biobankId === biobankId);
    let organization = biobank
      ? {
          id: biobank.biobankId,
          name: biobank.name,
          type: 'Biobank' as OrganizationType,
        }
      : undefined;
    setSelectedBiobank(organization);

    setSelectedSampleTypeId(sampleTypeId);
    setPatientRecordCountType(patientRecordCountType);
    setDiseaseAreaPatientRecordModalOpen(true);
  }

  async function handleDiseaseAreaChange() {
    setLoadingState({ status: 'Loading' });
    if (accessToken) {
      setDiseaseAreas(await GetDiseaseAreas(accessToken));
      setByBiobankCounts(await GetDiseaseAreaCountByBiobank(accessToken));
      setBySampleTypeCounts(await GetDiseaseAreaCountBySampleType(accessToken));
      setLoadingState({ status: 'Complete' });
    }
  }

  return (
    <AuthorizedPage>
      <Page title='Disease Areas'>
        <PageContent>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
            mb={2}
          >
            <Box>
              <Tabs value={gridCountType} onChange={handleCountTypeTabChange}>
                <Tab value='byBiobank' label='By Biobank' sx={{ color: theme.colors.alpha.black[100] }} />
                <Tab value='bySampleType' label='By Sample Type' sx={{ color: theme.colors.alpha.black[100] }} />
              </Tabs>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <Button
                id='disease-count-refresh-button'
                onClick={handleRefreshButtonClick}
                sx={[
                  {
                    color: theme.colors.alpha.black[100],
                    '&:hover': {
                      backgroundColor: 'transparent',
                    },
                  },
                ]}
              >
                {!isUpdatingCounts && <RefreshIcon />}
                {isUpdatingCounts && <HourglassBottomIcon />}
                <Typography variant='button' ml={1}>
                  Refresh
                </Typography>
              </Button>
              <PatientCountExportButton countType={gridCountType} itemOfResearchType={'DiseaseArea'} disabled={false} />
              <CreateDiseaseAreaModal onDiseaseAreaCreate={handleDiseaseAreaChange} />
            </Box>
          </Box>
          <FlexTableBox>
            {diseaseAreas &&
              biobanks &&
              sampleTypes &&
              byBiobankCounts &&
              bySampleTypeCounts &&
              loadingState.status === 'Complete' && (
                <DiseaseAreaCountGrid
                  diseaseAreas={diseaseAreas}
                  biobanks={biobanks}
                  sampleTypes={sampleTypes}
                  countType={gridCountType}
                  onCountClick={handleGridCountClick}
                  byBiobankCounts={byBiobankCounts}
                  bySampleTypeCounts={bySampleTypeCounts}
                  onDiseaseAreaChange={handleDiseaseAreaChange}
                />
              )}
            <LoadingIndicator type={'Circular'} loadingState={loadingState} margin={'LRT'} />
            <ErrorIndicator loadingState={loadingState} />
          </FlexTableBox>
          {loadingState.status === 'Loading' && (
            <Box display='flex' justifyContent='center' marginTop='100px'>
              <Spinner animation='border' />
            </Box>
          )}
        </PageContent>
        {selectedDiseaseArea && patientRecordCountType && (
          <PatientRecordModal
            open={diseaseAreaPatientRecordModalOpen}
            count={selectedCount ?? 0}
            itemOfResearch={{
              id: selectedDiseaseArea.diseaseAreaId,
              name: selectedDiseaseArea.name,
              type: 'DiseaseArea',
            }}
            timeInterval={'AllTime'}
            organization={selectedBiobank}
            sampleType={sampleTypes?.find(s => s.sampleTypeId === selectedSampleTypeId)}
            gridCountType={gridCountType}
            patientRecordCountType={patientRecordCountType}
            onClose={handleDiseaseAreaPatientRecordModalClose}
          />
        )}
      </Page>
    </AuthorizedPage>
  );
};
