import useAuth from '../../auth/UseAuth';
import { GetSampleAvailabilityType, SampleAvailabilityType } from '../../data/ReferenceData';
import { useEffect, useState } from 'react';

const useSampleAvailabilityTypes = (): ReadonlyArray<SampleAvailabilityType> => {
  const { accessToken } = useAuth();
  const [sampleAvailabilityTypes, setSampleAvailabilityTypes] = useState<ReadonlyArray<SampleAvailabilityType>>([]);

  useEffect(() => {
    async function getData() {
      const data = await GetSampleAvailabilityType(accessToken);
      setSampleAvailabilityTypes(data);
    }

    getData();
  }, [accessToken]);

  return sampleAvailabilityTypes;
};

export default useSampleAvailabilityTypes;
