import { Box, Button } from '@mui/material';
import { GridToolbarContainer, GridToolbarFilterButton } from '@mui/x-data-grid-pro';
import { QualityCheckStatus } from 'data/SampleTrackingData';
import useMemoTranslation from '../../hooks/UseMemoTranslation';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { FlexBox } from '../../components/FlexBox';
import { GridExportButton } from '../../components/GridExportButton';
import React, { MutableRefObject } from 'react';
import { GridApiPro } from '@mui/x-data-grid-pro/models/gridApiPro';

export interface InformaticsQcMetricsGridToolbarProps {
  onQcButtonClick: (value: QualityCheckStatus) => void;
  anyRowSelected: boolean;
  apiRef: MutableRefObject<GridApiPro>;
}

export const InformaticsQcMetricsGridToolbar = ({
  onQcButtonClick,
  anyRowSelected,
  apiRef,
}: InformaticsQcMetricsGridToolbarProps) => {
  const { t } = useMemoTranslation();

  return (
    <GridToolbarContainer sx={{ pb: 2 }}>
      <Box display='flex' flexDirection='row' alignItems='center' justifyContent='space-between' width='100%'>
        <Box display='flex' flexDirection='row' alignItems='center'>
          <GridToolbarFilterButton />
          <GridExportButton apiRef={apiRef?.current} fileName={t('informaticsMetricsFileName')} />
        </Box>
        <FlexBox flexDirection='row' alignItems='center'>
          <Button value='Passed' sx={{ mr: 0.1 }} onClick={() => onQcButtonClick('Passed')} disabled={!anyRowSelected}>
            <CheckIcon sx={{ mr: 1 }} />
            {t('informaticsPassedBtn')}
          </Button>
          <Button value='Failed' sx={{ mr: 0.1 }} onClick={() => onQcButtonClick('Failed')} disabled={!anyRowSelected}>
            <CloseIcon sx={{ mr: 1 }} />
            {t('informaticsFailedBtn')}
          </Button>
        </FlexBox>
      </Box>
    </GridToolbarContainer>
  );
};
