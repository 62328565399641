import { useTheme } from '@mui/material';
import { DataGridPro, DataGridProProps } from '@mui/x-data-grid-pro';
import { FlexTableBox } from 'components/FlexTableBox';

export interface CompactGridWrapperProps extends DataGridProProps<any> {
  flexGrow?: number;
}

export const CompactGridWrapper = (props: CompactGridWrapperProps) => {
  const theme = useTheme();
  const cellFontColor = theme.colors.alpha.black[100]; //#223354

  return (
    <FlexTableBox
      flexGrow={props.flexGrow}
      sx={{
        width: '100%',
        fontSize: 12,
        overflow: 'hidden',
        '& .compact-grid--header, .MuiDataGrid-columnHeaderTitle': {
          whiteSpace: 'break-spaces',
          lineHeight: 1.2,
          display: 'flex',
          textAlign: 'center',
        },
        '& .livinglab-grid--cell': {
          backgroundColor: 'rgba(224, 183, 60, 0.55)',
          color: cellFontColor,
          fontWeight: '400',
        },
        '& .livinglab-grid.pass': {
          backgroundColor: theme.colors.success.light,
          color: cellFontColor,
          fontWeight: '400',
        },
        '& .livinglab-grid.warn': {
          backgroundColor: theme.colors.warning.light,
          color: cellFontColor,
          fontWeight: '400',
        },
        '& .livinglab-grid.fail': {
          backgroundColor: theme.colors.error.light,
          color: cellFontColor,
          fontWeight: '400',
        },
        '& .MuiDataGrid-footerContainer': {
          height: '3vh',
          minHeight: '3vh',
        },
        '& .MuiDataGrid-columnSeparator': {
          visibility: 'visible !important',
        },
        '& .monospace-font': {
          fontFamily: 'monospace',
        },
      }}
    >
      <DataGridPro {...props} sx={{ fontSize: 12 }} density={'compact'} />
    </FlexTableBox>
  );
};
