import { Page } from 'components/Page';
import { StatusText } from 'components/Styles';
import { useAuth0 } from '@auth0/auth0-react';

type SigninAction = 'signin' | 'signin-callback';
interface Props {
  action: SigninAction;
}

export const SignInPage = ({ action }: Props) => {
  const { isAuthenticated, loginWithRedirect } = useAuth0();

  if (action === 'signin') {
    if (!isAuthenticated) {
      loginWithRedirect();
    }
  }

  return (
    <Page title='Sign In'>
      <StatusText>Signing in...</StatusText>
    </Page>
  );
};
