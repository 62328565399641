import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import React from 'react';
import { DialogOpenButton } from '../../DialogOpenButton';
import { PressableIconWrapper } from '../../PressableIconWrapper';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

export function renderCopyTextGridCell(params: GridRenderCellParams) {
  return <MemoizedCopyTextGridCell {...params} />;
}

const CopyTextGridCell = ({ value }: GridRenderCellParams) => {
  return (
    <span>
      {value && (
        <DialogOpenButton
          onClick={async () => {
            await navigator.clipboard.writeText(value);
          }}
        >
          <PressableIconWrapper>
            <ContentCopyIcon />
          </PressableIconWrapper>
        </DialogOpenButton>
      )}
    </span>
  );
};

const MemoizedCopyTextGridCell = React.memo(CopyTextGridCell, (prevProps, nextProps) => {
  return prevProps.value === nextProps.value;
});
