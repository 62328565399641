import { appSettings } from '../AppSettings';
import { BaseGet } from './BaseData';
import { Transition } from './SampleTrackingTransitionData';

export interface TransitionSampleQualityCheckGroup {
  transitionQualityCheckGroupId: string;
  createdAt: Date;
}

export interface QualityCheckGroupAndTransition {
  transitionSampleQualityCheckGroup: TransitionSampleQualityCheckGroup;
  transitionDtos: ReadonlyArray<Transition>;
}

export interface QualityCheckGroupCounts {
  qualityCheckGroupId: string;
  qualityCheckStatusCounts: ReadonlyArray<QualityCheckGroupStatusCounts>;
  supplementalFileCounts: number;
}

export interface QualityCheckGroupStatusCounts {
  qualityCheckGroupId: string;
  qualityCheckStatusEnumId: string;
  qualityCheckStatus: string;
  transitionSampleCounts: number;
}

export interface Message {
  message: string;
}

export async function GetAllTransitionWithQualityCheckGroupsForResearchProject(
  researchProjectId: string,
  accessToken?: string
): Promise<ReadonlyArray<QualityCheckGroupAndTransition>> {
  return await BaseGet(
    `${appSettings.api.endpoint}/api/v2/SampleTrackingQualityCheckGroup/Transition/${researchProjectId}`,
    accessToken
  );
}

export async function GetQualityCheckGroupCounts(
  researchProjectId: string,
  accessToken?: string
): Promise<ReadonlyArray<QualityCheckGroupCounts>> {
  return await BaseGet(
    `${appSettings.api.endpoint}/api/v2/SampleTrackingQualityCheckGroup/Counts/${researchProjectId}`,
    accessToken
  );
}

export async function GetUndoableQualityCheckGroupIds(
  researchProjectId: string,
  accessToken?: string
): Promise<ReadonlyArray<string>> {
  return await BaseGet(
    `${appSettings.api.endpoint}/api/v2/SampleTrackingUndoOperation/Undoable/QualityCheckGroup/${researchProjectId}`,
    accessToken
  );
}

export async function GetUndoableQualityCheckGroupReason(
  researchProjectId: string,
  qualityCheckGroupId: string,
  accessToken?: string
): Promise<Message> {
  return await BaseGet(
    `${appSettings.api.endpoint}/api/v2/SampleTrackingUndoOperation/UndoableReason/QualityCheckGroup/${researchProjectId}/${qualityCheckGroupId}`,
    accessToken
  );
}

export async function GetUndoableTransitionReason(
  researchProjectId: string,
  transitionId: string,
  accessToken?: string
): Promise<Message> {
  return await BaseGet(
    `${appSettings.api.endpoint}/api/v2/SampleTrackingUndoOperation/UndoableReason/Transition/${researchProjectId}/${transitionId}`,
    accessToken
  );
}
