export interface MenuItem {
  link?: string;
  authRole?: string;
  items?: MenuItem[];
  name: string;
}

export interface MenuItems {
  heading: string;
  items: MenuItem[];
}

export const menuItems: MenuItems[] = [
  {
    heading: 'Data Services Operations',
    items: [
      {
        name: 'Disease Areas',
        link: '/disease-areas',
      },
      {
        name: 'Pathogens',
        link: '/pathogens',
      },
      {
        name: 'Research Projects',
        link: '/research-project',
      },
    ],
  },
  {
    heading: 'Dashboards',
    items: [
      {
        name: 'Biobank',
        link: '/biobank-metrics',
      },
      {
        name: 'Lab',
        link: '/lab-metrics',
      },
      {
        name: 'Disease Area',
        link: '/disease-area-metrics',
      },
    ],
  },
  {
    heading: 'Living Lab Administration',
    items: [
      {
        name: 'Migrations',
        link: '/migrations',
      },
      {
        name: 'Generate BBIDs',
        link: '/bbidgen',
      },
      {
        name: 'Tokenization',
        link: '/tokenization',
      },
    ],
  },
];
