import { DataGridPro, GridColDef, GridRowsProp, GridSortDirection } from '@mui/x-data-grid-pro';
import useAuth from 'auth/UseAuth';
import { renderCellTooltip } from 'components/grid/cell/GridCellTooltip';
import { LoadingStatus } from 'components/LoadingStatus';
import {
  GetResearchProjectPatientCount,
  GetResearchProjectPatients,
  ResearchProjectPatient,
  ResearchProjectCohortRecordOrderBy,
} from 'data/ResearchProjectCohortData';
import { useEffect, useMemo, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { renderSampleCommentCellTooltip } from 'components/grid/cell/SampleCommentGridCellTooltip';
import { ConvertToCapitalizedDashSeparated } from 'data/conversions/StatusEnumConversions';
import { Box } from '@mui/material';
import { renderGuidCellTooltip } from '../../components/grid/cell/GuidGridCellTooltip';
import useMemoTranslation from 'hooks/UseMemoTranslation';
import {
  biobank,
  biobanks,
  ethnicity,
  gender,
  icd9AndIcd10Codes,
  lab,
  labs,
  latestSampleCollectionDate,
  numOtherProjects,
  numSamplesSelected,
  patientId,
  patientNotes,
  patientSelectionStatus,
  race,
  sampleComment,
  sampleComments,
  sampleCount,
  sampleType,
  sampleTypes,
  snomedConceptIdsWithCtValue,
  snomedTerms,
  yearOfBirth,
} from 'util/Constants';
import { ConvertToSingleValue } from 'data/conversions/StringConversions';

export interface ResearchProjectCohortGridProps {
  researchProjectId: string;
  autoHeight: boolean;
}

export const ResearchProjectCohortGrid = ({ researchProjectId, autoHeight }: ResearchProjectCohortGridProps) => {
  const { accessToken } = useAuth();

  const [status, setStatus] = useState<LoadingStatus>('NotStarted');
  const [totalPatients, setTotalPatients] = useState<number>(0);
  const [orderBy, setOrderBy] = useState<ResearchProjectCohortRecordOrderBy>(patientSelectionStatus);
  const [sortOrder, setSortOrder] = useState<GridSortDirection>('asc');
  const [pageSize, setPageSize] = useState<number>(100);
  const [pageStart, setPageStart] = useState<number>(0);
  const [researchProjectPatients, setResearchProjectPatients] = useState<ResearchProjectPatient[]>();
  const [rows, setRows] = useState<GridRowsProp>([]);
  const columns = useColumns();

  useEffect(() => {
    async function getState() {
      if (accessToken && researchProjectId !== undefined) {
        setTotalPatients(await GetResearchProjectPatientCount(researchProjectId, accessToken));
      }
    }

    getState();
  }, [accessToken, researchProjectId]);

  useEffect(() => {
    async function getState() {
      if (accessToken && sortOrder && researchProjectId !== undefined && totalPatients > 0) {
        setStatus('Loading');
        try {
          let researchProjectPatients = await GetResearchProjectPatients(
            researchProjectId,
            orderBy,
            sortOrder,
            pageSize,
            pageStart,
            accessToken
          );
          setResearchProjectPatients([...researchProjectPatients]);
          setStatus('Complete');
        } catch (err: any) {
          setStatus('Error');
        }
      }
    }

    getState();
  }, [accessToken, totalPatients, researchProjectId, orderBy, sortOrder, pageSize, pageStart]);

  useEffect(() => {
    if (researchProjectPatients) {
      setRows(getRows(researchProjectPatients));
    }
  }, [researchProjectPatients]);

  return (
    <>
      {status === 'Complete' && (
        <DataGridPro
          rows={rows}
          columns={columns}
          initialState={{ pinnedColumns: { left: [patientId, patientSelectionStatus] } }}
          autoHeight={autoHeight}
          disableColumnFilter={true}
          pagination={true}
          onPaginationModelChange={model => {
            setPageStart(model.page);
            setPageSize(model.pageSize);
          }}
          paginationModel={{ pageSize: pageSize, page: pageStart }}
          rowCount={totalPatients}
          pageSizeOptions={[100, 200, 500]}
          sortingMode='server'
          sortModel={[{ field: orderBy, sort: sortOrder }]}
          onSortModelChange={sortModel => {
            if (sortModel.length > 0) {
              setOrderBy(sortModel[0].field as ResearchProjectCohortRecordOrderBy);
              setSortOrder(sortModel[0].sort);
            }
          }}
          density='compact'
          hideFooterSelectedRowCount={true}
        />
      )}
      {status === 'Loading' && (
        <Box display='flex' alignItems='center' justifyContent='center'>
          <Spinner animation='border' />
        </Box>
      )}
    </>
  );
};

const useColumns = (): GridColDef[] => {
  const { t } = useMemoTranslation();

  return useMemo(() => {
    return [
      {
        field: patientId,
        headerName: t(patientId),
        width: 130,
        renderCell: renderGuidCellTooltip,
      },
      {
        field: patientSelectionStatus,
        headerName: t(patientSelectionStatus),
        width: 220,
      },
      {
        field: numOtherProjects,
        headerName: t(numOtherProjects),
        headerAlign: 'center',
        align: 'center',
        width: 250,
      },
      {
        field: sampleCount,
        headerName: t(sampleCount),
        headerAlign: 'center',
        align: 'center',
        width: 150,
      },
      {
        field: numSamplesSelected,
        headerName: t(numSamplesSelected),
        headerAlign: 'center',
        align: 'center',
        width: 250,
      },
      {
        field: biobanks,
        headerName: t(biobank),
        width: 230,
      },
      {
        field: labs,
        headerName: t(lab),
        width: 230,
      },
      {
        field: sampleTypes,
        headerName: t(sampleType),
        width: 200,
        renderCell: renderCellTooltip,
      },
      {
        field: latestSampleCollectionDate,
        headerName: t(latestSampleCollectionDate),
        headerAlign: 'center',
        align: 'center',
        width: 230,
      },
      {
        field: gender,
        headerName: t(gender),
        width: 100,
      },
      {
        field: yearOfBirth,
        headerName: t(yearOfBirth),
        headerAlign: 'center',
        align: 'center',
        width: 120,
      },
      {
        field: race,
        headerName: t(race),
        width: 170,
      },
      {
        field: ethnicity,
        headerName: t(ethnicity),
        width: 170,
      },
      {
        field: snomedConceptIdsWithCtValue,
        headerName: t(snomedConceptIdsWithCtValue),
        width: 230,
        renderCell: renderCellTooltip,
      },
      {
        field: snomedTerms,
        headerName: t(snomedTerms),
        width: 230,
        renderCell: renderCellTooltip,
      },
      {
        field: icd9AndIcd10Codes,
        headerName: t(icd9AndIcd10Codes),
        width: 230,
        renderCell: renderCellTooltip,
      },
      {
        field: sampleComments,
        headerName: t(sampleComment),
        width: 230,
        renderCell: renderSampleCommentCellTooltip,
      },
      {
        field: patientNotes,
        headerName: t(patientNotes),
        width: 200,
        renderCell: renderCellTooltip,
      },
    ];
  }, [t]);
};

function getRows(researchProjectPatients: ResearchProjectPatient[]) {
  const rows: any[] = [];

  researchProjectPatients.forEach((rpp, index) => {
    rows.push({
      id: index++,
      patientId: rpp.patientId,
      patientSelectionStatus: ConvertToCapitalizedDashSeparated(rpp.patientSelectionStatus),
      numOtherProjects: rpp.numOtherProjects,
      sampleCount: rpp.sampleCount,
      numSamplesSelected: rpp.numSamplesSelected,
      biobanks: ConvertToSingleValue(rpp.sampleCount, rpp.biobanks),
      labs: ConvertToSingleValue(rpp.sampleCount, rpp.labs),
      sampleTypes: ConvertToSingleValue(rpp.sampleCount, rpp.sampleTypes),
      latestSampleCollectionDate: dateToString(new Date(rpp.latestSampleCollectionDate)),
      gender: rpp.gender,
      yearOfBirth: rpp.yearOfBirth,
      race: rpp.race,
      ethnicity: rpp.ethnicity,
      snomedConceptIdsWithCtValue: rpp.snomedConceptIds.sort().join(', '),
      snomedTerms: rpp.snomedTerms.sort().join(', '),
      icd9AndIcd10Codes: rpp.icd9AndIcd10Codes.sort().join(', '),
      sampleComments: ConvertToSingleValue(rpp.sampleCount, rpp.sampleComments),
      patientNotes: rpp.notes,
    });
  });
  return rows;
}

function dateToString(date: Date) {
  return date.toLocaleString('en-US', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  });
}
