import { appSettings } from '../AppSettings';
import { BaseGet } from './BaseData';

export type SupplementalFileType = {
  supplementalFileTypeId: string;
  name: string;
};

export async function GetSupplementalFileTypes(accessToken: string): Promise<ReadonlyArray<SupplementalFileType>> {
  return await BaseGet(`${appSettings.api.endpoint}/api/v2/ReferenceData/SupplementalFileType`, accessToken);
}
