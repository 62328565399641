import { DialogOpenButton } from './DialogOpenButton';
import React from 'react';
import useMemoTranslation from '../hooks/UseMemoTranslation';
import { GridApiPro } from '@mui/x-data-grid-pro/models/gridApiPro';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

interface Props {
  apiRef: GridApiPro;
  fileName: string;
  fieldsToNotExport?: string[];
  label?: string;
}

export const GridExportButton = ({ apiRef, fileName, fieldsToNotExport, label }: Props) => {
  const { t } = useMemoTranslation();

  function download() {
    apiRef.exportDataAsCsv({
      fields: apiRef
        .getAllColumns()
        .filter(o => !o.disableExport)
        .map(o => o.field)
        .filter(o => !(fieldsToNotExport ?? []).includes(o)),
      fileName: fileName,
    });
  }

  return (
    <DialogOpenButton title={label ?? t('export')} onClick={() => download()} disabled={apiRef === null}>
      <FileDownloadIcon />
    </DialogOpenButton>
  );
};
