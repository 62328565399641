import { Box, Card, Divider } from '@mui/material';
import ChevronRightTwoToneIcon from '@mui/icons-material/ChevronRightTwoTone';
import { ButtonWrapper } from './ButtonWrapper';
import { useHistory } from 'react-router-dom';

export const DiseaseAreaBlock = () => {
  const history = useHistory();

  const handleButtonClick = async (): Promise<void> => {
    history.push('disease-areas');
  };

  return (
    <Card>
      <Box display='flex' alignItems='center' p={2}>
        <Box pl={1}></Box>
      </Box>
      <Divider />
      <ButtonWrapper fullWidth endIcon={<ChevronRightTwoToneIcon />} onClick={handleButtonClick}>
        {'Disease Areas'}
      </ButtonWrapper>
    </Card>
  );
};
