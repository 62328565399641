import { Button, ButtonProps } from '@mui/material';
import React from 'react';
import useMemoTranslation from '../hooks/UseMemoTranslation';
import { cancel } from 'util/Constants';

export const CancelButton = (props: ButtonProps) => {
  const { t } = useMemoTranslation();

  return (
    <Button color={'secondary'} {...props}>
      {t(cancel)}
    </Button>
  );
};
