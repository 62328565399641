import { FC, useEffect, useState } from 'react';
import { appSettings } from 'AppSettings';
import { Migration, GetMigrations } from 'data/MigrationsData';
import { MigrationSpan } from 'components/MigrationSpan';
import { orderBy } from 'lodash';
import { Page } from 'components/Page';
import { ScrollableDiv, SelectableErrorText, UlNoBullets } from 'components/Styles';
import LoadingOverlay from 'react-loading-overlay-ts';
import useAuth from 'auth/UseAuth';
import { AuthorizedPage } from 'auth/AuthorizedPage';
import { AuthorizedSection } from 'auth/AuthorizedSection';
import { Box, Card } from '@mui/material';

export const MigrationsPage: FC = () => {
  const [migrations, setMigrations] = useState<Migration[]>([]);
  const [tbdMigrations, setTbdMigrations] = useState<Migration[]>([]);
  const [doneMigrations, setDoneMigrations] = useState<Migration[]>([]);
  const [apiCallCount, refreshMigrations] = useState<number>(0);
  const [isSpinnerActive, setIsSpinnerActive] = useState<boolean>(false);

  const { accessToken } = useAuth();

  useEffect(() => {
    async function getState() {
      if (accessToken) {
        setMigrations(await GetMigrations(accessToken));
        setIsSpinnerActive(false);
      }
    }
    setIsSpinnerActive(true);
    getState();
  }, [accessToken, apiCallCount]);

  useEffect(() => {
    setTbdMigrations(migrations.filter(m => m.category === 'Pending'));
    const allDoneMigrations = [
      ...orderBy(
        migrations.filter(m => m.category !== 'Pending'),
        ['installedOn'],
        ['desc']
      ),
    ];

    const filtered: Migration[] = [];
    const seenVersions: { [ver: string]: boolean } = {};

    for (const m of allDoneMigrations) {
      if (!(m.version in seenVersions)) {
        seenVersions[m.version] = true;
        filtered.push(m);
      }
    }

    setDoneMigrations(filtered);
  }, [migrations]);

  const repairMigrations = async () => {
    setIsSpinnerActive(true);

    const response = await fetch(`${appSettings.api.endpoint}/api/v2/Migration/Repair`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (response.status === 200) {
      alert('Migration repair executed successfully.');
    } else {
      alert(`Migration repair failed: ${response.status} - ${response.statusText}`);
    }

    refreshMigrations(apiCallCount + 1);
  };

  const runMigrations = async () => {
    setIsSpinnerActive(true);

    const response = await fetch(`${appSettings.api.endpoint}/api/v2/Migration`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (response.status === 200) {
      alert('Migrations executed successfully.');
    } else {
      const msg = (await response.json()).title; //await response.json();
      alert(`Migrations failed: ${response.status} - ${response.statusText}\n\n${msg}`);
    }

    refreshMigrations(apiCallCount + 1);
  };

  return (
    <AuthorizedPage>
      <Page title='Living Lab Migrations'>
        <LoadingOverlay active={isSpinnerActive} spinner>
          <Card>
            <Box p={3}>
              <h4>Available Migrations {tbdMigrations.length === 0 ? '' : `(${tbdMigrations.length})`}</h4>
              <ScrollableDiv width='800px' height='100px'>
                <UlNoBullets>
                  {tbdMigrations.map(m => (
                    <li key={m.version}>
                      <MigrationSpan migration={m} />
                    </li>
                  ))}
                </UlNoBullets>
              </ScrollableDiv>
              <div style={{ paddingTop: '5px' }}>
                <AuthorizedSection requiredRole='ll-sys-admin'>
                  <button style={{ backgroundColor: 'lightgreen' }} onClick={runMigrations}>
                    Apply Migrations
                  </button>
                  <span style={{ paddingRight: '50px' }}></span>
                  <button style={{ backgroundColor: 'pink' }} onClick={repairMigrations}>
                    Repair Migrations
                  </button>
                </AuthorizedSection>
              </div>
              <p />
              <h4>Completed Migrations {doneMigrations.length === 0 ? '' : `(${doneMigrations.length})`}</h4>
              <ScrollableDiv width='800px' height='300px'>
                <UlNoBullets style={{ paddingLeft: '5px' }}>
                  {doneMigrations.map(m => (
                    <li key={m.id}>
                      <SelectableErrorText error={!m.success}>
                        <MigrationSpan migration={m} />
                      </SelectableErrorText>
                    </li>
                  ))}
                </UlNoBullets>
              </ScrollableDiv>
            </Box>
          </Card>
        </LoadingOverlay>
      </Page>
    </AuthorizedPage>
  );
};
