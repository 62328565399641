import { DataGridPro, GridColDef, GridRowsProp } from '@mui/x-data-grid-pro';
import useAuth from 'auth/UseAuth';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  downloadFile,
  orderedAt,
  sampleCount,
  sampleTrackingTransitionFileDialog,
  sampleTrackingTransitionUndoDialog,
  transition,
  transitionId,
} from '../../../util/Constants';
import {
  ExportTransitionManifest,
  GetSampleTrackingTransitionDetails,
  GetSampleTrackingTransitions,
  GetTransitionCounts,
  GetUndoableTransitionIds,
  Transition,
  TransitionCounts,
  TransitionDetails,
  TransitionDetailsPayload,
} from '../../../data/SampleTrackingTransitionData';
import { keyBy } from 'lodash';
import { renderSampleTrackingOrderSampleGridCell } from '../../../components/grid/cell/SampleTrackingOrderSampleGridCell';
import { renderSampleTrackingTransitionUndoGridCell } from '../../../components/grid/cell/SampleTrackingTransitionUndoGridCell';
import { renderSampleTrackingSupplementalFilesGridCell } from '../../../components/grid/cell/SampleTrackingSupplementalFilesGridCell';
import { LoadingIndicator } from '../../../components/LoadingIndicator';
import { ErrorManagement, LoadingState, LoadState } from '../../../components/LoadingStateUtil';
import { ErrorIndicator } from '../../../components/ErrorIndicator';
import { UseStateSetter } from '../../../util/TypeUtil';
import useMemoTranslation from '../../../hooks/UseMemoTranslation';
import { renderDownloadButtonGridCell } from '../../../components/grid/cell/DownloadButtonGridCell';
import { dateComparator, dateFormatter } from '../../../util/grid/TableUtils';
import { useResearchProjectTransitionPath } from '../../../components/hooks/UseResearchProjectTransitionPaths';
import { ResearchProjectTransitionPath } from '../../../data/ResearchProjectTransitionPathData';

export interface SampleTrackingOrderGridProps {
  researchProjectId: string;
}

export type SampleTrackingOrderRow = Omit<Transition, 'createdAt'> &
  Omit<Partial<TransitionDetails>, 'createdAt'> & {
    id: number;
    sampleCount?: number;
    supplementalFileCounts?: number;
  };

export const SampleTrackingOrderGrid = ({ researchProjectId }: SampleTrackingOrderGridProps) => {
  const { accessToken } = useAuth();

  const [loadingState, setLoadingState] = useState<LoadingState>({ status: 'NotStarted' });
  const [refreshTrigger, setRefreshTrigger] = useState<boolean>(true);

  const [transitionPaths] = useResearchProjectTransitionPath(researchProjectId, setLoadingState);

  const columns = useColumns(setRefreshTrigger, setLoadingState, researchProjectId, accessToken);
  const [rows, setRows] = useState<GridRowsProp<SampleTrackingOrderRow>>([]);

  const getRows = useCallback(
    (
      transitions: ReadonlyArray<Transition>,
      transitionDetails: TransitionDetailsPayload,
      undoableIds: ReadonlyArray<string>,
      counts: ReadonlyArray<TransitionCounts>,
      transitionPaths: ReadonlyArray<ResearchProjectTransitionPath>
    ) => {
      let index = 0;

      const countsByTransitionId = keyBy(counts, c => c.transitionId);
      const detailsById = keyBy(
        [
          ...transitionDetails.shippingDetails,
          ...transitionDetails.receivingDetails,
          ...transitionDetails.subsettingDetails,
          ...transitionDetails.extractionDetails,
          ...transitionDetails.libraryPrepDetails,
          ...transitionDetails.sequencingDetails,
        ],
        transitionId
      );
      const transitionById = keyBy(transitionPaths, i => i.configuredTransitionId);

      const rows: SampleTrackingOrderRow[] = transitions.map(r => {
        return {
          id: index++,
          ...r,
          ...(detailsById[r.transitionId ?? ''] ?? {}),
          transition: transitionById[r.configuredTransitionId ?? '']?.displayName,
          sampleTrackingTransitionSampleDialog: r.transitionId,
          sampleTrackingTransitionUndoDialog: undoableIds.includes(r.transitionId ?? '') ? r.transitionId : null,
          sampleTrackingTransitionFileDialog: r.transitionId,
          downloadFile: r.transitionId,
          researchProjectId: researchProjectId,
          sampleCount: countsByTransitionId[r.transitionId ?? '']?.transitionSampleCounts,
          supplementalFileCounts: countsByTransitionId[r.transitionId ?? '']?.supplementalFileCounts,
        };
      });

      return rows;
    },
    [researchProjectId]
  );

  useEffect(() => {
    return LoadState(setLoadingState, async () => {
      if (!(accessToken && researchProjectId !== undefined)) {
        return;
      }

      if (transitionPaths.length === 0) {
        return;
      }

      const [data, details, undoableIds, counts] = await Promise.all([
        await GetSampleTrackingTransitions(researchProjectId, accessToken),
        await GetSampleTrackingTransitionDetails(researchProjectId, accessToken),
        await GetUndoableTransitionIds(researchProjectId, accessToken),
        await GetTransitionCounts(researchProjectId, accessToken),
      ]);

      setRows(getRows(data, details, undoableIds, counts, transitionPaths));
    });
  }, [accessToken, researchProjectId, refreshTrigger, getRows, transitionPaths]);

  return (
    <>
      <DataGridPro
        rows={rows}
        columns={columns}
        density='compact'
        experimentalFeatures={{ columnGrouping: true }}
        initialState={{
          sorting: {
            sortModel: [{ field: orderedAt, sort: 'desc' }],
          },
        }}
      />
      <LoadingIndicator type={'Linear'} loadingState={loadingState} margin={'All'} />
      <ErrorIndicator loadingState={loadingState} />
    </>
  );
};

const useColumns = (
  setRefreshTrigger: UseStateSetter<boolean>,
  setLoadingStatus: UseStateSetter<LoadingState>,
  researchProjectId: string,
  accessToken: string | undefined
): GridColDef[] => {
  const { t } = useMemoTranslation();

  return useMemo(
    () => [
      {
        field: orderedAt,
        headerName: t(orderedAt),
        headerAlign: 'left',
        align: 'left',
        width: 150,
        sortComparator: dateComparator,
        valueFormatter: dateFormatter,
      },
      {
        field: transition,
        headerName: t(transition),
        headerAlign: 'left',
        align: 'left',
        minWidth: 150,
        maxWidth: 400,
        flex: 1,
        valueFormatter: ({ value }) => value && t(value),
      },
      {
        field: transitionId,
        headerName: t(transitionId),
        headerAlign: 'center',
        align: 'center',
        width: 150,
        renderCell: params =>
          renderSampleTrackingOrderSampleGridCell({
            ...params,
          }),
      },
      {
        field: sampleCount,
        headerName: t(sampleCount),
        headerAlign: 'center',
        align: 'center',
        width: 150,
      },
      {
        field: sampleTrackingTransitionUndoDialog,
        headerAlign: 'center',
        align: 'center',
        headerName: t('undo'),
        width: 75,
        renderCell: params =>
          renderSampleTrackingTransitionUndoGridCell({
            ...params,
            onClose: () => setRefreshTrigger(currentState => !currentState),
          }),
      },
      {
        field: sampleTrackingTransitionFileDialog,
        headerAlign: 'center',
        align: 'center',
        headerName: t('numOfFiles'),
        width: 75,
        renderCell: params =>
          renderSampleTrackingSupplementalFilesGridCell({
            ...params,
            scope: 'Transition',
            numberOfFiles: params.row.supplementalFileCounts,
            transitionType: params.row.transition,
          }),
      },
      {
        field: downloadFile,
        headerAlign: 'center',
        align: 'center',
        headerName: t('downloadManifest'),
        width: 100,
        renderCell: params =>
          renderDownloadButtonGridCell({
            ...params,
            downloadOperation: () => {
              ErrorManagement('Loading', setLoadingStatus, async () => {
                await ExportTransitionManifest(researchProjectId, params.row.transitionId, accessToken);
                setLoadingStatus({ status: 'Complete' });
              });
            },
          }),
      },
    ],
    [t, setRefreshTrigger, setLoadingStatus, researchProjectId, accessToken]
  );
};
