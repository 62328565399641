import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import React, { useEffect, useState } from 'react';
import { SampleTrackingFastQResolutionModal } from '../../../researchProjectManagement/sampleTracking/SampleTrackingFastQResolutionModal';
import { SampleTrackingCheckByFastqValidationResultPayload } from '../../../data/SampleTrackingData';
import { Dictionary } from '../../../util/TypeUtil';
import { find, trim } from 'lodash';
import {
  FastQUpdateFunction,
  SetValidityFunction,
} from '../../../researchProjectManagement/sampleTracking/SampleTrackingIdMappingConfirmationGrid';

export type FastQValidationState =
  | 'Invalid'
  | 'UnknownFile'
  | 'Valid'
  | 'Required'
  | 'RequiredAndHasRecommendation'
  | 'NotRequired'
  | 'NotRequiredAndHasRecommendation'
  | 'DoesNotMatchRecommendationAndInvalidFile'
  | 'DoesNotMatchRecommendationButKnownFile';

interface SampleTrackingFastQResolutionGridCellCellProps extends GridRenderCellParams {
  r1FastQResolution?: string;
  r2FastQResolution?: string;
  fastQValidationData: Dictionary<SampleTrackingCheckByFastqValidationResultPayload>;
  type: 'R1' | 'R2';
  onFastQUpdate: FastQUpdateFunction;
  setValidity: SetValidityFunction;
}

export function renderSampleTrackingFastQResolutionGridCellCell(
  params: SampleTrackingFastQResolutionGridCellCellProps
) {
  return <MemoizedSampleTrackingFastQResolutionGridCellCell {...params} />;
}

const SampleTrackingFastQResolutionGridCellCell = ({
  value,
  row,
  fastQValidationData,
  type,
  onFastQUpdate,
  setValidity,
}: SampleTrackingFastQResolutionGridCellCellProps) => {
  const [isR1Valid, setIsR1Valid] = useState<boolean>(false);
  const [r1ValidationState, setR1ValidationState] = useState<FastQValidationState>('Invalid');
  const [isR2Valid, setIsR2Valid] = useState<boolean>(false);
  const [r2ValidationState, setR2ValidationState] = useState<FastQValidationState>('Invalid');
  const [currentData, setCurrentData] = useState<SampleTrackingCheckByFastqValidationResultPayload>();

  useEffect(() => {
    const currentTmp = fastQValidationData[row.transitionId];
    setCurrentData(currentTmp);

    {
      const userInputPresent = !(row.r1FastQLocation === undefined || row.r1FastQLocation.length === 0);
      const recommendationPresent = !(
        row.r1FastQLocationRecommendation === undefined || row.r1FastQLocationRecommendation.length === 0
      );

      if (!!row.existingSequenceRun) {
        setIsR1Valid(true);
        setIsR2Valid(true);
        return;
      }

      let r1State: FastQValidationState = 'Invalid';
      let r1DoesNotMatchRecommendation = false;

      // 1. If no user input, then R1 is required
      if (!userInputPresent) {
        r1State = 'Required';

        // 2. if no user input but recommendation present
        if (recommendationPresent) {
          r1State = 'RequiredAndHasRecommendation';
        }
      }

      // 3. if user input present and recommendation present and they do not match
      if (
        userInputPresent &&
        recommendationPresent &&
        trim(row.r1FastQLocation ?? '').toLowerCase() !== trim(row.r1FastQLocationRecommendation ?? '').toLowerCase()
      ) {
        r1DoesNotMatchRecommendation = true;
      }

      // 4. does file match any possibles
      if (
        userInputPresent &&
        find(currentTmp.possibleFiles, file => file.toLowerCase() === row.r1FastQLocation.toLowerCase()) !== undefined
      ) {
        if (r1DoesNotMatchRecommendation) {
          r1State = 'DoesNotMatchRecommendationButKnownFile';
        } else {
          r1State = 'Valid';
        }
      } else if (userInputPresent) {
        if (r1DoesNotMatchRecommendation) {
          r1State = 'DoesNotMatchRecommendationAndInvalidFile';
        } else {
          r1State = 'UnknownFile';
        }
      }

      setR1ValidationState(r1State);
      // @ts-ignore
      setIsR1Valid(r1State === 'Valid' || r1State === 'NotRequired' || r1State === 'NotRequiredAndHasRecommendation');
    }

    {
      const userInputPresent = !(row.r2FastQLocation === undefined || row.r2FastQLocation.length === 0);
      const recommendationPresent = !(
        row.r2FastQLocationRecommendation === undefined || row.r2FastQLocationRecommendation.length === 0
      );

      let r2State: FastQValidationState = 'Invalid';
      let r2DoesNotMatchRecommendation = false;

      // 1. If no user input, then R2 is not required
      if (!userInputPresent) {
        r2State = 'NotRequired';

        // 2. if no user input but recommendation present
        if (recommendationPresent) {
          r2State = 'NotRequiredAndHasRecommendation';
        }
      }

      // 3. if user input present and recommendation present and they do not match
      if (
        userInputPresent &&
        recommendationPresent &&
        trim(row.r2FastQLocation ?? '').toLowerCase() !== trim(row.r2FastQLocationRecommendation ?? '').toLowerCase()
      ) {
        r2DoesNotMatchRecommendation = true;
      }

      // 4. does file match any possibles
      if (
        userInputPresent &&
        find(currentTmp.possibleFiles, file => file.toLowerCase() === row.r2FastQLocation.toLowerCase()) !== undefined
      ) {
        if (r2DoesNotMatchRecommendation) {
          r2State = 'DoesNotMatchRecommendationButKnownFile';
        } else {
          r2State = 'Valid';
        }
      } else if (userInputPresent) {
        if (r2DoesNotMatchRecommendation) {
          r2State = 'DoesNotMatchRecommendationAndInvalidFile';
        } else {
          r2State = 'UnknownFile';
        }
      }

      setIsR2Valid(r2State === 'Valid' || r2State === 'NotRequired' || r2State === 'NotRequiredAndHasRecommendation');
      setR2ValidationState(r2State);
    }
  }, [row, fastQValidationData]);

  useEffect(() => {
    setValidity(row.outputId, isR1Valid && isR2Valid);
  }, [row, isR1Valid, isR2Valid, setValidity]);

  return (
    <>
      {currentData && (
        <SampleTrackingFastQResolutionModal
          isValid={type === 'R1' ? isR1Valid : isR2Valid}
          isR1Valid={isR1Valid}
          isR2Valid={isR2Valid}
          fileValidationStates={[r1ValidationState, r2ValidationState]}
          row={row}
          fastQValidationData={currentData}
          onFastQUpdate={onFastQUpdate}
          value={value}
        />
      )}
    </>
  );
};

const MemoizedSampleTrackingFastQResolutionGridCellCell = React.memo(
  SampleTrackingFastQResolutionGridCellCell,
  (prevProps, nextProps) => {
    return prevProps.row === nextProps.row && prevProps.fastQValidationData === nextProps.fastQValidationData;
  }
);
