import { BaseGet, BasePostVoid } from './BaseData';
import { appSettings } from '../AppSettings';
import { TransitionEnum } from './ReferenceData';

export interface ResearchProjectTransitionPath {
  researchProjectId: string;
  configuredTransitionId: string;
  researchProjectTransitionPathId: string;
  transitionOrder: number;
  transitionEnum: TransitionEnum;
  displayName: string;
  canBeDeleted: boolean;
}
export interface ProcessResearchProjectTransitionPath {
  researchProjectId: string;
  researchProjectTransitionPathId?: string;
  transitionOrder: number;
  transitionEnumId: string;
  displayName: string;
  isDeleted: boolean;
}

export async function GetResearchProjectTransitionPath(
  researchProjectId: string,
  accessToken?: string
): Promise<ReadonlyArray<ResearchProjectTransitionPath>> {
  return await BaseGet(
    `${appSettings.api.endpoint}/api/v2/ResearchProjectTransitionPath/${researchProjectId}`,
    accessToken
  );
}

export async function ProcessResearchProjectTransitionPaths(
  data: ReadonlyArray<ProcessResearchProjectTransitionPath>,
  accessToken?: string
): Promise<void> {
  await BasePostVoid(`${appSettings.api.endpoint}/api/v2/ResearchProjectTransitionPath/process`, data, accessToken);
}

export async function SetTransitionPathToDefaultAsync(researchProjectId: string, accessToken?: string): Promise<void> {
  await BasePostVoid(
    `${appSettings.api.endpoint}/api/v2/ResearchProjectTransitionPath/setToDefault/${researchProjectId}`,
    {},
    accessToken
  );
}
