import { GridRowsProp } from '@mui/x-data-grid';
import { BiobankCountGridCellProps } from './BiobankCountGridCell';
import { ConvertAllCodesToUserFormat } from 'data/conversions/Icd10CodeConversions';
import { DiseaseArea } from 'data/DiseaseAreaData';
import { DiseaseAreaCountByBiobank, DiseaseAreaCountBySampleType } from 'data/DiseaseAreaCountData';
import { SampleTypeCountGridCellProps } from './SampleTypeCountGridCell';
import { PatientCountType } from 'components/grid/GridCountType';

export async function GetDiseaseAreaCountByBiobankRows(
  diseaseAreas: DiseaseArea[],
  counts: DiseaseAreaCountByBiobank[],
  onBiobankCountClick: (
    count: number,
    diseaseArea: DiseaseArea,
    patientRecordCountType: PatientCountType,
    biobankId?: string,
    sampleTypeId?: string
  ) => void
): Promise<GridRowsProp> {
  const rows: any[] = [];
  let id = 1;

  diseaseAreas.forEach(d => {
    let row: any = {};
    row.id = id++;
    row.diseaseArea = d;
    row.icd10Codes = ConvertAllCodesToUserFormat(d.icd10Codes).join(', ');
    row.dashboard = d.availableToShowOnDashboard;
    row.lastUpdated = counts.length > 0 ? new Date(counts[0].updatedAt) : undefined;

    counts.forEach(c => {
      if (c.diseaseAreaId === d.diseaseAreaId) {
        let count: BiobankCountGridCellProps = {
          diseaseArea: d,
          biobankId: c.biobankId,
          count: c.patientCount,
          onBiobankCountClick: onBiobankCountClick,
        };

        Object.defineProperty(row, `${c.biobankId}`, { value: count, writable: true });
      }
    });

    let totalCount = {
      diseaseArea: d,
    };
    row.total = totalCount;

    rows.push(row);
  });

  return rows;
}

export async function GetDiseaseAreaCountBySampleTypeRows(
  diseaseAreas: DiseaseArea[],
  counts: DiseaseAreaCountBySampleType[],
  onSampleTypeCountClick: (
    count: number,
    diseaseArea: DiseaseArea,
    patientRecordCountType: PatientCountType,
    biobankId?: string,
    sampleTypeId?: string
  ) => void
): Promise<GridRowsProp> {
  const rows: any[] = [];
  let id = 1;

  diseaseAreas.forEach(d => {
    let row: any = {};
    row.id = id++;
    row.diseaseArea = d;
    row.icd10Codes = ConvertAllCodesToUserFormat(d.icd10Codes).join(', ');
    row.dashboard = d.availableToShowOnDashboard;
    row.lastUpdated = counts.length > 0 ? new Date(counts[0].updatedAt) : undefined;

    counts.forEach(c => {
      if (c.diseaseAreaId === d.diseaseAreaId) {
        let count: SampleTypeCountGridCellProps = {
          diseaseArea: d,
          sampleTypeId: c.sampleTypeId,
          count: c.patientCount,
          onSampleTypeCountClick: onSampleTypeCountClick,
        };

        Object.defineProperty(row, `${c.sampleTypeId}`, { value: count, writable: true });
      }
    });

    let totalCount = {
      diseaseArea: d,
    };
    row.total = totalCount;

    rows.push(row);
  });

  return rows;
}
