/*
 * From https://jamiehaywood.medium.com/typesafe-global-state-with-typescript-react-react-context-c2df743f3ce
 */

import { User } from '@auth0/auth0-spa-js';
import { createContext, Dispatch, SetStateAction, useContext, useState } from 'react';

export interface GlobalStateInterface {
  accessToken: string | undefined;
  accessTokenExpired: (token: string | undefined) => boolean | undefined;
  user: User | undefined;
  isAuthenticated: boolean;
  isLoading: boolean;
}

const GlobalStateContext = createContext({
  globalState: {} as Partial<GlobalStateInterface>,
  setGlobalState: {} as Dispatch<SetStateAction<Partial<GlobalStateInterface>>>,
});

const GlobalStateProvider = ({
  children,
  value = {} as GlobalStateInterface,
}: {
  children: React.ReactNode;
  value?: Partial<GlobalStateInterface>;
}) => {
  const [globalState, setGlobalState] = useState(value);
  return <GlobalStateContext.Provider value={{ globalState, setGlobalState }}>{children}</GlobalStateContext.Provider>;
};

const useGlobalState = () => {
  const context = useContext(GlobalStateContext);
  if (!context) {
    throw new Error('useGlobalState must be used within a GlobalStateContext');
  }
  return context;
};

export { GlobalStateProvider, useGlobalState };
