import { appSettings } from 'AppSettings';
import { orderBy } from 'lodash';
import { DateAggregate } from 'historicCounts/DateAggregate';

export interface HistoricCount {
  date: Date;
  counts: any[];
}

export interface DiseaseAreaCount {
  icd10Codes: string[];
  totalPatients: number;
}

export async function GetBiobankPatientTotal(
  accessToken: string,
  startDate?: Date,
  endDate?: Date,
  diseaseAreas?: string[],
  biobankIds?: string[],
  sampleTypeIds?: string[]
): Promise<number> {
  let total = 0;

  if (accessToken) {
    try {
      if (startDate && endDate && diseaseAreas && biobankIds && sampleTypeIds) {
        let url = `${appSettings.api.endpoint}/api/v2/BiobankMetrics/CurrentPatientTotal/Filtered`;
        let params = new URLSearchParams();
        params.append('startDate', startDate.toISOString().split('T')[0]);
        params.append('endDate', endDate.toISOString().split('T')[0]);
        diseaseAreas.forEach(d => params.append('diseaseAreaIds', d));
        biobankIds.forEach(b => params.append('biobankIds', b));
        sampleTypeIds.forEach(s => params.append('sampleTypeIds', s));

        const response = await fetch((url += '?' + params), {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        if (response.ok) {
          total = await response.json();
        }
      } else {
        const response = await fetch(`${appSettings.api.endpoint}/api/v2/BiobankMetrics/CurrentPatientTotal`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
        if (response.ok) {
          total = await response.json();
        }
      }
    } catch (err) {
      console.dir(err);
    }
  }
  return total;
}

export async function GetBiobankSampleTotal(accessToken: string): Promise<number> {
  let total = 0;

  if (accessToken) {
    try {
      const response = await fetch(`${appSettings.api.endpoint}/api/v2/BiobankMetrics/CurrentSampleTotal`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      if (response.ok) {
        total = await response.json();
      }
    } catch (err) {
      console.dir(err);
    }
  }
  return total;
}

export async function GetLabPatientTotal(labIds: string[], accessToken: string): Promise<number> {
  let total = 0;

  if (accessToken) {
    let url = `${appSettings.api.endpoint}/api/v2/LabMetrics/CurrentPatientTotal`;
    let params = new URLSearchParams();
    labIds.forEach(l => params.append('labIds', l));

    try {
      const response = await fetch((url += '?' + params), {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      if (response.ok) {
        total = await response.json();
      }
    } catch (err) {
      console.dir(err);
    }
  }
  return total;
}

export async function GetLabSampleTotal(labIds: string[], accessToken: string): Promise<number> {
  let total = 0;

  if (accessToken) {
    let url = `${appSettings.api.endpoint}/api/v2/LabMetrics/CurrentSampleTotal`;
    let params = new URLSearchParams();
    labIds.forEach(l => params.append('labIds', l));

    try {
      const response = await fetch((url += '?' + params), {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      if (response.ok) {
        total = await response.json();
      }
    } catch (err) {
      console.dir(err);
    }
  }
  return total;
}

export async function GetBiobankHistoricCounts(
  accessToken: string,
  startDate: Date,
  endDate: Date,
  dateAggregation: DateAggregate,
  biobankIds: string[],
  countType: string
): Promise<HistoricCount[]> {
  let counts: HistoricCount[] = [];

  if (accessToken && biobankIds.length > 0) {
    let url = `${appSettings.api.endpoint}/api/v2/BiobankMetrics/HistoricCounts/Filtered`;
    let params = new URLSearchParams();
    params.append('startDate', startDate.toISOString().split('T')[0]);
    params.append('endDate', endDate.toISOString().split('T')[0]);
    params.append('dateAggregation', dateAggregation);
    biobankIds.forEach(b => params.append('biobankIds', b));
    params.append('countType', countType);

    try {
      const response = await fetch((url += '?' + params), {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      if (response.ok) {
        const values: HistoricCount[] = await response.json();
        values.forEach(count => (count.date = new Date(count.date)));
        counts = [...orderBy(values, ['date'], ['asc'])];
      }
    } catch (err) {
      console.dir(err);
    }
  }
  return counts;
}

export async function GetLabHistoricCounts(
  accessToken: string,
  startDate: Date,
  endDate: Date,
  dateAggregation: DateAggregate,
  labIds: string[],
  countType: string
): Promise<HistoricCount[]> {
  let counts: HistoricCount[] = [];

  if (accessToken && labIds.length > 0) {
    let url = `${appSettings.api.endpoint}/api/v2/LabMetrics/HistoricCounts/Filtered`;
    let params = new URLSearchParams();
    params.append('startDate', startDate.toISOString().split('T')[0]);
    params.append('endDate', endDate.toISOString().split('T')[0]);
    params.append('dateAggregation', dateAggregation);
    labIds.forEach(b => params.append('labIds', b));
    params.append('countType', countType);

    try {
      const response = await fetch((url += '?' + params), {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      if (response.ok) {
        const values: HistoricCount[] = await response.json();
        values.forEach(count => (count.date = new Date(count.date)));
        counts = [...orderBy(values, ['date'], ['asc'])];
      }
    } catch (err) {
      console.dir(err);
    }
  }
  return counts;
}

export async function GetDiseaseAreaHistoricCounts(
  accessToken: string,
  startDate: Date,
  endDate: Date,
  dateAggregation: DateAggregate,
  diseaseAreaIds: string[],
  biobankIds: string[],
  sampleTypeIds: string[]
): Promise<HistoricCount[]> {
  let counts: HistoricCount[] = [];

  if (accessToken && diseaseAreaIds.length > 0 && biobankIds.length > 0 && sampleTypeIds.length > 0) {
    let url = `${appSettings.api.endpoint}/api/v2/DiseaseAreaMetrics/HistoricCounts/Filtered`;
    let params = new URLSearchParams();
    params.append('startDate', startDate.toISOString().split('T')[0]);
    params.append('endDate', endDate.toISOString().split('T')[0]);
    params.append('dateAggregation', dateAggregation);
    diseaseAreaIds.forEach(d => params.append('diseaseAreaIds', d));
    biobankIds.forEach(b => params.append('biobankIds', b));
    sampleTypeIds.forEach(s => params.append('sampleTypeIds', s));

    try {
      const response = await fetch((url += '?' + params), {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      if (response.ok) {
        const values: HistoricCount[] = await response.json();
        values.forEach(count => (count.date = new Date(count.date)));
        counts = [...orderBy(values, ['date'], ['asc'])];
      }
    } catch (err) {
      console.dir(err);
    }
  }
  return counts;
}
