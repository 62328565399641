import { BaseGet } from './BaseData';
import { appSettings } from '../AppSettings';

export type InformaticsPipeline = {
  pipelineId: string;
  organismTypeId: number;
  organismTypeName: string;
  libraryTypeId: string;
  libraryTypeName: string;
  name: string;
  isAvailable: boolean;
  versionNumber: string;
  supportedReferences: ReadonlyArray<InformaticsReference>;
};

export type InformaticsReference = {
  referenceTypeId: number;
  name: string;
  baseName: string;
  relativePath: string;
};

export async function GetPipelines(accessToken?: string): Promise<ReadonlyArray<InformaticsPipeline>> {
  return await BaseGet(`${appSettings.api.endpoint}/api/v2/Informatics/Pipelines`, accessToken);
}

export async function GetReferences(accessToken?: string): Promise<ReadonlyArray<InformaticsReference>> {
  return await BaseGet(`${appSettings.api.endpoint}/api/v2/Informatics/References`, accessToken);
}
