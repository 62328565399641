import { Box } from '@mui/material';

export const SidebarFooter = () => {
  return (
    <Box
      sx={{
        height: 50,
      }}
      display='flex'
      alignItems='center'
      justifyContent='center'
    />
  );
};
