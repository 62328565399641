import { Button, Typography, useTheme } from '@mui/material';
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
import useAuth from 'auth/UseAuth';
import React, { useState } from 'react';
import { RefreshPatientEnrichments } from '../../data/EnrichmentsData';
import RefreshIcon from '@mui/icons-material/Refresh';

export interface Props {
  researchProjectId: string;
}

export const RefreshClinicalDataButton = ({ researchProjectId }: Props) => {
  const { accessToken } = useAuth();
  const theme = useTheme();

  const [isRefreshing, setIsRefreshing] = useState(false);

  const refreshEnrichments = async () => {
    if (accessToken) {
      setIsRefreshing(true);
      await RefreshPatientEnrichments(researchProjectId, accessToken);
      setIsRefreshing(false);
    }
  };

  return (
    <Button
      id='research-project-cohort-export-button'
      onClick={async () => {
        await refreshEnrichments();
      }}
      disabled={isRefreshing}
      sx={[
        {
          color: theme.colors.alpha.black[100],
          '&:hover': {
            backgroundColor: 'transparent',
          },
        },
      ]}
    >
      {isRefreshing ? <HourglassBottomIcon /> : <RefreshIcon />}
      <Typography variant='button' ml={1}>{`Refresh Clinical Data `}</Typography>
    </Button>
  );
};
