import { ReactNode } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { appSettings } from 'AppSettings';

export type Role = 'll-green-side-user' | 'll-sys-admin';

interface Props {
  requiredRole: Role;
  children?: ReactNode;
}
export const AuthorizedSection = ({ requiredRole, children }: Props) => {
  const { user } = useAuth0();

  if (user !== undefined) {
    if (`${appSettings.auth.audience}/roles` in user) {
      const found = user[`${appSettings.auth.audience}/roles`].find((role: string) => role === requiredRole);
      if (found) {
        return <>{children}</>;
      }
    }
  }

  return <></>;
};
