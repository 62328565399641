import { Accordion, Card } from 'react-bootstrap';
import { CustomToggle, getAccordianExpansionSymbolCOPY } from './HistoricCountFilters';
import { ChangeEvent } from 'react';
import { Biobank } from 'data/BiobankData';

export interface BiobankFilterProps {
  eventKey: string;
  toggleSelection: string | undefined;
  setToggleSelection: Function;
  biobanks: Biobank[];
  handleBiobankSelection: (ev: ChangeEvent<HTMLInputElement>) => void;
  biobankSelectAll: boolean;
  handleBiobankSelectAll: (ev: ChangeEvent<HTMLInputElement>) => void;
}

export const BiobankFilter = ({
  eventKey,
  toggleSelection,
  setToggleSelection,
  biobanks,
  handleBiobankSelection,
  biobankSelectAll,
  handleBiobankSelectAll,
}: BiobankFilterProps) => {
  return (
    <Card bsPrefix='historic-counts-filters' key={`card-${eventKey}`}>
      <Card.Header bsPrefix='historic-counts-filters-subgroup'>
        <CustomToggle eventKey={eventKey} toggleSelection={toggleSelection} setToggleSelection={setToggleSelection}>
          <div>Biobank Facilities</div>
          <div>{getAccordianExpansionSymbolCOPY(eventKey, toggleSelection)}</div>
        </CustomToggle>
      </Card.Header>
      <Accordion.Collapse eventKey={eventKey}>
        <Card.Body bsPrefix='historic-counts-filters-accordian-item-selection'>
          <div key='bioanks-select-all-div'>
            <input
              className='historic-counts-filters-select-all-checkbox'
              type='checkbox'
              id='bioanks-select-all-chckbx'
              key='bioanks-select-all-chckbx'
              name='bioanks-select-all'
              value='bioanks-select-all'
              checked={biobankSelectAll}
              onChange={handleBiobankSelectAll}
            />
            <label className='historic-counts-filters-select-all-checkboxlbl' key='bioanks-select-all-chckbxlbl'>
              Select All
            </label>
          </div>
          {biobanks.map(biobank => (
            <div key={`${biobank.name} selection`} className='historic-counts-filters-accordian-item-test'>
              <input
                className='historic-counts-filters-accordian-item-checkbox'
                type='checkbox'
                id={biobank.name}
                key={`${biobank.name} chckbx`}
                name={biobank.name}
                value={biobank.name}
                checked={biobank.selected}
                onChange={handleBiobankSelection}
              />
              <label
                className='historic-counts-filters-accordian-item-checkboxlbl'
                htmlFor={biobank.name}
                key={`${biobank.name} lbl`}
              >
                {biobank.name}
              </label>
            </div>
          ))}
        </Card.Body>
      </Accordion.Collapse>
    </Card>
  );
};
