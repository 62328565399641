import React from 'react';
import { Auth0Provider } from '@auth0/auth0-react';
import { appSettings } from '../AppSettings';
import { useHistory } from 'react-router-dom';

export const Auth0ProviderWithHistory: React.FC = ({ children }) => {
  const history = useHistory();

  const onRedirectCallback = (appState: any) => {
    history.push(appState?.returnTo || window.location.pathname);
  };

  return (
    <Auth0Provider
      domain={appSettings.auth.domain}
      clientId={appSettings.auth.client_id}
      redirectUri={window.location.origin}
      scope={appSettings.auth.scope}
      audience={appSettings.auth.audience}
      onRedirectCallback={onRedirectCallback}
    >
      {children}
    </Auth0Provider>
  );
};

export default Auth0ProviderWithHistory;
