import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import React from 'react';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';

interface Props {
  url: string;
}

export const GoBack = ({ url }: Props) => {
  return (
    <Button
      component={Link}
      to={url}
      sx={[
        {
          fontWeight: 'inherit',
          justifyContent: 'left',
          padding: 0,
          '&:hover': {
            backgroundColor: 'transparent',
          },
          minWidth: 0,
          marginRight: 1,
        },
      ]}
    >
      <KeyboardBackspaceIcon />
    </Button>
  );
};
