import { TimeInterval } from 'data/ResearchProjectPatientData';

export interface TimeIntervalOption {
  label: string;
  timeInterval: TimeInterval;
}

export const TimeIntervalOptions: TimeIntervalOption[] = [
  {
    label: 'Past week',
    timeInterval: 'Week',
  },
  {
    label: 'Past month',
    timeInterval: 'Month',
  },
  {
    label: 'Past 3 months',
    timeInterval: 'ThreeMonths',
  },
  {
    label: 'Past year',
    timeInterval: 'Year',
  },
  {
    label: 'All time',
    timeInterval: 'AllTime',
  },
];
