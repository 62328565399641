import CheckIcon from '@mui/icons-material/Check';
import { GridRenderCellParams } from '@mui/x-data-grid';

export function renderCellBooleanCheck(params: GridRenderCellParams) {
  if (params.value) {
    return <CheckIcon />;
  } else {
    return <></>;
  }
}
