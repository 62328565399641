import { Box, styled, Theme, ToggleButton, ToggleButtonGroup, ToggleButtonGroupProps } from '@mui/material';
import { GridFilterInputValueProps } from '@mui/x-data-grid-pro';
import { InformaticsQcThresholdEnum } from 'data/InformaticsQcData';

export function ThresholdInputValue(props: GridFilterInputValueProps) {
  const { item, applyValue } = props;

  const handleFilterChange: ToggleButtonGroupProps['onChange'] = (ev, newValue) => {
    applyValue({ ...item, value: newValue });
  };

  return (
    <Box
      sx={{
        display: 'inline-flex',
        flexDirection: 'row',
        alignItems: 'center',
        pl: '20px',
      }}
    >
      <StyledToggleButtonGroup
        id={`custom-threshold-filter-input-${item.field}-${item.id}}`}
        value={Number(item.value)}
        exclusive
        onChange={handleFilterChange}
        size='small'
      >
        <StyledToggleButton value={InformaticsQcThresholdEnum.Fail} aria-label='fail' />
        <StyledToggleButton value={InformaticsQcThresholdEnum.Warn} aria-label='warn' />
        <StyledToggleButton value={InformaticsQcThresholdEnum.Pass} aria-label='pass' />
      </StyledToggleButtonGroup>
    </Box>
  );
}

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  '& .MuiToggleButtonGroup-grouped': {
    margin: theme.spacing(0.7),
    border: 0,
    '&.Mui-disabled': {
      border: 0,
    },
    '&:not(:first-of-type)': {
      borderRadius: theme.shape.borderRadius,
    },
    '&:first-of-type': {
      borderRadius: theme.shape.borderRadius,
    },
  },
}));

const StyledToggleButton = styled(ToggleButton)(({ theme, value }) => ({
  backgroundColor: getBackgroundColor(value as InformaticsQcThresholdEnum, theme),
  height: 40,
  width: 40,
  '&:hover': {
    backgroundColor: getBackgroundColor(value as InformaticsQcThresholdEnum, theme),
    boxShadow: `${getBoxShadowColor(value as InformaticsQcThresholdEnum, theme)}`,
  },
  '&.Mui-selected': {
    backgroundColor: getBackgroundColor(value as InformaticsQcThresholdEnum, theme),
    border: `5px solid ${getBorderColor(value as InformaticsQcThresholdEnum, theme)}`,
  },
  '&.Mui-selected:hover': {
    backgroundColor: getBackgroundColor(value as InformaticsQcThresholdEnum, theme),
    boxShadow: `${getBoxShadowColor(value as InformaticsQcThresholdEnum, theme)}`,
    border: `5px solid ${getBorderColor(value as InformaticsQcThresholdEnum, theme)}`,
  },
}));

function getBackgroundColor(selection: InformaticsQcThresholdEnum, theme: Theme) {
  switch (selection) {
    case InformaticsQcThresholdEnum.Fail:
      return theme.colors.error.light;
    case InformaticsQcThresholdEnum.Warn:
      return theme.colors.warning.light;
    case InformaticsQcThresholdEnum.Pass:
      return theme.colors.success.light;
  }
}

function getBoxShadowColor(selection: InformaticsQcThresholdEnum, theme: Theme) {
  switch (selection) {
    case InformaticsQcThresholdEnum.Fail:
      return theme.colors.shadows.error;
    case InformaticsQcThresholdEnum.Warn:
      return theme.colors.shadows.warning;
    case InformaticsQcThresholdEnum.Pass:
      return theme.colors.shadows.success;
  }
}

function getBorderColor(selection: InformaticsQcThresholdEnum, theme: Theme) {
  switch (selection) {
    case InformaticsQcThresholdEnum.Fail:
      return theme.colors.error.dark;
    case InformaticsQcThresholdEnum.Warn:
      return theme.colors.warning.dark;
    case InformaticsQcThresholdEnum.Pass:
      return theme.colors.success.dark;
  }
}
