import './DatavantDatasets.css';
import { FC, useEffect, useState } from 'react';
import { DatavantSftpFile, GetSftpFiles } from 'data/DatavantTokenData';
import useAuth from 'auth/UseAuth';
import { Box } from '@mui/material';
import { DatavantSftpTree } from './DatavantSftpTree';
import { Spinner } from 'react-bootstrap';

export const DatavantDatasets: FC = () => {
  const { accessToken } = useAuth();

  const [, setIsSpinnerActive] = useState<boolean>(false);
  const [datasets, setDatasets] = useState<DatavantSftpFile[]>([]);
  useEffect(() => {
    async function getState() {
      if (accessToken) {
        setIsSpinnerActive(false);
        setDatasets(await GetSftpFiles('datasets', accessToken));
      }
    }

    setIsSpinnerActive(true);
    getState();
  }, [accessToken]);

  if (!datasets.length) {
    return <Spinner animation='border' />;
  }
  return (
    <Box>
      <DatavantSftpTree files={datasets} />
    </Box>
  );
};
