import { GRID_TREE_DATA_GROUPING_FIELD, GridColDef, GridRenderCellParams } from '@mui/x-data-grid-pro';
import useAuth from 'auth/UseAuth';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  GetSampleTrackingCountsBySampleType,
  SampleTrackingCounts,
  TransitionType,
} from '../../data/SampleTrackingData';
import { GridCellCallBack } from '../../components/grid/GridCellCallback';
import {
  SampleTrackingSelectedTransitionSampleModal,
  SampleTrackingTransitionSampleModal,
} from './SampleTrackingTransitionSampleModal';
import { formatPatientSampleCount, generateWidthFromProperty } from '../../util/grid/TableUtils';
import { all, dashDash, sampleGroup, selectedSampleCount } from '../../util/Constants';
import { LoadingIndicator } from '../../components/LoadingIndicator';
import { ErrorIndicator } from '../../components/ErrorIndicator';
import { LoadingState, LoadState } from '../../components/LoadingStateUtil';
import { createCaseInsensitiveMap, Dictionary, UseStateSetter } from '../../util/TypeUtil';
import useMemoTranslation from '../../hooks/UseMemoTranslation';
import { CustomizableSwitch } from '../../components/CustomizableSwitch';
import { Box, Typography } from '@mui/material';
import { CompactGridWrapper } from '../../components/grid/CompactGridWrapper';
import { TransitionTableHeaderIcon } from '../../util/TransitionUtil';
import { useResearchProjectTransitionPath } from '../../components/hooks/UseResearchProjectTransitionPaths';
import { ResearchProjectTransitionPath } from '../../data/ResearchProjectTransitionPathData';
import { orderBy } from 'lodash';
import { SampleTrackingPathConfigurationModal } from './SampleTrackingPathConfigurationModal';

export interface SampleTrackingGridProps {
  researchProjectId: string;
}

export type TransitionTypeExtended = TransitionType | 'Selected';

interface TransitionRecordModalProps {
  open: boolean;
  title?: string;
  transitionType?: TransitionTypeExtended;
  sampleGroup?: string;
  configuredTransitionId?: string;
}

type Row = {
  id: number;
  sampleGroup: string;
  hierarchy: string[];
  sampleCounts: Dictionary<number>;
  patientCounts: Dictionary<number>;
};

export const SampleTrackingGrid = ({ researchProjectId }: SampleTrackingGridProps) => {
  const { accessToken } = useAuth();
  const { t } = useMemoTranslation();

  const [refreshTrigger, setRefreshTrigger] = useState<boolean>(true);
  const [loadingState, setLoadingState] = useState<LoadingState>({ status: 'NotStarted' });

  const [countInputsWithWorkLeft, setCountInputsWithWorkLeft] = useState<boolean>(true);
  const [transitionRecordModalProps, setTransitionRecordModalProps] = useState<TransitionRecordModalProps>({
    open: false,
  });

  const [data, setData] = useState<ReadonlyArray<SampleTrackingCounts>>([]);
  const [transitionPath, transitionPathRefreshTrigger] = useResearchProjectTransitionPath(
    researchProjectId,
    setLoadingState
  );

  const columns = useColumns(setTransitionRecordModalProps, transitionPath);
  const rows = useRows(data, countInputsWithWorkLeft);

  useEffect(() => {
    return LoadState(setLoadingState, async () => {
      if (!(accessToken && researchProjectId !== undefined)) {
        return;
      }

      let rows = await GetSampleTrackingCountsBySampleType(researchProjectId, accessToken);

      setData(rows);
    });
  }, [accessToken, researchProjectId, refreshTrigger, transitionPath]);

  return (
    <>
      {loadingState.status === 'Complete' && (
        <>
          <Box display='flex' flexDirection='row' alignItems='center' mb={1} justifyContent='space-between'>
            <Typography variant='subtitle1'>{t('sampleTrackingGridInfo')}</Typography>
            <Box display='flex' flexDirection='row' alignItems='center'>
              <CustomizableSwitch
                title={t('countInputsWithWorkLeft')}
                informationText={t('countInputsWithWorkLeftInfo')}
                checked={!countInputsWithWorkLeft}
                onChange={() => {
                  setCountInputsWithWorkLeft(!countInputsWithWorkLeft);
                }}
              />
              <SampleTrackingPathConfigurationModal
                onClose={hasChanges => {
                  if (hasChanges) {
                    transitionPathRefreshTrigger();
                  }
                }}
              />
            </Box>
          </Box>
          <CompactGridWrapper
            rows={rows}
            columns={columns}
            disableColumnFilter={true}
            disableColumnMenu={true}
            initialState={{
              pinnedColumns: { left: [GRID_TREE_DATA_GROUPING_FIELD] },
              sorting: {
                sortModel: [{ field: GRID_TREE_DATA_GROUPING_FIELD, sort: 'asc' }],
              },
            }}
            density='compact'
            hideFooterSelectedRowCount={true}
            getTreeDataPath={row => row?.hierarchy}
            treeData
            groupingColDef={{
              headerAlign: 'center',
              align: 'center',
              headerName: t(sampleGroup),
              width: generateWidthFromProperty(data ?? [], 150, (row: SampleTrackingCounts) => row.sampleGroup),
            }}
            disableRowSelectionOnClick
          />
          {transitionRecordModalProps.open && (
            <>
              {transitionRecordModalProps.transitionType === 'Selected' ? (
                <SampleTrackingSelectedTransitionSampleModal
                  researchProjectId={researchProjectId}
                  title={transitionRecordModalProps.title ?? ''}
                  sampleGroup={transitionRecordModalProps.sampleGroup}
                  transitionType={transitionRecordModalProps.transitionType}
                  onClose={() => {
                    setTransitionRecordModalProps({ open: false });
                    setRefreshTrigger(currentState => !currentState);
                  }}
                />
              ) : (
                <SampleTrackingTransitionSampleModal
                  researchProjectId={researchProjectId}
                  title={transitionRecordModalProps.title ?? ''}
                  sampleGroup={transitionRecordModalProps.sampleGroup}
                  transitionType={transitionRecordModalProps.transitionType}
                  configuredTransitionId={transitionRecordModalProps.configuredTransitionId ?? ''}
                  onClose={() => {
                    setTransitionRecordModalProps({ open: false });
                    setRefreshTrigger(currentState => !currentState);
                  }}
                />
              )}
            </>
          )}
        </>
      )}
      <LoadingIndicator type={'Circular'} loadingState={loadingState} margin={'LR'} />
      <ErrorIndicator loadingState={loadingState} />
    </>
  );
};

const useRows = (
  sampleTrackingBySampleTypes: ReadonlyArray<SampleTrackingCounts>,
  countInputsWithWorkLeft: boolean
) => {
  const { t } = useMemoTranslation();

  return useMemo(() => {
    let index = 0;

    const rows: Row[] = sampleTrackingBySampleTypes.map(row => {
      return {
        id: index++,
        hierarchy: row.sampleGroup === 'all' ? [t(all)] : [t(all), row.sampleGroup],
        patientCounts: createCaseInsensitiveMap(row.patientCounts)?.get(String(countInputsWithWorkLeft)) ?? {},
        sampleCounts: createCaseInsensitiveMap(row.sampleCounts)?.get(String(countInputsWithWorkLeft)) ?? {},
        sampleGroup: row.sampleGroup,
      };
    });

    return rows;
  }, [t, sampleTrackingBySampleTypes, countInputsWithWorkLeft]);
};

const useColumns = (
  setTransitionRecordModalProps: UseStateSetter<TransitionRecordModalProps>,
  transitionPath: ReadonlyArray<ResearchProjectTransitionPath>
): GridColDef[] => {
  const { t } = useMemoTranslation();

  const gridCellRendererHelper = useCallback(
    (transitionType: TransitionTypeExtended, configuredTransitionId: string, text?: string) => {
      return (params: GridRenderCellParams) => {
        if (params.value === undefined) {
          return <></>;
        }

        const label = params.value.toString().length === 0 ? dashDash : text ?? params.value ?? dashDash;
        const sampleGroup = params.row?.sampleGroup === all ? undefined : params.row?.sampleGroup;

        return (
          <GridCellCallBack
            text={label}
            textStyle={{ fontWeight: 'bold' }}
            callBack={() =>
              setTransitionRecordModalProps({
                open: true,
                title: `'${t(transitionType)}' Samples (${sampleGroup ?? t(all)})`,
                transitionType: transitionType,
                sampleGroup: sampleGroup,
                configuredTransitionId: configuredTransitionId,
              })
            }
          />
        );
      };
    },
    [t, setTransitionRecordModalProps]
  );

  return useMemo(
    () => [
      {
        field: selectedSampleCount,
        renderHeader: () => <TransitionTableHeaderIcon transitionType={'Selected'} header={t(selectedSampleCount)} />,
        headerAlign: 'center',
        align: 'center',
        minWidth: 100,
        maxWidth: 300,
        flex: 1,
        valueGetter: params => {
          return formatPatientSampleCount(params?.row, 'Selected');
        },
        renderCell: gridCellRendererHelper('Selected', 'idk'),
      },
      ...orderBy(transitionPath, o => o.transitionOrder).map((o): GridColDef => {
        const transitionType = o.transitionEnum.name as TransitionType;

        return {
          field: o.displayName,
          headerName: o.displayName,
          renderHeader: () => <TransitionTableHeaderIcon transitionType={transitionType} header={o.displayName} />,
          headerAlign: 'center',
          align: 'center',
          minWidth: 100,
          maxWidth: 300,
          flex: 1,
          valueGetter: params => {
            return formatPatientSampleCount(params?.row, o.displayName);
          },
          renderCell: gridCellRendererHelper(transitionType, o.configuredTransitionId),
        };
      }),
    ],
    [t, gridCellRendererHelper, transitionPath]
  );
};
