import { Box, Checkbox, FormControlLabel, FormLabel } from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { LightTooltip } from 'components/grid/cell/GridCellTooltip';

interface SamplesToIncludeCheckBoxesProps {
  disableCheckboxes?: boolean;
  includeBiobankedSamples: boolean;
  includeNonBiobankedSamples: boolean;
  onIncludeBiobankedSamplesChange?: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  onIncludeNonBiobankedSamplesChange?: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  helpText?: string;
}

export const SamplesToIncludeCheckBoxes = ({
  disableCheckboxes = false,
  includeBiobankedSamples,
  includeNonBiobankedSamples,
  onIncludeBiobankedSamplesChange,
  onIncludeNonBiobankedSamplesChange,
  helpText,
}: SamplesToIncludeCheckBoxesProps) => {
  return (
    <>
      <Box display='flex' alignItems='center' mt={2} mb={1}>
        <FormLabel>Samples to Include</FormLabel>

        {helpText && (
          <LightTooltip title={helpText}>
            <HelpOutlineIcon sx={{ marginLeft: 0.5, fontSize: 14 }} />
          </LightTooltip>
        )}
      </Box>
      <Box display='flex' flexDirection='column' alignItems='left'>
        <FormControlLabel
          label='Biobanked'
          control={
            <Checkbox
              checked={includeBiobankedSamples}
              disabled={disableCheckboxes}
              onChange={onIncludeBiobankedSamplesChange}
            />
          }
        />
        <FormControlLabel
          label='Non-biobanked'
          control={
            <Checkbox
              checked={includeNonBiobankedSamples}
              disabled={disableCheckboxes}
              onChange={onIncludeNonBiobankedSamplesChange}
            />
          }
        />
      </Box>
    </>
  );
};
