import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import React, { useState } from 'react';
import { SampleTrackingOrderRow } from './SampleTrackingOrderGrid';
import { SampleTrackingOrderGridDetails } from './SampleTrackingOrderGridDetails';
import { TransitionType } from '../../../data/SampleTrackingData';
import { DialogCloseButton } from '../../../components/DialogCloseButton';
import { FlexTableBox } from '../../../components/FlexTableBox';
import useMemoTranslation from '../../../hooks/UseMemoTranslation';
import { DialogOpenButton } from '../../../components/DialogOpenButton';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';

export interface SampleTrackingOrderGridDetailsModalProps {
  transitionType: TransitionType;
  row: SampleTrackingOrderRow;
  onClose?: () => void;
}

export const SampleTrackingOrderGridDetailsModal = ({
  transitionType,
  row,
  onClose = () => {},
}: SampleTrackingOrderGridDetailsModalProps) => {
  const { t } = useMemoTranslation();

  const [modalOpen, setModalOpen] = useState<boolean>(false);

  function handleClose() {
    onClose();
    setModalOpen(false);
  }

  return (
    <>
      <DialogOpenButton title={t('orderDetails')} onClick={() => setModalOpen(true)}>
        <OpenInFullIcon />
      </DialogOpenButton>
      <Dialog maxWidth={'lg'} fullWidth={true} onClose={handleClose} open={modalOpen}>
        <DialogTitle sx={{ paddingBottom: 0 }}>
          <DialogCloseButton onClick={() => handleClose()} />
          {[t(transitionType), t('details')].join(' ')}
        </DialogTitle>
        <DialogContent>
          <FlexTableBox>
            <SampleTrackingOrderGridDetails row={row} transitionType={transitionType} />
          </FlexTableBox>
        </DialogContent>
      </Dialog>
    </>
  );
};
