import React, { useCallback, useEffect, useState } from 'react';
import { ActionButton, DialogOpenButton } from '../../components/DialogOpenButton';
import useMemoTranslation from '../../hooks/UseMemoTranslation';
import useAuth from '../../auth/UseAuth';

import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import {
  Autocomplete,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from '@mui/material';
import { LoadingIndicator } from '../../components/LoadingIndicator';
import { ErrorIndicator } from '../../components/ErrorIndicator';
import { CancelButton } from '../../components/CancelButton';
import { PrimaryButton } from '../../components/PrimaryButton';
import { ErrorManagement, LoadingState } from '../../components/LoadingStateUtil';
import DraggableList, { DraggableItem } from '../../components/DraggableList';
import { useResearchProjectTransitionPath } from '../../components/hooks/UseResearchProjectTransitionPaths';
import { useParams } from 'react-router-dom';
import { DropResult } from 'react-beautiful-dnd';
import {
  ProcessResearchProjectTransitionPath,
  ProcessResearchProjectTransitionPaths,
  ResearchProjectTransitionPath,
  SetTransitionPathToDefaultAsync,
} from '../../data/ResearchProjectTransitionPathData';
import { TransitionIcon } from '../../util/TransitionUtil';
import { TransitionType } from '../../data/SampleTrackingData';
import { FlexBox } from '../../components/FlexBox';
import useTransitionEnums from '../../components/hooks/UseTransitionEnums';
import { filter, find, orderBy, remove, some } from 'lodash';
import { TransitionEnum } from '../../data/ReferenceData';
import { DialogCloseButton } from '../../components/DialogCloseButton';
import DeleteIcon from '@mui/icons-material/Delete';

export interface SampleTrackingPathConfigurationModalProps {
  onClose?: (hasChanges: boolean) => void;
}

type Step = DraggableItem & {
  base?: ResearchProjectTransitionPath;
};

interface CreateStepForm {
  name?: string;
  transitionType?: string;
  transitionEnum?: TransitionEnum;
}

export const SampleTrackingPathConfigurationModal = ({
  onClose = () => {},
}: SampleTrackingPathConfigurationModalProps) => {
  const { t } = useMemoTranslation();
  const { accessToken } = useAuth();
  let { researchProjectId } = useParams() as { researchProjectId: string };

  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [loadingState, setLoadingState] = useState<LoadingState>({ status: 'NotStarted' });
  const [draggableItems, setDraggableItems] = useState<Step[]>([]);
  const [createStepForm, setCreateStepForm] = useState<CreateStepForm>({});
  const [hasChanges, setHasChanges] = useState<boolean>(false);

  const transitionEnums = useTransitionEnums();
  const [transitionPaths, transitionPathRefreshTrigger] = useResearchProjectTransitionPath(
    researchProjectId,
    setLoadingState
  );

  let processDraggableItemsUpdate: (data: DraggableItem[]) => void = useCallback(() => {}, []);

  const removeItem = useCallback(
    (toCompare: string, items: DraggableItem[]) => {
      const data = remove(items, p => {
        return p.id !== toCompare;
      });

      processDraggableItemsUpdate(data);
      setHasChanges(true);
    },
    [processDraggableItemsUpdate, setHasChanges]
  );

  const setChildrenOnDraggableItem = useCallback(
    (i: Step, items: DraggableItem[]): Step => {
      i.children = !i.base?.canBeDeleted ? (
        <></>
      ) : (
        <ActionButton testId='transition-path-delete' onClick={() => removeItem(i.id, items)}>
          <DeleteIcon />
        </ActionButton>
      );

      return i;
    },
    [removeItem]
  );

  processDraggableItemsUpdate = useCallback(
    (data: DraggableItem[]) => {
      // the draggable items have a reference onto themselves in order to support deleting
      // therefore after generating the list we need to update each element to a reference of the new list
      data.forEach(i => setChildrenOnDraggableItem(i, data));

      setDraggableItems(data);
    },
    [setChildrenOnDraggableItem, setDraggableItems]
  );

  const toDraggableItem = useCallback(
    (i: ResearchProjectTransitionPath): Step => {
      return {
        id: i.displayName,
        primary: i.displayName,
        secondary: t(i.transitionEnum.name as any),
        icon: (
          <TransitionIcon transitionType={i.transitionEnum.name as TransitionType} variant='h4' color={'primary'} />
        ),
        children: <></>,
        base: i,
      };
    },
    [t]
  );

  useEffect(() => {
    if (!transitionPaths) {
      return;
    }
    const data = orderBy(
      transitionPaths.map(i => {
        return toDraggableItem(i);
      }),
      i => i.base?.transitionOrder ?? -1
    );

    processDraggableItemsUpdate(data);
  }, [transitionPaths, processDraggableItemsUpdate, toDraggableItem]);

  function handleClose(hasChanges: boolean = false) {
    setModalOpen(false);
    setCreateStepForm({});
    onClose(hasChanges);
    setLoadingState({ status: 'Complete' });
    transitionPathRefreshTrigger();
  }

  function handleAddStep() {
    const data = [
      ...draggableItems,
      {
        id: createStepForm.name ?? '',
        primary: createStepForm.name ?? '',
        secondary: createStepForm.transitionType ?? '',
        icon: (
          <TransitionIcon
            transitionType={createStepForm.transitionEnum?.name as TransitionType}
            variant='h4'
            color={'primary'}
          />
        ),
        children: <></>,
        base: {
          researchProjectId: researchProjectId,
          configuredTransitionId: '',
          transitionOrder: -1,
          transitionEnum: createStepForm.transitionEnum ?? ({} as any),
          displayName: createStepForm.name ?? '',
        },
      },
    ];

    setHasChanges(true);
    processDraggableItemsUpdate(data);
    setCreateStepForm({});
  }

  function handleSubmit() {
    ErrorManagement('Loading', setLoadingState, async () => {
      const nonDeleted: string[] = [];

      const toSave = draggableItems.map((i, index): ProcessResearchProjectTransitionPath => {
        nonDeleted.push(i.base?.researchProjectTransitionPathId ?? 'random_place_holder');

        return {
          researchProjectId: researchProjectId,
          researchProjectTransitionPathId: i.base?.researchProjectTransitionPathId,
          transitionOrder: index,
          transitionEnumId: i.base?.transitionEnum.transitionEnumId ?? '',
          displayName: i.base?.displayName ?? '',
          isDeleted: false,
        };
      });

      const toDelete = filter(transitionPaths, i => !nonDeleted.includes(i.researchProjectTransitionPathId)).map(
        (i): ProcessResearchProjectTransitionPath => {
          return {
            ...i,
            researchProjectId: researchProjectId,
            transitionEnumId: i.transitionEnum.transitionEnumId,
            isDeleted: true,
          };
        }
      );

      await ProcessResearchProjectTransitionPaths([...toSave, ...toDelete], accessToken);
      handleClose(true);
    });
  }

  function handleResetToDefault() {
    ErrorManagement('Loading', setLoadingState, async () => {
      await SetTransitionPathToDefaultAsync(researchProjectId, accessToken);
      handleClose(true);
    });
  }

  function handleDragEnd({ destination, source }: DropResult) {
    if (!destination) {
      return;
    }

    const result = Array.from(draggableItems);
    const [removed] = result.splice(source.index, 1);
    result.splice(destination.index, 0, removed);

    processDraggableItemsUpdate(result);
    setHasChanges(true);
  }

  return (
    <>
      <DialogOpenButton title={t('editPath')} onClick={() => setModalOpen(true)}>
        <SettingsOutlinedIcon />
      </DialogOpenButton>
      <Dialog open={modalOpen} onClose={() => handleClose()} maxWidth={'md'}>
        <DialogTitle>
          <DialogCloseButton onClick={() => handleClose()} />
          {t('pathConfigTitle')}
        </DialogTitle>

        <DialogContent sx={{ paddingBottom: 0 }}>
          <Typography sx={{ whiteSpace: 'pre-line' }}>{t('pathConfigDescription')}</Typography>
          <FlexBox>
            <Box sx={{ marginTop: 2, width: '40%', flexGrow: 1, marginRight: 1 }}>
              <Typography variant={'h5'} sx={{ marginBottom: 1 }}>
                {t('pathConfigOrder')}{' '}
              </Typography>
              <Box sx={{ maxHeight: '50vh', overflow: 'scroll' }}>
                <DraggableList items={draggableItems} onDragEnd={handleDragEnd}></DraggableList>
              </Box>
            </Box>
            <Box sx={{ width: '60%', marginTop: 2 }}>
              <Typography variant={'h5'}>{t('pathConfigNew')}</Typography>
              <Box component='form'>
                <TextField
                  fullWidth
                  label={t('nameRequired')}
                  margin='normal'
                  type='text'
                  variant='outlined'
                  value={createStepForm.name ?? ''}
                  onChange={value => setCreateStepForm({ ...createStepForm, name: value.target.value })}
                />
                <Autocomplete
                  fullWidth
                  options={transitionEnums.map(value => t(value.name as any))}
                  renderInput={params => <TextField {...params} label={t('stepType')} margin='normal' />}
                  onChange={(event, value) =>
                    setCreateStepForm({
                      ...createStepForm,
                      transitionType: value,
                      transitionEnum: find(transitionEnums, o => t(o.name as any) === value),
                      name:
                        createStepForm.name ?? t(find(transitionEnums, o => t(o.name as any) === value)?.name as any),
                    })
                  }
                  value={createStepForm.transitionType}
                />
                <FlexBox flexDirection={'row-reverse'}>
                  <PrimaryButton
                    disabled={
                      loadingState.status === 'Loading' ||
                      !createStepForm.name ||
                      !createStepForm.transitionType ||
                      some(draggableItems, i => i.base?.displayName === createStepForm.name)
                    }
                    onClick={handleAddStep}
                  >
                    {t('addStep')}
                  </PrimaryButton>
                </FlexBox>
              </Box>
            </Box>
          </FlexBox>
        </DialogContent>

        <LoadingIndicator loadingState={loadingState} margin={'LRT'} />
        <ErrorIndicator loadingState={loadingState} />

        <DialogActions>
          <FlexBox sx={{ width: '100%', flexDirection: 'row', justifyContent: 'space-between' }}>
            <Box>
              {!some(transitionPaths, i => !i.canBeDeleted) && (
                <PrimaryButton disabled={loadingState.status === 'Loading'} onClick={handleResetToDefault}>
                  {t('resetToDefault')}
                </PrimaryButton>
              )}
            </Box>
            <Box>
              <CancelButton onClick={() => handleClose()} />
              <PrimaryButton disabled={loadingState.status === 'Loading' || !hasChanges} onClick={handleSubmit}>
                {t('submit')}
              </PrimaryButton>
            </Box>
          </FlexBox>
        </DialogActions>
      </Dialog>
    </>
  );
};
