import { Accordion, useAccordionToggle } from 'react-bootstrap';
import { Biobank } from 'data/BiobankData';
import { ChangeEvent, useEffect, useState } from 'react';
import { ConsentEntitlement } from 'data/ConsentEntitlementData';
import { CountType, CountTypeSelector } from './CountTypeSelector';
import { DateAggregate } from './DateAggregate';
import { DateAggregateSelector } from './DateAggregateSelector';
import { DatePicker } from 'antd';
import { DiseaseArea } from 'data/DiseaseAreaData';
import moment from 'moment';
import { SampleTypeSelection } from 'data/SampleTypeData';
import { Lab } from 'data/LabData';
import { LabFilter } from './LabFilter';
import { BiobankFilter } from './BiobankFilter';
import { SampleTypeFilter } from './SampleTypeFilter';
import { DiseaseAreaFilter } from './DiseaseAreaFilter';
import { ConsentEntitlementFilter } from './ConsentEntitlementFilter';

export interface Props {
  countType?: CountType;
  dateAggregate?: DateAggregate;
  startDate?: Date;
  endDate?: Date;
  diseaseAreas?: DiseaseArea[];
  biobanks?: Biobank[];
  labs?: Lab[];
  sampleTypes?: SampleTypeSelection[];
  consentEntitlements?: ConsentEntitlement[];
  onCountTypeSelect?: Function;
  onDateAggregateSelect?: Function;
  onStartDateSelect?: Function;
  onEndDateSelect?: Function;
  onDiseaseAreaSelect?: Function;
  onBiobankSelect?: Function;
  onLabSelect?: Function;
  onSampleTypeSelect?: Function;
  onConsentSelect?: Function;
}

export const HistoricCountFilters = ({
  countType,
  dateAggregate,
  startDate,
  endDate,
  diseaseAreas,
  biobanks,
  labs,
  sampleTypes,
  consentEntitlements,
  onCountTypeSelect,
  onDateAggregateSelect,
  onStartDateSelect,
  onEndDateSelect,
  onDiseaseAreaSelect,
  onBiobankSelect,
  onLabSelect,
  onSampleTypeSelect,
  onConsentSelect,
}: Props) => {
  enum FilterEventKeys {
    DiseaseAreas,
    Biobanks,
    Labs,
    SampleTypes,
    ConsentEntitlements,
  }

  const [countTypeCache, setCountTypeCache] = useState<CountType>(countType ?? 'patient');
  const [dateAggregateCache, setDateAggregateCache] = useState<DateAggregate>(dateAggregate ?? 'day');
  const [startDateCache, setStartDateCache] = useState<Date>(new Date());
  const [endDateCache, setEndDateCache] = useState<Date>(new Date());
  const [diseaseAreaCache, setDiseaseAreaCache] = useState<DiseaseArea[]>([]);
  const [diseaseAreaSelectAll, setDiseaseAreaSelectAll] = useState<boolean>(true);
  const [biobankCache, setBiobankCache] = useState<Biobank[]>([]);
  const [biobankSelectAll, setBiobankSelectAll] = useState<boolean>(true);
  const [labCache, setLabCache] = useState<Lab[]>([]);
  const [labSelectAll, setLabSelectAll] = useState<boolean>(true);
  const [sampleTypeCache, setSampleTypeCache] = useState<SampleTypeSelection[]>([]);
  const [sampleTypeSelectAll, setSampleTypeSelectAll] = useState<boolean>(true);
  const [consentEntitlementCache, setConsentEntitlementCache] = useState<ConsentEntitlement[]>([]);
  const [toggleSelection, setToggleSelection] = useState<string>();

  useEffect(() => {
    async function getState() {
      if (diseaseAreas?.find(d => d.selected === false)) setDiseaseAreaSelectAll(false);
      if (biobanks?.find(b => b.selected === false)) setBiobankSelectAll(false);
      if (labs?.find(l => l.selected === false)) setLabSelectAll(false);
      if (sampleTypes?.find(s => s.selected === false)) setSampleTypeSelectAll(false);
    }
    getState();
  });

  useEffect(() => {
    if (countType) setCountTypeCache(countType);
    if (dateAggregate) setDateAggregateCache(dateAggregate);
    if (startDate) setStartDateCache(startDate);
    if (endDate) setEndDateCache(endDate);
    if (diseaseAreas) setDiseaseAreaCache([...diseaseAreas]);
    if (biobanks) setBiobankCache([...biobanks]);
    if (labs) setLabCache([...labs]);
    if (sampleTypes) setSampleTypeCache([...sampleTypes]);
    if (consentEntitlements) setConsentEntitlementCache([...consentEntitlements]);
  }, [countType, dateAggregate, startDate, endDate, diseaseAreas, biobanks, labs, sampleTypes, consentEntitlements]);

  const handleDiseaseAreaSelection = async (ev: ChangeEvent<HTMLInputElement>) => {
    let temp = [...diseaseAreaCache];
    let index = temp.findIndex(disease => disease.name === ev.target.name);
    temp[index].selected = !temp[index].selected;
    setDiseaseAreaCache([...temp]);
  };

  const handleDiseaseAreaSelectAll = async (ev: ChangeEvent<HTMLInputElement>) => {
    let temp = [...diseaseAreaCache];
    temp.forEach(disease => (disease.selected = !diseaseAreaSelectAll));
    setDiseaseAreaCache([...temp]);
    setDiseaseAreaSelectAll(!diseaseAreaSelectAll);
  };

  const handleBiobankSelection = async (ev: ChangeEvent<HTMLInputElement>) => {
    if (biobanks) {
      let temp = [...biobankCache];
      let index = temp.findIndex(biobank => biobank.name === ev.target.name);
      biobanks[index].selected = !biobanks[index].selected;
      setBiobankCache([...temp]);
    }
  };

  const handleBiobankSelectAll = async (ev: ChangeEvent<HTMLInputElement>) => {
    let temp = [...biobankCache];
    temp.forEach(biobank => (biobank.selected = !biobankSelectAll));
    setBiobankCache([...temp]);
    setBiobankSelectAll(!biobankSelectAll);
  };

  const handleLabSelection = async (ev: ChangeEvent<HTMLInputElement>) => {
    if (labs) {
      let temp = [...labCache];
      let index = temp.findIndex(lab => lab.name === ev.target.name);
      labs[index].selected = !labs[index].selected;
      setLabCache([...temp]);
    }
  };

  const handleLabSelectAll = async (ev: ChangeEvent<HTMLInputElement>) => {
    let temp = [...labCache];
    temp.forEach(lab => (lab.selected = !labSelectAll));
    setLabCache([...temp]);
    setLabSelectAll(!labSelectAll);
  };

  const handleSampleTypeSelection = async (ev: ChangeEvent<HTMLInputElement>) => {
    if (sampleTypes) {
      let temp = [...sampleTypeCache];
      let index = temp.findIndex(sampleType => sampleType.displayName === ev.target.name);
      sampleTypes[index].selected = !sampleTypes[index].selected;
      setSampleTypeCache([...temp]);
    }
  };

  const handleSampleTypeSelectAll = async (ev: ChangeEvent<HTMLInputElement>) => {
    let temp = [...sampleTypeCache];
    temp.forEach(sampleType => (sampleType.selected = !sampleTypeSelectAll));
    setSampleTypeCache([...temp]);
    setSampleTypeSelectAll(!sampleTypeSelectAll);
  };

  const handleConsentSelection = async (ev: ChangeEvent<HTMLInputElement>) => {
    if (consentEntitlements) {
      let temp = [...consentEntitlements];
      let index = temp.findIndex(consent => consent.description === ev.target.name);
      consentEntitlements[index].selected = !consentEntitlements[index].selected;
      setConsentEntitlementCache([...temp]);
    }
  };

  const handleButtonClick = async () => {
    if (onCountTypeSelect) onCountTypeSelect(countTypeCache);
    if (onDateAggregateSelect) onDateAggregateSelect(dateAggregateCache);
    if (onStartDateSelect) onStartDateSelect(startDateCache);
    if (onEndDateSelect) onEndDateSelect(endDateCache);
    if (onDiseaseAreaSelect) onDiseaseAreaSelect([...diseaseAreaCache]);
    if (onBiobankSelect) onBiobankSelect([...biobankCache]);
    if (onLabSelect) onLabSelect([...labCache]);
    if (onSampleTypeSelect) onSampleTypeSelect([...sampleTypeCache]);
    if (onConsentSelect) onConsentSelect([...consentEntitlementCache]);
  };

  return (
    <div className='historic-counts-filters'>
      <div className='historic-counts-filters-group-header'>Filter Chart Results</div>
      <div className='historic-counts-filters-group'>
        {countType && (
          <div className='historic-counts-filters-subgroup'>
            <div className='historic-counts-filters-radio'>
              <div className='historic-counts-filters-subgroup-header'>Count Type:</div>
              <CountTypeSelector defaultCountType={countTypeCache} onCountTypeClick={setCountTypeCache} />
            </div>
          </div>
        )}
        {dateAggregate && (
          <div className='historic-counts-filters-subgroup'>
            <div className='historic-counts-filters-radio'>
              <div className='historic-counts-filters-subgroup-header'>Data Aggregation:</div>
              <DateAggregateSelector defaultAggregate={dateAggregateCache} onAggregateClick={setDateAggregateCache} />
            </div>
          </div>
        )}
        <div className='historic-counts-filters-subgroup'>
          {startDate && (
            <div className='historic-counts-filters-date'>
              <div className='historic-counts-filters-subgroup-header'>Start Date:</div>
              <div>
                <DatePicker
                  format={'MMM D, YYYY'}
                  placeholder={startDateCache.toLocaleString('en-US', {
                    year: 'numeric',
                    month: 'short',
                    day: 'numeric',
                  })}
                  defaultPickerValue={moment(startDateCache)}
                  showToday={false}
                  disabledDate={date => date.toDate() > endDateCache}
                  onChange={date => setStartDateCache(date ? date.toDate() : new Date())}
                />
              </div>
            </div>
          )}
          {endDate && (
            <div className='historic-counts-filters-date'>
              <div className='historic-counts-filters-subgroup-header'>End Date:</div>
              <div>
                <DatePicker
                  format={'MMM D, YYYY'}
                  placeholder={endDateCache.toLocaleString('en-US', {
                    year: 'numeric',
                    month: 'short',
                    day: 'numeric',
                  })}
                  defaultPickerValue={moment(endDateCache)}
                  showToday={false}
                  disabledDate={date => date.toDate() < startDateCache || date.toDate() > new Date()}
                  onChange={date => setEndDateCache(date ? date.toDate() : new Date())}
                />
              </div>
            </div>
          )}
        </div>
        <Accordion>
          {diseaseAreas && (
            <DiseaseAreaFilter
              eventKey={`${FilterEventKeys.DiseaseAreas}`}
              toggleSelection={toggleSelection}
              setToggleSelection={setToggleSelection}
              diseaseAreas={diseaseAreas}
              handleDiseaseAreaSelection={handleDiseaseAreaSelection}
              diseaseAreaSelectAll={diseaseAreaSelectAll}
              handleDiseaseAreaSelectAll={handleDiseaseAreaSelectAll}
            />
          )}
          {biobanks && (
            <BiobankFilter
              eventKey={`${FilterEventKeys.Biobanks}`}
              toggleSelection={toggleSelection}
              setToggleSelection={setToggleSelection}
              biobanks={biobanks}
              handleBiobankSelection={handleBiobankSelection}
              biobankSelectAll={biobankSelectAll}
              handleBiobankSelectAll={handleBiobankSelectAll}
            />
          )}
          {labs && (
            <LabFilter
              eventKey={`${FilterEventKeys.Labs}`}
              toggleSelection={toggleSelection}
              setToggleSelection={setToggleSelection}
              labs={labs}
              handleLabSelection={handleLabSelection}
              labSelectAll={labSelectAll}
              handleLabSelectAll={handleLabSelectAll}
            />
          )}
          {sampleTypes && (
            <SampleTypeFilter
              eventKey={`${FilterEventKeys.SampleTypes}`}
              toggleSelection={toggleSelection}
              setToggleSelection={setToggleSelection}
              sampleTypes={sampleTypes}
              handleSampleTypeSelection={handleSampleTypeSelection}
              sampleTypeSelectAll={sampleTypeSelectAll}
              handleSampleTypeSelectAll={handleSampleTypeSelectAll}
            />
          )}
          {consentEntitlements && (
            <ConsentEntitlementFilter
              eventKey={`${FilterEventKeys.ConsentEntitlements}`}
              toggleSelection={toggleSelection}
              setToggleSelection={setToggleSelection}
              consentEntitlements={consentEntitlements}
              handleConsentSelection={handleConsentSelection}
            />
          )}
        </Accordion>
      </div>
      <button className='black-button' onClick={handleButtonClick}>
        Apply Filters
      </button>
    </div>
  );
};

export function CustomToggle({ children, eventKey, toggleSelection, setToggleSelection }: any) {
  const decoratedOnClick = useAccordionToggle(eventKey, () => {
    if (eventKey === toggleSelection) {
      setToggleSelection(undefined);
    } else {
      setToggleSelection(eventKey);
    }
  });

  return (
    <button type='button' className='historic-counts-filters-accordian-item' key={eventKey} onClick={decoratedOnClick}>
      {children}
    </button>
  );
}

export function getAccordianExpansionSymbolTest(isFilterSelected: boolean) {
  return isFilterSelected ? '-' : '+';
}

export function getAccordianExpansionSymbolCOPY(eventKey: string, toggleSelection: string | undefined) {
  return toggleSelection === eventKey ? '-' : '+';
}
