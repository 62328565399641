import { Page } from 'components/Page';
import { StatusText } from 'components/Styles';
import { useAuth0 } from '@auth0/auth0-react';
import { appSettings } from '../AppSettings';

type SignoutAction = 'signout' | 'signout-callback';
interface Props {
  action: SignoutAction;
}

export const SignOutPage = ({ action }: Props) => {
  let message = 'Signing out ...';
  const { logout } = useAuth0();
  logout({ returnTo: appSettings.auth.redirect_uri });

  return (
    <Page title='Sign out'>
      <StatusText>{message}</StatusText>
    </Page>
  );
};
