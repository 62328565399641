import { Box, Typography } from '@mui/material';
import { PageTitleWrapper } from './PageTitleWrapper';
import React from 'react';
import { GoBack } from './GoBack';
import { FlexBox } from './FlexBox';

export interface PageHeaderProps {
  title: string;
  subtitle?: string;
  goBackUrl?: string;
}

export const PageHeader = ({ title, subtitle, goBackUrl }: PageHeaderProps) => {
  return (
    <PageTitleWrapper>
      <Box display='flex' alignItems='center'>
        <Box whiteSpace='pre-wrap'>
          <FlexBox flexDirection={'row'} alignItems={'center'}>
            {goBackUrl && <GoBack url={goBackUrl} />}
            <Typography variant='h3' component='h3' gutterBottom>
              {title}
            </Typography>
          </FlexBox>
          {subtitle && <Typography variant='subtitle2'>{subtitle}</Typography>}
        </Box>
      </Box>
    </PageTitleWrapper>
  );
};
