import { styled, Tooltip, tooltipClasses, TooltipProps } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';

export function renderCellTooltip(params: GridRenderCellParams) {
  return <GridCellTooltip value={params.value ?? ''} width={params.colDef.computedWidth} />;
}

interface GridCellTooltipProps {
  value: string;
  width: number;
}

const GridCellTooltip = ({ value }: GridCellTooltipProps) => {
  return (
    <LightTooltip title={value}>
      <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{value}</div>
    </LightTooltip>
  );
};

export const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} placement='bottom-start' classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
    fontSize: 14,
    whiteSpace: 'pre-line',
  },
}));
