import { Button } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import DownloadDoneIcon from '@mui/icons-material/DownloadDone';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
import LaunchIcon from '@mui/icons-material/Launch';
import { useState } from 'react';
import useAuth from 'auth/UseAuth';
import { appSettings } from 'AppSettings';
import { BaseDownloadFile } from 'data/BaseData';
import { DownloadFile, FileProcessingState } from 'helpers/FileProcessing';

export function renderCellMultiQcDownloadButton(params: GridRenderCellParams) {
  if (params.value === undefined) {
    return <></>;
  }

  return <GridCellMultiQcDownloadButton sequenceRunId={params.value} />;
}

interface GridCellMultiQcDownloadButtonProps {
  sequenceRunId: string;
}

const GridCellMultiQcDownloadButton = ({ sequenceRunId }: GridCellMultiQcDownloadButtonProps) => {
  const { accessToken } = useAuth();

  const [fileProcessingState, setFileProcessingState] = useState<FileProcessingState>({ status: 'NotStarted' });

  const invokeGenerator = async () => {
    let url = `${appSettings.api.endpoint}/api/v2/Informatics/${sequenceRunId}`;
    DownloadFile(setFileProcessingState, () => BaseDownloadFile(url, accessToken));
  };

  return (
    <Button
      onClick={invokeGenerator}
      sx={[
        {
          '&:hover': {
            backgroundColor: 'transparent',
          },
        },
      ]}
    >
      {fileProcessingState.status === 'NotStarted' && <LaunchIcon />}
      {fileProcessingState.status === 'Downloading' && <HourglassBottomIcon />}
      {fileProcessingState.status === 'Complete' && <DownloadDoneIcon />}
      {fileProcessingState.status === 'Error' && <ErrorOutlineIcon />}
    </Button>
  );
};
