import { GetSnomedClinicalTermPatientCounts, SnomedClinicalTermPatientCount } from 'data/SnomedClinicalTermData';

export interface PathogenGroupOption {
  label: string;
  snomedClinicalTerm: SnomedClinicalTermPatientCount;
}

export async function GetPathogenGroupOptions(accessToken: string) {
  const snomedClinicalTerms = await GetSnomedClinicalTermPatientCounts(accessToken);

  return snomedClinicalTerms.map(sct => ({
    label: `${sct.term} (${sct.conceptId})`,
    snomedClinicalTerm: sct,
  }));
}
